import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

export const Header = styled('nav')`
  background: ${({ theme }) => theme.header.background};
  grid-area: header;
  height: ${({ theme }) => theme.header.height}px;
  z-index: 11;
`;

export const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 25px;
`;

export const Right = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrgSelectorWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 25px;
`;

export const OrgSelectorInput = styled(InputBase)`
  & .MuiInputBase-input {
    padding: 8px;
    background-color: ${({ theme }) =>
      theme.header.organizationSelectorBackground};
    color: ${({ theme }) => theme.header.profileName};
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.header.profileName};
  }

  & .MuiList-root {
    padding: 0;
  }
`;
