import ResourcesStore from '@stores/resourcesStore';
import { getFileFromLink } from '@utils/fileUtils';

export class SpeakerCloneService {
  static prepareSpeaker = async speakerId => {
    const spk = await ResourcesStore.getSpeaker({ id: speakerId });

    const imgs = this.fetchSpeakerImages(spk);
    const { id, event_pos, ...strippedSpk } = spk;

    return {
      ...strippedSpk,
      image: await imgs.image,
    };
  };

  static fetchSpeakerImages = spk => {
    return {
      image: getFileFromLink(spk.image?.url),
    };
  };
}
