import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@api';
import routerStore from '@stores/routerStore';
import routes from '@routes';

const initialState = {
  isLoading: false,
  chatQuestion: null,
  defaultFormValues: {},
  initialLoading: true,
  formType: null,
};

class ChatQuestionStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateChatQuestion(...props);
    }

    return null;
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = { ...this.chatQuestion };
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      const promises = [];

      if (id) {
        promises.push(this.getChatQuestion({ id }));
      }

      await Promise.all(promises);

      if (id) {
        this.setupDefaultFormValues();
      }
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action getChatQuestion = async ({ id }) => {
    try {
      this.chatQuestion = await ResourcesStore.getChatQuestion(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getChatQuestion] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.chatQuestions);
      }
      throw err;
    }
  };

  @action updateChatQuestion = async ({ id, values, onSuccess }) => {
    this.isLoading = true;

    try {
      const res = await ResourcesStore.updateChatQuestion({
        id,
        payload: values,
      });

      await onSuccess?.(res);

      this.chatQuestion = res;
      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateChatQuestion] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteChatQuestion = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteChatQuestion({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteChatQuestion] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ChatQuestionStore();
