import ResourcesStore from '@stores/resourcesStore';
import { getFileFromLink } from '@utils/fileUtils';

export class SponsorCloneService {
  static prepareSponsor = async spoId => {
    const spo = await ResourcesStore.getSponsor({ id: spoId });

    const imgs = this.fetchSponsorImages(spo);
    const { id, event_pos, agenda_item_ids, ...strippedSpo } = spo;

    return {
      ...strippedSpo,
      logo: await imgs.logo,
    };
  };

  static fetchSponsorImages = spo => {
    return {
      logo: getFileFromLink(spo.logo?.url),
    };
  };
}
