import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
import TextField from '@components/TextField';
import Box from '@mui/material/Box';
import FieldTooltip from '@components/FieldTooltip';

const TextInput = ({
  input,
  meta,
  infoText,
  sx,
  fieldType,
  disabled,
  notAvailableInSP,
  ...props
}) => {
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TextField
        sx={{
          ...sx,
        }}
        error={Boolean(error)}
        helperText={error}
        size="small"
        fullWidth
        type={fieldType || undefined}
        disabled={Boolean(disabled)}
        {...input}
        {...props}
        onChange={e =>
          input.onChange(
            sanitizeHtml(e.target.value).replace(/javascript:/gim, ''),
          )
        }
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default TextInput;
