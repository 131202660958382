import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import InnerPage from '@app/components/InnerPage';
import CreateEvent from '@pages/dashboard/components/CreateEvent';
import PreviewEvents from '@pages/dashboard/components/PreviewEvents';
import BottomPanel from '@pages/dashboard/components/BottomPanel';
import styled from '@emotion/styled';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  padding-top: 20px;
  padding-bottom: 10px;
`;

const DashboardPage = ({
  dashboardPageStore: { setupData, clearStore, showCreate, isLoading },
}) => {
  useEffect(() => {
    setupData();
    return clearStore;
  }, []);

  return (
    <InnerPage noHeader>
      {!isLoading && !showCreate && (
        <Box>
          <Title>Dashboard</Title>
          <Divider light sx={{ borderBottomWidth: 2 }} />
        </Box>
      )}

      <Stack spacing={6} sx={{ marginTop: '20px' }}>
        {isLoading && (
          <Skeleton variant="rectangular" width="100%" height="400px" />
        )}
        {!isLoading && (
          <Box>{showCreate ? <CreateEvent /> : <PreviewEvents />}</Box>
        )}
        <Divider light sx={{ borderBottomWidth: 2 }} />
        <BottomPanel />
      </Stack>
    </InnerPage>
  );
};
export default inject('dashboardPageStore')(observer(DashboardPage));
