import React from 'react';
import { inject, observer } from 'mobx-react';
import { Field, Form } from 'react-final-form';
import { Button, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { required, composeValidators } from '@app/formValidators';
import { Loader } from '@components/Loader';
import { FieldGroup } from '@components/_form/FieldGroup';
import { FieldWrapper } from '@components/_form/FieldWrapper';
import { CloningLayer } from '@components/CloningLayer';
import AutocompleteInput from '@components/formFields/AutocompleteInput';

const CloningBannerOptionsModal = ({
  modalStore: { closeModal },
  bannerStore: { phase, cloneRunning },
  events,
  onClone,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          background: '#fff',
          padding: '40px',
          width: '650px',
          position: 'relative',
        }}
      >
        <Fade in={cloneRunning} timeout={300}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <CloningLayer />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <Loader width="50px" />
              <h2>Cloning {phase}...</h2>
            </Box>
          </Box>
        </Fade>
        <h1>Cloning banner options</h1>
        <Box display="flex" flexDirection="column" mt="20px">
          <Form
            onSubmit={values => {
              onClone({
                ...values,
                event_id: values.event_id?.value || undefined,
              });
            }}
            validate={values => {
              const errors = {
                event_id: required(values.event_id),
              };

              return errors;
            }}
          >
            {({ handleSubmit }) => {
              return (
                <>
                  <FieldGroup>
                    <FieldWrapper>
                      <Field
                        name="event_id"
                        label="Event"
                        required
                        options={events}
                        validate={composeValidators(required)}
                        component={AutocompleteInput}
                        isOptionEqualToValue={(option, value) =>
                          option?.value === value?.value
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option.value}>
                            {option.label}
                          </li>
                        )}
                      />
                    </FieldWrapper>
                  </FieldGroup>
                  <Stack direction="row" gap="15px" mt="20px">
                    <Button
                      onClick={closeModal}
                      variant="outlined"
                      color="dark"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Clone
                    </Button>
                  </Stack>
                </>
              );
            }}
          </Form>
        </Box>
      </Box>
    </Box>
  );
};

CloningBannerOptionsModal.propTypes = {
  onClone: PropTypes.func.isRequired,
};

export default inject(
  'modalStore',
  'bannerStore',
)(observer(CloningBannerOptionsModal));
