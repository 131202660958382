import { action, extendObservable, computed } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { prepareSelectOptions } from '@utils/formUtils';
import { FORM_TYPES, TRANSLATION_LANG } from '@constants';
import { CancelToken } from '@app/api';

const initialState = {
  isLoading: false,
  events: [],
  organizations: [],
  translation: {},
  translationJson: {},
  defaultFormValues: {},
  formType: null,
};
export class TranslationStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }
    this.getResources({ id });
  };

  @action submitForm = (...props) => {
    if (this.isEdit) {
      return this.updateTranslation(...props);
    }
    if (this.isCreate) {
      return this.createTranslation(...props);
    }

    return null;
  };

  @action getTranslation = async ({ id }) => {
    try {
      this.translation = await ResourcesStore.getTranslation(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (error) {
      console.debug('[getTranslation] failed', error);
    }
  };

  @action deleteTranslation = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;

      await ResourcesStore.deleteTranslation({ id, onSuccess, onError });
    } catch (error) {
      console.debug('[deleteTranslation] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateTranslation = async ({ values, id, onSuccess, ...props }) => {
    try {
      this.isLoading = true;

      const onS = payload => {
        if (onSuccess) onSuccess(payload);
        this.translation = payload;

        this.setupDefaultValues();
        this.getTranslationJson();
      };

      const { event_ids, organization_ids, file, lang, ...rest } = values;

      const payload = {
        ...rest,
        lang: lang.value,
        resource_translations_attributes: [
          ...this.getMappedEvents(event_ids),
          ...this.getMappedOrganizations(organization_ids),
        ],
      };

      const result = await ResourcesStore.updateTranslation({
        payload,
        id,
        onSuccess: onS,
        ...props,
      });

      onSuccess(result);
      this.translation = result;

      this.setupDefaultValues();
      this.getTranslationJson();
      return null;
    } catch (err) {
      if (err.response.data.errors) {
        await this.getTranslation({ id: this.translation.id });
        this.setupDefaultValues();
        return this.filterErrors(
          err.response.data.errors.resource_translations,
        );
      }
      return null;
    } finally {
      this.isLoading = false;
    }
  };

  @action createTranslation = async ({ values, onSuccess, ...props }) => {
    try {
      this.isLoading = true;

      const { event_ids, organization_ids, lang, ...rest } = values;

      const eventResourcesMapped = (event_ids || []).map(ev => ({
        translatable_type: 'Event',
        translatable_id: ev.value,
      }));

      const orgResourcesMapped = (organization_ids || []).map(org => ({
        translatable_type: 'Organization',
        translatable_id: org.value,
      }));

      const payload = {
        ...rest,
        lang: lang.value,
        resource_translations_attributes: [
          ...eventResourcesMapped,
          ...orgResourcesMapped,
        ],
      };

      const result = await ResourcesStore.createTranslation({
        payload,
        ...props,
      });

      await onSuccess(result);

      return null;
    } catch (err) {
      if (err.response.data.errors) {
        return this.filterErrors(
          err.response.data.errors.resource_translations,
        );
      }
      return null;
    } finally {
      this.isLoading = false;
    }
  };

  @action filterErrors = errors => {
    const final = errors.reduce(
      (prev, err) =>
        err.translatable_type === 'Event'
          ? { ...prev, event_ids: `${prev.event_ids || ''}${err.errors[0]}\n` }
          : {
              ...prev,
              organization_ids: [
                `${prev.organization_ids || ''}${err.errors[0]}\n`,
              ],
            },
      {},
    );

    return final;
  };

  @action getResources = async ({ id }) => {
    try {
      this.isLoading = true;

      await Promise.allSettled([
        this.getEventSelectOptions(),
        this.getOrganizations(),
      ]);
      if (this.isEdit) {
        await this.getTranslation({ id });
        await this.getTranslationJson();
        this.setupDefaultValues();
      }
    } catch (error) {
      console.debug('[getResources] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getTranslationJson = async () => {
    this.translationJson = await ResourcesStore.getJsonTranslation(
      {
        lang: this.translation?.lang,
        customTranslationUrl: this.translation?.file?.url,
      },
      { cancelToken: this.__cancelToken?.token },
    );
  };

  @action setupDefaultValues = () => {
    const { resource_translations, ...rest } = this.translation;
    const mappedResources = resource_translations.reduce(
      (prev, resource) => {
        const {
          translatable_type: type,
          translatable_id: id,
          id: translationId,
        } = resource;

        if (type === 'Event') {
          prev.event_ids = [
            ...(prev.event_ids || []),
            {
              ...this.events.find(ev => ev.value === id),
              translationId,
            },
          ];
        } else if (type === 'Organization') {
          prev.organization_ids = [
            ...(prev.organization_ids || []),
            {
              ...this.organizations.find(org => org.value === id),
              translationId,
            },
          ];
        }

        return prev;
      },
      {
        event_ids: null,
        organization_ids: null,
      },
    );
    const langName = Object.keys(TRANSLATION_LANG).find(
      key => TRANSLATION_LANG[key] === rest.lang,
    );

    this.defaultFormValues = {
      ...rest,
      ...mappedResources,
      lang: { value: rest.lang, label: langName },
    };
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.events = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (error) {
      console.debug('[getEventSelectOptions] failed', error);
    }
  };

  @action getMappedEvents = (events = []) => {
    if (events === null) {
      return [];
    }
    const removedEvents = (this.defaultFormValues.event_ids || []).filter(
      ev => !events.find(newEv => newEv.value === ev.value),
    );

    const newAddedEvents = events.filter(
      ev =>
        !(this.defaultFormValues.event_ids || []).find(
          def => def.value === ev.value,
        ),
    );

    const eventResourcesMapped = newAddedEvents.map(ev => ({
      translatable_type: 'Event',
      translatable_id: ev.value,
    }));

    const eventResourcesDeleted = removedEvents.map(ev => ({
      id: ev.translationId,
      _destroy: 1,
    }));

    return [...eventResourcesDeleted, ...eventResourcesMapped];
  };

  @action getDefaultLanguageTranslation = async lang => {
    this.isDefaultLangLoading = true;
    this.translationJson = {};

    const results = await ResourcesStore.getJsonTranslation({
      lang,
    });

    this.translationJson = results;
    this.isDefaultLangLoading = false;
  };

  @action getMappedOrganizations = (orgs = []) => {
    if (orgs === null) {
      return [];
    }
    const removedOrgs = (this.defaultFormValues.organization_ids || []).filter(
      org => !orgs.find(newOrg => newOrg.value === org.value),
    );

    const newAddedOrgs = orgs.filter(
      org =>
        !(this.defaultFormValues.organization_ids || []).find(
          def => def.value === org.value,
        ),
    );

    const orgResourcesMapped = newAddedOrgs.map(ev => ({
      translatable_type: 'Organization',
      translatable_id: ev.value,
    }));

    const orgResourcesDeleted = removedOrgs.map(ev => ({
      id: ev.translationId,
      _destroy: 1,
    }));

    return [...orgResourcesDeleted, ...orgResourcesMapped];
  };

  @action getOrganizations = async () => {
    try {
      const { results } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });
      this.organizations = prepareSelectOptions(
        results,
        el => el.name,
        el => el.id,
      );
    } catch (error) {
      console.debug('[getOrganizations] failed', error);
    }
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new TranslationStore();
