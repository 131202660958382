import { inject, observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldGroup } from '@components/_form/FieldGroup';
import { Field, useForm, useFormState } from 'react-final-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AutocompleteMultiInput from '@components/formFields/AutocompleteMultiInput';
import AutocompleteInput from '@components/formFields/AutocompleteInput';

export const ASSIGNMENT_TYPE = {
  EVENT: 1,
  AGENDA: 2,
  GROUP: 3,
};

const Assignments = ({
  types,
  assignmentsStore: { events, agendaItems, groups },
  requiredEvent,
}) => {
  const form = useForm();
  const { values } = useFormState({
    subscriptions: { values: true, errors: true },
  });
  const [showAgendas, setShowAgendas] = useState(
    !!values.agenda_item_assignments?.length,
  );

  const [showGroups, setShowGroups] = useState(
    !!values.group_item_assignments?.length,
  );

  const filteredAgendaItems = useMemo(
    () => agendaItems.list.filter(ai => ai.event_id === values.event_id?.value),
    [values.event_id?.value, agendaItems.list],
  );

  useEffect(() => {
    const field = form.getFieldState('agenda_item_assignments')?.value;

    const formAgenda = field?.filter(
      ai => ai.event_id === values.event_id?.value,
    );

    form.change('agenda_item_assignments', formAgenda);
  }, [values.event_id]);

  const filteredGroups = useMemo(
    () =>
      groups.list.filter(grp =>
        values.agenda_item_assignments?.find(
          ai => ai.value === grp.agenda_item_id,
        ),
      ),
    [groups.list, values.agenda_item_assignments],
  );

  useEffect(() => {
    const field = form.getFieldState('group_item_assignments')?.value;

    const formGroup = field?.filter(grp =>
      values.agenda_item_assignments?.find(
        fAi => fAi.value === grp.agenda_item_id,
      ),
    );

    form.change('group_item_assignments', formGroup);
  }, [values.agenda_item_assignments]);

  useEffect(() => {
    if (showGroups === true) {
      setShowGroups(!!filteredGroups.length);
    }
  }, [filteredGroups]);

  return (
    <Box width="100%">
      {types[ASSIGNMENT_TYPE.EVENT] && (
        <>
          <FieldGroup noPadding>
            <Field
              name="event_id"
              label="Event"
              required={requiredEvent}
              options={events.list}
              component={AutocompleteInput}
              infoText="Select event to assign resource to"
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </FieldGroup>

          {showAgendas && types[ASSIGNMENT_TYPE.AGENDA] && (
            <>
              <FieldGroup>
                <Field
                  name="agenda_item_assignments"
                  label="Agenda items and/or breakout rooms"
                  options={filteredAgendaItems}
                  component={AutocompleteMultiInput}
                  infoText="Select Agenda item so resource will be visible only during selected Agenda items"
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  combineSelectedAndFilter
                  getOptionLabel={option => `
                        ${
                          option.agenda_type === 'agenda_item' ? '[AI]' : '[BR]'
                        }
                      ${option.label}`}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      <Box
                        display="inline"
                        fontWeight="fontWeightMedium"
                        mr="7px"
                        color="primary.main"
                      >
                        {option.agenda_type === 'agenda_item' ? 'AI' : 'BR'}
                      </Box>
                      {option.label}
                    </li>
                  )}
                />
              </FieldGroup>

              {showGroups && types[ASSIGNMENT_TYPE.GROUP] && (
                <FieldGroup>
                  <Field
                    name="group_item_assignments"
                    label="Groups"
                    options={filteredGroups}
                    component={AutocompleteMultiInput}
                    infoText="Select Group so resource will be visible only during selected Groups"
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value?.value
                    }
                    getOptionLabel={option => option.label || option.value}
                    combineSelectedAndFilter
                    renderOption={(props, option) => (
                      <li {...props} key={option.value}>
                        {option.label || option.value}
                      </li>
                    )}
                  />
                </FieldGroup>
              )}

              {!showGroups && types[ASSIGNMENT_TYPE.GROUP] && (
                <Box width="fit-content" mt="20px">
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={!filteredGroups.length}
                    onClick={() => setShowGroups(true)}
                  >
                    Assign to groups
                  </Button>
                </Box>
              )}
            </>
          )}

          {!showAgendas && types[ASSIGNMENT_TYPE.AGENDA] && (
            <Box width="fit-content" mt="20px">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setShowAgendas(true)}
              >
                Assign to agenda item
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default inject('assignmentsStore')(observer(Assignments));
