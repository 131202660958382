import { action, observable } from 'mobx';
import { MOBILE_BREAKPOINT } from '../_styles/breakpoints';

export class LayoutStore {
  @observable menuOpened = false;

  @observable width = null;

  @observable isMobile = null;

  @observable sidenavOpen = true;

  @action setMenuOpened = async val => {
    this.menuOpened = val;
  };

  @action updateDimension = async width => {
    this.width = width;

    this.isMobile = width <= MOBILE_BREAKPOINT;
  };

  @action setSidenavOpen = val => {
    this.sidenavOpen = val;
  };

  @action clearStore = async () => {
    this.menuOpened = false;
    this.width = null;
    this.isMobile = null;
    this.sidenavOpen = false;
  };
}

export default new LayoutStore();
