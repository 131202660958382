import routes from '@routes';

export const WILDCARD_ACCESS = '*';

export const ROLES = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  MODERATOR: 'moderator',
  USER: 'user',
  ANALYST: 'analyst',
  EVENT_MANAGER: 'event_manager',
  SHARED_ORG_ADMIN: 'lite_shared_org_admin',
};

export const ROLES_ACCESS_LIST = {
  [ROLES.ADMIN]: [WILDCARD_ACCESS],
  [ROLES.MODERATOR]: [
    routes.main.chatMessages,
    routes.chatMessage(),

    routes.main.chatAbuses,

    routes.main.chatQuestions,
    routes.chatQuestion.edit,

    routes.main.chatRooms,
    routes.chatRoom(),

    routes.main.exploreItems,
    routes.exploreItems(),

    routes.main.ratings,
    routes.rating(),
    routes.main.ratingsVotes,

    routes.main.polls,
    routes.poll(),
    routes.main.pollAnswerUsers,

    routes.main.globalMessages,
    routes.globalMessage(),

    routes.unauthorized,
    routes.emailVerificationError,
    routes.main.profile,
  ],
  [ROLES.EDITOR]: [
    routes.main.analytics(),
    routes.main.analytics_report(),

    routes.main.chatMessages,
    routes.chatMessage(),

    routes.main.chatAbuses,

    routes.main.attendances,
    routes.attendance(),

    routes.main.chatQuestions,
    routes.chatQuestion.edit,

    routes.main.chatRooms,
    routes.chatRoom(),

    routes.main.exploreItems,
    routes.exploreItems(),

    routes.main.ratings,
    routes.rating(),
    routes.main.ratingsVotes,

    routes.main.polls,
    routes.poll(),
    routes.main.pollAnswerUsers,

    routes.main.banners,
    routes.banner(),

    routes.main.dashboard,

    routes.main.events,
    routes.event(),

    routes.main.agenda,
    routes.agendaItems(),

    routes.main.breakout,
    routes.breakoutRooms(),

    routes.main.groups,
    routes.group(),

    routes.main.players,
    routes.player(),

    routes.main.documents,
    routes.document(),

    routes.main.presenters,
    routes.presenter(),

    routes.main.sponsors,
    routes.sponsor(),

    routes.main.products,
    routes.product(),

    routes.main.translations,
    routes.translation(),

    routes.main.globalMessages,
    routes.globalMessage(),

    routes.unauthorized,
    routes.emailVerificationError,
    routes.main.profile,
  ],
  [ROLES.ANALYST]: [
    routes.main.analytics(),
    routes.main.analytics_report(),
    routes.main.reports(),

    routes.main.profile,
    routes.unauthorized,
    /*
      This route is used in onboarding process.
      Currently analyst is not considered on onboarding process.
    */
    // routes.emailVerificationError,
  ],
  [ROLES.SHARED_ORG_ADMIN]: [
    routes.main.analytics(),
    routes.main.analytics_report(),

    routes.main.chatMessages,
    routes.chatMessage(),

    routes.main.chatAbuses,

    routes.main.attendances,
    routes.attendance(),

    routes.main.chatRooms,
    routes.chatRoom(),

    routes.main.exploreItems,
    routes.exploreItems(),

    routes.main.ratings,
    routes.rating(),
    routes.main.ratingsVotes,

    routes.main.polls,
    routes.poll(),
    routes.main.pollAnswerUsers,

    routes.main.dashboard,

    routes.main.events,
    routes.event(),

    routes.main.agenda,
    routes.agendaItems(),

    routes.main.breakout,
    routes.breakoutRooms(),

    routes.main.groups,
    routes.group(),

    routes.main.banners,
    routes.banner(),

    routes.main.products,
    routes.product(),

    /*

    Shared org admin shouldn't have access to this
    sections as the contect of subscription plan 
    is being lost. Eg. Lite plan on event - products appears 
    in left panel after leaving event settings.

    routes.main.players,
    routes.player(),
    */

    routes.main.documents,
    routes.document(),

    routes.main.presenters,
    routes.presenter(),

    routes.main.sponsors,
    routes.sponsor(),

    routes.main.translations,
    routes.translation(),

    routes.main.globalMessages,
    routes.globalMessage(),

    routes.unauthorized,
    routes.emailVerificationError,
    routes.main.profile,

    routes.main.eventPayment(),
  ],
  [ROLES.USER]: [],
  [ROLES.EVENT_MANAGER]: [
    routes.main.chatMessages,
    routes.chatMessage(),

    routes.main.chatAbuses,

    routes.main.chatQuestions,
    routes.chatQuestion.edit,

    routes.main.chatRooms,
    routes.chatRoom(),

    routes.main.exploreItems,
    routes.exploreItems(),

    routes.main.ratings,
    routes.rating(),
    routes.main.ratingsVotes,

    routes.main.polls,
    routes.poll(),
    routes.main.pollAnswerUsers,

    routes.main.banners,
    routes.banner(),

    routes.main.dashboard,

    routes.main.events,
    routes.event(),

    routes.main.agenda,
    routes.agendaItems(),

    routes.main.breakout,
    routes.breakoutRooms(),

    routes.main.groups,
    routes.group(),

    routes.main.players,
    routes.player(),

    routes.main.documents,
    routes.document(),

    routes.main.presenters,
    routes.presenter(),

    routes.main.sponsors,
    routes.sponsor(),

    routes.main.products,
    routes.product(),

    routes.main.translations,
    routes.translation(),

    routes.main.globalMessages,
    routes.globalMessage(),

    routes.unauthorized,
    routes.emailVerificationError,
    routes.main.profile,
  ],
};
