import React, { useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { styled } from '@mui/material/styles';
import CKEditorBuild from 'ckeditor-light';
import Box from '@mui/material/Box';
import '@components/_form/CKEditor/customContentStyles.css';
import { UploadAdapterPlugin } from '@components/_form/CKEditor/UploadAdapter';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormState } from 'react-final-form';
import { checkFontItalicsAvailable } from '@utils/otherUtils';
import FieldTooltip from '@components/FieldTooltip';

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: inline-block;
  p,
  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  ul,
  ol {
    padding-inline-start: 40px;
  }
`;

const CKEditorConfig = {
  extraPlugins: [UploadAdapterPlugin],
  toolbar: {
    items: [
      'textPartLanguage',
      'heading',
      'bold',
      'italic',
      'strikethrough',
      'bulletedList',
      'numberedList',
      'underline',
      'subscript',
      'superscript',
      'horizontalLine',
      '|',
      'alignment',
      'link',
      '-',
      'insertTable',
      'mediaEmbed',
      'htmlEmbed',
      'imageUpload',
      '|',
      'undo',
      'redo',
      'removeFormat',
    ],
    shouldNotGroupWhenFull: true,
  },
  ui: {
    viewportOffset: { top: 88 },
  },
  mediaEmbed: {
    previewsInData: true,
    removeProviders: [
      'facebook',
      'instagram',
      'twitter,',
      'googleMaps',
      'flickr',
    ],
  },
  image: {
    toolbar: [
      'resizeImage',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignRight',
      '|',
      'linkImage',
    ],
    resizeOptions: [
      {
        name: 'resizeImage:original',
        value: null,
      },
      {
        name: 'resizeImage:25',
        value: '25',
      },
      {
        name: 'resizeImage:50',
        value: '50',
      },
      {
        name: 'resizeImage:75',
        value: '75',
      },
    ],
    styles: [
      { name: 'alignRight', icon: 'right' },
      { name: 'alignLeft', icon: 'left' },
      { name: 'alignCenter', icon: 'center' },
    ],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
    ],
    tableProperties: {
      alignment: 'left',
      width: '100%',
    },
  },
  link: {
    decorators: [
      {
        mode: 'manual',
        label: 'External Link',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    ],
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5',
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6',
      },
    ],
  },
  language: {
    textPartLanguage: [
      { title: 'Arabic', languageCode: 'ar' },
      { title: 'Hebrew', languageCode: 'he' },
    ],
  },
};

const CKEditorConfigSimple = {
  extraPlugins: [UploadAdapterPlugin],
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'strikethrough',
      'bulletedList',
      'numberedList',
      'underline',
      'subscript',
      'superscript',
      'horizontalLine',
      'alignment',
      'removeFormat',
    ],
    shouldNotGroupWhenFull: true,
  },
  ui: {
    viewportOffset: { top: 88 },
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5',
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6',
      },
    ],
  },
};

// Check https://github.com/ckeditor/ckeditor5-react/issues/197 and update @ckeditor/ckeditor5-react when error is fixed
const CKEditorInput = ({
  meta,
  input,
  label,
  required,
  customEditorConfig,
  isHeaderText,
  simpleConfig,
  infoText,
  disabled,
}) => {
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  const { values } = useFormState();

  const italicsUnavailable = useMemo(() => {
    checkFontItalicsAvailable(values);
  }, [
    values?.event_custom_theme?.header_font_family,
    values?.event_custom_theme?.header_font_family,
  ]);

  return (
    <Box>
      <Box position="relative" width="100%">
        {label && (
          <FormLabel
            sx={{ mb: '5px', display: 'block' }}
            required={required}
            error={Boolean(error)}
          >
            {label}
          </FormLabel>
        )}
        <FieldWrapper>
          <CKEditor
            disabled={disabled}
            data={input.value}
            editor={customEditorConfig || CKEditorBuild}
            onBlur={(_, editor) => input.onChange(editor.getData())}
            config={simpleConfig ? CKEditorConfigSimple : CKEditorConfig}
          />
        </FieldWrapper>
        {infoText && <FieldTooltip infoText={infoText} corner />}
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}

      {((italicsUnavailable?.header && isHeaderText) ||
        (italicsUnavailable?.body && !isHeaderText)) && (
        <FormHelperText error>
          *italics not supported for selected font
        </FormHelperText>
      )}
    </Box>
  );
};

export default CKEditorInput;
