import { action, extendObservable } from 'mobx';
import ResourcesStore from './resourcesStore';

const initialState = {
  isLoading: false,
  eventsSelectOptions: [],
};

export class EventRefreshStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action refreshFrontend = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;

      await ResourcesStore.refreshFrontend({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[refreshFrontend] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();

      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new EventRefreshStore();
