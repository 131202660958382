import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  isDeletingId: null,
  chatRooms: null,
  eventsSelectOptions: [],
};

export class ChatRoomsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getChatRooms = async ({ page, search, per, silent, chatable_id }) => {
    try {
      if (!silent) this.isLoading += 1;

      const res = await ResourcesStore.getChatRooms({
        page,
        search,
        per,
        chatable_id,
      });

      this.chatRooms = {
        ...res,
        search,
        page,
        chatable_id,
      };
    } catch (err) {
      console.debug('[getChatRooms] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'chatable_id'].every(
      param => params[param] === this.chatRooms?.[param],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action deleteChatRoom = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteChatRoom({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteChatRoom] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new ChatRoomsPageStore();
