import React from 'react';
import InnerPage from '@components/InnerPage';
import { TabPanel } from '@components/TabPanel';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useTabs } from '@utils/useTabs';
import { CustomTabs } from '@components/CustomTabs';
import { Box } from '@mui/material';
import { BUTTON_ICON_TYPES } from '@constants';
import routes from '@routes';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import EventsPageTab from './EventsPageTab';
import { COLUMNS } from './columnsDefinition';

const HEADER_BUTTONS = [
  {
    label: 'Add event',
    redirectPath: routes.event('create'),
    icon: BUTTON_ICON_TYPES.CREATE,
  },
];

const tabsConfig = [
  { value: 'upcoming', label: 'Upcoming events', Icon: EventAvailableIcon },
  { value: 'past', label: 'Past events', Icon: EventBusyIcon },
  { value: 'live', label: 'Live events', Icon: LiveTvIcon },
];

const overrideParams = () => ({
  page: 1,
  search: undefined,
  sort: undefined,
  organization: undefined,
});

const EventsPage = () => {
  const { tab } = useTabs(tabsConfig[0].value);
  return (
    <InnerPage
      pageTitle="Events"
      Tabs={
        <CustomTabs tabsConfig={tabsConfig} overrideParamsFn={overrideParams} />
      }
      headerButtons={HEADER_BUTTONS}
    >
      <Box>
        <TabPanel index={0} visible={tab === tabsConfig[0].value}>
          <EventsPageTab columns={COLUMNS} scope="upcoming" tab={tab} />
        </TabPanel>
        <TabPanel index={1} visible={tab === tabsConfig[1].value}>
          <EventsPageTab columns={COLUMNS} scope="past" tab={tab} />
        </TabPanel>
        <TabPanel index={2} visible={tab === tabsConfig[2].value}>
          <EventsPageTab columns={COLUMNS} scope="live" tab={tab} />
        </TabPanel>
      </Box>
    </InnerPage>
  );
};

export default EventsPage;
