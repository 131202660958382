import {
  composeValidators,
  required,
  maxEventNameLength,
} from '@app/formValidators';
import { styled } from '@mui/material/styles';
import { Loader } from '@components/Loader';
import DatePickerInput from '@components/formFields/DatePickerInput';
import { FieldGroup } from '@components/_form/FieldGroup';
import EventSlugs from '@components/formFields/EventSlugs';
import EventPreviewLinks from '@components/EventPreviewLinks';
import SwitchInput from '@components/formFields/SwitchInput';
import Fade from '@mui/material/Fade';
import { useTheme, Box, Stack, Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import urlSlug from 'url-slug';
import { constructBaseUrl } from '@utils/otherUtils';
import PropTypes from 'prop-types';
import TextInputWithLimit from '@components/formFields/TextInputWithLimit';
import { CloningLayer } from '@components/CloningLayer';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import AutocompleteInput from '@components/formFields/AutocompleteInput';
import Select from '@components/formFields/Select';
import { LANG_SELECT_OPTIONS } from '@constants';
import { orgAutocompleteOptions } from '@pages/events';
import { AdditionalInfo } from '@components/_form/AdditionalInfo';

const CopyButton = styled('button')`
  background: ${({ theme }) => `${theme.colors.error}15`};
  padding: 5px 7px;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  border: none;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => `${theme.colors.error}35`};
  }

  &:active {
    background: ${({ theme }) => `${theme.colors.error}55`};
  }
`;

const CloningEventOptionsModal = ({
  modalStore: { closeModal },
  eventStore: { cloneRunning, organizations, currentOrganization },
  onClone,
}) => {
  const theme = useTheme();

  const initialState = useMemo(
    () => ({
      event_resource_params: {
        planned_start_date: moment(),
        organization_id: (organizations || []).find(
          o => o.id === currentOrganization.id,
        ),
        mm_language: '',
      },
      with_resources: {
        event_custom_theme: true,
      },
    }),
    [organizations, currentOrganization.id],
  );

  const baseUrl = useMemo(() => constructBaseUrl(currentOrganization.domain), [
    currentOrganization,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Fade in={!!cloneRunning} timeout={300}>
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <CloningLayer />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              padding: '0 40px',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2,
              textAlign: 'center',
            }}
          >
            {cloneRunning === 'running' ? (
              <>
                <Loader width="50px" />
                <h2>Clone in progress...</h2>
              </>
            ) : (
              <>
                <CheckIcon
                  sx={t => ({
                    fontSize: '96px',
                    marginBottom: '35px',
                    fill: t.colors.success,
                  })}
                />
                <h2>Clone is finished you will be redirected shortly </h2>
              </>
            )}
          </Box>
        </Box>
      </Fade>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          background: '#fff',
          padding: '40px',
          width: '650px',
          maxHeight: '95vh',
          overflow: 'auto',
          position: 'relative',
          boxShadow: '0 0 35px 3px rgb(0, 0, 0, 0.3)',
        }}
      >
        <h1>Cloning event options</h1>
        <Box display="flex" flexDirection="column" mt="20px">
          <Form
            onSubmit={values =>
              onClone({
                values: {
                  ...values,
                  event_resource_params: {
                    ...values.event_resource_params,
                    slugs: values.event_resource_params.slugs?.length
                      ? values.event_resource_params.slugs
                      : [urlSlug(values.event_resource_params.name)],
                    organization_id:
                      values.event_resource_params.organization_id.id,
                  },
                },
              })
            }
            initialValues={initialState}
            validate={values => {
              const errors = {
                event_resource_params: {
                  planned_start_date: required(
                    values.event_resource_params.planned_start_date,
                  ),
                  name: composeValidators(
                    required,
                    maxEventNameLength(100),
                  )(values.event_resource_params.name),
                  organization_id: required(
                    values.event_resource_params.organization_id,
                  ),
                },
              };

              return errors;
            }}
          >
            {({ handleSubmit, values: formValues, submitError }) => {
              return (
                <>
                  <FieldGroup>
                    <Field
                      name="event_resource_params.name"
                      label="Event name"
                      limit={100}
                      component={TextInputWithLimit}
                    />
                  </FieldGroup>
                  <FieldGroup>
                    <Field
                      name="event_resource_params.planned_start_date"
                      label="Event start date"
                      type="dateOnly"
                      component={DatePickerInput}
                    />
                  </FieldGroup>
                  <FieldGroup>
                    <Field
                      name="event_resource_params.organization_id"
                      label="Organization"
                      options={organizations}
                      component={AutocompleteInput}
                      {...orgAutocompleteOptions}
                    />
                  </FieldGroup>
                  {!formValues.event_resource_params.slugs?.length && (
                    <FieldGroup>
                      <EventPreviewLinks
                        suggested={
                          formValues.event_resource_params.slugs?.length
                            ? null
                            : urlSlug(
                                formValues.event_resource_params.name || '',
                              )
                        }
                        baseUrl={baseUrl}
                      />
                    </FieldGroup>
                  )}
                  <FieldGroup>
                    <Field
                      label="Set your event URL:"
                      placeholder="Type event slug"
                      placeholderBelowInput="HINT: Type event slug and hit enter after each one"
                      name="event_resource_params.slugs"
                      baseUrl={baseUrl}
                      normalizer={urlSlug}
                      component={EventSlugs}
                    />
                  </FieldGroup>
                  <FieldGroup>
                    <Field
                      name="event_resource_params.mm_language"
                      label="Language"
                      selectOptions={LANG_SELECT_OPTIONS}
                      displayLabelValue
                      component={Select}
                    />
                    <AdditionalInfo mt="3px">
                      Hint: Please fill field above if you want to force event
                      language. When empty it will be automatically resolved by
                      user locale.
                    </AdditionalInfo>
                  </FieldGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > div': { flexBasis: '50%' },
                    }}
                  >
                    <FieldGroup>
                      <Field
                        name="with_resources.event_custom_theme"
                        label="Clone theme"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.registration_fields"
                        label="Clone registration fields"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.agenda_items"
                        label="Clone agenda"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.event_speakers"
                        label="Clone presenters"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.player"
                        label="Clone players"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.products"
                        label="Clone products"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.sponsors"
                        label="Clone sponsors"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                    <FieldGroup>
                      <Field
                        name="with_resources.documents"
                        label="Clone documents"
                        component={SwitchInput}
                        type="checkbox"
                      />
                    </FieldGroup>
                  </Box>
                  {submitError && (
                    <>
                      <Box
                        sx={{ color: theme.colors.error, marginTop: '20px' }}
                      >
                        {submitError.msg}.
                      </Box>
                      <Box sx={{ color: theme.colors.error }}>
                        Copy an error log here:{' '}
                        <CopyButton
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(submitError.errors);
                          }}
                        >
                          COPY
                        </CopyButton>
                      </Box>
                    </>
                  )}
                  <Stack direction="row" gap="15px" mt="20px">
                    <Button
                      onClick={closeModal}
                      variant="outlined"
                      color="dark"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Clone
                    </Button>
                  </Stack>
                </>
              );
            }}
          </Form>
        </Box>
      </Box>
    </Box>
  );
};

CloningEventOptionsModal.propTypes = {
  onClone: PropTypes.func.isRequired,
};

export default inject(
  'modalStore',
  'eventStore',
)(observer(CloningEventOptionsModal));
