import React from 'react';
import { Loader } from '@components/Loader';
import { FieldGroup } from '@components/_form/FieldGroup';
import Fade from '@mui/material/Fade';
import { Box, Button, Stack } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { CloningLayer } from '@components/CloningLayer';
import {
  Assignments,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
  validateAssignmentsForm,
} from '../Assignments';

const defaultFormValues = {
  ...assignmentsSetupDefaultFormValues({}),
};

const CloningDocumentOptionsModal = ({
  modalStore: { closeModal },
  documentStore: { phase, cloneRunning },
  onClone,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          background: '#fff',
          padding: '40px',
          width: '650px',
          position: 'relative',
        }}
      >
        <Fade in={cloneRunning} timeout={300}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <CloningLayer />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <Loader width="50px" />
              <h2>Cloning {phase}...</h2>
            </Box>
          </Box>
        </Fade>
        <h1>Cloning resource options</h1>
        <Box display="flex" flexDirection="column" mt="20px">
          <Form
            initialValues={defaultFormValues}
            onSubmit={values => {
              onClone({
                ...values,
              });
            }}
            validate={values => {
              const errors = {
                ...validateAssignmentsForm(values),
              };
              return errors;
            }}
          >
            {({ handleSubmit }) => {
              return (
                <>
                  <FieldGroup noPadding>
                    <Assignments
                      types={{
                        [ASSIGNMENT_TYPE.EVENT]: true,
                        [ASSIGNMENT_TYPE.AGENDA]: true,
                      }}
                      requiredEvent
                      expanded
                    />
                  </FieldGroup>
                  <Stack direction="row" gap="15px" mt="20px">
                    <Button
                      onClick={closeModal}
                      variant="outlined"
                      color="dark"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Clone
                    </Button>
                  </Stack>
                </>
              );
            }}
          </Form>
        </Box>
      </Box>
    </Box>
  );
};

CloningDocumentOptionsModal.propTypes = {
  onClone: PropTypes.func.isRequired,
};

export default inject(
  'modalStore',
  'documentStore',
)(observer(CloningDocumentOptionsModal));
