import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import MovieIcon from '@mui/icons-material/MovieOutlined';
import PeopleOutline from '@mui/icons-material/PeopleOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCityOutlined';
import Today from '@mui/icons-material/TodayOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAddOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CurtainsOutlinedIcon from '@mui/icons-material/CurtainsOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import Settings from '@mui/icons-material/Settings';
import routes from '@routes';
import { ROLES } from '@app/authManager';

export default [
  {
    title: 'Main',
    allowed: [
      ROLES.ADMIN,
      ROLES.EDITOR,
      ROLES.ANALYST,
      ROLES.EVENT_MANAGER,
      ROLES.SHARED_ORG_ADMIN,
    ],
    pages: [
      {
        title: 'Dashboard',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        href: `${routes.main.dashboard}`,
        icon: DashboardIcon,
      },
      {
        title: 'Events',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        href: `${routes.main.events}?page=1`,
        icon: Today,
      },
      {
        title: 'Registrations',
        allowed: [ROLES.ADMIN, ROLES.EDITOR, ROLES.SHARED_ORG_ADMIN],
        href: `${routes.main.attendances}?page=1`,
        icon: DraftsOutlinedIcon,
      },
      {
        title: 'Analytics',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.ANALYST,
          ROLES.SHARED_ORG_ADMIN,
        ],
        href: routes.main.analytics(''),
        icon: PieChartOutlinedIcon,
      },
    ],
  },
  {
    title: 'Content',
    allowed: [
      ROLES.ADMIN,
      ROLES.EDITOR,
      ROLES.EVENT_MANAGER,
      ROLES.SHARED_ORG_ADMIN,
    ],
    pages: [
      {
        title: 'Agenda items',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        icon: PlaylistAddIcon,
        href: `${routes.main.agenda}?page=1`,
      },
      {
        title: 'Breakout rooms',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        icon: MeetingRoomIcon,
        href: `${routes.main.breakout}?page=1`,
      },
      {
        title: 'Players',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          // ROLES.SHARED_ORG_ADMIN, as is not player in Lite feature set
        ],
        href: `${routes.main.players}?page=1`,
        icon: MovieIcon,
        featureSet: 'players_menu',
      },
      {
        title: 'Presenters',
        href: `${routes.main.presenters}?page=1`,
        icon: AssignmentIndOutlinedIcon,
      },
      {
        title: 'Products',
        href: `${routes.main.products}?page=1`,
        icon: LocalOfferOutlinedIcon,
        featureSet: 'products_menu',
      },
      {
        title: 'Resources',
        href: `${routes.main.documents}?page=1`,
        icon: DescriptionIcon,
      },
      {
        title: 'Sponsors',
        href: `${routes.main.sponsors}?page=1`,
        icon: MonetizationOnOutlinedIcon,
      },
    ],
  },
  {
    title: 'Chat',
    allowed: [
      ROLES.ADMIN,
      ROLES.EDITOR,
      ROLES.MODERATOR,
      ROLES.EVENT_MANAGER,
      ROLES.SHARED_ORG_ADMIN,
    ],
    pages: [
      {
        title: 'Messages',
        href: `${routes.main.chatMessages}?page=1`,
        icon: MailOutlineOutlinedIcon,
      },
      {
        title: 'Chat rooms',
        href: `${routes.main.chatRooms}?page=1`,
        icon: MeetingRoomOutlinedIcon,
        featureSet: 'chat_rooms_menu',
      },
      {
        title: 'Abuse reports',
        href: `${routes.main.chatAbuses}?page=1`,
        icon: ReportProblemIcon,
      },
    ],
  },
  {
    title: 'Interaction',
    allowed: [
      ROLES.ADMIN,
      ROLES.EDITOR,
      ROLES.MODERATOR,
      ROLES.EVENT_MANAGER,
      ROLES.SHARED_ORG_ADMIN,
    ],
    pages: [
      {
        title: 'Global messages',
        href: `${routes.main.globalMessages}?page=1`,
        icon: NotificationsNoneIcon,
      },
      {
        title: 'Q&A',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.MODERATOR,
          ROLES.EVENT_MANAGER,
        ],
        href: `${routes.main.chatQuestions}?page=1&tab=questions`,
        icon: QuestionAnswerIcon,
        featureSet: 'q_and_a_menu',
      },
      {
        title: 'Polls',
        icon: PollOutlinedIcon,
        href: `${routes.main.polls}?page=1`,
      },
      {
        title: 'Explore',
        icon: OpenInNewOutlinedIcon,
        href: `${routes.main.exploreItems}?page=1`,
      },
      {
        title: 'Banners',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        icon: ViewCarouselIcon,
        href: `${routes.main.banners}?page=1`,
        featureSet: 'banners_menu',
      },
      {
        title: 'Ratings',
        icon: StarBorderIcon,
        href: `${routes.main.ratings}?page=1`,
      },
    ],
  },
  {
    title: 'Admin',
    allowed: [
      ROLES.ADMIN,
      ROLES.EDITOR,
      ROLES.EVENT_MANAGER,
      ROLES.SHARED_ORG_ADMIN,
      ROLES.ANALYST,
      ROLES.MODERATOR,
    ],
    pages: [
      {
        title: 'Organizations',
        allowed: [ROLES.ADMIN],
        href: `${routes.main.organizations}?page=1`,
        icon: LocationCityIcon,
      },
      {
        title: 'Multi-market groups',
        allowed: [ROLES.ADMIN],
        href: `${routes.main.multiMarket}?page=1`,
        icon: LanguageOutlinedIcon,
        featureSet: 'multi_market_menu',
      },
      {
        title: 'Stages',
        allowed: [ROLES.ADMIN],
        href: `${routes.main.stages}?page=1`,
        icon: CurtainsOutlinedIcon,
        featureSet: 'stages_menu',
      },
      {
        title: 'Users',
        href: `${routes.main.users}?page=1`,
        allowed: [ROLES.ADMIN],
        icon: PeopleOutline,
      },
      {
        title: 'Translations',
        allowed: [
          ROLES.ADMIN,
          ROLES.EDITOR,
          ROLES.EVENT_MANAGER,
          ROLES.SHARED_ORG_ADMIN,
        ],
        href: `${routes.main.translations}`,
        icon: TranslateOutlinedIcon,
      },
      // {
      //   title: 'Live attendance reports',
      //   allowed: [ROLES.ADMIN, ROLES.ANALYST],
      //   href: `${routes.main.reports()}`,
      //   icon: AssessmentIcon,
      // },
      {
        title: 'GDPR',
        href: `${routes.main.gdpr.eventSelect}`,
        allowed: [ROLES.ADMIN],
        icon: DeleteSweepOutlinedIcon,
      },
      {
        title: 'Event refresh',
        href: `${routes.main.eventRefresh}`,
        allowed: [ROLES.ADMIN],
        icon: RefreshOutlinedIcon,
      },
      {
        title: 'Logs',
        href: `${routes.main.logs}`,
        allowed: [ROLES.ADMIN],
        icon: WarningIcon,
      },
      {
        title: 'Account management',
        href: `${routes.main.profile}`,
        icon: Settings,
      },
    ],
  },
];
