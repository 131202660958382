import React, { useCallback } from 'react';
import TextField from '@components/TextField';
import Box from '@mui/material/Box';
import FieldTooltip from '@components/FieldTooltip';
import Autocomplete from '@mui/material/Autocomplete';

const AutocompleteInput = ({
  input,
  meta,
  infoText,
  label,
  sx,
  inputSx,
  required,
  inputProps,
  multipleValues,
  freeSolo,
  options,
  placeholder,
  notAvailableInSP,
  ...props
}) => {
  const { value, onChange, ...inputStripped } = input;
  const handleChange = useCallback(
    (_, newValue) => {
      onChange(newValue);
    },
    [input.onChange],
  );

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box sx={{ position: 'relative' }}>
      <Autocomplete
        sx={sx}
        value={multipleValues ? value || [] : value || null} // prevent from empty string when not multiple and array as a default for multiple
        onChange={handleChange}
        multiple={multipleValues}
        options={freeSolo ? [] : options}
        freeSolo={freeSolo}
        renderInput={iProps => (
          <TextField
            sx={{
              ...inputSx,
            }}
            error={Boolean(error)}
            label={label}
            helperText={error}
            size="small"
            fullWidth
            required={Boolean(required)}
            placeholder={placeholder}
            {...iProps}
            {...inputProps}
          />
        )}
        {...(!freeSolo && inputStripped)} // with passed props autocomplete without options was crashing
        {...(!freeSolo && props)} // with passed props autocomplete without options was crashing
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

AutocompleteInput.defaultProps = {
  isOptionEqualToValue: (option, val) => val === option,
  getOptionLabel: option => option.label || '',
};

export default AutocompleteInput;
