import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { API, APIRoutes, CancelToken } from '@app/api';

const initialState = {
  isLoading: 0,
  isDeletingId: null,
  isUpdateLoading: false,
  chatQuestions: null,
  chatQuestionsInQueue: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
};

export class ChatQuestionsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  __token = null;

  @action getChatQuestions = async ({
    page,
    search,
    event_id,
    moderation_status,
    answered,
    agenda_item_id,
    in_answer_queue,
    silent,
    sort,
  }) => {
    if (!silent) this.isLoading += 1;
    this.__token?.cancel();
    this.__token = CancelToken.source();

    try {
      const res = await ResourcesStore.getChatQuestions(
        {
          page,
          search,
          event_id,
          agenda_item_id,
          moderation_status: in_answer_queue ? undefined : moderation_status,
          answered,
          in_answer_queue,
          sort,
        },
        {
          cancelToken: this.__token.token,
        },
      );

      if (in_answer_queue) {
        this.chatQuestionsInQueue = {
          ...res,
          search,
          event_id,
          page,
          answered,
          sort,
          agenda_item_id,
        };
      } else {
        this.chatQuestions = {
          ...res,
          search,
          event_id,
          page,
          answered,
          moderation_status,
          sort,
          agenda_item_id,
        };
      }
    } catch (err) {
      console.debug('[getChatQuestions] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchQuestionsPageSearchQuery = (params = {}) => {
    return [
      'search',
      'page',
      'event_id',
      'agenda_item_id',
      'sort',
      'answered',
      'moderation_status',
    ].every(param => params[param] === this.chatQuestions?.[param]);
  };

  @action matchQueuePageSearchQuery = (params = {}) => {
    return [
      'search',
      'page',
      'event_id',
      'agenda_item_id',
      'sort',
      'answered',
    ].every(param => params[param] === this.chatQuestionsInQueue?.[param]);
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
  };

  @action deleteChatQuestion = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteChatQuestion({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteChatQuestion] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  // switches in table

  @action handlePartialUpdate = async ({ id, payload }) => {
    try {
      const resp = await API.put(`${APIRoutes.CHAT_QUESTIONS}/${id}`, payload);
      return resp;
    } catch (err) {
      console.debug('[handlePartialUpdate] failed', err);
      return Promise.reject(err);
    }
  };

  @action updateChatQuestionsItem = data => {
    this.chatQuestions.results = this.chatQuestions.results.map(item =>
      item.id === data.id ? data : item,
    );
  };

  @action clearStore = () => {
    this.chatQuestion = initialState.chatQuestion;
  };
}

export default new ChatQuestionsStore();
