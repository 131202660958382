import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
);

export const InnerAppearance = theme => ({
  theme: 'stripe',
  rules: {
    '.Input': {
      backgroundColor: 'transparent',
      border: 0,
      color: '#000',
      borderBottom: `1px solid ${theme.palette.grey[600]}`,
      borderRadius: 0,
      paddingLeft: 0,
      outline: 'none',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '.Input:focus': {
      outline: 'none',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      boxShadow: 0,
    },
    '.Input:hover': {
      borderBottom: '1px solid #000',
    },
    '.Input:hover:focus': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '.Input--invalid': {
      boxShadow: 0,
    },
    '.Input--invalid:focus': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '.Input--invalid:focus:hover': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '.Input--invalid:hover': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '.Input::placeholder': { color: '#878DA4' },
    '.Label': {
      fontSize: '14px',
    },
    '.Tab': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },

    '.Tab:hover': {
      border: `1px solid ${theme.palette.grey[600]}`,
    },
    '.Tab--selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${theme.palette.primary.main}`,
    },
    '.Tab--selected:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${theme.palette.primary.main}`,
    },
    '.TabIcon--selected': {
      fill: theme.palette.primary.main,
    },
    '.TabIcon--selected:hover': {
      fill: theme.palette.primary.main,
    },
  },
  variables: {
    fontFamily: theme.fonts.main,
    colorDanger: theme.palette.error.main,
  },
});
