import { action, extendObservable, when } from 'mobx';
import { ASSIGNMENT_TYPE } from '@components/Assignments';
import ResourcesStore from '@stores/resourcesStore';
import moment from 'moment';

const initialState = {
  events: { lastFetch: null, list: [], isFetching: false },
  agendaItems: { lastFetch: null, list: [], isFetching: false },
  groups: { lastFetch: null, list: [], isFetching: false },
};

export class AssignmentsStore {
  constructor() {
    extendObservable(this, initialState);

    ResourcesStore.invalidateAssignmentsCache = this.invalidateCache;
  }

  @action getResources = async ({ types }) => {
    try {
      const promises = [];

      if (types[ASSIGNMENT_TYPE.EVENT]) {
        promises.push(
          this.getResource({
            fieldName: 'events',
            fn: ResourcesStore.getEventSelectOptions,
          }),
        );
      }

      if (types[ASSIGNMENT_TYPE.AGENDA]) {
        promises.push(
          this.getResource({
            fieldName: 'agendaItems',
            fn: ResourcesStore.getAgendaItemsSelectOptions,
          }),
          this.getResource({
            fieldName: 'groups',
            fn: ResourcesStore.getGroupSelectOptions,
          }),
        );
      }

      await Promise.all(promises);
    } catch (err) {
      console.debug('[AssignmentsStore.getResources] failed', err);
    }
  };

  @action invalidateCache = ({ types }) => {
    if (types[ASSIGNMENT_TYPE.EVENT]) this.events.lastFetch = null;
    if (types[ASSIGNMENT_TYPE.AGENDA]) {
      this.agendaItems.lastFetch = null;
      this.groups.lastFetch = null;
    }
  };

  @action getResource = async ({ fieldName, fn }) => {
    if (!this[fieldName].isFetching) {
      this[fieldName].isFetching = true;
    } else {
      await when(() => !this[fieldName].isFetching);
      this[fieldName].isFetching = true;
    }

    try {
      if (
        !this[fieldName].lastFetch ||
        moment().diff(moment(this[fieldName].lastFetch), 'minutes') > 1
      ) {
        let results = [];
        if (fieldName === 'agendaItems') {
          const resultsAgendas = await ResourcesStore.getAgendaItemsSelectOptions(
            { additionalResponseFields: ['event_id', 'agenda_type'] },
          );
          results = [...resultsAgendas];
        } else {
          const { results: otherFieldNameResults } = await fn();
          results = [...otherFieldNameResults];
        }
        this[fieldName] = {
          list: results,
          lastFetch: moment(),
          isFetching: false,
        };
      }
    } catch (err) {
      console.debug(`[AssignmentsStore.getResource.${fieldName}] failed`, err);
      throw err;
    } finally {
      this[fieldName].isFetching = false;
    }
  };
}

export default new AssignmentsStore();
