import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

export const useFilters = params => {
  const history = useHistory();
  const { pathname } = useLocation();
  return useCallback(
    filters => {
      history.push(
        `${pathname}?${qs.stringify({
          ...params,
          page: 1,
          ...filters,
        })}`,
      );
    },
    [history, pathname, params],
  );
};
