import React from 'react';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { Logo } from '@components/Logo';
import { Profile } from '@components/Profile';
import OrgSelector from './OrgSelector';
import { Header, Content, Right } from './style';

const TopBar = ({ profileStore: { profile, getFormattedRoleName } }) => {
  const history = useHistory();

  return (
    <Header>
      <Content>
        <Box display="flex" alignItems="center">
          <Logo onClick={() => history.push('/')} />
        </Box>
        <Right>
          <OrgSelector />
          <Profile profile={profile} userRole={getFormattedRoleName()} />
        </Right>
      </Content>
    </Header>
  );
};

export default inject('profileStore')(observer(TopBar));
