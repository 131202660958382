import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FieldGroup = styled(Box, {
  shouldForwardProp: prop => !['noPadding'].includes(prop),
})`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: column;

  ${({ noPadding }) => noPadding && `padding-top: 0;`}
`;
