import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const PulseAnimation = styled(Box)`
  border-radius: 5px;
  box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.primary.main};
  transform: scale(1);
  ${({ play }) => play && 'animation: pulse 2s infinite;'}

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0
        ${({ theme }) => alpha(theme.palette.primary.main, 0.7)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 7px
        ${({ theme }) => alpha(theme.palette.primary.main, 0)};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${({ theme }) => alpha(theme.palette.primary.main, 0)};
    }
  }
`;

export default PulseAnimation;
