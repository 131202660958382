import { styled } from '@mui/material/styles';
import { Table as MUITable } from '@mui/material';

export const Table = styled(MUITable)`
  margin: 10px 0px;

  thead > tr > th,
  tbody > tr > th,
  tbody > tr > td {
    padding: 10px 0;
  }

  tbody > tr > th,
  tbody > tr > td {
    font-size: 0.7rem;
  }

  tbody > tr {
    &:last-of-type {
      > th,
      > td {
        border-bottom: none;
      }

      &.no-padding {
        > th,
        > td {
          padding-bottom: 0;
        }
      }
    }

    &.nested {
      background: ${({ theme }) => theme.colors.background};
    }
  }

  thead > tr > th {
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    font-size: 0.8rem;
    border-bottom: none;
  }

  .pointer {
    cursor: pointer;
  }
`;
