import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  globalMessages: null,
  eventsSelectOptions: [],
  isDeletingId: null,
};

export class GlobalMessagesPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getGlobalMessages = async ({
    page,
    search,
    sort,
    event_id,
    silent,
  }) => {
    try {
      if (!silent) this.isLoading += 1;

      const res = await ResourcesStore.getGlobalMessages({
        page,
        search,
        sort,
        event_id,
      });
      this.globalMessages = {
        ...res,
        search,
        page,
        sort,
        event_id,
      };
    } catch (err) {
      console.debug('[getGlobalMessages] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'event_id'].every(
      param => params[param] === this.globalMessages?.[param],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action publishGlobalMessage = async ({ id, onSuccess, onError }) => {
    try {
      const res = await ResourcesStore.publishGlobalMessage({ id, onError });
      await onSuccess?.(res);
    } catch (err) {
      console.debug('[publishGlobalMessage] failed', err);
    }
  };

  @action deleteGlobalMessage = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      const res = await ResourcesStore.deleteGlobalMessage({ id, onError });

      await onSuccess?.(res);
    } catch (err) {
      console.debug('[deleteGlobaMessage] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new GlobalMessagesPageStore();
