import React from 'react';
import { Box, Button } from '@mui/material';
import { useSafeForm } from '@utils/useSafeForm';
import { redirectWithWarning } from '@utils/otherUtils';
import { useHistory } from 'react-router';
import routes from '@routes';

const UpgradePlanTooltip = () => {
  const form = useSafeForm();
  const history = useHistory();

  const redirect = () => history.push(`${routes.main.profile}?tab=plans`);

  return (
    <Box p="10px">
      <Box fontSize="12px" mb="10px">
        This setting requires plan upgrade. Upgrade now to access this and many
        more features.
      </Box>

      <Button
        variant="contained"
        size="small"
        onClick={
          form
            ? redirectWithWarning(
                form,
                'You have unsaved changes. Do you still want to navigate away?',
                redirect,
              )
            : redirect
        }
      >
        Upgrade plan
      </Button>
    </Box>
  );
};

export default UpgradePlanTooltip;
