import React, { useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import qs from 'query-string';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import routes from '@routes';
import SignUpForm from '@pages/signup/components/SignUpForm';

export const Title = styled(Box)`
  display: flex;
  font-size: 30px;
  color: white;
  font-weight: bold;
  margin-bottom: 15px !important;
  justify-content: center;
  width: 100%;
`;

export const Description = styled(Box)`
  display: flex;
  font-size: 16px;
  line-height: 20px;
  color: white;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px !important;
`;

const AccountCreate = ({
  authStore: { signUp },
  profileStore: { profile },
}) => {
  const history = useHistory();
  const { search } = useLocation();

  const params = useMemo(() => qs.parse(search), [search]);

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      history.replace(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (profile?.created_via_saas_api) {
      if (window.location.pathname === routes.auth.signUp('lite')) {
        history.replace(`${window.location.pathname}?step=billingDetails`);
      } else {
        history.replace(routes.main.dashboard);
      }
      return;
    }
    if (profile) {
      history.replace(routes.main.dashboard);
    }
  }, [profile]);

  const isLite = useMemo(
    () => window.location.pathname === routes.auth.signUp('lite'),
    [window.location.pathname],
  );

  const handleSubmit = val => {
    const payload = {
      ...val,
      confirm_success_url: isLite
        ? `${process.env.REACT_APP_CMS_URL}sign-up/lite?step=billingDetails`
        : `${process.env.REACT_APP_CMS_URL}dashboard`,
    };
    signUp({
      payload,
      onSuccess: () => {
        history.replace(
          `${window.location.pathname}?step=confirmEmail&email=${payload?.email}`,
        );
      },
    });
  };

  return (
    <>
      <Title>
        {isLite ? 'Create your C+ Lite account' : 'Create your trial account'}
      </Title>
      <Description>
        Complete the following steps to activate your trial account.
      </Description>
      <SignUpForm onSubmit={handleSubmit} />
    </>
  );
};

export default inject('authStore', 'profileStore')(observer(AccountCreate));
