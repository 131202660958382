import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const DaySelectedLine = styled('div')`
  display: block;
  height: 3px;
  width: calc(100% - 30px);
  transform: scaleX(0);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 15px;
  background-color: ${({ theme }) => {
    return theme.palette.primary.main;
  }};
  transition: transform 0.3s cubic-bezier(0.23, -0.91, 0, 1.39),
    opacity 0.3s ease-in-out;
`;

export const DaySelector = styled(NavLink)`
  background: none;
  border: none;
  cursor: pointer;
  height: 100%;
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing(9)};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 30px;
    width: 1px;
    background-color: #dfe3e9;
    top: calc(50% - 15px);
    position: absolute;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  & + & {
    margin-left: -1px;
  }

  &:hover {
    ${DaySelectedLine} {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  ${({ active }) =>
    active
      ? `${DaySelectedLine} {
      transform: scaleX(1);
      opacity: 1;
    }`
      : null};
`;
