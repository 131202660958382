import React from 'react';
import { action, extendObservable, computed } from 'mobx';
import { FORM_ERROR } from 'final-form';
import ResourcesStore from '@stores/resourcesStore';
import modalStore from '@stores/modalStore';
import CloningExploreItemOptionsModal from '@components/modals/CloningExploreItemOptionsModal';
import { ExploreItemCloneService } from '@app/CloningServices/ExploreItemCloneService';
import routerStore from '@stores/routerStore';
import assignmentsStore from '@stores/assignmentsStore';
import routes from '@routes';
import {
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';
import { CancelToken } from '@app/api';
import { FORM_TYPES } from '@constants';
import axios from 'axios';

const initialState = {
  formType: null,
  initialLoading: true,
  isLoading: false,
  exploreItem: null,
  defaultFormValues: {
    ...assignmentsSetupDefaultFormValues({}),
  },
  cloneRunning: false,
};

export class ExploreItemStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateExploreItem(...props);
    }
    if (this.isCreate) {
      return this.createExploreItem(...props);
    }

    return null;
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      await Promise.all(
        [
          this.isEdit && this.getExploreItem({ id }),
          assignmentsStore.getResources({
            types: {
              [ASSIGNMENT_TYPE.EVENT]: true,
              [ASSIGNMENT_TYPE.AGENDA]: true,
            },
          }),
        ].filter(Boolean),
      );

      if (id) {
        this.setupDefaultFormValues();
      }
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.exploreItem,
      ...assignmentsSetupDefaultFormValues({
        item: this.exploreItem,
        events: assignmentsStore.events.list,
        agendas: assignmentsStore.agendaItems.list,
        groups: assignmentsStore.groups.list,
      }),
    };
  };

  @action getExploreItem = async ({ id }) => {
    try {
      this.exploreItem = await ResourcesStore.getExploreItem(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.debug('[getExploreItem] failed', err);
      }
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.exploreItems);
      }
      throw err;
    }
  };

  @action createExploreItem = async ({ values, onSuccess }) => {
    this.isLoading = true;

    try {
      const payload = {
        ...values,
        ...assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }),
      };

      const createdExploreItem = await ResourcesStore.createExploreItem({
        payload,
      });
      await onSuccess?.(createdExploreItem);

      return null;
    } catch (err) {
      console.debug('[createExploreItem] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateExploreItem = async ({ id, values, onSuccess }) => {
    this.isLoading = true;
    try {
      const payload = {
        ...values,
        ...assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }),
      };

      const updatedExplore = await ResourcesStore.updateExploreItem({
        id,
        payload,
      });

      await onSuccess?.(updatedExplore);

      this.exploreItem = updatedExplore;
      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateExploreItem] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteExploreItem = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteExploreItem({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteExploreItem] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action handleCloneExploreItem = () => {
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'exlporeCloneModal',
      content: <CloningExploreItemOptionsModal onClone={this.cloneProcess} />,
    });
  };

  @action cloneProcess = async values => {
    this.cloneRunning = 'running';
    let payload = null;
    try {
      const preparedExploreItem = await ExploreItemCloneService.prepareExploreItem(
        this.exploreItem.id,
      );

      payload = {
        ...preparedExploreItem,
        event_id: values.event_id,
        assignments_attributes: values.assignments_attributes,
      };

      const res = await ResourcesStore.createExploreItem({
        payload,
        requestOptions: {
          headers: {
            bypass_uploaders_validation: true,
          },
        },
      });

      this.cloneRunning = 'success';

      setTimeout(() => {
        routerStore.push(routes.exploreItems(res.id));
        modalStore.closeModal();
        this.cloneRunning = false;
      }, 2000);

      return null;
    } catch (err) {
      this.cloneRunning = false;

      return {
        [FORM_ERROR]: {
          msg: 'Cloning failed, please contact an administrator',
          errors: JSON.stringify({ payload, error: err }),
        },
      };
    }
  };

  @action clearStore = () => {
    modalStore.killModal('exlporeCloneModal');
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ExploreItemStore();
