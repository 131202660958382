import React from 'react';
import { Loader } from '@components/Loader';

export default () => ({
  MuiLoadingButton: {
    defaultProps: {
      loadingIndicator: <Loader stroke="currentColor" width="40px" />,
    },
  },
});
