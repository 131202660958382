import React from 'react';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';

const ProfileMenu = ({ open, setOpen, logout, navigateToProfile }) => {
  return (
    <Box sx={{ position: 'absolute', right: '0', top: 'calc(100% + 10px)' }}>
      <Grow in={open} timeout={200} unmountOnExit>
        <Paper sx={{ overflow: 'hidden' }} elevation={3}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <MenuList sx={{ p: 0 }}>
              <MenuItem onClick={navigateToProfile}>View Profile</MenuItem>
              <MenuItem
                onClick={logout}
                sx={{
                  color: 'red.contrastText',
                  backgroundColor: 'red.main',
                  '&:hover': {
                    backgroundColor: 'red.light',
                  },
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    </Box>
  );
};

export default observer(ProfileMenu);
