import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useFormState } from 'react-final-form';
import PulseAnimation from './PulseAnimation';

const FormActions = ({
  primaryAction,
  secondaryAction,
  secondaryActionLabel,
  primaryActionLabel,
  disabledPrimaryAction,
  sx,
  title,
  fullWidth,
}) => {
  const formState = useFormState({ subscription: { valid: true } });

  const Wrapper = !fullWidth ? Stack : Box;

  return (
    <Box
      display={!fullWidth && 'flex'}
      justifyContent={!fullWidth && 'space-between'}
      sx={sx}
    >
      <Box fontSize="20px" fontWeight="fontWeightMedium">
        {title}
      </Box>
      <Wrapper
        direction={!fullWidth ? 'row' : undefined}
        gap={!fullWidth ? '10px' : undefined}
      >
        {secondaryAction && (
          <Button
            variant="outlined"
            color="red"
            onClick={() => secondaryAction()}
          >
            {secondaryActionLabel}
          </Button>
        )}
        {primaryAction && (
          <PulseAnimation play={formState.valid && !disabledPrimaryAction}>
            <Button
              variant="contained"
              sx={{ width: fullWidth && '100%' }}
              onClick={() => primaryAction()}
              disabled={disabledPrimaryAction}
            >
              {primaryActionLabel}
            </Button>
          </PulseAnimation>
        )}
      </Wrapper>
    </Box>
  );
};

export default FormActions;
