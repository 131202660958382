import MuiTextField from '@mui/material/TextField';
import React from 'react';

const TextField = ({ fullWidth, variant, ...props }) => {
  return (
    <MuiTextField
      variant={variant || 'standard'}
      fullWidth={fullWidth || true}
      {...props}
    />
  );
};

export default TextField;
