import React, { useMemo, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Popover, Button, useTheme } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const Wrapper = styled('div', {
  shouldForwardProp: prop => !['forPrint'].includes(prop),
})`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;

  & .MuiSvgIcon-root {
    font-size: 28px;
  }

  ${({ forPrint }) =>
    forPrint
      ? `
    top: 10px;
    right: 10px;

    & .MuiSvgIcon-root {
      font-size: 24px;
    }
  `
      : ''}
`;

export const CardOptions = ({
  icon,
  type,
  color,
  forPrint,
  analyticsStore: { loadingSingleCSVs, generateSingleCSV, enabledCSVs },
}) => {
  const theme = useTheme();
  const more = useRef();
  const [open, setOpen] = useState(false);
  const currentProgress = useMemo(
    () => loadingSingleCSVs.find(a => a.type === type)?.progress || null,
    [loadingSingleCSVs?.length],
  );

  const Icon = icon;

  const handleDownload = () => {
    generateSingleCSV(type);
    setOpen(false);
  };
  return (
    <Wrapper forPrint={forPrint}>
      {enabledCSVs.includes(type) ? (
        <>
          {icon && (
            <Icon style={{ color: color || theme.palette.primary.main }} />
          )}
          <MoreVert
            sx={{
              color: color || theme.palette.primary.main,
              cursor: 'pointer',
              mr: '-10px',
            }}
            onClick={() => setOpen(a => !a)}
            ref={more}
          />
          <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={more?.current}
          >
            <Button
              variant="outlined"
              color="dark"
              onClick={() => handleDownload()}
              sx={{ cursor: 'pointer' }}
            >
              {currentProgress ? `${currentProgress}%` : 'Download XLS'}
            </Button>
          </Popover>
        </>
      ) : (
        <>
          {icon && (
            <Icon
              style={{
                color: color || theme.palette.primary.main,
              }}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default inject('analyticsStore')(observer(CardOptions));
