import React from 'react';
import TextField from '@components/TextField';
import Box from '@mui/material/Box';
import FieldTooltip from '@components/FieldTooltip';
import { InputAdornment } from '@mui/material';

const TextInputWithLimit = ({
  input,
  meta,
  infoText,
  sx,
  InputProps,
  limit,
  notAvailableInSP,
  ...props
}) => {
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  const inputLen = input.value?.length || 0;

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        sx={{
          ...sx,
        }}
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box
                sx={{
                  fontSize: '12px',
                  color: error ? 'error.main' : 'text',
                }}
              >
                {inputLen}/{limit}
              </Box>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        size="small"
        fullWidth
        {...input}
        {...props}
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default TextInputWithLimit;
