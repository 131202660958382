import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Header = styled(Box)`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const ChartWrapper = styled('div', {
  shouldForwardProp: prop => !['forPrint'].includes(prop),
})`
  height: ${({ forPrint }) => (forPrint ? 120 : 168)}px;
  position: relative;
  margin: ${({ forPrint }) => (forPrint ? 0 : '15px 0 10px 0')};
`;
