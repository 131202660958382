import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const NavigationSkeleton = () => {
  return (
    <Stack spacing="8px" sx={{ p: '16px' }}>
      {Array(10)
        .fill(1)
        .map((_, i) => (
          <Skeleton variant="rectangular" width="100%" height={35} key={i} />
        ))}
    </Stack>
  );
};

export default NavigationSkeleton;
