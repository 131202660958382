import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'query-string';
import pickBy from 'lodash/pickBy';
import { LS_KEYS, PASSWORD_EXPIRED_ERROR } from '@constants';
// eslint-disable-next-line
import { authStore } from '@stores';
import history from '@app/history';
import routes from '@routes';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const { CancelToken } = axios;

const AUTH_TOKEN = localStorage.getItem(LS_KEYS.AUTH_TOKEN);
const CLIENT = localStorage.getItem(LS_KEYS.CLIENT);
const UID = localStorage.getItem(LS_KEYS.UID);

API.defaults.headers.common['access-token'] = AUTH_TOKEN;
API.defaults.headers.common.client = CLIENT;
API.defaults.headers.common.UID = UID;
API.defaults.headers.contentType = 'multipart/form-data';

API.defaults.headers['token-type'] = 'Bearer';

API.interceptors.response.use(
  response => response,
  async error => {
    try {
      if (axios.isCancel(error)) return Promise.reject(error);
      if (error.response?.status === 401) {
        if (
          Array.isArray(error.response?.data?.errors) &&
          error.response.data.errors.includes(PASSWORD_EXPIRED_ERROR)
        ) {
          authStore.previousRoute = history.location;
          history.push(routes.auth.expired);
        } else {
          authStore.logout();
        }
      } else if (error.response?.status === 403) {
        toast.cError(`[PERMISSIONS ERROR]: ${error.response.data.error}`);
        history.push(routes.unauthorized);
      } else if (error.response?.data?.errors) {
        const msgArr = [];
        Object.keys(error.response?.data.errors).forEach(key => {
          msgArr.push(
            `[${key}]: ${error.response.data.errors[key]
              .map(err => err)
              .join(', ')}`,
          );
        });
        toast.cError(error.message);
        toast.cError(
          msgArr.join(', ').replace(/spatial|spatial_chat/i, 'spaces'),
        );
      } else {
        toast.cError(error.message);
      }
    } catch (err) {
      console.debug('@axios error', err); // eslint-disable-line
    }

    return Promise.reject(error);
  },
);

const setAuthenticationToken = token => {
  localStorage.setItem(LS_KEYS.AUTH_TOKEN, token);
  API.defaults.headers.common['access-token'] = token;
};

const setClient = client => {
  localStorage.setItem(LS_KEYS.CLIENT, client);
  API.defaults.headers.common.client = client;
};

const setUid = uid => {
  localStorage.setItem(LS_KEYS.UID, uid);
  API.defaults.headers.common.UID = uid;
};

const prefix = '/cms_api/v1';

const APIRoutes = {
  SIGN_IN: `${prefix}/auth/sign_in`,
  SIGN_OUT: `${prefix}/auth/sign_out`,
  SIGN_UP: 'saas_api/v1/auth',
  SIGN_UP_RESEND_EMAIL: 'saas_api/v1/auth/confirmation',
  SIGN_UP_UPDATE_USER: 'saas_api/v1/user',
  EXPIRED: `${prefix}/password_expired`,
  RESET_PASSWORD: `${prefix}/auth/password`,
  REDIRECT_URL: `${process.env.REACT_APP_CMS_URL}create-password`,
  ME: `${prefix}/users/me`,
  ORGANIZATIONS: `${prefix}/organizations`,
  EVENTS: `${prefix}/events`,
  EVENTS_FOR_DROPDOWN: `${prefix}/events/event_select_options`,
  EVENTS_UPCOMING: `${prefix}/events/upcoming`,
  EVENTS_PAST: `${prefix}/events/past`,
  EVENTS_LIVE: `${prefix}/events/live`,
  AGENDA_ITEMS: `${prefix}/agenda_items`,
  PLAYERS: `${prefix}/players`,
  USERS: `${prefix}/users`,
  DOCUMENTS: `${prefix}/documents`,
  PRODUCTS: `${prefix}/products`,
  IMAGES: `${prefix}/images`,
  SPEAKERS: `${prefix}/event_speakers`,
  CHAT_MESSAGES: `${prefix}/chat_messages`,
  CHAT_ABUSES: `${prefix}/chat_message_abuse_reports`,
  CHAT_ROOMS: `${prefix}/chat_rooms`,
  CHAT_QUESTIONS: `${prefix}/chat_questions`,
  POLLS: `${prefix}/polls`,
  POLL_ANSWERS: `${prefix}/poll_answers`,
  POLL_QUESTIONS: `${prefix}/poll_questions`,
  ATTENDANCES: {
    DEFAULT: (id = null, queries = null) =>
      `${prefix}/attendances${id ? `/${id}` : ''}${
        queries ? `?${qs.stringify(queries)}` : ''
      }`,
    BULK_CREATE: `${prefix}/attendances/bulk_create`,
    BULK_UPDATE: `${prefix}/attendances/bulk_update`,
    REGISTRATION_FIELDS: (eventId = null) =>
      `${prefix}/attendances/available_user_registration_fields${
        eventId ? `?event_id=${eventId}` : ''
      }`,
    EXPORT_CSV: (queries = null) =>
      `${prefix}/attendances/export${
        queries ? `?${qs.stringify(queries)}` : ''
      }`,
    RECALCULATE: (queries = null) =>
      `${prefix}/attendances/recalculate${
        queries ? `?${qs.stringify(queries)}` : ''
      }`,
  },
  SPONSORS: `${prefix}/sponsors`,
  EVENT: id => `${prefix}/events/${id}`,
  EVENT_THEME_FIELDS: `${prefix}/events/custom_theme_fields`,
  EVENT_THEME_GALLERY: `${prefix}/gallery_themes`,
  AGENDA_THEME_FIELDS: `${prefix}/agenda_items/custom_theme_fields`,
  EVENT_STATS: (eventId, agendaId, from) =>
    `${prefix}/events/${eventId}/stats?agendaId=${agendaId || ''}&from=${from ||
      ''}`,
  EVENT_STATS_20: ({ eventId, agendaId, from }) => {
    const queryParams = { agendaId, from };
    return qs.stringifyUrl({
      url: `${prefix}/events/${eventId}/stats20`,
      query: queryParams,
    });
  },
  EVENT_COUNTERS_CHARTS: ({ eventId, agendaId, from, ts }) => {
    const queryParams = { agendaId, from, ts };
    return qs.stringifyUrl({
      url: `${prefix}/events/${eventId}/stats20_counters`,
      query: queryParams,
    });
  },
  EVENT_ATTENDEES: (eventId, itemId, startDate, endDate) =>
    `${prefix}/events/${eventId}/attendees?agenda_item_id=${itemId ||
      ''}&start_date=${startDate || ''}&end_date=${endDate || ''}`,
  EVENT_ATTENDEES_20: ({ eventId, agendaId, startDate, endDate, ts, from }) => {
    const queryParams = {
      startDate,
      endDate,
      ts,
      agendaId,
      from,
    };

    const query = pickBy(queryParams, qp => !!qp);
    return qs.stringifyUrl({
      url: `${prefix}/events/${eventId}/attendees20`,
      query,
    });
  },
  USER_STATS: (eventId, userId) =>
    `${prefix}/events/${eventId}/user_stats?user_id=${userId}`,
  EVENT_SYNC_EF_USERS: (eventId, sync_all = false) =>
    `${prefix}/events/${eventId}/sync_ef_users?sync_all=${sync_all}`,
  EVENT_REPORTS: eventId => `${prefix}/events/${eventId}/report_exports`,
  CHAT_ROOM_MESSAGES: room => `${prefix}/chat_rooms/${room}/chat_messages`,
  CHAT_MESSAGE_ABUSES: message =>
    `${prefix}/chat_messages/${message}/chat_message_abuse_reports`,
  EVENT_ONLY_USERS: `${prefix}/users/event_only_users`,
  GDPR_PROCEDURE: `${prefix}/users/delete_event_only_users`,
  MAILCHIMP_AUDIENCES: key => `${prefix}/mailchimp/lists?api_key=${key}`,
  EXPLORE_ITEMS: `${prefix}/explore_items`,
  BANNERS: `${prefix}/banners`,
  GLOBAL_MESSAGES: `${prefix}/global_messages`,
  GROUPS: `${prefix}/groups`,
  LINKED_EVENTS: `${prefix}/linked_events_groups`,
  MULTI_MARKET_EVENTS: `${prefix}/multi_market_events_groups`,
  REFRESH_FRONTEND: eventId => `${prefix}/events/${eventId}/refresh`,
  TRANSLATIONS: `${prefix}/translations`,
  IMPORT_BREAKOUT_MAPPING: agendaId =>
    `${prefix}/${
      agendaId ? `agenda_items/${agendaId}/` : ''
    }import_breakout_mappings`,
  GENERATE_REPORT: (eventId = null) =>
    `${prefix}/generators/pdf?event_id=${eventId}`,
  GENERATE_CSV: (eventId = null) =>
    `${prefix}/events/${eventId}/stats20/report/attendance`,
  GENERATE_LIVE_ATTENDANCE_CSV: (eventId = null) =>
    `${prefix}/events/${eventId}/live_attendees.xlsx`,
  SINGLE_CSV: (event_id, type) =>
    `/cms_api/v1/events/${event_id}/stats20/report/${type}`,
  RATINGS: `${prefix}/ratings`,
  SUBSCRIPTION_PLANS: `${prefix}/subscription_plans`,
  CLONE_EVENT: ({ eventId }) => `${prefix}/events/${eventId}/clone`,
  CLONE_POLL: ({ pollId }) => `${prefix}/polls/${pollId}/clone`,
  UPLOAD_FILE: `${prefix}/pictures`,
  API_PARTNER: (eventId, id = '') =>
    `${prefix}/events/${eventId}/api_partner/${id}`,
  LOGS: `${prefix}/securities`,
  SETUP_PAYMENT_METHOD: `${prefix}/payments/setup_payment_method`,
  INVOICES: `${prefix}/payments/invoices`,
  PAYMENT_METHODS: `${prefix}/payments/payment_methods`,
  DELETE_PAYMENT_METHOD: `${prefix}/payments/delete_payment_method`,
  CHECKOUT_SESSION: `${prefix}/payments/checkout_session`,
  MAILER_CONFIG: `${prefix}/mailer_configs`,
  PUBLISH_EVENT: ({ id }) => `${prefix}/events/${id}/publish`,
  UNPUBLISH_EVENT: ({ id }) => `${prefix}/events/${id}/unpublish`,
  START_EVENT_PAYMENT: ({ id }) => `${prefix}/events/${id}/start_payment`,
  CHECK_EMAIL_AVAILABILITY: ({ email }) =>
    `${prefix}/users/check_email_available?email=${email}`,
  STAGES: `${prefix}/stages`,
};

export { API, APIRoutes, setAuthenticationToken, setUid, setClient };
