import MuiTableRow from '@mui/material/TableRow';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import { useCplusTableContext } from './Table';

const TableRow = ({ children, isLoading, ...props }) => {
  const { colCount } = useCplusTableContext();
  if (isLoading) {
    return (
      <MuiTableRow {...props}>
        <TableCell colSpan={colCount}>
          <Skeleton variant="rectangular" width="100%" height="20px" />
        </TableCell>
      </MuiTableRow>
    );
  }
  return <MuiTableRow {...props}>{children}</MuiTableRow>;
};

export default TableRow;
