import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)`
  position: relative;
  height: calc(100vh - ${({ theme }) => theme.header.height}px);
  grid-area: side-menu;
  background-color: ${({ theme }) => theme.sidebar.background};
  border-right: ${({ theme }) => theme.sidebar.border};
  width: 235px;
  margin-left: ${({ open }) => (!open ? '-235px' : 0)};
  transition: ${({ theme }) =>
    theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    })};
  will-change: margin;
`;

export const TogglerBox = styled(Box)`
  display: flex;
  position: absolute;
  z-index: 900;
  top: 50%;
  transform: translateY(-50%);
  right: -16px;
  width: 16px;
  height: 23px;
  background-color: ${({ theme }) => theme.sidebar.background};
  border-right: ${({ theme }) => theme.sidebar.border};
  border-bottom: ${({ theme }) => theme.sidebar.border};
  border-top: ${({ theme }) => theme.sidebar.border};
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Nav = styled('nav')`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
