import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FieldTooltip from '@components/FieldTooltip';

const AutocompleteMultiInput = ({
  meta,
  label,
  options,
  input,
  required,
  disabled,
  selectedValues,
  getOptionLabel,
  getOptionSelected,
  combineSelectedAndFilter, // when you dynamically change options list and you see warning that no options matches selected
  getOptionDisabled,
  renderTags,
  renderOption,
  infoText,
  freeSolo,
  disableClearable,
  notAvailableInSP,
}) => {
  const handleSelect = useCallback(
    (_, value) => {
      input.onChange(value);
    },
    [input.onChange],
  );
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Autocomplete
        size="small"
        multiple
        freeSolo={freeSolo}
        disabled={disabled}
        options={
          combineSelectedAndFilter
            ? [...(selectedValues || input?.value || []), ...options]
            : options
        }
        filterSelectedOptions={combineSelectedAndFilter}
        value={selectedValues || input.value || []}
        isOptionEqualToValue={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        disableClearable={disableClearable}
        getOptionLabel={getOptionLabel}
        renderTags={renderTags}
        onChange={handleSelect}
        onBlur={input.onBlur}
        renderOption={renderOption}
        renderInput={params => (
          <TextField
            variant="standard"
            label={label}
            error={Boolean(error)}
            helperText={error}
            required={required}
            {...params}
          />
        )}
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

AutocompleteMultiInput.defaultProps = {
  getOptionLabel: option => option?.label,
  getOptionSelected: (option, value) => option?.value === value?.value,
};

export default AutocompleteMultiInput;
