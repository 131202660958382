import React from 'react';
import MuiTableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import TableRow from './TableRow';
import { useCplusTableContext } from './Table';

const TableBody = ({ children, removeLastRowBorder }) => {
  const {
    isLoading,
    colCount,
    noContent,
    noContentText,
    uncollapsableRows,
  } = useCplusTableContext();

  if (isLoading) {
    return (
      <MuiTableBody
        sx={{
          ...(removeLastRowBorder && {
            '& > .MuiTableRow-root:last-of-type > .MuiTableCell-root': {
              borderBottom: 'unset',
            },
          }),
        }}
      >
        {Array(25)
          .fill(1)
          .map((_, i) => (
            <TableRow key={i}>
              <TableCell colSpan={colCount}>
                <Skeleton variant="rectangular" width="100%" height="20px" />
              </TableCell>
            </TableRow>
          ))}
      </MuiTableBody>
    );
  }

  if (noContent) {
    return (
      <MuiTableBody
        sx={{
          ...(removeLastRowBorder && {
            '& > .MuiTableRow-root:last-of-type > .MuiTableCell-root': {
              borderBottom: 'unset',
            },
          }),
        }}
      >
        <TableRow>
          <TableCell colSpan={colCount} align="center">
            {noContentText || 'There is no content 😐'}
          </TableCell>
        </TableRow>
      </MuiTableBody>
    );
  }
  return (
    <MuiTableBody
      sx={{
        ...(uncollapsableRows && {
          '& > .MuiTableRow-root:nth-of-type(2n + 1) > .MuiTableCell-root': {
            borderBottom: 'unset',
          },
        }),
        ...(removeLastRowBorder && {
          '& > .MuiTableRow-root:last-of-type > .MuiTableCell-root': {
            borderBottom: 'unset',
          },
        }),
      }}
    >
      {children}
    </MuiTableBody>
  );
};

export default TableBody;
