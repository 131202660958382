import React from 'react';
import Image from '@components/Image';
import AvatarDefaultCustomizable from '@assets/svgs/avatarDefaultCustomizable';
import { useTheme } from '@mui/material';
import { PictureWrapper } from './style';

const Avatar = ({ url }) => {
  const theme = useTheme();
  return (
    <PictureWrapper>
      {url ? (
        <Image src={url} sx={{ height: '100%', width: '100%' }} />
      ) : (
        <AvatarDefaultCustomizable
          width="100%"
          height="100%"
          fill={theme.palette.primary.main}
          stroke={theme.palette.primary.contrastText}
        />
      )}
    </PictureWrapper>
  );
};

export default Avatar;
