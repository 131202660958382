import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTabs } from '@utils/useTabs';
import { DEFAULT_TAB } from '@constants';

export const CustomTabs = ({
  tabsConfig,
  overrideParamsFn,
  onChangeCallback,
  tabName,
  ...rest
}) => {
  const tabs = useTabs(tabsConfig[0].value, {
    autoSwitch: true,
    overrideParamsFn,
    onChangeCallback,
    allTabs: tabsConfig,
    tabName,
  });
  const { changeTab } = tabs;
  const tab = tabs[tabName || DEFAULT_TAB];

  return (
    <Tabs
      sx={theme => ({
        '& .MuiTabs-flexContainer': {
          width: 'fit-content',
          minWidth: '100%',
          borderBottom: `2px solid ${theme.colors.border2}`,
        },
      })}
      variant="scrollable"
      value={tab}
      scrollButtons="auto"
      onChange={changeTab}
      {...rest}
    >
      {tabsConfig.map(tabConfig => (
        <Tab
          sx={{
            textTransform: 'none',
            fontWeight: 'fontWeightMedium',
            minWidth: '100px',
            minHeight: '48px',
            ...(tabConfig.hide && { visibility: 'hidden' }),
          }}
          key={tabConfig.value}
          component="div"
          iconPosition="start"
          icon={tabConfig.Icon ? <tabConfig.Icon /> : undefined}
          value={tabConfig.value}
          label={tabConfig.label}
        />
      ))}
    </Tabs>
  );
};
