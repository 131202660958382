import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { action, extendObservable, computed } from 'mobx';
import { CancelToken } from '@app/api';
import { FORM_TYPES } from '@constants';
import { modalStore, routerStore, assignmentsStore } from '@stores';
import ResourcesStore from '@stores/resourcesStore';
import CloningDocumentOptionsModal from '@components/modals/CloningDocumentOptionsModal';
import routes from '@routes';
import { DocumentCloneService } from '@app/CloningServices/DocumentCloneService';
import {
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';

const initialState = {
  isLoading: false,
  initialLoading: true,
  document: {},
  events: [],
  agendaItems: [],
  defaultFormValues: {
    ...assignmentsSetupDefaultFormValues({}),
  },
  cloneRunning: false,
  phase: '',
  filteredAgendaItems: [],
  formType: null,
};

export class DocumentStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateDocument(...props);
    }
    if (this.isCreate) {
      return this.createDocument(...props);
    }

    return null;
  };

  @action getDocument = async ({ id }) => {
    try {
      this.document = await ResourcesStore.getDocument(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getDocument] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.documents);
      }
      throw err;
    }
  };

  @action getUserList = async ({ id, search } = {}) => {
    try {
      // FIXME: maybe define AsyncAutocompleteMulti first featch function
      const args = id ? { documentId: this.document.id } : { search };
      const users = await ResourcesStore.getUserSelectOptions(args);

      return users;
    } catch (err) {
      console.debug('[getUserList] failed', err);
      return Promise.reject(err);
    }
  };

  @action updateDocument = async ({ id, values, onSuccess }) => {
    this.isLoading = true;
    try {
      const preparedValues = this.prepareValuesToSubmit(values);

      const onS = pload => {
        if (onSuccess) onSuccess(pload);
        this.document = pload;
        this.setupDefaultFormValues();
      };

      await ResourcesStore.updateDocument({
        id,
        payload: preparedValues,
        onSuccess: onS,
      });
      return {};
    } catch (err) {
      console.debug('[updateDocument] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createDocument = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;
      const preparedValues = this.prepareValuesToSubmit(values);

      await ResourcesStore.docPageCreateDocument({
        payload: preparedValues,
        onSuccess,
      });

      return {};
    } catch (err) {
      console.debug('[createDocument] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    const { assignments_attributes, event_id } =
      assignmentsPrepareValues({
        values,
        initialValues: this.defaultFormValues,
      }) || {};

    return {
      ...values,
      agenda_item_ids: (assignments_attributes || []).map(
        ai => ai.agenda_item_id,
      ),
      event_id: event_id || undefined,
      show_when_live:
        values.event_id &&
        !(assignments_attributes && !isEmpty(assignments_attributes))
          ? false
          : values.show_when_live,
    };
  };

  @action deleteDocument = async ({ id, onSuccess }) => {
    this.isLoading = true;

    try {
      await ResourcesStore.deleteDocument({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteDocument] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;

    try {
      await Promise.all([
        this.isEdit && this.getDocument({ id }),
        assignmentsStore.getResources({
          types: {
            [ASSIGNMENT_TYPE.EVENT]: true,
            [ASSIGNMENT_TYPE.AGENDA]: true,
          },
        }),
      ]);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.document,
      ...assignmentsSetupDefaultFormValues({
        item: {
          ...this.document,
          agenda_item_assignments: this.document.agenda_item_ids,
        },
        events: assignmentsStore.events.list,
        agendas: assignmentsStore.agendaItems.list,
        groups: assignmentsStore.groups.list,
      }),
    };
  };

  @action handleCloneDocument = () => {
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'documentCloneModal',
      content: (
        <CloningDocumentOptionsModal
          onClone={this.cloneProcess}
          agendaItems={this.agendaItems}
          events={this.events}
        />
      ),
    });
  };

  @action cloneProcess = async values => {
    this.cloneRunning = true;
    this.phase = 'Document';

    try {
      const preparedDocument = await DocumentCloneService.prepareDocument(
        this.document.id,
      );

      const { assignments_attributes, event_id } =
        assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }) || {};

      const res = await ResourcesStore.docPageCreateDocument({
        payload: {
          ...preparedDocument,
          event_id,
          agenda_item_ids:
            assignments_attributes.map(ai => ai.agenda_item_id) || [],
        },
        requestOptions: {
          headers: {
            bypass_uploaders_validation: true,
          },
        },
      });

      this.defaultFormValues = {};
      routerStore.push(`${routes.document(res.id)}`);
      modalStore.closeModal();
    } catch (err) {
      console.debug('[cloneProcess] failed', err);
    } finally {
      this.cloneRunning = false;
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    modalStore.killModal('documentCloneModal');
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new DocumentStore();
