import { MAPPING_TYPES, PLAYER_TYPES } from '@constants';
import isNil from 'lodash/isNil';
import moment from 'moment';
import validator from 'validator';

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined,
  );

export const passwordMustBeStrength = pass => {
  let passwordStrength = 0;
  if (pass.match(/[a-z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/[A-Z]/)) {
    passwordStrength += 1;
  }
  if (pass.match(/\d/)) {
    passwordStrength += 1;
  }
  if (pass.match(/[\W_]/)) {
    passwordStrength += 1;
  }
  if (pass && pass.length >= 8) {
    passwordStrength += 1;
  }

  return passwordStrength < 5 ? 'Complexity requirement not met.' : undefined;
};

export const passwordsMustMatchIfExists = (value, values) => {
  if (!value && !values.password) return undefined;

  return value === values.password ? undefined : 'Passwords must match.';
};

export const validatePasswordIfExists = value => {
  if (!value) {
    return undefined;
  }

  return passwordMustBeStrength(value);
};

export const passwordsMustMatch = (value, allValues) => {
  let message;
  if (value) {
    message = value === allValues.password ? undefined : 'Passwords must match';
  } else {
    message = 'This field is required';
  }
  return message;
};

export const required = value =>
  isNil(value) ? 'This field is required' : undefined;

export const notEmpty = arr => {
  return !Array.isArray(arr) || arr.length === 0
    ? 'Set at least one value'
    : undefined;
};

export const emailPool = new Set();

export const mustBeUniqueEmail = initialEmail => value => {
  if (emailPool.has(value) && initialEmail !== value) {
    return 'Email already exists';
  }
  return undefined;
};

export const mustBeValidEmail = value =>
  validator.isEmail(value || '') ? undefined : 'Must be valid email';

export const mustBeAtLeast = atLeast => (value = 0) =>
  value && value.length >= atLeast ? undefined : `Must be at least ${atLeast}`;

export const maxLength = maxValue => value => {
  return !value || (value && validator.isLength(value, { max: maxValue }))
    ? undefined
    : `Can not be longer than ${maxValue}`;
};
export const maxEventNameLength = maxValue => value => {
  return !value || (value && validator.isLength(value, { max: maxValue }))
    ? undefined
    : `Event title can be at most ${maxValue} characters`;
};

export const playerOnlyOneSelected = (value, allValues) =>
  [!allValues.event_id, !allValues.agenda_item_id, !allValues.group_id].filter(
    x => x === false,
  ).length > 1
    ? 'Please select only one: Agenda item, Event or Group'
    : undefined;

export const playerAnySelected = (value, allValues) =>
  !allValues.event_id && !allValues.agenda_item_id && !allValues.group_id
    ? "Please select 'agenda item', 'event' or 'group"
    : undefined;

export const docsOnlyOneSelected = (value, allValues) =>
  allValues.event_id && allValues.agenda_item_id
    ? "Please select only 'agenda item' or 'event'"
    : undefined;

export const docsAnySelected = (value, allValues) =>
  !allValues.event_id && !allValues.agenda_item_id
    ? "Please select 'agenda item' or 'event'"
    : undefined;

export const translationOnlyOneSelected = (value, allValues) =>
  allValues.event_id && allValues.organization_id
    ? "Please select only 'organization' or 'event'"
    : undefined;

export const translationAnySelected = (value, allValues) =>
  !allValues.event_id && !allValues.organization_id
    ? "Please select 'organization' or 'event'"
    : undefined;

export const agoraioValidConfig = (value, allValues) =>
  (allValues.agoraio_app_id && !allValues.agoraio_app_certificate) ||
  (!allValues.agoraio_app_id && allValues.agoraio_app_certificate)
    ? 'Please set pair conference+_app_certificate and conference+_app_id'
    : undefined;

export const requiredMmLanguage = (value, group) =>
  group && !value ? 'Please select multi market language' : undefined;

export const numberOfAllowedDevices = value => {
  if (!value) {
    return undefined;
  }
  if (validator.isInt(`${value}`, { min: 2, max: 100 })) {
    return undefined;
  }

  return 'Please enter a number between 2 and 100 inclusive';
};

export const validDomainName = value => {
  // https://stackoverflow.com/a/7933253
  if (/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/.test(value)) {
    return undefined;
  }

  return 'Please enter correct domain name';
};

export const validateOrganizationRegistrationFields = arr => {
  if (!arr?.length) return undefined;

  return arr.reduce(
    (curr, val) => [
      ...curr,
      {
        name: required(val.name),
        field_type: required(val.field_type),
        visibility_scope: required(val.visibility_scope),
        field_name: required(val.field_name),
      },
    ],
    [],
  );
};

export const validateIfRoleIs = (value, role, rolesToMatch) => {
  if (!rolesToMatch.includes(role)) return undefined;

  return !value ? notEmpty(value) : undefined;
};

export const validateEventTag = value => {
  if (!value || /^[A-Za-z0-9-_]{1,80}$/.test(value)) return undefined;

  return 'Event tag has to have only letters, numbers and "_", "-", also should be less than 80 characters';
};

export const atLeastOneEventOrOrganization = (validating, other) => {
  if (validating?.length) return undefined;

  if (other?.length) return undefined;

  return 'Please add event or organization or both';
};

export const chatMsgImgOrMsgRequired = (msg, img) => {
  return !msg && (!img || (!(img instanceof File) && !img?.thumb?.url))
    ? "Please fill 'message' or 'image'"
    : undefined;
};

export const publicBRMapping = (value, isPublicEvent) => {
  return (value === 'agoraio' || value === 'spatial_chat') && isPublicEvent
    ? 'Cannot set Conference+/Spaces mapping type for public events. Please reselect mapping type.'
    : undefined;
};

export const awsHlsStackNameValid = (value, values) => {
  if (values.player_type !== PLAYER_TYPES.AWS_HLS_STREAM) return undefined;
  if (!value?.length && values.player_type === PLAYER_TYPES.AWS_HLS_STREAM)
    return `Name can't be blank`;

  if (!/^[A-Za-z][A-Za-z0-9-]{0,109}$/.test(value)) {
    // eslint-disable-next-line max-len
    return "Stack name can contain only alphanumeric characters (case-sensitive) and hyphens. It must start with an alphabetic character and can't be longer than 110 characters.";
  }

  return undefined;
};

export const playerUrlPresence = (value, values) => {
  if (!value?.length && values.player_type !== PLAYER_TYPES.DEFAULT)
    return undefined;

  if (!value?.length && values.player_type === PLAYER_TYPES.DEFAULT)
    return `This field is required`;

  return undefined;
};

export const fileOrUrlPresent = values => {
  const fileExisting = values?.file?.size || values?.file?.url;
  const isSourceUrlExiting = !!values?.source_url;
  if (
    (fileExisting && isSourceUrlExiting) ||
    (!fileExisting && !isSourceUrlExiting)
  ) {
    return 'Please fill only file or source URL';
  }
  return undefined;
};

export const thumbnailPresentIfNoFile = (value, values) => {
  const fileExisting = values?.file?.size || values?.file?.url;
  if (!fileExisting && !value) {
    return 'Please upload custom thumbnail';
  }
  return undefined;
};

export const verifyRestrictedDbFields = (fields, index) => (value, values) => {
  if (
    fields.includes(value) &&
    !fields.includes(values.registration_fields?.[index].field_type)
  )
    return `Can not use this name, it is reserved for "${value}" type`;

  return undefined;
};

export const validDomainEmail = value => {
  // same as Rails validation
  if (/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/.test(value)) {
    return undefined;
  }

  return 'Please enter correct domain name';
};

export const validArrayDomain = value => {
  if (!value) return undefined;
  const notValidDomains = value.filter(v => validDomainEmail(v));

  if (notValidDomains.length !== 0)
    return `${notValidDomains.join(', ')} ${
      notValidDomains.length === 1 ? 'is' : 'are'
    } not valid ${notValidDomains.length === 1 ? 'domain' : 'domains'}`;

  return undefined;
};

export const validDbName = value => {
  if (/[^a-z0-9_]/.test(value)) {
    return 'Only lowercase letters, numbers and underscore can be entered';
  }
  return undefined;
};

export const checkIfFileImage = value => {
  if (value && value.type && !value.type.startsWith('image')) {
    return 'File must be image!';
  }
  return undefined;
};
export const mappingUserExistence = (isDefault, users) => {
  if (!isDefault) {
    if (!Array.isArray(users) || users.length === 0) {
      return 'Add at least one user';
    }
  }
  return undefined;
};

export const stagedEventsExistence = events => {
  if (!Array.isArray(events) || events.length <= 1) {
    return 'Staged group must have at least two events';
  }
  if (events.length > 3) {
    return 'Staged group cannot contain more than three events';
  }

  return undefined;
};

export const validatevalidateMappingTypeAndPlayer = player => value => {
  return value !== MAPPING_TYPES.PLAYER && player
    ? 'Cannot have player for this mapping type. Please change mapping type or delete players.'
    : undefined;
};

export const validatePickedPlayer = player => value => {
  return !player && value === MAPPING_TYPES.PLAYER
    ? 'You must create at least one player'
    : undefined;
};

export const validateGroupUrl = player => value => {
  return value && player
    ? "Please fill 'Url/zoom link' or 'player' only"
    : undefined;
};

export const validatePollAnswers = value => {
  if (!value?.length) return true;
  return undefined;
};

export const fileOrUrl = input1 => input2 => {
  if (input1 && input2) {
    return 'Please fill only file or source URL';
  }
  return undefined;
};

export const chatModerator = (value, values) => {
  if (values.chat_mode === 'with_moderator_only') {
    return !value ? 'Please select moderator' : undefined;
  }
  return undefined;
};

export const paidEvent = (value, values) => {
  if (values.paid_event) {
    return !value
      ? 'Please fill this field if paid event field is set'
      : undefined;
  }

  return undefined;
};

export const correctInputPriceFormat = value => {
  if (value) {
    const regex = /^\d+(\.\d{1,2})?$/;
    if (!regex.test(value)) {
      return 'Please enter correct price format like: 123.45';
    }
  }
  return undefined;
};

export const shouldBeFutureDateTime = value => {
  if (value && moment(value).isAfter(moment())) {
    return undefined;
  }

  return 'Date and time cannot be in past';
};

export const validateStackName = value => {
  if (/^[a-zA-Z][-a-zA-Z0-9]*$/.test(value)) {
    return undefined;
  }
  return 'Only letters, numbers and hyphen can be entered';
};
