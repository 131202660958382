import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '@components/Image';
import { hexToRgba } from '@utils/stylingUtils';

export const Container = styled(Box)`
  display: flex;
`;

export const Dropzone = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed
    ${({ active, theme }) =>
      active ? theme.palette.primary.main : theme.palette.grey[600]};
  border-radius: 3px;
  height: 150px;
  transition: ${({ theme }) =>
    theme.transitions.create(['border-color'], { duration: 300 })};

  &:hover {
    border: 2px dashed
      ${({ theme, active }) =>
        hexToRgba(
          active ? theme.palette.primary.main : theme.palette.grey[600],
          0.6,
        )};
  }
`;

export const DropzoneText = styled(Box)`
  color: ${({ theme, active }) =>
    active ? theme.colors.primary.main : theme.palette.grey[600]};
  padding-left: 13px;
`;

export const IconWrapper = styled(Box)`
  ${({ disabled }) => disabled && `display: none`}
  color: #000000;
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  transition: all 0.2s ease-in;
  :hover {
    transform: scale(1.1) translateY(-50%);
  }

  ${({ position }) => position === 'corner' && `top: 4px; right: -8px;`}
`;

export const PreviewImgWrapper = styled(Box)`
  position: relative;
  ${props =>
    props.label === 'Avatar'
      ? `

      width: 100%;
      height: auto;
      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    `
      : `
      width: fit-content;
      text-align: center;
    `}
`;

export const PreviewImage = styled(Image)`
  ${props =>
    props.label === 'Avatar'
      ? `
      position: absolute;
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `
      : `
      max-height: 100%;
      max-width: 100%;
    `}
`;

export const Preview = styled(Box)`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: center;
  width: 100%;
`;

export const Title = styled(Box)`
  word-break: break-word;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.text};
`;

export const Size = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes.micro};
`;
