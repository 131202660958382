import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ProfileSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center" spacing="4px">
      <Skeleton variant="circular" height={35} width={35} />
      <Stack spacing="4px">
        <Skeleton variant="rectangular" width="110px" height={14} />
        <Skeleton variant="rectangular" width="70px" height={10} />
      </Stack>
    </Stack>
  );
};

export default ProfileSkeleton;
