import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

const COLORS = theme => ({
  standard: {
    backgroundColor: theme.palette.grey[200],
    color: theme.colors.text,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  success: {
    backgroundColor: theme.palette.green[200],
    color: theme.colors.text,
    '&:hover': {
      backgroundColor: theme.palette.green[300],
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    },
  },
});

const TableIconButton = ({ children, sx, color, tooltip, ...props }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top">
        <IconButton
          sx={theme => ({
            ...(COLORS(theme)[color] || COLORS(theme).standard),
            ...sx,
          })}
          {...props}
        >
          {children}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton
      sx={theme => ({
        ...(COLORS(theme)[color] || COLORS(theme).standard),
        ...sx,
      })}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default TableIconButton;
