import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import truncate from 'lodash/truncate';
import routes from '@routes';
import Badge from '@components/Badge/Badge';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
`;

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.description};
`;

const Image = styled(CardMedia)`
  padding: 50px;
  background-color: ${({ theme }) =>
    theme.header.organizationSelectorBackground};
`;

const BadgeWrapper = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const EventCard = styled(Card)`
  min-height: 200;
  cursor: pointer;
  position: relative;
  height: 100%;
`;

function TimeBadge({
  agenda_start_datetime,
  planned_start_date,
  agenda_end_datetime,
}) {
  const theme = useTheme();
  const eventDuration = moment(agenda_end_datetime).diff(
    moment(agenda_start_datetime || planned_start_date),
  );

  const dayInMiliseconds = 1 * 24 * 60 * 60 * 1000;

  return (
    <BadgeWrapper>
      <Badge
        style={{
          background: theme.colors.primary,
          letterSpacing: '-0.02em',
        }}
      >
        {moment(agenda_start_datetime || planned_start_date).format(
          'Do MMM HH:mm',
        )}
        {' - '}
        {eventDuration > dayInMiliseconds &&
          moment(agenda_end_datetime).format('Do MMM HH:mm')}{' '}
        (
        {moment
          .duration(eventDuration, 'milliseconds')
          .humanize()
          .replace(/^(an|a)/g, 1)
          .replace(' ', '\u00a0')}
        )
      </Badge>
    </BadgeWrapper>
  );
}

export function PastEventCard({ event }) {
  const theme = useTheme();
  const history = useHistory();
  const { name, id, users_count } = event;

  return (
    <EventCard onClick={() => history.push(routes.event(id))}>
      <Image component="img" image={theme.header.logo} />
      {TimeBadge(event)}
      <CardContent>
        <Title>{truncate(name, { length: 30 })}</Title>
        <Subtitle>Attendances: {users_count}</Subtitle>
      </CardContent>
    </EventCard>
  );
}

export function UpcomingEventCard({ event }) {
  const theme = useTheme();
  const history = useHistory();
  const { name, id, attendances_count } = event;

  return (
    <EventCard onClick={() => history.push(routes.event(id))}>
      <Image component="img" image={theme.header.logo} />
      {TimeBadge(event)}
      <CardContent>
        <Title>{truncate(name, { length: 30 })}</Title>
        <Subtitle>Reigstered attendees: {attendances_count}</Subtitle>
      </CardContent>
    </EventCard>
  );
}
