import React from 'react';
import last from 'lodash/last';
import moment from 'moment';
import { Box } from '@mui/material';
import { percentageFromArrayValues } from '@utils/math';
import {
  Forum,
  InsertEmoticon,
  Comment,
  LiveHelp,
  LibraryAdd,
  Favorite,
  RecentActors,
  Poll,
} from '@mui/icons-material';
import { ITALICS_UNAVAILABLE, MAILER_TYPE } from '@constants';
import modalStore from '@stores/modalStore';
import { WarningModalContent } from '@components/WarningModalContent';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase(),
  );

export const getById = ({ id, arr }) => {
  if (id) {
    return arr && arr.find(ev => ev.id === id);
  }
  return null;
};
const dictionary = {
  header_image: 'header_background',
  header_profile_border: 'profile_border',
  speaker_tooltip_text_color: 'presenter_tooltip_text_color',
  landing_page_bckg_img: 'image_behind_login_form',
  landing_page_form_bottom_img: 'image_below_login_form',
  bottom_img_file: 'image_below_player_(desktop)',
  bottom_img_mobile_file: 'image_below_player_(mobile)',
  speakers_popup_header_img: 'presenter_popup_image',
  speaker_tab_visible: 'presenter_tab_visible',
  use_lite_settings_light_logo: 'use_light_styling_for_logo',
  _stream_reaction_icon_pos: 'video_reaction_position',
  button_active_reaction_bg: 'Reaction button overlay active',
  button_reaction_bg: 'Reaction button overlay',
  product_accent: 'Accent colour',
  sidebar_tab_separator: 'Chat Panel Tab Inactive Separator',
  sidebar_tab_highlight_gradient1: 'Chat Panel Tab Active Sep: Grad 1',
  sidebar_tab_highlight_gradient2: 'Chat Panel Tab Active Sep: Grad 2',
  chat_panel_highlight_color: 'Chat Panel Tab Active Text',
  chat_panel_text_color: 'Chat Panel Tab Inactive Text',
  event_brief_header_text: 'Event brief custom title',
  // theme general
  body1: 'Background Color',
  icons: 'Icon Color',
  back_button_color: 'Alternate Button Text',
  tooltip_bckg_color: 'Tooltip Background',
  buttons_bg: 'Button Background',
  buttons_bg_gradient1: 'Button Background Gradient 1',
  buttons_bg_gradient2: 'Button Background Gradient 2',
  buttons_text_color: 'Button Text',
  alternative_buttons_bg: 'Alternate Button Background',
  alternative_buttons_text_color: 'Alternate Button Text',
  callout_box_outline_gradient1: 'Callout Box Outline Gradient 1',
  callout_box_outline_gradient2: 'Callout Box Outline Gradient 2',
  no_content_text: 'No Content Text Color',
  tooltip_text_color: 'Tooltip Text',
  // theme landing
  event_reg_login_title_color: 'Left Title',
  landing_page_body: 'Left Background',
  event_brief_header_color: 'Right Title',
  event_brief_bg: 'Right Background',
  event_brief_text: 'Text Primary',
  event_brief_tabs_separator_color: 'Tabs Separator',
  event_brief_gradient_top: 'Right Background Gradient 1',
  event_brief_gradient_bottom: 'Right Background Gradient 2',
  event_brief_tabs_highlight_color: 'Tabs Highlight Color',
  event_brief_tabs_highlight_color_gradient1: 'Tabs Highlight Gradient 1',
  event_brief_tabs_highlight_color_gradient2: 'Tabs Highlight Gradient 2',
  event_brief_tabs_text_color: 'Inactive Tabs Color',
  event_brief_speaker_popup_color: 'Popup Color',
  event_brief_accent_color: 'Accent Color',
  event_brief_text_secondary: 'Text Secondary',
  event_brief_detail_color: 'Detail Color',
  event_brief_tile_background: 'Tile Background',
  event_brief_time_color: 'Time Color',
  // theme agenda
  main_gradient1: 'Background Gradient 1',
  main_gradient2: 'Background Gradient 2',
  agenda_details_title_color: 'Tabs Title Color',
  ai_bckg_active: 'Active Agenda Background',
  selected_agenda_outline_gradient1: 'Selected Agenda Gradient 1',
  selected_agenda_outline_gradient2: 'Selected Agenda Gradient 2',
  agenda_active_time_color: 'Active Agenda Time',
  agenda_inactive_time_color: 'Inactive Agenda Time',
  agenda_inactive_background_color: 'Inactive Agenda Background',
  agenda_timeline: 'Timeline Color',
  agenda_timeline_dots: 'Timeline Points Color',
  breakout_item_bckg: 'Inactive Breakout Background',
  breakout_item_bckg_active: 'Active Breakout Background',
  breakout_item_text: 'Inactive Breakout Text',
  breakout_item_active_text_color: 'Active Breakout Text',
  // theme tabs
  tabbed_sections_bg: 'Background Color',
  tabs_highlight_color: 'Active Tab',
  tabs_highlight_color_gradient1: 'Active Tab Gradient 1',
  tabs_highlight_color_gradient2: 'Active Tab Gradient 2',
  tabs_text_color: 'Inactive Tabs',
  speaker_popup_primary: 'Popup Color',
  speaker_tile_bg: 'Tile Background Color',
  sponsors_tab_text_color: 'Tabs Text Secondary',
  agenda_details_body_color: 'Tabs Text Primary',
  tabs_accent_color: 'Accent Color',
};

export const translateWithDictionary = str => {
  return dictionary[str] || str;
};

const fieldsAdditionalInfo = {
  landing_page_bckg_img: 'Ideal dimensions: 900x900',
  landing_page_form_bottom_img: 'Ideal dimensions: 800x200',
  bottom_img_file: 'Ideal dimensions: 1050x800',
  speakers_popup_header_img: 'Ideal dimensions: 1600x250',
  event_image: 'Event image for i.e. brief or CMS dashboard',
  event_image_thumbnail:
    'Event image thumbnail for i.e. brief or CMS dashboard',
  event_brief_bg_image: 'Ideal dimensions: 234:55 (max width of 1170px)',
  hybrid_player_image: 'Ideal dimensions: 16:9 (1920 x 1080px)',
  player_placeholder_image: 'Ideal dimensions: 16:9 (1920 x 1080px)',
  custom_default_avatar:
    'Ideal dimensions 1:1 (square). Max dimensions 200x200',
  global_message_avatar:
    'Ideal dimensions 1:1 (square). Max dimensions 200x200',
  header_logo: (
    <Box>
      File format: JPG/JPEG or PNG
      <br /> Ideal dimensions: 4934px by 221px
      <br /> Max file size: 500KB
    </Box>
  ),
  header_image: (
    <Box>
      File format: JPG/JPEG or PNG
      <br /> Max file size: 500KB
    </Box>
  ),
};

export const getFieldAdditionalInfo = str => {
  return fieldsAdditionalInfo[str];
};

const ckEditorSimpleConfigDisplay = [
  'header_custom_title',
  'event_listing_custom_title',
  'event_brief_header_text',
];

export const shouldShowSimpleConfig = name =>
  ckEditorSimpleConfigDisplay.includes(name);

const fieldsFileSizeLimit = {
  header_logo: { val: 500000, text: '500KB' },
  bottom_img_file: { val: 500000, text: '500KB' },
  event_image: { val: 500000, text: '500KB' },
  event_image_thumbnail: { val: 500000, text: '500KB' },
  image_below_event_series: { val: 500000, text: '500KB' },
  landing_page_bckg_img: { val: 500000, text: '500KB' },
  landing_page_form_bottom_img: { val: 500000, text: '500KB' },
  event_brief_bg_image: { val: 500000, text: '500KB' },
  header_image: { val: 500000, text: '500KB' },
  speakers_popup_header_img: { val: 500000, text: '500KB' },
  custom_default_avatar: { val: 500000, text: '500KB' },
  global_message_avatar: { val: 500000, text: '500KB' },
  hybrid_player_image: { val: 500000, text: '500KB' },
  player_placeholder_image: { val: 500000, text: '500KB' },
  bottom_img_mobile_file: { val: 500000, text: '500KB' },
};

export const getFileSizeLimit = str => {
  return fieldsFileSizeLimit[str];
};

const eventsDictionary = {
  // CHAT REACTIONS EVENTS
  'chat_message_reaction delete': 'Deleted Msg Reaction',
  'chat_message_reaction update': 'Updated Msg Reaction',
  'chat_message_reaction create': 'Created Msg Reaction',

  // CHAT MSG EVENTS
  'chat_message create': 'Message to',
  'direct_chat_message create': 'Message to', // direct chat msg to recipient

  // Q&A EVENTS
  'chat_question create': 'Posted Question',
  'chat_question vote': 'Voted Question',

  // CHATROOM EVENTS
  'chat_room create': 'Created Chat Room',

  // USER EVENTS
  'user sign_in': 'Joined Event',
  'user sign_up': 'Created Chat Room',
  'user sso_sign_in': 'Joined Event Via SSO',
  // note: missing left event

  // VIDEO REACTION EVENTS
  'video_reaction create': 'Video Reacted',

  // NETWORKING EVENTS
  'networking_contact_request create': 'Requested Contact',

  // POLL EVENTS
  'poll_answer vote': 'Voted in Poll',
  'poll_answer unvote': 'Unvoted in Poll',

  // CLICKS EVENTS
  'document download': 'Downloaded document',
  'explore_item get': 'Visited link',
  'product get': 'Visited link',
  'group join': 'Joined group',
  'group left': 'Left group',
};

export const translateEventDictionary = str => {
  return eventsDictionary[str] || capitalize(str.replace(/_/g, ' '));
};

export const getEventKey = (eventSubtype, eventSubtypeAction) => {
  return `${eventSubtype} ${eventSubtypeAction}`;
};

export const getEventTitleDetails = (eventKey, trackableResource) => {
  let titleDetails = '';
  if (eventKey && trackableResource) {
    if (eventKey === 'chat_question vote') {
      titleDetails = `${truncate(trackableResource.question, 10)}`;
    }
    if (eventKey === 'poll_answer vote' || eventKey === 'poll_answer unvote') {
      titleDetails = ` ${truncate(trackableResource?.question, 10)}`;
    }
    if (eventKey === 'chat_room create') {
      if (trackableResource?.name) {
        titleDetails = `${truncate(trackableResource?.name, 10)}`;
      }
    }
    if (eventKey === 'chat_message create') {
      if (trackableResource.chat_room_type === 'default') {
        titleDetails = 'All';
      } else if (trackableResource.chat_room_type === 'group') {
        titleDetails = 'Group';
      } else if (trackableResource.chat_room_type === 'private') {
        if (trackableResource.recipient_name) {
          titleDetails = trackableResource?.recipient_name;
        }
      }
    }
    if (eventKey === 'direct_chat_message create') {
      if (trackableResource.recipient_name) {
        titleDetails = trackableResource?.recipient_name;
      }
    }
  }
  return titleDetails;
};

export const getMoreDetails = (
  eventSubtype,
  trackableResource,
  eventSubtypeAction,
) => {
  let details = '';
  if (eventSubtype && trackableResource && eventSubtypeAction) {
    if (
      eventSubtype === 'chat_message_reaction' ||
      eventSubtype === 'video_reaction'
    ) {
      details = trackableResource.reaction_type;
    }
    if (eventSubtype === 'chat_message') {
      details = stripHtmlTags(trackableResource.message);
    }
    if (eventSubtype === 'chat_question' && eventSubtypeAction === 'create') {
      details = trackableResource.question;
    }
    if (eventSubtype === 'chat_question' && eventSubtypeAction === 'vote') {
      // TODO: VOTE VALUE FROM Q&A
      // if (trackableResource.voted === false) {
      //   details = 'Downvoted';
      // } else {
      //   details = 'Upvoted';
      // }
    }
    if (eventSubtype === 'poll_answer') {
      details = trackableResource.answer;
    }
    if (eventSubtype === 'document') {
      details = trackableResource.name;
    }
    if (eventSubtype === 'explore_item') {
      details = trackableResource.url;
    }
    if (eventSubtype === 'product') {
      details = trackableResource.action_button_url;
    }
    if (eventSubtype === 'networking_contact_request') {
      details = trackableResource.name;
    }
    if (eventSubtype === 'group') {
      details = trackableResource.name;
    }
  }
  return details;
};

export const getLocalTimezone = () => {
  try {
    const date = new Date();
    const string = date.toLocaleTimeString(undefined, {
      timeZoneName: 'short',
    });
    return last(string.split(' '));
  } catch (e) {
    return '';
  }
};

export const stripHtmlTags = unsafe => {
  return unsafe?.replace(/<\/?[^>]+(>|$)/g, '');
};

export const truncate = (str, n) => {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const isLiveAgendaItem = (currAi, ais) => {
  if (currAi.live) return true;

  if (moment().isBetween(currAi.start_date, currAi.end_date)) {
    return !ais.find(ai => ai.live === true);
  }

  return false;
};

export const constructBaseUrl = organization => {
  const [protocol, url] = process.env.REACT_APP_BASE_WWW_URL.split('//');
  if (organization) {
    return `${protocol}//${organization}.${url}sign-in`;
  }
  return `${protocol}//<organization>.${url}sign-in`;
};

export const destructStats = (stats = [{}]) => {
  const values = stats.map(el =>
    el.value !== undefined ? el.value : el.count,
  );
  const labels = stats.map(el => el.label || el.name);
  const percentages = percentageFromArrayValues(values);
  const data = stats.map(el => ('data' in el ? destructStats(el.data) : {}));

  return {
    values,
    labels,
    percentages,
    data,
  };
};

export const formatPollString = (message, index) =>
  (message || '').startsWith('Poll') ? `Poll #${index}` : message;

const iconStyle = {
  position: 'absolute',
  top: '11px',
  right: '11px',
};

export const IconOfSubtype = {
  chat_message_reaction: ({ analytics }) => (
    <InsertEmoticon
      sx={{ ...iconStyle, color: analytics.sidebarUserActivity.one }}
    />
  ),
  chat_message: ({ analytics }) => (
    <Comment sx={{ ...iconStyle, color: analytics.sidebarUserActivity.one }} />
  ),
  direct_chat_message: ({ analytics }) => (
    <Forum sx={{ ...iconStyle, color: analytics.sidebarUserActivity.one }} />
  ),
  chat_question: ({ analytics }) => (
    <LiveHelp
      sx={{
        ...iconStyle,
        color: analytics.sidebarUserActivity.one,
      }}
    />
  ),
  chat_room: ({ analytics }) => (
    <LibraryAdd
      sx={{ ...iconStyle, color: analytics.sidebarUserActivity.one }}
    />
  ),
  video_reaction: ({ analytics }) => (
    <Favorite sx={{ ...iconStyle, color: analytics.sidebarUserActivity.one }} />
  ),
  networking_contact_request: ({ analytics }) => (
    <RecentActors
      sx={{
        ...iconStyle,
        color: analytics.sidebarUserActivity.one,
      }}
    />
  ),
  poll_answer: ({ analytics }) => (
    <Poll
      sx={{
        ...iconStyle,
        color: analytics.sidebarUserActivity.one,
      }}
    />
  ),
  user: null,
  document: null,
  explore_item: null,
  product: null,
  group: null,
};

export const generateSortingConfigForColumn = (key, params) => ({
  sortable: true,
  columnKey: key,
  currentDirection: new RegExp(`^${key} (ASC|DESC)$`).test(params.sort)
    ? params.sort.split(' ')[1]
    : null,
});

// detect the support for the plaintext-only
export const supportsPlainText = () => {
  const d = document.createElement('div');
  try {
    d.contentEditable = 'PLAINtext-onLY';
  } catch (e) {
    return false;
  }
  return d.contentEditable === 'plaintext-only';
};

export const checkFontItalicsAvailable = ev => {
  return {
    header: ITALICS_UNAVAILABLE.includes(
      ev?.event_custom_theme?.header_font_family,
    ),
    body: ITALICS_UNAVAILABLE.includes(
      ev?.event_custom_theme?.body_font_family,
    ),
  };
};

export const resolveEmailTooltipHelper = (
  isInFuture,
  type,
  translations,
  fieldName = '',
) => {
  if (!isEmpty(translations)) {
    if (type === MAILER_TYPE.REMINDER && fieldName === 'subject') {
      return translations[
        `${type}:subject_${isInFuture ? 'future' : 'past'}`
      ].replaceAll('%', '');
    }
    if (type === MAILER_TYPE.REMINDER && fieldName === 'title') {
      return translations[
        `${type}:title_${isInFuture ? 'future' : 'past'}`
      ].replaceAll('%', '');
    }

    if (type === MAILER_TYPE.ACCESS_CONFIRMATION && fieldName === 'body') {
      return `
    <p style="text-align:justify;line-height: 24px; margin-bottom:0px; ">
    ${translations[`${type}:hello`].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
    ${translations[`${type}:body1`].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
    ${translations[`${type}:body2`].replaceAll('%', '')}
    </p>
  `;
    }
    if (type === MAILER_TYPE.SIGN_UP_CONFIRMATION && fieldName === 'body') {
      return `
    <p style="text-align:justify;line-height: 24px; margin-bottom:0px; ">
      ${translations[`${type}:hello`].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
      ${translations[`${type}:body1`].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
      ${translations[`${type}:body2`].replaceAll('%', '')}
    </p>
  `;
    }
    if (type === MAILER_TYPE.REMINDER && fieldName === 'body') {
      return `
    <p style="text-align:justify;line-height: 24px; margin-bottom:0px; ">
      ${translations[`${type}:hello`].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
      ${translations[
        `${type}:body1_${isInFuture ? 'future' : 'past'}`
      ].replaceAll('%', '')}
    </p>
    <p style=" margin-bottom:0px;margin-top:25px!important;">
      ${translations[`${type}:body2`].replaceAll('%', '')}
    </p>
  `;
    }
    return translations[`${type}:${fieldName}`]?.toString().replaceAll('%', '');
  }
  return '';
};

export const redirectWithWarning = (form, text, fn) => {
  return () => {
    const { values, initialValues } = form.getState();
    if (isEqual(values, initialValues)) {
      fn();
      return;
    }

    modalStore.openModal({
      content: (
        <WarningModalContent
          msg={text}
          onCancel={modalStore.closeModal}
          onConfirm={async () => {
            await modalStore.closeModal();
            fn();
          }}
        />
      ),
    });
  };
};
