import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import Image from '@components/Image';
import routes from '@routes';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const StyledImg = styled(Image)`
  width: 8em;
  height: 8em;
`;

const Button = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 50px;
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  text-transform: uppercase;
  color: #60ddff;
  border-radius: 10px;
  background-color: #2f354f;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    background-color: #9feaf9;
    color: #2f354f;
  }
`;

const StyledText = styled(Box)`
  color: #2e354f;
`;

const NotFoundPage = ({ profileStore: { profile } }) => {
  return (
    <Container>
      <StyledImg
        draggable="false"
        alt="🤷"
        src="https://twemoji.maxcdn.com/v/13.0.1/svg/1f937.svg"
      />
      <StyledText mt={4} mb={2} fontSize="28px" fontWeight="fontWeightBold">
        404 Page Not Found
      </StyledText>
      <StyledText>Please check URL provided</StyledText>
      <Button to={profile ? routes.main.dashboard : routes.auth.signIn}>
        back to homepage
      </Button>
    </Container>
  );
};

export default inject('profileStore')(observer(NotFoundPage));
