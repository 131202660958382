import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const AvatarCharWrapper = styled('div')`
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
`;

export const FiltersWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
`;

export const SelectWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;
