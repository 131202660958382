import React from 'react';
import { useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Loader } from '@components/Loader';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  textBtnRoot: {
    fontFamily: theme.fonts.main,
  },
}));

const TextButton = ({ label, isLoading, disabled, onClick }) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <LoadingButton
      classes={{ root: classes.textBtnRoot }}
      disabled={disabled}
      onClick={onClick}
      loading={isLoading}
      loadingIndicator={<Loader stroke="#fff" width="50px" />}
    >
      {label}
    </LoadingButton>
  );
};

export default TextButton;
