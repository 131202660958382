import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

export const useSorting = (params, { method, noPage } = {}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  return useCallback(
    sortingKey => {
      let value;
      if (!params.sort) {
        value = `${sortingKey} ASC`;
      } else if (params.sort === `${sortingKey} ASC`) {
        value = `${sortingKey} DESC`;
      } else if (!new RegExp(`^${sortingKey} (ASC|DESC)$`).test(params.sort)) {
        value = `${sortingKey} ASC`;
      }

      history[method || 'push'](
        `${pathname}?${qs.stringify({
          ...params,
          page: noPage ? undefined : 1,
          sort: value,
        })}`,
      );
    },
    [history, pathname, params, method],
  );
};
