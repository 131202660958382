import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  banners: null,
  eventsSelectOptions: [],
  isDeletingId: null,
};

export class BannersPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getBanners = async ({
    page,
    search,
    sort,
    event_id,
    silent,
    active,
  }) => {
    try {
      if (!silent) this.isLoading += 1;

      const res = await ResourcesStore.getBanners({
        page,
        search,
        sort,
        event_id,
        active,
      });
      this.banners = {
        ...res,
        page: res.page_number.toString(),
        search,
        sort,
        event_id,
        active,
      };
    } catch (err) {
      console.debug('[getBanners] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'event_id', 'active'].every(
      param => params[param] === this.banners?.[param],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action handleBannerSwitch = async ({ id, payload, onSuccess, onError }) => {
    try {
      const result = await ResourcesStore.updateBanner({
        id,
        payload,
        onSuccess,
        onError,
      });
      return result;
    } catch (err) {
      console.debug('[handleBannerSwitch] failed', err);
      return Promise.reject(err);
    }
  };

  @action deleteBanner = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteBanner({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[deleteBanner] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new BannersPageStore();
