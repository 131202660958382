import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { Box } from '@mui/material';
import MaterialSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import get from 'lodash/get';
import FormControl from '@mui/material/FormControl';
import { capitalizeFirstLetter } from '@utils/stylingUtils';
import FormHelperText from '@mui/material/FormHelperText';
import { SelectLoader } from '@app/components/SelectLoader';
import FieldTooltip from '@components/FieldTooltip';

const Select = ({
  classes,
  meta,
  label,
  selectOptions,
  displayLabelValue,
  input,
  required,
  getLabelPath,
  disabled,
  onChangeCallback,
  previewComponent: PreviewComponent,
  inputProps,
  isLoading,
  infoText,
  notAvailableInSP,
  notAvailableInStages,
  ...props
}) => {
  const handleSelect = e => {
    if (onChangeCallback !== undefined) {
      onChangeCallback(e);
    }

    const { value } = e.target;
    input.onChange(value);
  };

  const getSelectedDisplayValue = () => {
    if (getLabelPath) return getLabelPath(input.value);
    if (displayLabelValue) {
      return selectOptions.find(el => el.value === input.value)?.label;
    }

    return input.value;
  };

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <>
      <FormControl
        sx={{ width: '100%' }}
        variant="standard"
        required={required}
      >
        {label && (
          <InputLabel error={Boolean(error)} id={label}>
            {label}
          </InputLabel>
        )}
        <MaterialSelect
          value={input.value !== null ? input.value : ''}
          onChange={handleSelect}
          input={
            <Input
              inputProps={inputProps}
              error={Boolean(error)}
              id={get(inputProps, 'id')}
            />
          }
          renderValue={() => (
            <Box display="flex" alignItems="center">
              {PreviewComponent ? (
                <PreviewComponent>
                  {getLabelPath ? getLabelPath(input.value) : input.value}
                </PreviewComponent>
              ) : (
                <Box sx={theme => ({ fontFamily: theme.fonts.main })}>
                  {capitalizeFirstLetter(getSelectedDisplayValue())}
                </Box>
              )}
            </Box>
          )}
          disabled={disabled}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          {...props}
        >
          {isLoading && <SelectLoader />}
          {!isLoading &&
            selectOptions.map(({ label: itemLabel, value }, idx) => (
              <MenuItem key={`menu-item-${idx}`} value={value}>
                {itemLabel}
              </MenuItem>
            ))}
        </MaterialSelect>

        <FieldTooltip
          infoText={infoText}
          corner
          notAvailableInSP={notAvailableInSP}
          notAvailableInStages={notAvailableInStages}
        />
      </FormControl>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default Select;
