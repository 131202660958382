import { action, extendObservable, computed } from 'mobx';
import moment from 'moment';
import ResourcesStore from '@stores/resourcesStore';
import { CancelToken } from '@app/api';
import routerStore from '@stores/routerStore';
import routes from '@routes';
import {
  ATTENDANCE_MODES,
  ATTENDANCE_STATUSES,
  FORM_TYPES,
  NETOWRKING_NOT_SET,
} from '@constants';

const initialState = {
  isLoading: false,
  isUsersLoading: false,
  events: [],
  submitResult: undefined,
  defaultSectionValues: [],
  formType: null,
};
export class AttendanceStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = ({ id, user_id }) => {
    this.__cancelToken = CancelToken.source();

    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }
    if (this.isCreate) {
      this.getResources({ user_id });
    }
    if (this.isEdit) {
      this.getResources({ id, user_id });
    }
  };

  @action submitForm = (...props) => {
    if (this.isEdit) {
      return this.updateAttendance(...props);
    }
    if (this.isCreate) {
      return this.batchCreateAttendances(...props);
    }

    return null;
  };

  @action updateAttendance = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;

      const { attendances } = values;
      const payload = this.prepareSectionToSubmit(attendances[0]);
      const data = await ResourcesStore.updateAttendance({
        id: this.attendance.id,
        payload,
        onSuccess,
      });
      this.attendance = data;
      this.setDeafultSectionValue({});
    } catch (error) {
      console.debug('[updateAttendance] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteAttendance = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteAttendance({ id, onSuccess });
    } catch (error) {
      console.debug('[deleteAttendance] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action batchCreateAttendances = async ({ values, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      const { attendances } = values;
      const data = {
        attendances: (attendances || []).map(attendance =>
          this.prepareSectionToSubmit(attendance),
        ),
      };
      this.submitResult = await ResourcesStore.batchCreateAttendances({
        payload: data,
        onSuccess,
        onError,
      });
      const parsedErrors = this.submitResult
        .filter(({ status }) => status === 422)
        .map(({ errors }) => ({
          event_id: errors.event_id ? errors.event_id[0] : undefined,
          user_id: errors.user_id ? errors.user_id[0] : undefined,
        }));
      return { attendances: parsedErrors };
    } catch (error) {
      console.debug('[batchCreateAttendances] failed', error);
      return Promise.reject(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getResources = async ({ id, user_id }) => {
    try {
      if (this.isEdit) {
        await this.getAttendance({ id });
      }
      await Promise.allSettled([this.getEventSelectOptions()]);
      this.setDeafultSectionValue({ user_id });
    } catch (error) {
      console.debug('[getResources] failed', error);
    }
  };

  @action getAttendance = async ({ id }) => {
    try {
      this.isLoading = true;

      const data = await ResourcesStore.getAttendance({ id });
      this.attendance = data;
    } catch (error) {
      routerStore.push(routes.main.attendances);
      console.debug('[getAttendance] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.events = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (error) {
      console.debug('[getEventSelectOptions] failed', error);
    }
  };

  @action getUsersList = async ({ search, id }) => {
    try {
      this.isUsersLoading = true;
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
      });
      return users;
    } catch (error) {
      console.debug('[getUsersList] failed', error);
      return Promise.reject(error);
    } finally {
      this.isUsersLoading = false;
    }
  };

  @action prepareSectionToSubmit = attendance => {
    return {
      ...attendance,
      event_id: attendance.event_id.value,
      networking_set_date:
        attendance.networking_enabled === NETOWRKING_NOT_SET
          ? null
          : attendance.networking_set_date,
      networking_enabled:
        attendance.networking_enabled === NETOWRKING_NOT_SET
          ? false
          : attendance.networking_enabled,
    };
  };

  @action setDeafultSectionValue = ({ user_id }) => {
    if (this.isEdit) {
      this.defaultSectionValues = [
        {
          ...this.attendance,
          event_id: this.events.find(
            ev => ev.value === this.attendance.event_id,
          ),
          mode: this.attendance.mode,
          status: this.attendance.status,
          networking_enabled: this.attendance.networking_set_date
            ? this.attendance.networking_enabled
            : NETOWRKING_NOT_SET,
          networking_set_date: this.attendance.networking_set_date || moment(),
        },
      ];
    } else {
      this.defaultSectionValues = [
        {
          event_id: undefined,
          user_id,
          status: ATTENDANCE_STATUSES.ACCEPTED,
          mode: ATTENDANCE_MODES.VIRTUAL,
          hide_mode: false,
          networking_enabled: NETOWRKING_NOT_SET,
          networking_set_date: moment(),
        },
      ];
    }
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new AttendanceStore();
