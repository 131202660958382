import { action, observable } from 'mobx';
import moment from 'moment';

export class ModalStore {
  @observable modalsArray = [];

  @action removeContent = ({ key }) => {
    const modal = this.modalsArray.find(m => key === m.key);
    if (modal) {
      this.modalsArray.remove(modal);
      modal.resolvePromise();
      modal.onCloseCallback?.();
    }
  };

  @action closeModal = async ({ onCloseCallback, key } = {}) => {
    let modalObj = null;
    if (key) modalObj = this.modalsArray.find(m => m.key === key);
    else modalObj = this.modalsArray.find(m => m.opened);

    if (modalObj) {
      modalObj.opened = false;
      modalObj.onCloseCallback = onCloseCallback || null;
      modalObj.closePromise = new Promise(resolve => {
        modalObj.resolvePromise = resolve;
      });
      await modalObj.closePromise;
    }
  };

  @action killModal = key => {
    const modal = this.modalsArray.find(m => key === m.key);
    if (modal) {
      this.modalsArray.remove(modal);
      modal.resolvePromise?.();
      modal.onCloseCallback?.();
    }
  };

  @action openModal = async ({ content, disableBackgroundClose, name }) => {
    this.modalsArray.unshift({
      opened: true,
      content,
      disableBackgroundClose,
      onCloseCallback: null,
      closePromise: Promise.resolve(),
      resolvePromise: null,
      key: name || Math.round(moment().unix() * Math.random()),
    });
  };
}

export default new ModalStore();
