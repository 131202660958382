import ResourcesStore from '@stores/resourcesStore';

export class BannerCloneService {
  static prepareBanner = async banId => {
    const ban = await ResourcesStore.getBanner({ id: banId });

    ban.active = false;
    const { id, ...strippedBan } = ban;

    return {
      ...strippedBan,
    };
  };
}
