import { action, extendObservable, computed } from 'mobx';
import { CancelToken } from '@app/api';
import ResourcesStore from '@stores/resourcesStore';
import {
  manipulateRegistrationFieldsArray,
  mapRfErrors,
} from '@utils/formUtils';
import { FORM_TYPES } from '@constants';

const initialState = {
  isLoading: false,
  isLoadingAudiences: false,
  initialLoading: true,
  organization: {},
  audiences: [],
  linkedEvents: [],
  defaultFormValues: {},
  subscriptionPlans: [],
  audienceError: undefined,
  formType: null,
};

export class OrganizationStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateOrganization(...props);
    }
    if (this.isCreate) {
      return this.createOrganization(...props);
    }

    return null;
  };

  @action getOrganization = async ({ id }) => {
    try {
      this.organization = await ResourcesStore.getOrganization({ id });
    } catch (err) {
      console.debug('[getOrganization] failed', err);
      throw err;
    }
  };

  @action updateOrganization = async ({ id, values, onSuccess }) => {
    this.isLoading = true;

    const onS = payload => {
      if (onSuccess) onSuccess(payload);
      this.organization = payload;
      this.setupDefaultFormValues();
    };

    try {
      await ResourcesStore.updateOrganization({
        id,
        payload: this.prepareValues(values),
        onSuccess: onS,
      });

      return null;
    } catch (e) {
      console.debug('[updateOrganization] failed', e);
      const error_msgs = e?.response?.data?.errors;

      if (error_msgs) {
        return mapRfErrors(error_msgs);
      }
      return e?.response?.data;
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValues = values => {
    const {
      organization_registration_fields,
      subscription_plan_id,
      ...newValues
    } = values;
    return {
      ...newValues,
      // event_ids: newValues.event_ids.map(ev => ev.value.id),
      subscription_plan_id: subscription_plan_id?.value || null,
      organization_registration_fields_attributes: manipulateRegistrationFieldsArray(
        organization_registration_fields,
        this.defaultFormValues.organization_registration_fields,
      ),
    };
  };

  @action createOrganization = async ({ values, ...props }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.createOrganization({
        payload: this.prepareValues(values),
        ...props,
      });

      return null;
    } catch (e) {
      console.debug('[createOrganization] failed', e);
      const error_msgs = e?.response?.data?.errors;

      if (error_msgs) {
        return mapRfErrors(error_msgs);
      }
      return e?.response?.data;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteOrganization = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteOrganization({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteOrganization] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;

    try {
      const promises = [this.getSubscriptionPlanSelectOptions()];

      if (this.isEdit) {
        await this.getOrganization({ id });
      }

      if (this.organization?.mailchimp_api_key) {
        await this.getAudiences({ key: this.organization.mailchimp_api_key });
      }

      await Promise.all(promises);
      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed');
    } finally {
      this.initialLoading = false;
    }
  };

  @action getSubscriptionPlanSelectOptions = async () => {
    const { results } = await ResourcesStore.getSubscriptionPlanSelectOptions();
    this.subscriptionPlans = results;
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.organization,
      // event_ids: this.organization.event_ids.map(id =>
      //   this.events.find(ev => ev.value.id === id),
      // ),
      subscription_plan_id: this.organization.subscription_plan_id
        ? this.subscriptionPlans.find(
            plan => plan.value === this.organization.subscription_plan_id,
          )
        : null,
    };
  };

  @action getAudiences = async ({ key, item }) => {
    this.isLoadingAudiences = true;
    let keyVal = null;
    if (key) {
      keyVal = key;
    } else {
      keyVal = item.target.value;
    }

    try {
      this.audienceError = undefined;
      const audiences = await ResourcesStore.getAudiences(keyVal);

      if (audiences?.lists) {
        this.audiences = audiences.lists.map(list => ({
          label: `${list.name} (${list.id})`,
          value: list.id,
        }));
      }

      if (audiences?.error) {
        this.audienceError = audiences?.error;
        this.audiences = [];
      }
    } catch (err) {
      console.debug('[getAudiences] failed', err);
      throw err;
    } finally {
      this.isLoadingAudiences = false;
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new OrganizationStore();
