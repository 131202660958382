import React from 'react';
import { Autocomplete, Box } from '@mui/material';
import TextField from '@components/TextField';
import FieldTooltip from '@components/FieldTooltip';

const FontSelectInput = ({
  meta,
  label,
  input,
  required,
  disabled,
  availablefonts,
  infoText,
  notAvailableInSP,
}) => {
  const handleSelect = (_, val) => {
    input.onChange(val);
  };

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box position="relative" width="100%">
      <Autocomplete
        value={input.value} // prevent from empty string when not multiple and array as a default for multiple
        onChange={handleSelect}
        options={availablefonts}
        disabled={disabled}
        renderInput={iProps => (
          <TextField
            error={Boolean(error)}
            label={label}
            helperText={error}
            size="small"
            fullWidth
            required={required}
            {...iProps}
          />
        )}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            <Box sx={{ fontFamily: option }}>{option}</Box>
          </li>
        )}
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default FontSelectInput;
