import Moment from 'moment';
import { extendMoment } from 'moment-range';

export const moment = extendMoment(Moment);

export const DATE_FORMATS = {
  DEFAULT: 'DD MMM YYYY',
  URL: 'YYYY-MM-DD',
  EXTENDED: 'DD/MM/YYYY HH:mm',
};

export const formatDate = (
  date,
  format = DATE_FORMATS.DEFAULT,
  initialFormat = 'YYYY-MM-DD',
) => {
  const d = moment(date, initialFormat);
  const isValidDate = d.isValid();

  if (isValidDate) {
    return d.format(format);
  }
  console.debug(`Invalid date format: ${date}`); // eslint-disable-line no-console
  return date;
};

export const getDuration = duration => moment.duration(duration);

export const formatDuration = value => {
  const duration = getDuration(value);

  return `${
    duration.days() > 0 ? `${duration.days()} days,` : ''
  } ${duration.hours()} hrs, ${duration.minutes()} min`;
};
