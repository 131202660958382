import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '@components/Image';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
`;

const StyledImg = styled(Image)`
  width: 8em;
  height: 8em;
`;

const StyledText = styled(Box)`
  color: #2e354f;
`;

const EmailVerificationErrorPage = () => {
  return (
    <Container>
      <StyledImg
        draggable="false"
        alt="🤷"
        src="https://twemoji.maxcdn.com/v/13.0.1/svg/1f937.svg"
      />
      <StyledText mt={2} mb={2} fontSize="22px">
        There was a problem with your email verification
      </StyledText>
      <StyledText fontSize="16px">
        Please check the newest verification email or contact administrator.
      </StyledText>
    </Container>
  );
};

export default EmailVerificationErrorPage;
