import React from 'react';
import { Field } from 'react-final-form';
import modalStore from '@stores/modalStore';
import { WarningModalContent } from '@components/WarningModalContent';
import CKEditor from '@components/_form/CKEditor/CKEditorInput';
import ColorPickerInput from '@components/formFields/ColorPickerInput';
import { FileUploadInput } from '@components/formFields/FileUploadInput';
import Select from '@components/formFields/Select';
import SwitchInput from '@components/formFields/SwitchInput';
import TextInput from '@components/formFields/TextInput';
import { THEME_FIELDS_TYPES } from '@constants';
import { shouldShowSimpleConfig } from '@utils/otherUtils';
import moment from 'moment';
import { serialize } from 'object-to-formdata';
import SliderInput from '@components/formFields/SliderInput';
import FontSelectInput from '@components/formFields/FontSelect';
import set from 'lodash/set';

export const prepareSelectOptions = (arr, getLabel, getValue) => {
  return arr
    ? arr.map(el => ({ label: getLabel(el), value: getValue(el) }))
    : [];
};

export const filterDocsAis = (currAis, availableAis) =>
  (currAis || [])
    .map(docAi =>
      availableAis.find(
        ai => ai.value?.id === docAi || ai.value?.id === docAi.value?.id,
      ),
    )
    .filter(el => el !== undefined);

export const filterAis = (currAis, availableAis) =>
  (currAis || [])
    .map(speakerAi =>
      availableAis.find(ai => ai.value?.id === speakerAi.value?.id),
    )
    .filter(el => el !== undefined);

export const filterElements = (currElements, availableElements) =>
  (currElements || [])
    .map(el => availableElements.find(item => item.value === el))
    .filter(el => el !== undefined);

export const prepareThemeGroupsWithTypes = (
  themeFields,
  themeGroups,
  banned,
  unmatchingGroupKey,
  bannedSections = [],
) => {
  if (!themeFields) return null;

  const mergedGroupsWithFields = [];

  let fieldsEntries = Object.entries(themeFields);
  const filteredGroups = themeGroups.filter(
    gr => !bannedSections.includes(gr[0]),
  );

  filteredGroups.forEach(group => {
    const [name, fields] = group;
    const temporary = [];

    // special tab
    if (fields === null) {
      mergedGroupsWithFields.push([name, fields]);
      return;
    }

    fields.forEach(field => {
      const match = fieldsEntries.find(
        entry => entry[0] === field && !banned.includes(field),
      );
      if (match) {
        temporary.push(match);
        fieldsEntries = fieldsEntries.filter(e => e[0] !== match[0]);
      }
    });
    if (temporary.length) {
      mergedGroupsWithFields.push([name, temporary]);
    }
  });

  fieldsEntries = fieldsEntries.filter(ent => !banned.includes(ent[0]));

  const otherIdx = mergedGroupsWithFields.findIndex(
    el => el[0] === unmatchingGroupKey && Array.isArray(el[1]),
  );
  if (otherIdx === -1) {
    mergedGroupsWithFields.push([unmatchingGroupKey, fieldsEntries]);
  } else {
    mergedGroupsWithFields[otherIdx][1].push(...fieldsEntries);
  }
  return mergedGroupsWithFields;
};

export const renderThemeField = (
  field,
  { availablefonts, fieldNamePrefix, sizeLimit, label, infoText },
) => {
  const {
    FONT_CONFIG,
    TEXT,
    SELECT,
    COLOR,
    RICH_TEXT,
    IMAGE,
    INTEGER,
    BOOL,
  } = THEME_FIELDS_TYPES;
  const [name, type] = field;

  if (type === FONT_CONFIG) {
    if (!availablefonts) {
      return null;
    }

    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        availablefonts={availablefonts}
        label={label}
        infoText={infoText}
        component={FontSelectInput}
      />
    );
  }

  if (type === TEXT) {
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        component={TextInput}
        label={label}
        infoText={infoText}
        multiline
        parse={v => v}
      />
    );
  }

  if (type === IMAGE) {
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        component={FileUploadInput}
        label={label}
        additionalInfo={`Max file size ${sizeLimit?.text || '50MB'}`}
        stackDirection="column"
        infoText={infoText}
        maxFileSize={sizeLimit?.val}
      />
    );
  }

  if (type === RICH_TEXT) {
    const displaySimpleCfg = shouldShowSimpleConfig(name);
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        component={CKEditor}
        label={label}
        parse={v => v}
        simpleConfig={displaySimpleCfg}
      />
    );
  }

  if (type === BOOL) {
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        infoText={infoText}
        component={SwitchInput}
        label={label}
        type="checkbox"
      />
    );
  }

  if (name.includes('_percent') && type === INTEGER) {
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        component={SliderInput}
        label={label}
        infoText={infoText}
        labelOnBottom
      />
    );
  }

  if (type === INTEGER) {
    return (
      <Field
        name={`${fieldNamePrefix}${name}`}
        type="number"
        label={label}
        infoText={infoText}
        component={TextInput}
      />
    );
  }

  if (type === COLOR) {
    return (
      <Field
        parse={v => v}
        name={`${fieldNamePrefix}${name}`}
        label={label}
        infoText={infoText}
        component={ColorPickerInput}
      />
    );
  }

  if (type === SELECT) {
    const finalOptions = [
      { label: 'Left', value: 'left' },
      {
        label: 'Right',
        value: 'right',
      },
    ];

    return (
      <Field
        parse={v => v}
        name={`${fieldNamePrefix}${name}`}
        label={label}
        selectOptions={finalOptions}
        component={Select}
      />
    );
  }

  return (
    <Field name={`${fieldNamePrefix}${name}`} component={TextInput} multiline />
  );
};

export const cropPropsSetterWithClone = (formRef, crop) => {
  const { height, width, x, y } = crop || {};
  formRef.batch(() => {
    formRef.change('crop_h', height ?? undefined);
    formRef.change('crop_w', width ?? undefined);
    formRef.change('crop_x', x ?? undefined);
    formRef.change('crop_y', y ?? undefined);
  });
};
export const cropPropsSetterNoClone = (form, crop) => {
  if (!crop) {
    form.change('crop_params', undefined);
    return;
  }
  const { height, width, x, y } = crop;

  form.change('crop_params', {
    crop_w: width,
    crop_h: height,
    crop_x: x,
    crop_y: y,
  });
};

export const imageExtractorForCrop = value => {
  if (value?.cropped) return value.cropped;
  if (value?.url) return value;

  return {};
};

export const generateBannedFieldsExtension = (
  isPlatformLiteSetting,
  organization,
  isAdmin,
) => {
  const finalArray = [];

  if (!isPlatformLiteSetting && !organization?.platform_lite_settings) {
    finalArray.push('use_lite_settings_light_logo');
  }

  if (!isAdmin) {
    finalArray.push('hide_event_brief_details');
  }

  return finalArray;
};

export const manipulateRegistrationFieldsArray = (
  regFields,
  beginRegFields = [],
) => {
  if (!regFields) return [];

  const removedRegFields = beginRegFields.filter(
    beginReg => !regFields.find(newReg => newReg.id === beginReg.id),
  );

  const removedRegistrationFieldsMapped = removedRegFields.map(reg => ({
    id: reg.id,
    _destroy: 1,
  }));

  regFields.forEach(el => {
    if (!el.description) {
      el.description = '';
    }
  });

  return [...removedRegistrationFieldsMapped, ...regFields];
};

const serializeRegistrationFields = (regFields, form, fieldKey) => {
  (regFields || []).forEach((reg, index) => {
    Object.entries(reg).forEach(([key, val]) => {
      if (key === 'orf_reference_id' && !val) return;
      if (key === 'options' && Array.isArray(val)) {
        val.forEach(el => {
          form.append(`${fieldKey}[${index}][options][]`, el);
        });
        return;
      }
      form.append(`${fieldKey}[${index}][${key}]`, val);
    });
  });
};

export const serializeEventObject = data => {
  data.planned_start_date = moment(data.planned_start_date).format(
    'YYYY-MM-DD',
  );
  data.sunset_date = data.sunset_date
    ? moment(data.sunset_date).format('YYYY-MM-DD')
    : null;

  const { registration_fields_attributes, ...otherProperties } = data;

  const form = serialize(otherProperties, { allowEmptyArrays: true });

  serializeRegistrationFields(
    registration_fields_attributes,
    form,
    'registration_fields_attributes',
  );

  return form;
};

export const serializeOrganizationObject = data => {
  const {
    organization_registration_fields_attributes,
    ...otherProperties
  } = data;

  const form = serialize(otherProperties, { allowEmptyArrays: true });

  serializeRegistrationFields(
    organization_registration_fields_attributes,
    form,
    'organization_registration_fields_attributes',
  );

  return form;
};

export const serializePollObject = data => {
  const { assignments_attributes, ...otherProperties } = data;

  const form = serialize(otherProperties, { allowEmptyArrays: true });

  serialize(
    { assignments_attributes },
    { allowEmptyArrays: true, indices: true },
    form,
  );

  return form;
};

export const actionWithWarning = (text, fn) => {
  return () => {
    modalStore.openModal({
      content: (
        <WarningModalContent
          msg={text}
          onCancel={modalStore.closeModal}
          onConfirm={async () => {
            await modalStore.closeModal();
            fn();
          }}
        />
      ),
    });
  };
};

export const mapRfErrors = errors => {
  const error_msgs_to_return = {};
  Object.keys(errors).forEach(key => {
    set(error_msgs_to_return, key, errors[`${key}`]);
  });
  return error_msgs_to_return;
};

export const revalidateField = ([name], state, { changeValue }) => {
  changeValue(state, name, old => old);
};
