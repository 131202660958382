import { action, extendObservable, computed } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { CancelToken } from '@app/api';
import { FORM_TYPES } from '@constants';
import routes from '@routes';
import routerStore from '@stores/routerStore';

const initialState = {
  rating: {},
  isLoading: true,
  events: [],
  agendaItems: [],
  defaultFormValues: {},
  formType: null,
};

export class RatingStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      this.updateRating(...props);
    }
    if (this.isCreate) {
      this.createRating(...props);
    }

    return null;
  };

  @action createRating = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;

      const payload = this.prepareValuesToSubmit(values);

      await ResourcesStore.createRating({
        payload,
        onSuccess,
      });
    } catch (error) {
      console.debug('[createRating] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateRating = async ({ id, values, onSuccess }) => {
    try {
      this.isLoading = true;

      const payload = this.prepareValuesToSubmit(values);

      const response = await ResourcesStore.updateRating({
        id,
        payload,
        onSuccess,
      });

      this.rating = response;
      this.setupDefaultFormValues();
    } catch (error) {
      console.debug('[updateRating] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    return {
      ...values,
      event_id: values.event_id.value,
      agenda_item_id: values.agenda_item_id?.value || '',
    };
  };

  @action deleteRating = async props => {
    try {
      this.isLoading = true;

      await ResourcesStore.deleteRating(props);
    } catch (error) {
      console.debug('[deleteRating] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.isLoading = true;

    if (this.isEdit) {
      await this.getRating({ id });
      await this.getAgendaSelectOptions(this.rating.event_id);
    }

    await Promise.allSettled([this.getEventSelectOptions()]);

    this.setupDefaultFormValues();

    this.isLoading = false;
  };

  @action getRating = async ({ id }) => {
    try {
      this.rating = await ResourcesStore.getRating(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getRating] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.ratings);
      }
      throw err;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.rating,
      event_id: this.events.find(ev => ev.value === this.rating.event_id),
      agenda_item_id: this.agendaItems.find(
        ai => ai.value === this.rating.agenda_item_id,
      ),
    };
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.events = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    try {
      const resp = await ResourcesStore.getAgendaItemsSelectOptions({
        eventId,
      });
      this.agendaItems = resp;
    } catch (error) {
      console.debug('[getAgendaSelectOptions] failed', error);
    }
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new RatingStore();
