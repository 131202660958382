import { action, extendObservable, computed } from 'mobx';
import urlSlug from 'url-slug';
import ResourcesStore from '@stores/resourcesStore';
import subscriptionStore from '@stores/subscriptionStore';
import { CancelToken } from '@app/api';
import routes from '@routes';
import isNull from 'lodash/isNull';
import {
  FORM_TYPES,
  MAPPING_TYPES,
  PLAYER_DESTINY_TYPES,
  PLAYER_TYPES,
} from '@constants';
import routerStore from './routerStore';

const initialState = {
  formType: null,
  isLoading: false,
  isLoadingUsers: false,
  isPlayerLoading: false,
  isLoadingEvent: true,
  agendaItems: [],
  group: {},
  player: null,
  backup_player: null,
  event: null,
  defaultFormValues: {},
  isUsersDefault: false,
  initialLoading: true,
  isPlayersLoading: false,
};
export class GroupStore {
  constructor() {
    extendObservable(this, initialState);
  }

  __cancelToken = null;

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = ({ id, breakout_id }) => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }
    this.getHelpResources({ id, breakout_id });
  };

  @action getHelpResources = async ({ id, breakout_id }) => {
    try {
      this.initialLoading = true;

      await this.fetchAgendaItems();
      if (this.isEdit) {
        await this.getGroupItem({ id, breakout_id });
        const playerId = this.group.player?.id;
        const backupPlayerId = this.group.backup_player?.id;

        await this.getPlayer({ playerId, backupPlayerId });
      }
      await this.getEventContext({ breakout_id });
      this.setupDefaultFormValues({ breakout_id });
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action getEventContext = async ({ breakout_id }) => {
    const eventId = this.getAgendaItemObject({ breakout_id })?.event_id;
    await subscriptionStore.setContextEvent(null, eventId);
  };

  @action calculateMapping = values => {
    if (!values) return undefined;
    const { mapping_type, player } = values;
    if (player && mapping_type === MAPPING_TYPES.CUSTOM) {
      return MAPPING_TYPES.PLAYER;
    }
    return mapping_type;
  };

  getAgendaItemObject = ({ breakout_id }) =>
    this.isEdit
      ? this.agendaItems.find(x => x.value === this.group.agenda_item_id)
      : this.agendaItems.find(x => x.value === breakout_id);

  @action setupDefaultFormValues = ({ breakout_id }) => {
    this.defaultFormValues = {
      move_on_click: true,
      user_ids: [],
      default_breakout_room_group: true,
      ...this.group,
      open_in_new_window: this.isEdit ? !this.group.open_in_new_window : true,
      agenda_item_id: this.getAgendaItemObject({ breakout_id }),
      mapping_type: this.isEdit
        ? this.calculateMapping(this.group)
        : MAPPING_TYPES.CUSTOM,
    };
  };

  @action setIsUsersDefault = value => {
    this.isUsersDefault = value;
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateGroupItem(...props);
    }
    if (this.isCreate) {
      return this.createGroupItem(...props);
    }

    return null;
  };

  @action deleteGroup = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteGroupItem({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteGroup] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action createGroupItem = async ({ values, onSuccess }) => {
    this.isLoading = true;
    const preparedValues = this.prepareValuesToSubmit(values);

    try {
      await ResourcesStore.createGroupItem({
        payload: preparedValues,
        onSuccess,
      });
      return null;
    } catch (err) {
      console.debug('[createGroupItem] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateGroupItem = async ({ id, values, onSuccess }) => {
    this.isLoading = true;
    const preparedValues = this.prepareValuesToSubmit(values);

    try {
      const group = await ResourcesStore.updateGroupItem({
        id,
        payload: preparedValues,
        onSuccess,
      });
      this.group = group;

      const { default_breakout_room_group } = group;
      if (default_breakout_room_group === false)
        await this.getUserSelectOptions({ group_id: this.group.id });
      return null;
    } catch (err) {
      console.debug('[updateGroup] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    return {
      ...values,
      open_in_new_window: ![
        MAPPING_TYPES.AGORAIO,
        MAPPING_TYPES.SPATIALCHAT,
        MAPPING_TYPES.PLAYER,
      ].includes(values.mapping_type)
        ? !values.open_in_new_window
        : false,
      user_ids: values.default_breakout_room_group
        ? []
        : values.user_ids.map(el => el.id),
      spatial_moderator_id: values.spatial_moderator_id || null,
      zoom_link: [MAPPING_TYPES.CUSTOM, MAPPING_TYPES.ZOOM].includes(
        values.mapping_type,
      )
        ? values.zoom_link
        : '',
      zoom_meeting_id:
        values.mapping_type === MAPPING_TYPES.ZOOM
          ? values.zoom_meeting_id
          : '',
      zoom_passcode:
        values.mapping_type === MAPPING_TYPES.ZOOM ? values.zoom_passcode : '',
      mapping_type:
        MAPPING_TYPES.PLAYER === values.mapping_type
          ? MAPPING_TYPES.CUSTOM
          : values.mapping_type,
      agenda_item_id: values.agenda_item_id.value,
      spaces_initial_pos_x: values.spaces_initial_pos_x
        ? values.spaces_initial_pos_x
        : 0,
    };
  };

  @action getGroupItem = async ({ id, breakout_id }) => {
    this.isLoading = true;
    try {
      const group = await ResourcesStore.getGroup(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
      this.group = group;
      const { default_breakout_room_group } = group;
      if (default_breakout_room_group === false)
        await this.getUserSelectOptions({ group_id: this.group.id });
    } catch (err) {
      console.debug('[getGroupItem] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(`${routes.breakoutRooms(breakout_id)}?tab=groups`);
      }
      throw err;
    } finally {
      this.isLoading = false;
    }
  };

  @action getUserSelectOptions = async ({ search, group_id }) => {
    this.isLoadingUsers = true;

    try {
      const params = {
        search,
        ...(group_id && { groupId: group_id }),
        response_fields: ['id', 'email', 'first_name', 'last_name', 'avatar'],
      };
      const users = await ResourcesStore.getUserSelectOptions(params);

      if (group_id) this.group.user_ids = users;

      return users;
    } catch (err) {
      console.debug('[getUserList] failed', err);
      return Promise.reject(err);
    } finally {
      this.isLoadingUsers = false;
    }
  };

  @action getModeratorSelectOptions = async ({ search, id }) => {
    this.isLoadingUsers = true;

    try {
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
      });

      return users;
    } catch (err) {
      console.debug('[getUserList] failed', err);
      return Promise.reject(err);
    } finally {
      this.isLoadingUsers = false;
    }
  };

  @action fetchAgendaItems = async () => {
    this.isLoading = true;

    try {
      this.agendaItems = await ResourcesStore.getAgendaItemsSelectOptions({
        agendaType: 'breakout_room',
        additionalResponseFields: ['is_belongs_to_public_event', 'event_id'],
      });
      this.isLoading = false;
    } catch (err) {
      console.debug('[getBreakoutSelectOptions] failed', err);
    }
  };

  @action getPlayer = async ({ playerId, backupPlayerId }) => {
    try {
      this.isPlayerLoading = true;
      if (playerId) {
        this.player = await ResourcesStore.getPlayer({
          id: playerId,
        });
      }
      if (backupPlayerId) {
        this.backup_player = await ResourcesStore.getPlayer({
          id: backupPlayerId,
        });
      }
    } catch (err) {
      console.debug('[getPlayer] failed', err);
    } finally {
      this.isPlayerLoading = false;
    }
  };

  @action submitPlayer = async ({ values, onSuccess, onError, isPrimary }) => {
    try {
      this.isPlayersLoading = true;
      const finalPlayer = !isPrimary ? this.backup_player : this.player;
      const playerRole = !isPrimary ? 'BackupPlayer' : 'Player';
      let resultPlayer;

      if (finalPlayer) {
        resultPlayer = await ResourcesStore.updatePlayer({
          id: values.id,
          payload: values,
        });
      } else {
        const payload = {
          ...values,
          playable_id: this.group.id,
          player_role: playerRole,
        };
        resultPlayer = await ResourcesStore.createPlayer({
          payload,
        });
      }

      if (!isPrimary) {
        this.backup_player = resultPlayer;
      } else {
        this.player = resultPlayer;
        if (this.backup_player) {
          this.getPlayer({ backupPlayerId: this.backup_player.id });
        }
        this.group.player = this.player;
        this.group.backup_player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      console.debug('[submitPlayer] failed', error);
    } finally {
      this.isPlayersLoading = false;
    }
  };

  initPlayerValues = ({ isPrimary }) => {
    if (!isNull(this.backup_player) && !isPrimary) return this.backup_player;
    if (!isNull(this.player) && isPrimary) return this.player;

    return {
      player_type: PLAYER_TYPES.DEFAULT,
      playable_type: PLAYER_DESTINY_TYPES.GROUP,
      aws_hls_stream_stack_id: null,
      aws_hls_stack_name: urlSlug(this.group.name),
      aws_hls_stream_stack_encoding_profile: 'HD-1080p',
    };
  };

  @action deletePlayer = async ({ id, onSuccess, onError }) => {
    try {
      this.isPlayersLoading = true;

      await ResourcesStore.deletePlayer({ id });

      if (id === this.backup_player?.id) this.backup_player = null;

      if (id === this.player?.id) {
        this.player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      console.debug('[deletePlayer] failed', error);
      if (onError) onError();
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    subscriptionStore.resetContextEvent();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new GroupStore();
