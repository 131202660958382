import * as React from 'react';

const EyeOff = ({ fill, height, width, ...props }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      {...props}
    >
      <path
        d="M8.137 15.147A4.912 4.912 0 016.991 12c0-2.76 2.241-5 5-5 1.201 
        0 2.291.435 3.148 1.145l1.897-1.897C15.595 5.51 13.914 5 12.001 5 
        5.886 5 1.976 10.355 1.159 11.584c.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294A3.925 
        3.925 0 0011.991 8c-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146L2.699 22l-.708-.707 
        3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169C2.409 7.473 6.934 4 12.001 4c2.065 0 4.021.566 5.782 1.501L21.284 
        2l.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274C22.114 12.897 18.307 19 12.001 19c-1.425 
        0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436a16.217 16.217 
        0 00-4.474-4.978zM15.991 12c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 
        5a4.943 4.943 0 01-2.295-.583l.734-.735A4.001 4.001 0 0015.991 12z"
      />
    </svg>
  );
};

export default EyeOff;
