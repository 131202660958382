import React, { useEffect, useState } from 'react';
import FieldTooltip from '@components/FieldTooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { sxDatePickerFix } from '@app/utils/stylingUtils';

const DatePickerInput = ({
  input,
  meta,
  infoText,
  label,
  sx,
  type,
  notAvailableInSP,
  popperProps,
  ...props1
}) => {
  const { onChange, value, ...inputProps } = input;
  const [currentInputValue, setCurrentInputValue] = useState(value);

  useEffect(() => {
    setCurrentInputValue(value);
  }, [value]);

  const dateOnly = type === 'dateOnly';
  const Component = dateOnly ? DatePicker : DateTimePicker;

  const onChangeMiddleware = newVal => {
    setCurrentInputValue(newVal || '');

    if (newVal?.isValid()) {
      onChange(newVal.format());
    }

    if (!newVal) {
      onChange('');
    }
  };

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Component
        label={label}
        ampm={false}
        mask={dateOnly ? `__/__/____` : `__/__/____ __:__`}
        inputFormat={dateOnly ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'}
        renderInput={props2 => (
          <TextField
            sx={theme => ({
              ...sxDatePickerFix(theme),
              ...sx,
            })}
            variant="standard"
            helperText={error}
            size="small"
            fullWidth
            {...props2}
            error={props2.error || Boolean(error)}
          />
        )}
        onChange={onChangeMiddleware}
        value={currentInputValue || null}
        PopperProps={popperProps}
        {...inputProps}
        {...props1}
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default DatePickerInput;
