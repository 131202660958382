import { required } from '@app/formValidators';
import { Loader } from '@components/Loader';
import { FieldGroup } from '@components/_form/FieldGroup';
import Fade from '@mui/material/Fade';
import { Box, Button, Stack } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { CloningLayer } from '@components/CloningLayer';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Assignments,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';

const defaultFormValues = {
  ...assignmentsSetupDefaultFormValues({}),
};

const CloningPollOptionsModal = ({
  modalStore: { closeModal },
  pollPageStore: { cloneRunning },
  onClone,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Fade in={!!cloneRunning} timeout={300}>
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <CloningLayer />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              padding: '0 40px',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2,
              textAlign: 'center',
            }}
          >
            {cloneRunning === 'running' ? (
              <>
                <Loader width="50px" />
                <h2>Clone in progress...</h2>
              </>
            ) : (
              <>
                <CheckIcon
                  sx={theme => ({
                    fontSize: '96px',
                    marginBottom: '35px',
                    fill: theme.colors.success,
                  })}
                />
                <h2>Clone is finished you will be redirected shortly </h2>
              </>
            )}
          </Box>
        </Box>
      </Fade>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          background: '#fff',
          padding: '40px',
          width: '650px',
          maxHeight: '95vh',
          overflow: 'auto',
          position: 'relative',
          boxShadow: '0 0 35px 3px rgb(0, 0, 0, 0.3)',
        }}
      >
        <h1>Cloning poll options</h1>
        <Box display="flex" flexDirection="column" mt="20px">
          <Form
            onSubmit={values =>
              onClone({
                values: {
                  ...values,
                  poll_resource_params: {
                    ...values.poll_resource_params,
                    event_id: values.event_id?.value,
                  },
                },
              })
            }
            initialValues={defaultFormValues}
            validate={values => {
              const errors = {
                event_id: required(values.event_id),
              };

              return errors;
            }}
          >
            {({ handleSubmit, submitError }) => {
              return (
                <>
                  <FieldGroup noPadding>
                    <Assignments
                      types={{
                        [ASSIGNMENT_TYPE.EVENT]: true,
                      }}
                      requiredEvent
                    />
                  </FieldGroup>
                  {submitError && (
                    <>
                      <Box sx={{ color: 'red.main', marginTop: '20px' }}>
                        {submitError.msg}.
                      </Box>
                      <Box sx={{ color: 'red.main' }}>
                        Copy an error log here:{' '}
                        <Button
                          color="red"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(submitError.errors);
                          }}
                        >
                          COPY
                        </Button>
                      </Box>
                    </>
                  )}
                  <Stack direction="row" gap="15px" mt="20px">
                    <Button
                      onClick={closeModal}
                      variant="outlined"
                      color="dark"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Clone
                    </Button>
                  </Stack>
                </>
              );
            }}
          </Form>
        </Box>
      </Box>
    </Box>
  );
};

CloningPollOptionsModal.propTypes = {
  onClone: PropTypes.func.isRequired,
};

export default inject(
  'modalStore',
  'pollPageStore',
)(observer(CloningPollOptionsModal));
