import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { monkeyPatchToasts } from '@components/Toasts';
import MuiTheme from '@styles/theme';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/lab';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import ModalsController from '@components/modals/ModalsController';
import GlobalStyle from '@styles/globalStyles';
import { Router } from 'react-router-dom';
import history from '@app/history';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as stores from './stores';

window.APP_STATE = stores;
window.APP_THEME = MuiTheme;

monkeyPatchToasts();

ReactDOM.render(
  <Provider {...stores}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={MuiTheme}>
          <CssBaseline />
          <GlobalStyle />
          <ToastContainer />
          <Router history={history}>
            <ModalsController />
            <App />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
