import React from 'react';
import { Typography, useTheme } from '@mui/material';
import CplusContextWrapper from './CplusToastContextWrapper';

const SuccessToast = ({ duration, text, closeToast }) => {
  const theme = useTheme();
  return (
    <CplusContextWrapper duration={duration} type="success" close={closeToast}>
      <Typography sx={{ color: theme.toast.success.textColor }}>
        {text}
      </Typography>
    </CplusContextWrapper>
  );
};

export default SuccessToast;
