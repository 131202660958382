import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { FormError } from '@components/_form/FormError';

const FieldWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Label = styled('label')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
`;

const Checkbox = ({ input, meta, label, checkboxContent, ...custom }) => {
  return (
    <Box mt={2}>
      <FieldWrapper>
        <Label htmlFor={label}>
          <input id={label} {...input} {...custom} type="checkbox" />
          <Box ml="5px" fontSize="14px" width="90%">
            {checkboxContent}
          </Box>
        </Label>
      </FieldWrapper>
      {meta && (meta.error || meta.submitError) && (
        <Box>
          <FormError meta={meta} />
        </Box>
      )}
    </Box>
  );
};

export default memo(Checkbox);
