import { action, observable } from 'mobx';
import ResourcesStore from './resourcesStore';

export class EventStatsStore {
  @observable isLoading = false;

  @observable isLoadingStats = false;

  @observable isLoadingReports = false;

  @observable isLoadingEvents = false;

  @observable eventSelectOptions = [];

  @observable stats = null;

  @observable attendees = null;

  @observable reports = null;

  @action getStats = async ({ eventId, agendaId }) => {
    this.isLoadingStats = true;

    this.stats = await ResourcesStore.getEventStats(eventId, agendaId);

    this.isLoadingStats = false;
  };

  @action reportsGetStats = async ({ eventId, agendaId, from }) => {
    this.isLoadingStats = true;

    const resp = await ResourcesStore.getEventStats(eventId, agendaId, from);

    this.isLoadingStats = false;
    return resp;
  };

  @action getAttendees = async ({ eventId, agendaId = null }) => {
    this.isLoading = true;

    this.attendees =
      (await ResourcesStore.getEventAttendees(eventId, agendaId)) || [];

    this.isLoading = false;
    return this.attendees;
  };

  @action getReports = async ({ eventId }) => {
    this.isLoading = true;

    this.reports = await ResourcesStore.getEventReports(eventId);

    this.isLoading = false;
  };

  @action generateReport = async ({ eventId }) => {
    this.isLoadingReports = true;

    this.reports = await ResourcesStore.generateEventReport(eventId);

    this.isLoadingReports = false;
  };

  @action getEventSelectOptions = async () => {
    try {
      this.isLoadingEvents = true;
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });

      return this.eventSelectOptions;
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
      return [];
    } finally {
      this.isLoadingEvents = false;
    }
  };

  @action clearStore = async () => {
    this.attendees = null;
    this.stats = null;
    this.reports = null;
    this.eventSelectOptions = [];
  };
}

export default new EventStatsStore();
