export default {
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
    },
  },
};
