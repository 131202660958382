import React from 'react';
import MuiTablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TablePagination = ({ page, count, ...props }) => {
  if (page === undefined || count === undefined) {
    return (
      <Box
        sx={theme => ({
          minHeight: '52px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: '14px',
          backgroundColor: theme.mainTable.background,
          borderRadius: '0 0 5px 5px',
        })}
      >
        <Skeleton variant="rectangular" height="25px" width="75px" />
      </Box>
    );
  }
  return (
    <MuiTablePagination
      sx={theme => ({
        backgroundColor: theme.mainTable.background,
        borderRadius: '0 0 5px 5px',
      })}
      showFirstButton
      showLastButton
      component="div"
      page={page - 1}
      count={count}
      {...props}
    />
  );
};

TablePagination.defaultProps = {
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
};

export default TablePagination;
