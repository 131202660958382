import { action, extendObservable, computed } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_ERROR } from 'final-form';
import { CancelToken } from '@app/api';
import sortBy from 'lodash/sortBy';
import routerStore from '@stores/routerStore';
import routes from '@routes';
import { FORM_TYPES, PLAYER_TYPES } from '@constants';

const initialState = {
  isLoading: false,
  player: {},
  events: [],
  groups: [],
  agendaItems: [],
  defaultFormValues: {},
  formType: null,
};

export class PlayerStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updatePlayer(...props);
    }
    if (this.isCreate) {
      return this.createPlayer(...props);
    }

    return null;
  };

  @action getPlayer = async ({ id }) => {
    try {
      this.player = await ResourcesStore.getPlayer(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getPlayer] failed', err);
      throw err;
    }
  };

  @action createPlayer = async ({ values, ...props }) => {
    this.isLoading = true;
    const playableInfo = this.handlePlayable(values);
    try {
      const payload = {
        ...values,
        playable_type: playableInfo.type,
        playable_id: playableInfo.id,
      };

      await ResourcesStore.createPlayer({
        payload,
        ...props,
      });
    } catch (err) {
      console.debug('[createPlayer] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deletePlayer = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deletePlayer({ id, onSuccess });
    } catch (err) {
      console.debug('[deletePlayer] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action handlePlayable = values => {
    if (values.event_id) return { type: 'Event', id: values.event_id?.value };
    if (values.agenda_item_id)
      return { type: 'AgendaItem', id: values.agenda_item_id?.value };
    if (values.group_id) return { type: 'Group', id: values.group_id?.value };
    return {};
  };

  @action updatePlayer = async ({ values, onSuccess, ...props }) => {
    this.isLoading = true;

    const onS = newVals => {
      onSuccess?.(newVals);

      this.player = newVals;

      this.setupDefaultFormValues();
    };
    const playableInfo = this.handlePlayable(values);
    const payload = {
      ...values,
      playable_type: playableInfo.type,
      playable_id: playableInfo.id,
    };

    try {
      await ResourcesStore.updatePlayer({
        payload,
        onSuccess: onS,
        ...props,
      });
    } catch (err) {
      return { [FORM_ERROR]: err.response?.data?.errors };
    } finally {
      this.isLoading = false;
    }
    return null;
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.player,
      agenda_item_id:
        this.player.playable_type === 'AgendaItem'
          ? this.agendaItems.find(ai => ai.value === this.player.playable_id)
          : undefined,
      event_id:
        this.player.playable_type === 'Event'
          ? this.events.find(ev => ev.value === this.player.playable_id)
          : undefined,
      group_id:
        this.player.playable_type === 'Group'
          ? this.groups.find(ev => ev.value === this.player.playable_id)
          : undefined,
    };
  };

  @action getHelpResources = async ({ id }) => {
    this.isLoading = true;
    try {
      await Promise.all([
        this.isEdit && this.getPlayer({ id }),
        this.getAgendaSelectOptions(),
        this.getEventSelectOptions(),
        this.getGroupSelectOptions(),
      ]);

      if (this.isEdit) {
        this.setupDefaultFormValues();
      } else {
        this.defaultFormValues = {
          ...this.defaultFormValues,
          player_type: PLAYER_TYPES.DEFAULT,
          aws_hls_stream_stack_id: null,
          aws_hls_stream_stack_encoding_profile: 'HD-1080p',
        };
      }
    } catch (err) {
      if (err.response.status === 404) {
        routerStore.replace(routes.main.players);
      }
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getGroupSelectOptions = async () => {
    try {
      const {
        results: groupResults,
      } = await ResourcesStore.getGroupSelectOptions();
      this.groups = sortBy(groupResults, e => e.label);
    } catch (err) {
      console.debug('[getGroupSelectOptions] failed', err);
      throw err;
    }
  };

  @action getAgendaSelectOptions = async () => {
    try {
      this.agendaItems = await ResourcesStore.getAgendaItemsSelectOptions({
        agendaType: 'agenda_item',
      });
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
      throw err;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const {
        results: eventsResult,
      } = await ResourcesStore.getEventSelectOptions();
      this.events = sortBy(eventsResult, e => e.label);
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
      throw err;
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new PlayerStore();
