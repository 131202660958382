const BASE_FONT_SIZE = 14;

const remCalc = size => `${size / BASE_FONT_SIZE}rem`;

const typography = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: remCalc(30),
    fontWeight: 400,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: remCalc(20),
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: '1.0625rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  body1: {
    fontSize: 14,
  },
  button: {
    textTransform: 'none',
  },
};

export default typography;
