const STORAGE_KEY_PREFFIX = 'cplus:';

export const KEYS = {
  AUTOREFRESH_INTERBAL: 'AUTOREFRESH_INTERBAL',
};

const MAP_KEYS_NAMES = {
  [KEYS.AUTOREFRESH_INTERBAL]: `${STORAGE_KEY_PREFFIX}autoRefreshInterval`,
};

export const setItem = (key, value) =>
  localStorage.setItem(MAP_KEYS_NAMES[key], value);

export const getItem = key => localStorage.getItem(MAP_KEYS_NAMES[key]);

export const removeItem = key => localStorage.removeItem(MAP_KEYS_NAMES[key]);

export const clear = () => {
  const lsKeys = Object.keys(localStorage);
  lsKeys.forEach(key => {
    if (key.indexOf(STORAGE_KEY_PREFFIX) !== -1) {
      localStorage.removeItem(key);
    }
  });
};
