import { action, extendObservable } from 'mobx';
import { prepareSelectOptions } from '@utils/formUtils';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  stagesGroup: null,
  organizations: [],
  isDeletingId: null,
};

export class StagesPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getGroups = async ({
    organization_id,
    page,
    silent,
    sort,
    search,
  }) => {
    if (!silent) this.isLoading += 1;
    try {
      const res = await ResourcesStore.getStagesGroups({
        organization_id,
        page,
        sort,
      });

      this.stagesGroup = {
        ...res,
        organization_id,
        sort,
        search,
      };
    } catch (err) {
      console.debug('[getGroups] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    if (
      params.search === this.stagesGroup?.search &&
      parseInt(params.page, 10) === this.stagesGroup?.page_number &&
      params.sort === this.stagesGroup?.sort &&
      params.organization_id === this.stagesGroup?.organization_id
    )
      return true;

    return false;
  };

  @action deleteStagesGroup = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteStagesGroup({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteStagesGroup] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });

      this.organizations = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      );
    } catch (err) {
      console.debug('[getOrganizationsSelectOptions] failed', err);
    }
  };
}

export default new StagesPageStore();
