import { useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

export const usePagination = params => {
  const container = useRef(document.querySelector('#main-container'));
  const history = useHistory();
  const { pathname } = useLocation();
  return useCallback(
    (e, page) => {
      container.current?.scrollTo({ top: 0 });
      history.push(
        `${pathname}?${qs.stringify({ ...params, page: page + 1 })}`,
      );
    },
    [history, pathname, params],
  );
};
