import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const NotAvailableInStagesTooltip = () => {
  return (
    <Tooltip
      title="Not available when event is included in Stages."
      placement="top-end"
      leaveDelay={900}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            padding: '10px',
          },
        },
      }}
    >
      <WarningAmberIcon
        sx={theme => ({
          fontSize: theme.typography.pxToRem(14),
          display: 'block',
          color: 'yellow.800',
          pointerEvents: 'all',
        })}
      />
    </Tooltip>
  );
};

export default NotAvailableInStagesTooltip;
