export default () => ({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: 'inherit',
        },
      },
    },
  },
});
