import { useForm } from 'react-final-form';

export function useSafeForm() {
  try {
    const form = useForm();
    return form;
  } catch (err) {
    return null;
  }
}
