import { styled } from '@mui/material/styles';
import { Tab as muiTab, Box } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';

export const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.analytics.sidebar.background};
  border-left: ${({ theme }) => theme.border};
  height: calc(
    100vh -
      ${({
        theme: {
          header: { height },
        },
      }) => height}px
  );
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const EventName = styled('div')`
  margin-top: 30px;
  font-size: ${({ theme }) => theme.fontSizes.heading};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  opacity: 0.8;
  padding: 0 25px;
`;

export const EventDate = styled('div')`
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  opacity: 0.8;
  margin: 5px 0 15px 0;
  padding: 0 25px;
`;

export const Content = styled('div')`
  padding: 8px 16px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const CardWrapper = styled('div')`
  margin: 15px 7px;
`;

export const TitleBox = styled('div')`
  background-color: #fafbfc;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  z-index: 10;
  position: sticky;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
`;

export const ScrollBox = styled('div')`
  overflow: scroll;
  height: 100%;
`;

export const Tab = withTheme(
  withStyles({
    root: {
      minWidth: '50%',
    },
  })(muiTab),
);
