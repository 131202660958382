import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Stack } from '@mui/material';
import EventsBox, { STATUS } from '@pages/dashboard/components/EventsBox';

function PreviewEvents({
  dashboardPageStore: { upcomingEvents, pastEvents, liveEvents },
}) {
  return (
    <Stack spacing={5}>
      <EventsBox
        eventsList={liveEvents}
        title="Live events"
        status={STATUS.LIVE}
      />
      <Divider light sx={{ borderBottomWidth: 2 }} />
      <EventsBox
        eventsList={upcomingEvents}
        title="Upcoming events"
        status={STATUS.UPCOMING}
      />
      <Divider light sx={{ borderBottomWidth: 2 }} />
      <EventsBox
        eventsList={pastEvents}
        title="Past events"
        status={STATUS.PAST}
      />
    </Stack>
  );
}

export default inject('dashboardPageStore')(observer(PreviewEvents));
