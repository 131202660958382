import React, { useCallback } from 'react';
import { Box, FormLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@components/TextField';
import FieldTooltip from '@components/FieldTooltip';

const FreeSoloInput = ({
  meta,
  label,
  input,
  required,
  disabled,
  normalizer,
  infoText,
  placeholder,
  notAvailableInSP,
}) => {
  const handleSelect = useCallback(
    (_, value) => {
      if (typeof normalizer === 'function') {
        input.onChange(value.map(v => normalizer(v)));
      } else {
        input.onChange(value);
      }
    },
    [input.onChange, normalizer],
  );

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <Autocomplete
        size="small"
        options={[]}
        multiple
        freeSolo
        fullWidth
        disabled={disabled}
        value={input.value || []}
        onChange={handleSelect}
        onBlur={input.onBlur}
        renderInput={params => (
          <TextField
            helperText={error}
            error={Boolean(error)}
            placeholder={input.value?.length ? undefined : placeholder}
            {...params}
          />
        )}
      />
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default FreeSoloInput;
