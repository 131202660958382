import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  sponsors: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
  isDeletingId: null,
};

export class SponsorsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getSponsors = async ({
    page,
    search,
    event_id,
    silent,
    sort,
    agenda_item_id,
  }) => {
    if (!silent) this.isLoading += 1;
    try {
      const res = await ResourcesStore.getSponsors({
        page,
        search,
        ...(!agenda_item_id && { event: event_id }),
        agenda_item_id,
        sort,
      });

      this.sponsors = {
        ...res,
        sort,
        search,
        event_id,
        agenda_item_id,
      };
    } catch (err) {
      console.debug('[getSponsors] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    if (
      params.search === this.sponsors?.search &&
      parseInt(params.page, 10) === this.sponsors?.page_number &&
      params.sort === this.sponsors?.sort &&
      params.event_id === this.sponsors?.event_id &&
      params.agenda_item_id === this.sponsors?.agenda_item_id
    )
      return true;

    return false;
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
  };

  @action deleteSponsor = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteSponsor({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteSponsor] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new SponsorsPageStore();
