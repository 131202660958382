import { useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import omit from 'lodash/omit';

export function useListing(getData, matchParams, { excludeParams = [] } = {}) {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const { allParams, params } = useMemo(() => {
    const p = qs.parse(search);
    return { allParams: p, params: omit(p, excludeParams) };
  }, [search, excludeParams]);

  const isParamsMatching = matchParams(params);

  useEffect(() => {
    if (params.page) {
      if (isParamsMatching) {
        getData({ ...params, silent: true });
      } else {
        getData({ ...params });
      }
    } else {
      history.replace(`${pathname}?${qs.stringify({ ...allParams, page: 1 })}`);
    }
    // we are caring only about filtered params
  }, [JSON.stringify(params), history, pathname]);

  return { params: allParams, isParamsMatching };
}
