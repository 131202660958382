import { useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import qs from 'query-string';
import { DEFAULT_TAB } from '@constants';

const isTabValid = (tabs, tabValue) => tabs.find(t => t.value === tabValue);

export const useTabs = (
  defaultTab,
  { autoSwitch, onChangeCallback, overrideParamsFn, allTabs, tabName } = {
    allTabs: [],
  },
) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const tab = tabName || DEFAULT_TAB;

  const tabValue = useMemo(() => qs.parse(search)[tab], [search]);

  const changeTab = useCallback(
    (param1, param2) => {
      let newTab = param1;
      if (param1?.target) {
        newTab = param2;
      }
      let params = qs.parse(search);
      params[tab] = newTab;

      if (overrideParamsFn) {
        params = { ...params, ...overrideParamsFn(params) };
      }

      history.replace(`${pathname}?${qs.stringify(params)}`);
      if (onChangeCallback) onChangeCallback(newTab);
    },
    [onChangeCallback, search, pathname, overrideParamsFn],
  );

  useEffect(() => {
    if ((!tabValue || !isTabValid(allTabs || [], tabValue)) && autoSwitch)
      changeTab(defaultTab);
  }, [tabValue]);

  const result = { changeTab };
  result[tab] = tabValue || defaultTab;
  return result;
};
