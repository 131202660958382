import { green, yellow } from '@mui/material/colors';

export const basicPalette = {
  pink: '#e61b5d',
  red: {
    main: '#e60808',
    light: '#ff2415',
    dark: '#ce0000',
    contrastText: '#fff',
    50: '#fee7e6',
    100: '#ffc7b9',
    200: '#ffa28c',
    300: '#ff7a5e',
    400: '#ff583b',
    500: '#ff2c19',
    600: '#ff2415',
    700: '#f41a0e',
    800: '#e60808',
    900: '#ce0000',
  },
  blue: {
    50: '#e7f8ff',
    100: '#c2edff',
    200: '#9ae1ff',
    300: '#72d4fe',
    400: '#53cbfe',
    500: '#35c2fe',
    600: '#30bcfe',
    700: '#28b4fe',
    800: '#22acfe',
    900: '#169ffd',
  },
  orange: {
    50: '#fdece5',
    100: '#f9cfbf',
    200: '#f6af95',
    300: '#f28e6a',
    400: '#ef764a',
    500: '#ec5e2a',
    600: '#ea5625',
    700: '#e74c1f',
    800: '#e44219',
    900: '#df310f',
  },
  green,
  yellow,
  lightGreen: '#dbf2dd',
  navyBlue: '#3e4768',
  purple: '#4A365D',
  darkPurple: '#362f39',
  dark: {
    50: '#faf9fc',
    100: '#f4f3f6',
    200: '#ecebed',
    300: '#dddbde',
    400: '#b9b8bb',
    500: '#9a999b',
    600: '#717072',
    700: '#5d5c5f',
    800: '#3f3e40',
    900: '#1e1d1f',
    light: '#5d5c5f',
    contrastText: '#fff',
    main: '#3f3e40',
    dark: '#1e1d1f',
  },
  lightDark: {
    main: '#9699a4',
  },
  darkGray: '#2C2F31',
  temperedSteel: '#939393',
  riverLady: '#A0A0A0',
  lightStoneGray: '#B0B0B0',
  warmGray: '#9699a4',
  screamingGrey: '#AAAAAA',
  lightSilver: '#d8d8d8',
  gray: '#e0e0e0',
  whiteSmoke: '#F5F5F5',
  athensGray: '#EFEEF4',
  almostWhite: '#EFF3F6',
  whitey: '#f9f9f9',
  closeToWhite: '#fcfcfc',
  white: '#FFFFFF',
  darkBlue: '#1a202b',
  materialBlue: '#273142',
  gold: '#FFA500',
};

const shared = {
  fonts: {
    main: 'Poppins, sans-serif',
  },
  fontSizes: {
    large: '36px',
    heading: '28px',
    subtitle: '18px',
    section: '16px',
    body: '14px',
    tiny: '12px',
    micro: '9px',
  },
  fontWeights: {
    fit: 400,
    regular: 500, // or medium
    semibold: 600,
    almostbold: 700,
    bold: 800,
  },
};

export const getShared = () => {
  return { palette: basicPalette, ...shared };
};
