import React from 'react';
import { Box } from '@mui/material';

export const TabPanel = ({ children, index, visible }) => {
  if (visible)
    return (
      <Box
        role="tabpanel"
        id={`tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
      >
        {children}
      </Box>
    );

  return null;
};
