import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Popover, Box, Button, useTheme } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { percentage } from '@utils/math';
import Badge from '@components/Badge/Badge';
import * as Styled from '@pages/analytics/components/Sidebar/Overview/style';

export const ActiveUsersCard = ({
  analyticsStore: {
    csvLoadingProgress,
    generateLiveAttendeesCSV,
    countersCharts,
    dataKey,
    selectedDate,
  },
}) => {
  const theme = useTheme();

  const [moreAnchor, setMoreAnchor] = useState(null);
  const moreOpen = Boolean(moreAnchor);

  const stats = useMemo(
    () => countersCharts[dataKey] || countersCharts[selectedDate],
    [countersCharts, dataKey, selectedDate],
  );

  const isLoadingCSV = useMemo(() => csvLoadingProgress !== 100, [
    csvLoadingProgress,
  ]);

  return (
    <>
      <Box sx={{ position: 'absolute', top: '10px', right: 0 }}>
        <MoreVert
          style={{
            color: theme.palette.primary.main,
            cursor: 'pointer',
            width: '30px',
            height: '30px',
            marginTop: '2px',
          }}
          onClick={event => setMoreAnchor(event.currentTarget)}
        />
      </Box>

      <Popover
        open={moreOpen}
        onClose={() => setMoreAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={moreAnchor}
      >
        <Button
          variant="outlined"
          color="dark"
          onClick={() => {
            generateLiveAttendeesCSV();
            setMoreAnchor(null);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {isLoadingCSV ? `${csvLoadingProgress}%` : 'Download XLS'}
        </Button>
      </Popover>
      <Box
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          position: 'relative',
        }}
      >
        <Styled.Header>Active users</Styled.Header>
      </Box>
      <Styled.ViewersAmount>
        {stats.active_users.value.toLocaleString('en')}
      </Styled.ViewersAmount>
      <Styled.AudiencePercentage>
        <Badge
          style={{
            background: theme.colors.successBackground,
            color: theme.colors.success,
          }}
        >
          {percentage(stats.active_users) || '0'} %
        </Badge>
        <Styled.AudiencePercentageText>
          of total event attendance
        </Styled.AudiencePercentageText>
      </Styled.AudiencePercentage>
    </>
  );
};

export default inject('analyticsStore')(observer(ActiveUsersCard));
