import { styled } from '@mui/material/styles';
import clamp from 'lodash/clamp';
import MUICard from '@components/Card';

export const Description = styled('div')`
  margin: 5px 0 13px;
`;

export const Range = styled('div')`
  width: 100%;
  height: 5px;
  position: relative;
  margin-top: 8px 0;

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${({ color, theme }) => color || theme.palette.primary.main};
    opacity: 0.5;
  }

  &::after {
    content: '';
    top: 0;
    left: 0;
    width: ${({ value }) => clamp(value, 0, 100)}%;
    height: 100%;
    position: absolute;
    background: ${({ color, theme }) => color || theme.palette.primary.main};
  }
`;

export const Card = styled(MUICard)`
  margin: 8px 0;
  position: relative;

  .MuiSvgIcon-root {
    font-size: 36px;
  }
`;
