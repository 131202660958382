import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

const LogoContainer = styled('img')`
  cursor: pointer;
`;

export const Logo = ({ onClick }) => {
  const theme = useTheme();
  return (
    <LogoContainer
      onClick={onClick}
      src={theme.header.logo}
      sx={{ height: `${theme.header.logoHeight}px` }}
    />
  );
};
