import React, { useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { inject, observer } from 'mobx-react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import isEmpty from 'lodash/isEmpty';
import { OrgSelectorWrapper, OrgSelectorInput } from './style';

const OrgSelector = ({
  profileStore: { profile },
  subscriptionStore: {
    selectedManagedOrg,
    setSelectedOrganization,
    setProfileOrg,
    clearStore,
  },
}) => {
  useEffect(() => {
    return clearStore;
  }, []);

  useEffect(() => {
    const { managed_organizations_details: managedOrgDetails } = profile || {};

    if (
      !selectedManagedOrg &&
      managedOrgDetails &&
      !isEmpty(managedOrgDetails)
    ) {
      setProfileOrg(managedOrgDetails[0]?.id);
    }
  }, [profile, selectedManagedOrg]);

  const orgSelectOptions = useMemo(() => {
    return (profile?.managed_organizations_details || []).map(el => ({
      key: el.name,
      value: el.id,
    }));
  }, [profile]);

  if (!selectedManagedOrg) {
    return null;
  }

  return (
    <OrgSelectorWrapper>
      <Box>
        <Select
          labelId="org-select-label"
          value={selectedManagedOrg}
          MenuProps={{
            sx: {
              '& .MuiList-root': { padding: 0 },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: 'theme.colors.primary',
              },
            },
          }}
          label="Organization"
          onChange={setSelectedOrganization}
          input={<OrgSelectorInput />}
        >
          {orgSelectOptions.map(org => (
            <MenuItem key={`org_${org?.value}`} value={org.value}>
              {org.key}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </OrgSelectorWrapper>
  );
};

export default inject(
  'subscriptionStore',
  'profileStore',
)(observer(OrgSelector));
