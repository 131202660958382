import React from 'react';
import { Box } from '@mui/material';
import { inject, observer } from 'mobx-react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Navigation, NavigationSkeleton } from '@components/Navigation';
import { Wrapper, Nav, TogglerBox } from './style';

const SideBar = ({
  profileStore: { profile },
  layoutStore: { setSidenavOpen, sidenavOpen },
}) => {
  return (
    <Wrapper open={sidenavOpen}>
      <TogglerBox onClick={() => setSidenavOpen(!sidenavOpen)}>
        {sidenavOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </TogglerBox>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <Nav>
          {profile ? (
            <Box pb="20px">
              <Navigation />
            </Box>
          ) : (
            <NavigationSkeleton />
          )}
        </Nav>
      </Box>
    </Wrapper>
  );
};

export default inject(
  'authStore',
  'layoutStore',
  'profileStore',
)(observer(SideBar));
