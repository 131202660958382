import React from 'react';
import { Box, FormHelperText, FormLabel } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import get from 'lodash/get';
import FieldTooltip from '@components/FieldTooltip';
import { styled } from '@mui/material/styles';

const Slider = styled(MuiSlider, {
  shouldForwardProp: prop => !['labelOnBottom'].includes(prop),
})(({ labelOnBottom }) => ({
  ...(labelOnBottom && {
    '& .MuiSlider-valueLabelOpen': {
      top: '60px',
      '&:before': {
        bottom: '100%',
      },
    },
  }),
}));

const SliderInput = ({
  input,
  inputProps,
  meta,
  label,
  infoText,
  labelOnBottom,
  notAvailableInSP,
}) => {
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);
  return (
    <Box sx={{ position: 'relative' }}>
      <FormLabel>{label}</FormLabel>
      <Box mt="10px" pb="10px">
        <Slider
          labelOnBottom={labelOnBottom}
          defaultValue={100}
          id={get(inputProps, 'id') || `slider-${input.name}`}
          onChange={(_, val) => input.onChange(val)}
          value={input.value || 0}
          min={0}
          max={100}
          valueLabelDisplay="on"
        />
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
      />
    </Box>
  );
};

export default SliderInput;
