import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { prepareSelectOptions } from '@utils/formUtils';

const initialState = {
  isLoading: 0,
  users: null,
  adminUsers: null,
  isDeletingId: [],
  selectedRows: [],
  organizations: [],
};
export class UsersPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getUsers = async ({
    page,
    sort,
    search,
    silent,
    role,
    organization_id,
    onlyAdmins,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      this.setSelectedRows([]);

      const res = await ResourcesStore.getUsers({
        page,
        sort,
        search,
        role,
        organization_id,
        ...(onlyAdmins && { role: 'non_user_role' }),
      });

      if (onlyAdmins) {
        this.adminUsers = {
          ...res,
          sort,
          page,
          search,
          role,
          organization_id,
        };
      } else {
        this.users = {
          ...res,
          sort,
          page,
          search,
          role,
          organization_id,
        };
      }
    } catch (err) {
      console.debug('[getUsers] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action setSelectedRows = val => {
    this.selectedRows = val;
  };

  @action deleteUser = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = [id];

      await ResourcesStore.deleteUser({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteUser] failed', err);
    } finally {
      this.isDeletingId = [];
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'role', 'organization_id'].every(
      param => params[param] === this.users?.[param],
    );
  };

  @action matchPageAdminsSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'role', 'organization_id'].every(
      param => params[param] === this.adminUsers?.[param],
    );
  };

  @action bulkDeleteUsers = async ({ payload, onSuccess }) => {
    try {
      this.setSelectedRows([]);
      this.isDeletingId = payload.ids;
      await ResourcesStore.bulkDeleteUsers({
        payload,
      });
      await onSuccess?.();
      return null;
    } catch (err) {
      console.debug('[bulkDeleteUsers] failed', err);
      return Promise.reject(err);
    } finally {
      this.isDeletingId = [];
    }
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });

      this.organizations = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      );
    } catch (err) {
      console.debug('[getOrganizationsSelectOptions] failed', err);
    }
  };

  @action softClearStore = () => {
    this.setSelectedRows([]);
  };
}

export default new UsersPageStore();
