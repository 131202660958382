import get from 'lodash/get';
import merge from 'lodash/merge';
import moment from 'moment';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import { serialize } from 'object-to-formdata';
import { API, APIRoutes } from '@api';
import {
  serializeEventObject,
  serializeOrganizationObject,
  serializePollObject,
} from '@utils/formUtils';
import qs from 'query-string';
import { ASSIGNMENT_TYPE, serializeAssignments } from '@components/Assignments';
import { envMappingType } from '@styles/_theme';

export class ResourcesStore {
  invalidateAssignmentsCache = null;

  getAgendaThemeFields = async axiosOptions => {
    try {
      const { data } = await API.get(
        `${APIRoutes.AGENDA_THEME_FIELDS}`,
        axiosOptions,
      );
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventThemeFields = async axiosOptions => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENT_THEME_FIELDS}`,
        axiosOptions,
      );
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventThemeGallery = async axiosOptions => {
    try {
      const { data } = await API.get(
        APIRoutes.EVENT_THEME_GALLERY,
        axiosOptions,
      );
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getOrganizations = async (
    { page, search, deleted, per, sort },
    axiosOptions,
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.ORGANIZATIONS}?${qs.stringify({
          page,
          search,
          deleted,
          per,
          order_by: sort,
        })}
        `,
        axiosOptions,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getOrganizationSelectOptions = async (
    { additionalResponseFields, additionalResourceFields },
    axiosOptions,
  ) => {
    try {
      let responseFields = ['id', 'name'];
      if (additionalResponseFields) {
        responseFields = [...responseFields, ...additionalResponseFields];
      }
      const { data } = await API.get(
        `${APIRoutes.ORGANIZATIONS}/organization_select_options`,
        {
          params: {
            response_fields: responseFields || [],
            response_resources: additionalResourceFields,
          },
          ...axiosOptions,
        },
      );
      const orgSelectOptions = (data || []).map(org => ({
        value: org,
        label: org.name,
      }));

      return orgSelectOptions;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getOrganization = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.ORGANIZATIONS}/${id}`,
        axiosOptions,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createOrganization = async ({ payload, onSuccess, onError }) => {
    try {
      const { events, ...payloadWithoutEvents } = payload; // there is still event_ids field
      const form = serializeOrganizationObject(payloadWithoutEvents);

      const { data } = await API.post(`${APIRoutes.ORGANIZATIONS}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  updateOrganization = async ({ id, payload, onSuccess, onError }) => {
    try {
      const { events, ...payloadWithoutEvents } = payload; // there is still event_ids field

      const form = serializeOrganizationObject(payloadWithoutEvents);

      const { data } = await API.put(`${APIRoutes.ORGANIZATIONS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteOrganization = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.ORGANIZATIONS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getAudiences = async key => {
    try {
      const { data } = await API.get(APIRoutes.MAILCHIMP_AUDIENCES(key));

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEvents = async ({
    page,
    pageSize,
    search,
    per,
    deleted,
    organization,
    sort,
    scope,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENTS}?${qs.stringify({
          page,
          pageSize,
          search,
          deleted,
          per,
          organization_id: organization,
          order_by: sort,
          scope,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getLiveEvents = async ({ per, page }) => {
    try {
      const {
        data: { results },
      } = await API.get(
        `${APIRoutes.EVENTS_LIVE}?${qs.stringify({ per, page })}`,
      );

      return results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPastEvents = async ({ per, page }) => {
    try {
      const {
        data: { results },
      } = await API.get(
        `${APIRoutes.EVENTS_PAST}?${qs.stringify({ per, page })}`,
      );

      return results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUpcomingEvents = async ({ per, page }) => {
    try {
      const {
        data: { results },
      } = await API.get(
        `${APIRoutes.EVENTS_UPCOMING}?${qs.stringify({ per, page })}`,
      );

      return results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEvent = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(`${APIRoutes.EVENTS}/${id}`, axiosOptions);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createEvent = async ({ payload, onSuccess, onError }) => {
    try {
      const serializedForm = serializeEventObject(payload);

      const { data } = await API.post(`${APIRoutes.EVENTS}`, serializedForm);

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.EVENT]: true },
      });

      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  updateEvent = async ({ id, payload, onSuccess, onError }) => {
    try {
      const serializedForm = serializeEventObject(payload);

      const { data } = await API.put(
        `${APIRoutes.EVENTS}/${id}`,
        serializedForm,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.EVENT]: true },
      });
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  deleteEvent = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.EVENTS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.EVENT]: true },
      });
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getSubscriptionPlanSelectOptions = async () => {
    try {
      const { data } = await API.get(
        `${APIRoutes.SUBSCRIPTION_PLANS}/subscription_plan_select_options`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventSelectOptions = async () => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENTS}/event_select_options`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getGroupSelectOptions = async () => {
    try {
      const { data } = await API.get(
        `${APIRoutes.GROUPS}/group_select_options`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventAgendaItems = async eventId => {
    try {
      const { data } = await API.get(
        `${APIRoutes.AGENDA_ITEMS}?event_id=${eventId}`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAgendaItems = async ({ page, search, per, event, sort, agenda_type }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.AGENDA_ITEMS}?${qs.stringify({
          page,
          search,
          per,
          event_id: event,
          order_by: sort || 'start_date DESC',
          agenda_type,
        })}`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAgendaItemsSelectOptions = async ({
    eventId,
    agendaType,
    additionalResponseFields,
  } = {}) => {
    let responseFields = ['id', 'description'];
    if (additionalResponseFields) {
      responseFields = [...responseFields, ...additionalResponseFields];
    }
    try {
      const { data } = await API.get(
        `${APIRoutes.AGENDA_ITEMS}/agenda_item_select_options?${qs.stringify({
          event_id: eventId,
          agenda_type: agendaType,
        })}`,
        {
          params: {
            response_fields: responseFields || [],
          },
        },
      );
      const agendaSelectOptions = (data || []).map(agenda => ({
        ...omit(agenda, ['id', 'description']),
        value: agenda.id,
        label: agenda.description,
      }));

      return agendaSelectOptions;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAgendaItem = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.AGENDA_ITEMS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createAgendaItem = async ({ payload, onSuccess, onError }) => {
    try {
      // get normal date representation from moment.js
      payload.start_date = payload.start_date.toString();
      payload.end_date = payload.end_date.toString();
      const form = serialize(payload);
      form.delete('slate_image');
      if (get(payload, 'slate_image.name')) {
        form.set('slate_image', payload.slate_image, payload.slate_image.name);
      }

      form.delete('end_slate_image');
      if (get(payload, 'end_slate_image.name')) {
        form.set(
          'end_slate_image',
          payload.end_slate_image,
          payload.end_slate_image.name,
        );
      }
      const { data } = await API.post(`${APIRoutes.AGENDA_ITEMS}`, form);

      if (onSuccess) {
        onSuccess(data);
        this.invalidateAssignmentsCache?.({
          types: { [ASSIGNMENT_TYPE.AGENDA]: true },
        });
      }

      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  updateAgendaItem = async ({ id, payload, onSuccess, onError }) => {
    try {
      // get normal date representation from moment.js
      payload.start_date = payload.start_date.toString();
      payload.end_date = payload.end_date.toString();
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.AGENDA_ITEMS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.AGENDA]: true },
      });

      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteAgendaItem = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.AGENDA_ITEMS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getPlayers = async ({
    page,
    pageSize,
    search,
    per,
    player_type,
    event_id,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PLAYERS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          player_type,
          event_id,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPlayer = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.PLAYERS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  generateApiPartner = async eventId => {
    try {
      const { data } = await API.post(APIRoutes.API_PARTNER(eventId));
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateApiPartner = async ({ eventId, apiPartnerId, payload, onSuccess }) => {
    try {
      const { data } = await API.put(
        APIRoutes.API_PARTNER(eventId, apiPartnerId),
        payload,
      );
      if (onSuccess) onSuccess();
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createPlayer = async ({ payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.PLAYERS}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) onError(e);
      return Promise.reject(e);
    }
  };

  updatePlayer = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.PLAYERS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deletePlayer = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.PLAYERS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getUsers = async (
    { page, pageSize, search, per, sort, role, organization_id },
    axiosOptions,
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.USERS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          role,
          organization_id,
          order_by: sort || 'created_at DESC',
        })}`,
        axiosOptions,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUser = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.USERS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUserSelectOptions = async ({
    search,
    id,
    groupId,
    documentId,
    event_id,
    accepted_only,
    response_fields,
  } = {}) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.USERS}/user_select_options?${qs.stringify({
          search,
          event_id,
          accepted_only,
        })}`,
        {
          params: {
            ...(id ? { user_ids: id } : {}),
            ...(groupId ? { group_id: groupId } : {}),
            ...(documentId ? { document_id: documentId } : {}),
            response_fields,
            // IDEA: if you like to get diffrent atributes just add response_fields param to the request - it's already implemented in API
            // response_fields: responseFields || ['id', 'email', 'first_name', 'last_name'],
          },
        },
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createUser = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.USERS}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateUser = async ({ id, payload, onSuccess }) => {
    try {
      const { organization_id: _, ...payloadModified } = payload;
      const form = serialize(payloadModified, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.USERS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteUser = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.USERS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }

      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  bulkDeleteUsers = async ({ payload }) => {
    try {
      const result = await API.post(
        `${APIRoutes.USERS}/bulk_soft_delete`,
        payload,
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getDocuments = async ({
    page,
    pageSize,
    search,
    per,
    event_id,
    sort,
    agenda_item_id,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.DOCUMENTS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          event_id,
          agenda_item_id,
          order_by: sort || 'id DESC',
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getProducts = async ({
    page,
    pageSize,
    search,
    per,
    sort,
    event_id,
    agenda_item_id,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PRODUCTS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          order_by: sort || 'id DESC',
          event_id,
          agenda_item_id,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEntityDocuments = async ({ type, id, pageSize, per }, axiosOptions) => {
    try {
      const route =
        type === 'event' ? APIRoutes.EVENTS : APIRoutes.AGENDA_ITEMS;

      const { data } = await API.get(
        `${route}/${id}/documents?${qs.stringify({
          pageSize,
          per,
        })}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEntityProducts = async ({ type, id, pageSize, per }, axiosOptions) => {
    try {
      const route =
        type === 'event' ? APIRoutes.EVENTS : APIRoutes.AGENDA_ITEMS;

      const { data } = await API.get(
        `${route}/${id}/products?${qs.stringify({
          pageSize,
          per,
        })}`,
        axiosOptions,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEntityEmails = async ({ event }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.MAILER_CONFIG}?event_id=${event}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEmailView = async ({ email_id }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.MAILER_CONFIG}/${email_id}/preview`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getDocument = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.DOCUMENTS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEmail = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.MAILER_CONFIG}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateEmail = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.MAILER_CONFIG}/${id}`, form);

      onSuccess?.(data);

      return data;
    } catch (e) {
      console.debug('[updateProduct] failed', e);
      return Promise.reject(e);
    }
  };

  deleteEmail = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.MAILER_CONFIG}/${id}`);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  publishEmail = async ({ id, values, onError }) => {
    try {
      values.perform_at = values.perform_at
        ? values?.perform_at?.toString()
        : null;

      const payload = serialize(values, { allowEmptyArrays: true });

      const { data } = await API.post(
        `${APIRoutes.MAILER_CONFIG}/${id}/perform_execution`,
        payload,
      );
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  cancelEmail = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.post(
        `${APIRoutes.MAILER_CONFIG}/${id}/cancel_execution`,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  activateEmail = async ({ id, onSuccess, onError }) => {
    this.isLoading = true;
    let result;
    try {
      const { data } = await API.post(
        `${APIRoutes.MAILER_CONFIG}/${id}/toggle_activate`,
      );

      result = data;
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return e;
    } finally {
      this.isLoading = false;
    }

    return result;
  };

  testEmail = async ({ mailerId, userId, onSuccess }) => {
    try {
      const { data } = await API.post(
        `${APIRoutes.MAILER_CONFIG}/${mailerId}/send_test_email?user_id=${userId}`,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  createEmail = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });
      const { data } = await API.post(`${APIRoutes.MAILER_CONFIG}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getProduct = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.PRODUCTS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  docPageCreateDocument = async ({ payload, onSuccess, requestOptions }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(
        APIRoutes.DOCUMENTS,
        form,
        requestOptions,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  prodPageCreateDocument = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(APIRoutes.PRODUCTS, form);

      onSuccess?.(data);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createDocument = async ({ parentId, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const url = `${APIRoutes.EVENTS}/${parentId}/documents`;

      const { data } = await API.post(url, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createProduct = async ({ type, parentId, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const url =
        type === 'event'
          ? `${APIRoutes.EVENTS}/${parentId}/products`
          : `${APIRoutes.AGENDA_ITEMS}/${parentId}/products`;

      const { data } = await API.post(url, form);

      onSuccess?.(data);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateDocument = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.DOCUMENTS}/${id}`, form);
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateProduct = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.PRODUCTS}/${id}`, form);

      onSuccess?.(data);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteDocument = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.DOCUMENTS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteProduct = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.PRODUCTS}/${id}`);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  getInvoices = async () => {
    try {
      const { data } = await API.get(APIRoutes.INVOICES);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPaymentMethods = async () => {
    try {
      const { data } = await API.get(APIRoutes.PAYMENT_METHODS);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getProfile = async () => {
    try {
      const { data } = await API.get(`${APIRoutes.ME}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateProfile = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.ME}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.isLoading = false;
    }
  };

  getSpeakers = async (
    { page, pageSize, search, per, event, sort, agenda_item_id },
    axiosOptions,
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.SPEAKERS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          event_id: event,
          agenda_item_id,
          order_by: sort || 'id DESC',
        })}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  reorderSpeakers = async ({ agenda_id, event_id, speaker_ids }) => {
    try {
      const form = new FormData();
      speaker_ids.forEach(id => form.append('event_speaker_ids[]', id));

      const route = agenda_id
        ? `${APIRoutes.AGENDA_ITEMS}/${agenda_id}/reorder_speakers`
        : `${APIRoutes.EVENTS}/${event_id}/event_speakers/reorder`;

      await API.put(`${route}`, form);
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  reorderDocuments = async ({ agenda_id, event_id, documents_ids }) => {
    try {
      const form = new FormData();
      documents_ids.forEach(id => form.append('document_ids[]', id));

      const route = agenda_id
        ? `${APIRoutes.AGENDA_ITEMS}/${agenda_id}`
        : `${APIRoutes.EVENTS}/${event_id}`;

      await API.put(`${route}/documents/reorder`, form);
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  reorderProducts = async ({ event_id, agenda_id, products_ids }) => {
    try {
      const form = new FormData();
      products_ids.forEach(id => form.append('product_ids[]', id));

      const route = agenda_id
        ? `${APIRoutes.AGENDA_ITEMS}/${agenda_id}`
        : `${APIRoutes.EVENTS}/${event_id}`;

      await API.put(`${route}/products/reorder`, form);
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getSpeaker = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.SPEAKERS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createSpeaker = async ({ parentId, payload, onSuccess, requestOptions }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(
        `${APIRoutes.EVENTS}/${parentId}/event_speakers`,
        form,
        requestOptions,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateSpeaker = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.SPEAKERS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteSpeaker = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.SPEAKERS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getEntitySpeakers = async ({ type, id, pageSize, per }, axiosOptions) => {
    try {
      const route =
        type === 'event' ? APIRoutes.EVENTS : APIRoutes.AGENDA_ITEMS;

      const { data } = await API.get(
        `${route}/${id}/event_speakers?${qs.stringify({
          pageSize,
          per,
        })}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEntityStickers = async ({ id }) => {
    try {
      const URL = `${APIRoutes.EVENTS}/${id}/stickers`;

      const { data } = await API.get(URL);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createSticker = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(
        `${APIRoutes.EVENTS}/${payload.event_id}/stickers`,
        form,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteSticker = async ({ id, eventId, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(
        `${APIRoutes.EVENTS}/${eventId}/stickers/${id}`,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getChatQuestion = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.CHAT_QUESTIONS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getChatQuestions = async (
    {
      page,
      search,
      per,
      event_id,
      moderation_status,
      sort,
      answered,
      in_answer_queue,
      agenda_item_id,
    },
    axiosOptions,
  ) => {
    const params = qs.stringify({
      page,
      per,
      search,
      event_id,
      moderation_status,
      answered,
      in_answer_queue,
      agenda_item_id,
      order_by: sort || 'posted_at DESC',
    });
    try {
      const { data } = await API.get(
        `${APIRoutes.CHAT_QUESTIONS}?${params}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateChatQuestion = async ({ id, payload, onSuccess }) => {
    try {
      const { data } = await API.put(
        `${APIRoutes.CHAT_QUESTIONS}/${id}`,
        payload,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteChatQuestion = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.CHAT_QUESTIONS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getChatMessages = async ({
    page,
    pageSize,
    search,
    per,
    room,
    sort,
    pinned,
  }) => {
    let url;

    const searchQuery = qs.stringify({
      page,
      pageSize,
      search,
      per,
      order_by: sort || 'posted_at DESC',
      pinned,
    });

    if (room) {
      url = `${APIRoutes.CHAT_ROOM_MESSAGES(room)}?${searchQuery}`;
    } else {
      url = `${APIRoutes.CHAT_MESSAGES}?${searchQuery}`;
    }
    try {
      const { data } = await API.get(url);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getChatMessage = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.CHAT_MESSAGES}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteChatMessage = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.CHAT_MESSAGES}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  bulkDeleteChatMessages = async ({ payload, onSuccess }) => {
    try {
      const response = await API.put(
        `${APIRoutes.CHAT_MESSAGES}/bulk_delete`,
        payload,
      );

      onSuccess?.(response);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateChatMessage = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.CHAT_MESSAGES}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getChatMessagesExport = async ({ room: chatRoomId, query }) => {
    try {
      const { data } = await API.post(
        `${APIRoutes.CHAT_MESSAGES}/export?${qs.stringify({
          chat_room_id: chatRoomId,
          search: query,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateMessagePinned = async ({ id, payload }) => {
    try {
      return await API.put(`${APIRoutes.CHAT_MESSAGES}/${id}`, payload);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // * ChatRooms
  getChatRooms = async ({ page, pageSize, search, per, chatable_id }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.CHAT_ROOMS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          chatable_id,
          order_by: 'id DESC',
        })}
        `,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getChatRoom = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.CHAT_ROOMS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateChatRoom = async ({ id, payload, onSuccess, onError }) => {
    try {
      const values = serialize(payload, { allowEmptyArrays: true });
      const { data } = await API.put(`${APIRoutes.CHAT_ROOMS}/${id}`, values);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  deleteChatRoom = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.CHAT_ROOMS}/${id}`);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  createChatRoom = async ({ payload, onSuccess, onError }) => {
    try {
      const values = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.CHAT_ROOMS}`, values);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  // * ChatAbuses
  getChatAbuses = async ({
    page,
    pageSize,
    search,
    per,
    message,
    sort,
    event_id,
  }) => {
    const query = qs.stringify({
      page,
      pageSize,
      search,
      per,
      order_by: sort || 'id DESC',
      event_id,
    });

    let url;

    if (message) {
      url = `${APIRoutes.CHAT_MESSAGE_ABUSES(message)}?${query}`;
    } else {
      url = `${APIRoutes.CHAT_ABUSES}?${query}`;
    }

    try {
      const { data } = await API.get(url);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteChatAbuse = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.CHAT_ABUSES}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }

      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  // * !ChatAbuses

  getPolls = async ({
    page,
    pageSize,
    search,
    per,
    event_id,
    agenda_item_id,
    active,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.POLLS}?${qs.stringify({
          page,
          pageSize,
          search,
          per,
          agenda_item_id,
          event_id,
          active,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPoll = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(`${APIRoutes.POLLS}/${id}`, axiosOptions);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createPoll = async ({ payload, onSuccess }) => {
    try {
      const { data } = await API.post(APIRoutes.POLLS, payload);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updatePoll = async ({ id, payload, onSuccess }) => {
    try {
      const serialized = serializePollObject(payload);

      const { data } = await API.put(`${APIRoutes.POLLS}/${id}`, serialized);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deletePoll = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.POLLS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getPollAnswers = async ({ page, pageSize, search, per }) => {
    try {
      const { data } = await API.get(
        `${
          APIRoutes.POLL_ANSWERS
        }?page=${page}&pageSize=${pageSize}&search=${search}${
          per ? `&per=${per}` : ''
        }`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPollAnswerUsers = async ({ id }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.POLL_ANSWERS}/${id}/answer_users`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getPollAnswer = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.POLL_ANSWERS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.isLoading = false;
    }
  };

  getAttendances = async ({
    page,
    pageSize,
    event_id,
    status,
    search,
    per,
    sort,
  }) => {
    try {
      const searchParams = {
        page,
        pageSize,
        search,
        per,
        event_id,
        status,
        order_by: sort || 'id DESC',
      };

      const { data } = await API.get(
        APIRoutes.ATTENDANCES.DEFAULT(null, searchParams),
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAttendancesExport = async ({ event, status, search }) => {
    const queries = {
      search,
      event_id: event,
      status,
    };
    try {
      const { data } = await API.post(
        APIRoutes.ATTENDANCES.EXPORT_CSV(null, queries),
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  postRecalculateAttendances = async event_id => {
    const queries = {
      event_id,
    };
    try {
      const { data } = await API.post(
        APIRoutes.ATTENDANCES.RECALCULATE(queries),
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAttendance = async ({ id }) => {
    try {
      const { data } = await API.get(APIRoutes.ATTENDANCES.DEFAULT(id));
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateAttendance = async ({ id, payload, onSuccess }) => {
    try {
      const { data } = await API.put(
        APIRoutes.ATTENDANCES.DEFAULT(id),
        payload,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (e.response?.data.errors.event_id) {
        toast.cError(e.response.data.errors.event_id[0]);
      }
      return Promise.reject(e);
    }
  };

  deleteAttendance = async ({ id, onSuccess }) => {
    try {
      const response = await API.delete(APIRoutes.ATTENDANCES.DEFAULT(id));
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  batchCreateAttendances = async ({ payload, onSuccess }) => {
    try {
      const { data } = await API.post(
        APIRoutes.ATTENDANCES.BULK_CREATE,
        payload,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  batchUpdateStatuses = async payload => {
    try {
      return await API.put(APIRoutes.ATTENDANCES.BULK_UPDATE, payload);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getSponsors = async ({
    page,
    pageSize,
    search,
    per,
    event,
    sort,
    agenda_item_id,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.SPONSORS}?${qs.stringify({
          page,
          search,
          pageSize,
          per,
          event_id: event,
          agenda_item_id,
          order_by: sort || 'event_pos ASC',
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getSponsor = async ({ id }) => {
    try {
      const { data } = await API.get(`${APIRoutes.SPONSORS}/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createSponsor = async ({ payload, onSuccess, requestOptions }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(APIRoutes.SPONSORS, form, requestOptions);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateSponsor = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.SPONSORS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteSponsor = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.SPONSORS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  reorderSponsors = async ({ event_id, sponsors_ids }) => {
    try {
      const form = new FormData();
      sponsors_ids.forEach(id => form.append('sponsor_ids[]', id));

      await API.put(`${APIRoutes.EVENTS}/${event_id}/sponsors/reorder`, form);
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventStats = async (eventId, agendaId = null, from = null) => {
    try {
      const { data } = await API.get(
        APIRoutes.EVENT_STATS(eventId, agendaId, from),
      );
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventAttendees = async (eventId, agendaId = null) => {
    try {
      const { data } = await API.get(
        APIRoutes.EVENT_ATTENDEES(eventId, agendaId),
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  syncEFUsers = async (eventId, sync_all = false) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENT_SYNC_EF_USERS(eventId, sync_all)}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getEventReports = async eventId => {
    try {
      const { data } = await API.get(`${APIRoutes.EVENT_REPORTS(eventId)}`);
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  generateEventReport = async eventId => {
    try {
      const { data } = await API.post(`${APIRoutes.EVENT_REPORTS(eventId)}`);
      return data.results;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  gdprProcedudure = async eventIds => {
    try {
      const payload = { event_ids: eventIds };
      const result = await API.post(APIRoutes.GDPR_PROCEDURE, payload);

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUsersToView = async ({ events }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENT_ONLY_USERS}?${qs.stringify(
          { event_ids: events },
          { arrayFormat: 'bracket' },
        )}`,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getGroups = async ({
    page,
    pageSize,
    search,
    per,
    agenda_item_id,
    sort,
    event_id,
  } = {}) => {
    const url = `${APIRoutes.GROUPS}?${qs.stringify({
      page,
      pageSize,
      search,
      per,
      agenda_item_id,
      order_by: sort || 'id DESC',
      event_id,
    })}`;

    try {
      const { data } = await API.get(url);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteGroupItem = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.GROUPS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.AGENDA]: true },
      });

      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  createGroupItem = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.GROUPS}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.AGENDA]: true },
      });
      return data;
    } catch (e) {
      if (e.response?.data.default_breakout_room_group) {
        toast.cError(e.response.data.default_breakout_room_group[0]);
      }
      return Promise.reject(e);
    }
  };

  getGroup = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(`${APIRoutes.GROUPS}/${id}`, axiosOptions);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateGroupItem = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.GROUPS}/${id}`, form);
      if (onSuccess) {
        onSuccess(data);
      }
      this.invalidateAssignmentsCache?.({
        types: { [ASSIGNMENT_TYPE.AGENDA]: true },
      });
      return data;
    } catch (e) {
      if (e.response?.data.default_breakout_room_group) {
        toast.cError(e.response.data.default_breakout_room_group[0]);
      }
      if (e.response?.data.users) {
        toast.cError(e.response.data.users[0]);
      }
      return Promise.reject(e);
    }
  };

  getMultiMarketGroups = async (
    { organization_id, page, per, sort, search, pageSize },
    axiosOptions,
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.MULTI_MARKET_EVENTS}?${qs.stringify({
          page,
          organization_id,
          pageSize,
          per,
          order_by: sort || 'id DESC',
          search,
        })}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getMultiMarketGroup = async ({ groupId }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.MULTI_MARKET_EVENTS}/${groupId}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getMultiMarketEvents = async ({ orgId }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.MULTI_MARKET_EVENTS}?organization_id=${orgId}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUnlinkedMultiMarketEvents = async ({ orgId, page, per }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENTS}/non_multi_market?organization_id=${orgId}&page=${page}&per=${per}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createMultiMarketEventsGroup = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(APIRoutes.MULTI_MARKET_EVENTS, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteMultiMarketEventsGroup = async ({ id, onSuccess }) => {
    try {
      const { data } = await API.delete(
        `${APIRoutes.MULTI_MARKET_EVENTS}/${id}`,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateMultiMarketEventsGroup = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const resp = await API.put(
        `${APIRoutes.MULTI_MARKET_EVENTS}/${id}`,
        form,
      );

      if (onSuccess) {
        onSuccess(resp);
      }
      return resp;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateMultiMarketEventsGroupData = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const result = await API.put(
        `${APIRoutes.MULTI_MARKET_EVENTS}/${id}`,
        form,
      );

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getLinkedEvents = async ({ orgId }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.LINKED_EVENTS}?organization_id=${orgId}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUnlinkedEvents = async ({ orgId, page, per }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENTS}/unlinked?organization_id=${orgId}&page=${page}&per=${per}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createLinkedEventsGroup = async ({ payload, onSuccess }) => {
    try {
      const { data } = await API.post(APIRoutes.LINKED_EVENTS, payload);
      if (onSuccess) {
        onSuccess(data);
      }

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteLinkedEventsGroup = async ({ id, event_id, onSuccess }) => {
    try {
      const { data } = await API.put(
        `${APIRoutes.LINKED_EVENTS}/${id}`,
        event_id,
      );

      if (onSuccess) {
        onSuccess(data);
      }

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateLinkedEventsGroup = async ({ id, payload, onSuccess }) => {
    try {
      const result = await API.put(`${APIRoutes.LINKED_EVENTS}/${id}`, payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  refreshFrontend = async ({ id, onSuccess, onError }) => {
    try {
      await API.post(APIRoutes.REFRESH_FRONTEND(id));

      if (onSuccess) {
        onSuccess();
      }
      return null;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getTranslations = async ({ page, search, per, sort }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.TRANSLATIONS}?${qs.stringify({
          page,
          search,
          per,
          order_by: sort || 'id DESC',
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteTranslation = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.TRANSLATIONS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getTranslation = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.TRANSLATIONS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getJsonTranslation = async ({ lang, customTranslationUrl }) => {
    try {
      let defaultTranslationJson = {};
      let customTranslationJson = {};
      if (customTranslationUrl) {
        customTranslationJson = await (
          await fetch(customTranslationUrl)
        ).json();
      }

      if (lang) {
        // HACK: Please do not remove timestamp param - it's tempomary fix for FE app response caching
        const defaultTranslationUrl = `${
          process.env.REACT_APP_FRONTEND_URL
        }translations/${lang}.json?timestamp=${Date.now()}`;
        defaultTranslationJson = await (
          await fetch(defaultTranslationUrl)
        ).json();
      }

      return merge(defaultTranslationJson, customTranslationJson);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateTranslation = async ({ id, payload, onSuccess }) => {
    try {
      const { data } = await API.put(
        `${APIRoutes.TRANSLATIONS}/${id}`,
        payload,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createTranslation = async ({ payload, onSuccess }) => {
    try {
      const { data } = await API.post(`${APIRoutes.TRANSLATIONS}`, payload);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  breakoutMappingImportCreate = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(
        `${APIRoutes.IMPORT_BREAKOUT_MAPPING(payload.name)}`,
        form,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getlastImportBreakoutMapping = async ({ agendaId }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.IMPORT_BREAKOUT_MAPPING(agendaId)}/last_import`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // AWS hls stream
  awsRefreshStackInfo = async ({ playerId }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PLAYERS}/${playerId}/aws_refresh_stack_info`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  awsCreateStack = async ({ playerId, payload }) => {
    try {
      const { data } = await API.post(
        `${APIRoutes.PLAYERS}/${playerId}/aws_create_stack`,
        payload,
      );
      return data;
    } catch (e) {
      toast.cError(`${envMappingType} Video Player creation failed!`);
      return Promise.reject(e);
    }
  };

  awsStartChannel = async ({ playerId, onSuccess }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PLAYERS}/${playerId}/aws_start_channel`,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  awsStopChannel = async ({ playerId, onSuccess }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PLAYERS}/${playerId}/aws_stop_channel`,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  awsDescribeChannel = async ({ playerId, onSuccess }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.PLAYERS}/${playerId}/aws_describe_channel`,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  awsDeleteStack = async ({ playerId, onSuccess }) => {
    try {
      const { data } = await API.delete(
        `${APIRoutes.PLAYERS}/${playerId}/aws_delete_stack`,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getBanners = async ({ page, search, sort, event_id, active }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.BANNERS}?${qs.stringify({
          page,
          search,
          event_id,
          order_by: sort || 'created_at DESC',
          active,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getBanner = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.BANNERS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createBanner = async ({ payload, onSuccess, requestOptions }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(
        `${APIRoutes.BANNERS}`,
        form,
        requestOptions,
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateBanner = async ({ id, payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.BANNERS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteBanner = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.BANNERS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getGlobalMessages = async ({ page, search, sort, event_id }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.GLOBAL_MESSAGES}?${qs.stringify({
          page,
          search,
          event_id,
          order_by: sort || 'created_at DESC',
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getGlobalMessage = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.GLOBAL_MESSAGES}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createGlobalMessage = async ({ payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.GLOBAL_MESSAGES}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  updateGlobalMessage = async ({ id, payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(
        `${APIRoutes.GLOBAL_MESSAGES}/${id}`,
        form,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteGlobalMessage = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.GLOBAL_MESSAGES}/${id}`);

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  publishGlobalMessage = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.GLOBAL_MESSAGES}/${id}/publish`,
      );

      onSuccess?.(data);
      return data;
    } catch (e) {
      onError?.(e);
      return Promise.reject(e);
    }
  };

  getRatings = async ({
    page,
    search,
    per,
    sort,
    active,
    event_id,
    agenda_item_id,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.RATINGS}?${qs.stringify({
          page,
          search,
          per,
          order_by: sort,
          active,
          event_id,
          agenda_item_id,
        })}
        `,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getRating = async ({ id }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.RATINGS}/${id}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getRatingsVotesStats = async ({ voteId, q_score }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.RATINGS}/${voteId}/rating_votes?${qs.stringify({
          q_score,
        })}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateRating = async ({ id, payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.put(`${APIRoutes.RATINGS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (err) {
      if (onError) {
        onError(err);
      }
      console.debug('[updateRating] failed', err);
      return Promise.reject(err);
    }
  };

  deleteRating = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.RATINGS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  createRating = async ({ payload, onSuccess, onError }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(`${APIRoutes.RATINGS}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getExploreItem = async ({ id }, axiosOptions = {}) => {
    try {
      const { data } = await API.get(`${APIRoutes.EXPLORE_ITEMS}/${id}`, {
        ...axiosOptions,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateExploreItem = async ({
    id,
    payload: { assignments_attributes, ...payload },
    onSuccess,
    onError,
  }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });
      serializeAssignments(form, assignments_attributes);

      const { data } = await API.put(`${APIRoutes.EXPLORE_ITEMS}/${id}`, form);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  createExploreItem = async ({
    payload: { assignments_attributes, ...payload },
    onSuccess,
    onError,
    requestOptions,
  }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });
      serializeAssignments(form, assignments_attributes);

      const { data } = await API.post(
        `${APIRoutes.EXPLORE_ITEMS}`,
        form,
        requestOptions,
      );

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  deleteExploreItem = async ({ id, onSuccess, onError }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.EXPLORE_ITEMS}/${id}`);

      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return Promise.reject(e);
    }
  };

  getExploreItems = async ({
    page,
    search,
    per,
    sort,
    event_id,
    agenda_item_id,
    active,
  }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EXPLORE_ITEMS}?${qs.stringify({
          page,
          search,
          per,
          event_id,
          agenda_item_id,
          order_by: sort || 'id DESC',
          active,
        })}
        `,
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  cloneEvent = async ({ payload, eventId, onSuccess, onError }) => {
    try {
      payload.clone.event_resource_params.planned_start_date = moment(
        payload.clone.event_resource_params.planned_start_date,
      ).format('YYYY-MM-DD');

      // dont use serialize lib here
      const { data } = await API.post(
        APIRoutes.CLONE_EVENT({ eventId }),
        payload,
      );

      onSuccess?.(data);
      return data;
    } catch (err) {
      onError?.(err);
      return Promise.reject(err);
    }
  };

  clonePoll = async ({ payload, pollId, onSuccess, onError }) => {
    try {
      // dont use serialize lib here
      const { data } = await API.post(
        APIRoutes.CLONE_POLL({ pollId }),
        payload,
      );

      onSuccess?.(data);
      return data;
    } catch (err) {
      onError?.(err);
      return Promise.reject(err);
    }
  };

  getLogs = async (
    { page, per, resource, log_action, show_sign_in, event_id },
    axiosOptions = {},
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.LOGS}?${qs.stringify({
          page,
          per,
          association_chain: resource,
          log_action,
          hide_sign_in: show_sign_in ? undefined : true,
          event_id,
        })}`,
        { ...axiosOptions },
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getGroupsImports = async ({ agenda_item_id } = {}) => {
    const url = `${APIRoutes.AGENDA_ITEMS}/${agenda_item_id}/import_breakout_mappings`;

    try {
      const { data } = await API.get(url);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  publishEvent = async ({ id }) => {
    try {
      const { data } = await API.post(APIRoutes.PUBLISH_EVENT({ id }));
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  unpublishEvent = async ({ id }) => {
    try {
      const { data } = await API.post(APIRoutes.UNPUBLISH_EVENT({ id }));
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deletePaymentMethod = async ({ id }) => {
    try {
      const { data } = await API.post(APIRoutes.DELETE_PAYMENT_METHOD, {
        pm_id: id,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getStagesGroups = async (
    { organization_id, page, per, sort, search, pageSize },
    axiosOptions,
  ) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.STAGES}?${qs.stringify({
          page,
          organization_id,
          pageSize,
          per,
          order_by: sort || 'id DESC',
          search,
        })}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getStagesGroup = async ({ groupId }, axiosOptions) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.STAGES}/${groupId}`,
        axiosOptions,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createStagesGroup = async ({ payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const { data } = await API.post(APIRoutes.STAGES, form);
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateStagesGroup = async ({ id, payload, onSuccess }) => {
    try {
      const form = serialize(payload, { allowEmptyArrays: true });

      const resp = await API.put(`${APIRoutes.STAGES}/${id}`, form);

      if (onSuccess) {
        onSuccess(resp);
      }
      return resp;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteStagesGroup = async ({ id, onSuccess }) => {
    try {
      const { data } = await API.delete(`${APIRoutes.STAGES}/${id}`);
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getUnstagedEvents = async ({ orgId, page, per }) => {
    try {
      const { data } = await API.get(
        `${APIRoutes.EVENTS}/non_stages?organization_id=${orgId}&page=${page}&per=${per}`,
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new ResourcesStore();
