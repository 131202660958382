import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@components/TextField';
import { SearchBar } from '@components/SearchBar';
import { useFilters } from '@utils/useFilters';
import { useSearch } from '@utils/useSearch';
import Filters from '@components/Filters';
import ButtonContainer from '@components/ButtonContainer';

const InnerHeader = ({
  eventsPageStore: { organizationSelectOptions, getOrganizationsSelectOptions },
}) => {
  useEffect(() => {
    getOrganizationsSelectOptions();
  }, []);

  const { search } = useLocation();
  const params = useMemo(() => qs.parse(search), [search]);

  const handleFiltering = useFilters(params);
  const handleSearch = useSearch(params);

  return (
    <Box
      py="20px"
      display="flex"
      alignItem="center"
      justifyContent="space-between"
    >
      <ButtonContainer>
        <SearchBar onSubmit={handleSearch} value={params.search} />
        <Filters active={!!params.organization}>
          <Autocomplete
            onChange={(_, val) =>
              handleFiltering({ organization: val?.value || undefined })
            }
            value={
              params.organization && organizationSelectOptions.length
                ? organizationSelectOptions.find(
                    e => e.value === params.organization,
                  )
                : null
            }
            getOptionLabel={option => option.label}
            options={organizationSelectOptions}
            renderInput={props => <TextField {...props} label="Organization" />}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            disablePortal
          />
        </Filters>
      </ButtonContainer>
    </Box>
  );
};
export default inject('eventsPageStore')(observer(InnerHeader));
