import React, { useMemo, useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import uniqueId from 'lodash/uniqueId';
import { TableBody, TableHead, TableCell, TableRow } from '@mui/material';
import { ExpandLess, ExpandMore, Star } from '@mui/icons-material';
import * as Styled from '@components/CardTable/style';
import { destructStats, formatPollString } from '@utils/otherUtils';

const DataRows = memo(
  ({ labels, values, percentages, nestedData, nested, isRating }) => {
    const [expanded, setExpanded] = useState(-1);

    const sortedLabels = useMemo(
      () =>
        isRating && nested
          ? labels
              .map(l => parseFloat(l))
              .sort((a, b) => b - a)
              .map(e => times(e, index => <Star key={index} />))
          : labels,
      [labels, values, percentages, nestedData, nested, isRating],
    );

    const getProperIndex = useCallback(
      index => (isRating && nested ? sortedLabels.length - 1 - index : index),
      [sortedLabels],
    );

    return (
      <>
        {sortedLabels?.map((label, index) => (
          <React.Fragment key={uniqueId()}>
            <TableRow className={nested ? 'nested' : 'no-padding'}>
              <TableCell width="40%" component="th" scope="row">
                {isRating && nested
                  ? label
                  : formatPollString(label, getProperIndex(index))}
              </TableCell>
              <TableCell align="right">
                {values[getProperIndex(index)]?.toLocaleString('en')}
              </TableCell>
              <TableCell align="right">
                {Math.floor(percentages[getProperIndex(index)]) || 0}%
              </TableCell>
              {nestedData[getProperIndex(index)]?.labels?.length > 0 ? (
                <TableCell align="right">
                  {expanded !== getProperIndex(index) ? (
                    <ExpandMore
                      className="pointer"
                      onClick={() => setExpanded(getProperIndex(index))}
                    />
                  ) : (
                    <ExpandLess
                      className="pointer"
                      onClick={() => setExpanded(-1)}
                    />
                  )}
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>

            {nestedData?.length > 0 && expanded === getProperIndex(index) ? (
              <DataRows
                labels={nestedData[getProperIndex(index)]?.labels}
                values={nestedData[getProperIndex(index)]?.values}
                percentages={nestedData[getProperIndex(index)]?.percentages}
                nestedData={nestedData[getProperIndex(index)]?.data}
                nested
                isRating={isRating}
              />
            ) : null}
          </React.Fragment>
        ))}
      </>
    );
  },
);

const CardTable = ({
  tableHeader: { action, counter, percent },
  data,
  nolimit = false,
  isRating,
}) => {
  const stats = useMemo(() => {
    const lastItem = { label: 'Others', value: 0 };
    if (!nolimit && data.length > 6) {
      for (let i = 4; i < data.length; i += 1) {
        lastItem.value += data[i].value || data[i].count;
      }
      return [...data.slice(0, 4), lastItem];
    }
    return data;
  }, [data]);

  const values = useMemo(() => destructStats(stats).values, [stats]);

  const labels = useMemo(() => destructStats(stats).labels, [stats]);

  const nestedData = useMemo(() => destructStats(stats).data, [stats]);

  const percentages = useMemo(() => destructStats(stats).percentages, [stats]);

  if (data.length === 0 || values.length === 0 || labels.length === 0) {
    return null;
  }

  return (
    <Styled.Table>
      <TableHead>
        <TableRow>
          <TableCell>{action}</TableCell>
          <TableCell align="right">{counter}</TableCell>
          <TableCell align="right">{percent || 0}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <DataRows
          labels={labels}
          values={values}
          percentages={percentages}
          nestedData={nestedData}
          isRating={isRating}
        />
      </TableBody>
    </Styled.Table>
  );
};

CardTable.propTypes = {
  tableHeader: PropTypes.object,
  data: PropTypes.array,
  isRating: PropTypes.bool,
};

CardTable.defaultProps = {
  tableHeader: null,
  data: null,
  isRating: false,
};

export default memo(CardTable);
