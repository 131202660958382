import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiTableContainer from '@mui/material/TableContainer';
import { Box } from '@mui/material';

const StyledTableContainer = styled(MuiTableContainer, {
  shouldForwardProp: prop =>
    !['secondaryTable', 'allRounded', 'outlined'].includes(prop),
})`
  border-radius: ${({ secondaryTable }) =>
    secondaryTable ? '0' : '5px 5px 0 0'};
  background-color: ${({ theme, secondaryTable }) =>
    secondaryTable
      ? theme.mainTable.secondaryBackground
      : theme.mainTable.background};
  min-width: fit-content;
  border: ${({ outlined }) =>
    outlined ? '1px solid rgba(224, 224, 224, 1)' : 'unset'};
  border-bottom: ${({ outlined }) => (outlined ? '0' : 'unset')};
  ${({ allRounded }) => allRounded && 'border-radius: 5px;'}
`;

const TableContainer = ({ children, ...props }) => {
  const wrapRef = useRef(null);
  const tabContRef = useRef(null);
  const [indicator, setIndicator] = useState(false);

  const observer = useMemo(
    () =>
      new ResizeObserver(() => {
        if (tabContRef.current && wrapRef.current) {
          if (tabContRef.current.offsetWidth > wrapRef.current.offsetWidth) {
            setIndicator(true);
          } else {
            setIndicator(false);
          }
        }
      }),
    [wrapRef.current, tabContRef.current],
  );

  useEffect(() => {
    observer.observe(tabContRef.current);
    observer.observe(wrapRef.current);

    return () => observer.disconnect();
  }, [wrapRef.current, tabContRef.current, observer]);

  return (
    <Box
      sx={{
        position: 'relative',
        ...(indicator && {
          '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '20px',
            borderRadius: '0 5px 0 0',
            background:
              'linear-gradient(to left, rgba(0, 0, 0, 0.17), rgba(255, 255, 255, 0))',
          },
        }),
      }}
    >
      <Box
        ref={wrapRef}
        sx={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        <StyledTableContainer ref={tabContRef} {...props}>
          {children}
        </StyledTableContainer>
      </Box>
    </Box>
  );
};

export default TableContainer;
