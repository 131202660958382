import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Link from '@components/Link';

const Title = styled(CardHeader)`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
`;

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const Icon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 10px;
`;

const Content = styled(CardContent)`
  display: flex;
  height: 100%;
`;

function PanelCard({ cardTitle, icon, description, path }) {
  return (
    <Card sx={{ cursor: 'pointer', height: '100%' }}>
      <Content component={Link} href={path} target="_blank">
        <Icon>{icon}</Icon>
        <Box>
          <Title title={cardTitle} />
          <Description>{description}</Description>
        </Box>
      </Content>
    </Card>
  );
}
export default PanelCard;
