import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Popover, Button, useTheme, Box } from '@mui/material';
import {
  MoreVert,
  FullscreenSharp,
  FullscreenExitSharp,
} from '@mui/icons-material';
import WorldMap from '@components/WorldMap';
import Card from '@components/Card';

const Wrapper = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const PopoutContainer = styled('div')`
  height: 70vh;
  width: 70vw;
`;

export const CardMap = ({
  color,
  analyticsStore: { loadingSingleCSVs, generateSingleCSV, enabledCSVs },
  markers,
}) => {
  const theme = useTheme();

  const [moreAnchor, setMoreAnchor] = useState(null);
  const [mapOpen, setMapOpen] = useState(false);

  const moreOpen = Boolean(moreAnchor);

  const currentProgress = useMemo(
    () => loadingSingleCSVs.find(a => a.type === 'location')?.progress || null,
    [loadingSingleCSVs?.length],
  );

  const handleDownload = () => {
    generateSingleCSV('location');
    setMoreAnchor(null);
  };

  return (
    <Wrapper>
      {enabledCSVs.includes('location') && (
        <Box ml="15%">
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <FullscreenSharp
              style={{
                color: color || theme.palette.primary.main,
                cursor: 'pointer',
                width: '30px',
                height: '30px',
                marginTop: '2px',
              }}
              onClick={() => setMapOpen(true)}
            />
            <MoreVert
              style={{
                color: color || theme.palette.primary.main,
                cursor: 'pointer',
                width: '30px',
                height: '30px',
                marginTop: '2px',
              }}
              onClick={event => setMoreAnchor(event.currentTarget)}
            />
          </Box>
          <Popover
            open={mapOpen}
            onClose={() => setMapOpen(false)}
            anchorReference="none"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card>
              <PopoutContainer>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 'auto',
                    justifyContent: 'flex-end',
                  }}
                >
                  <FullscreenExitSharp
                    style={{
                      color: color || theme.palette.primary.main,
                      cursor: 'pointer',
                      width: '30px',
                      height: '30px',
                      marginTop: '2px',
                    }}
                    onClick={() => setMapOpen(false)}
                  />
                  <MoreVert
                    style={{
                      color: color || theme.palette.primary.main,
                      cursor: 'pointer',
                      width: '30px',
                      height: '30px',
                      marginTop: '2px',
                    }}
                    onClick={event => setMoreAnchor(event.currentTarget)}
                  />
                </Box>
                <WorldMap markers={markers} markerSize={7} />
              </PopoutContainer>
            </Card>
          </Popover>
          <Popover
            open={moreOpen}
            onClose={() => setMoreAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={moreAnchor}
          >
            <Button
              variant="outlined"
              color="dark"
              onClick={() => handleDownload()}
              style={{ cursor: 'pointer' }}
            >
              {currentProgress ? `${currentProgress}%` : 'Download XLS'}
            </Button>
          </Popover>
        </Box>
      )}
    </Wrapper>
  );
};

export default inject('analyticsStore')(observer(CardMap));
