import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import assignmentsStore from '@stores/assignmentsStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@api';
import routerStore from '@stores/routerStore';
import routes from '@routes';
import { ASSIGNMENT_TYPE } from '@components/Assignments';

const initialState = {
  isLoading: false,
  globalMessage: null,
  defaultFormValues: {},
  events: [],
  formType: null,
  initialLoading: true,
};

export class GlobalMessageStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateGlobalMessage(...props);
    }
    if (this.isCreate) {
      return this.createGlobalMessage(...props);
    }

    return null;
  };

  @action getHelpResources = async ({ id }) => {
    try {
      this.initialLoading = true;
      const promises = [
        assignmentsStore.getResources({
          types: { [ASSIGNMENT_TYPE.EVENT]: true },
        }),
      ];

      if (id) {
        promises.push(this.getGlobalMessage({ id }));
      }

      await Promise.all(promises);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.globalMessage,
      event_id: assignmentsStore.events.list.find(
        ev => ev.value === this.globalMessage?.event_id,
      ),
    };
  };

  @action getGlobalMessage = async ({ id }) => {
    try {
      this.globalMessage = await ResourcesStore.getGlobalMessage(
        { id },
        { cancelToken: this.__cancelToken.token },
      );
    } catch (err) {
      routerStore.replace(routes.main.globalMessages);
      throw err;
    }
  };

  @action updateGlobalMessage = async ({ id, values, onSuccess, onError }) => {
    try {
      this.isLoading = true;

      const payload = {
        ...values,
        event_id: values.event_id.value,
      };

      const res = await ResourcesStore.updateGlobalMessage({
        id,
        payload,
        onError,
      });

      this.globalMessage = res;

      await onSuccess?.(res);

      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateGlobalMessage] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createGlobalMessage = async ({ values, onSuccess, onError }) => {
    try {
      this.isLoading = true;

      const payload = {
        ...values,
        event_id: values.event_id.value,
      };

      const res = await ResourcesStore.createGlobalMessage({
        payload,
        onError,
      });

      await onSuccess?.(res);

      return null;
    } catch (err) {
      console.debug('[createGlobalMessage] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteGlobalMessage = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteGlobalMessage({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteGlobalMessage] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action publishGlobalMessage = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      this.globalMessage = await ResourcesStore.publishGlobalMessage({
        id,
      });

      await onSuccess?.(this.globalMessage);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[publishGlobalMessage] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel?.();
    Object.entries(initialState).forEach(([key, val]) => {
      this[key] = val;
    });
  };
}

export default new GlobalMessageStore();
