import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import useTheme from '@mui/styles/useTheme';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={`
        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin: 0;
          padding: 0;
        }
        body {
          background: ${theme.colors.background};
          color: ${theme.colors.text};
          font-family: ${theme.fonts.main};
          font-weight: ${theme.typography.fontWeightRegular};
        }
        .wf-loading {
          visibility: hidden;
        }
        .wf-active body .wf-inactive {
          visibility: visible;
          font-family: Font1;
        }

        // Hack for disabling sticky toolbar in ckeditor
        .ck.ck-editor__top.ck-reset_all {
          position: unset;
        }

        .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
          position: unset;
        }

        .lock-scroll {
          overflow: hidden !important;
        }

        .chart_tooltip_arrow:before {
          content: "";
          position: absolute;
          bottom: -8px;
          left: -8px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 15px solid black;
          transform:rotate(45deg);
        }

        .Toast-cplus:is(.Toastify__toast) {
          padding: 0;
          background: unset;
          box-shadow: none;
          overflow: unset
        }
        .unstyled-link, .unstyled-link:visited, .unstyled-link:hover, .unstyled-link:active {
          color: inherit;
        }
      `}
    />
  );
};

export default GlobalStyle;
