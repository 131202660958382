import React, { Suspense, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import routes from '@routes';
import SignInPage from '@pages/signIn';
import SignUpPage from '@pages/signup';
import ResetPassword from '@pages/resetPassword';
import CreatePassword from '@pages/createPassword';
import GenericLoadingPage from '@pages/genericLoadingPage';
import NotFoundPage from '@pages/notFoundPage';
import EmailVerificationErrorPage from '@pages/emailVerificationError';
import Expired from '@pages/expired';
import { useWindowSize } from '@utils/useWindowSize';
import { Page } from '@components/Page';
import ProtectedRoute from '@components/ProtectedRoute';
import routesConfig from '@app/routesConfig';
import { isJackEthos } from '@styles/_theme';

const App = ({
  layoutStore,
  profileStore: { getProfile, profile },
  authStore: { isAuthenticated },
}) => {
  const { width } = useWindowSize();

  useEffect(() => {
    layoutStore.updateDimension(width);
  }, [width]);

  useEffect(() => {
    if (!profile && isAuthenticated()) getProfile();
  }, []);

  return (
    <Page>
      <Suspense fallback={<GenericLoadingPage suspended />}>
        <Switch>
          <Route exact path={routes.auth.signIn} component={SignInPage} />
          {!isJackEthos && (
            <Route
              exact
              path={routes.auth.signUp('free')}
              component={SignUpPage}
            />
          )}
          {!isJackEthos && (
            <Route
              exact
              path={routes.auth.signUp('lite')}
              component={SignUpPage}
            />
          )}
          {!isJackEthos && (
            <Route
              exact
              path={routes.emailVerificationError}
              component={EmailVerificationErrorPage}
            />
          )}

          <Route
            exact
            path={routes.auth.resetPassword}
            component={ResetPassword}
          />
          <Route exact path={routes.auth.expired} component={Expired} />
          <Route
            exact
            path={routes.auth.createPassword}
            component={CreatePassword}
          />
          <Route exact path={routes.notFound} component={NotFoundPage} />
          {!profile && !isAuthenticated() && (
            <Redirect to={routes.auth.signIn} />
          )}
          {!profile && <Route path="*" component={GenericLoadingPage} />}
          {routesConfig.map(({ Component, exact, path, secure }) => {
            if (secure) {
              return (
                <ProtectedRoute
                  key={path}
                  component={Component}
                  exact={exact}
                  path={path}
                />
              );
            }

            return (
              <Route
                key={path}
                component={Component}
                exact={exact}
                path={path}
              />
            );
          })}
          <Redirect to={routes.notFound} />
        </Switch>
      </Suspense>
    </Page>
  );
};

export default inject(
  'layoutStore',
  'profileStore',
  'authStore',
)(observer(App));
