/* eslint-disable no-unused-vars */
import React from 'react';
import { Pane } from '@pages/_common/Pane';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const BasicSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Pane>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="35px"
            mb="30px"
            sx={{ mb: '30px' }}
          />

          <Box maxWidth="600px" width="100%">
            <Stack spacing="20px">
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
            </Stack>
          </Box>

          <Box maxWidth="600px" width="100%">
            <Skeleton
              variant="rectangular"
              width="100%"
              height="35px"
              sx={{ my: '30px' }}
            />
            <Stack spacing="20px">
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Skeleton variant="rectangular" width="100%" height="20px" />
              <Stack direction="row" spacing="10px">
                <Box flex="1">
                  <Skeleton variant="rectangular" height="150px" width="100%" />
                </Box>
                <Box flex="1">
                  <Skeleton variant="rectangular" height="30px" width="150px" />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Pane>
      </Grid>
    </Grid>
  );
};

export default BasicSkeleton;
