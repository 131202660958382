import ResourcesStore from '@stores/resourcesStore';
import { getFileFromLink } from '@utils/fileUtils';

export class ExploreItemCloneService {
  static prepareExploreItem = async expId => {
    const exp = await ResourcesStore.getExploreItem({ id: expId });

    const imgs = this.fetchExploreItemImages(exp);
    exp.active = false;
    const { id, event_id, ...strippedExp } = exp;

    return {
      ...strippedExp,
      image: await imgs.image,
    };
  };

  static fetchExploreItemImages = exp => {
    return {
      image: getFileFromLink(exp.image?.url),
    };
  };
}
