import { action, extendObservable, computed } from 'mobx';
import { prepareSelectOptions } from '@utils/formUtils';
import { CancelToken } from '@app/api';
import ResourcesStore from '@stores/resourcesStore';
import axios from 'axios';
import urlSlug from 'url-slug';
import isEmpty from 'lodash/isEmpty';
import { FORM_TYPES, PLAYER_DESTINY_TYPES, PLAYER_TYPES } from '@constants';
// import eventStore from '@stores/eventStore';

const initialState = {
  isLoading: false,
  stagesGroups: null,
  organizationSelectOptions: [],
  unlinkedStagesEvents: [],
  stagesGroup: null,
  defaultFormValues: {},
  formType: null,

  initialLoading: true,
  isPlayersLoading: false,
  isActivePlayerLoading: false,

  // requests promises
  promises: {},

  stagesEvents: {},
};

const resolveDependencies = async dependencies => {
  const result = await Promise.all(dependencies);

  const err = result.find(item => item !== null);
  if (err) {
    throw new Error(err);
  }
};

const returnErrorMessage = (err, message) => {
  if (axios.isCancel(err)) {
    return 'Request canceled';
  }
  return message;
};
export class StagesStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @computed get isNotInitializedType() {
    return this.formType === null;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }
    this.getResources({ id });
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateGroup(...props);
    }
    if (this.isCreate) {
      return this.createGroup(...props);
    }
    return null;
  };

  @action getGroups = async ({ orgId, page }) => {
    try {
      const resp = await ResourcesStore.getStagesGroups(
        {
          orgId,
          page,
        },
        { cancelToken: this.__cancelToken?.token },
      );
      this.stagesGroups = resp;
    } catch (error) {
      console.debug('[geStagesGroups] failed', error);
    }
  };

  @action getGroup = async ({ id }) => {
    try {
      const resp = await ResourcesStore.getStagesGroup(
        { groupId: id },
        { cancelToken: this.__cancelToken?.token },
      );
      this.stagesGroup = resp;
    } catch (error) {
      console.debug('[getStagesGroup] failed', error);
    }
  };

  @action createGroup = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);

      this.stagesGroup = await ResourcesStore.createStagesGroup({
        payload,
        onSuccess,
      });
    } catch (err) {
      console.debug('[createStagesGroup] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateGroup = async ({ id, values, onSuccess }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);

      const { data } = await ResourcesStore.updateStagesGroup({
        id,
        payload,
        onSuccess,
      });

      this.stagesGroup = data;

      // eventStore.setupMainStageEvent(this.stagesGroup?.event_ids[0], true);

      // refetch values to show properly stages
      this.setupForm(id);
    } catch (err) {
      console.debug('[updateStagesGroup] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteStagesGroup = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteStagesGroup({ id, onSuccess });
    } catch (error) {
      console.debug('[deleteStagesGroup] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    return {
      name: values.name,
      image: values.image,
      thumbnail: values.thumbnail,
      organization_id: values.organization.value,
      event_ids: values.events.map(item => item.value?.id),
    };
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });

      this.organizationSelectOptions = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      ).sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (error) {
      console.debug('[getOrganizations] failed', error);
    }
  };

  @action getResources = async ({ id }) => {
    // all promises are stored in this.promises.
    // Start setting up sections as soon as all promises
    // required for that section was called (you can await them in the setup function)

    try {
      this.initialLoading = true;
      const sectionPromises = [];

      if (this.isEdit) {
        this.promises.getGroup = this.getGroup({ id });
      }

      if (this.isEdit) {
        await resolveDependencies([this.promises.getGroup]);
        const stagesArray = await Promise.all(
          this.stagesGroup.events.map(async stageEvent => {
            const [stageAgendaItems, event] = await Promise.all([
              this.getAgendaItems({ id: stageEvent.id }),
              this.getEvent({ id: stageEvent.id }),
            ]);
            let stagePlayer = {};
            let backup_player = {};
            if (event) {
              stagePlayer = await this.getPlayer({
                playerId: event?.player?.id,
              });
              backup_player = await this.getPlayer({
                backupPlayerId: event?.backup_player?.id,
              });
            }
            return {
              stageAgendaItems,
              stagePlayer,
              backup_player,
              eventName: event?.name,
              use_backup_player: event?.use_backup_player,
              eventId: event?.id,
            };
          }),
        );
        this.stagesEvents = Object.fromEntries(
          stagesArray.map((el, index) => [`stage-${index + 1}`, el]),
        );
      }
      await this.getOrganizationsSelectOptions();
      this.setupDefaultValues();

      await resolveDependencies(sectionPromises);
    } catch (err) {
      console.debug(
        '[getHelpResources] failed - form failed to initialize',
        err,
      );
    } finally {
      this.initialLoading = false;
    }
  };

  /// ---HELPERS---

  @action getAgendaItems = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getAgendaItems({
        event: id,
        sort: 'start_date ASC',
      });
      return results;
    } catch (err) {
      return returnErrorMessage(err, 'getAgendaItems failed');
    }
  };

  @action getEvent = async ({ id }) => {
    try {
      const ev = await ResourcesStore.getEvent(
        { id },
        { cancelToken: this.__cancelToken.token },
      );
      return ev;
    } catch (err) {
      return returnErrorMessage(err, 'getEvent failed');
    }
  };

  // ---- PLAYER HELPERS ----

  @action getPlayer = async ({ playerId, backupPlayerId }) => {
    try {
      let player = {};
      if (playerId) {
        player = await ResourcesStore.getPlayer({
          id: playerId,
        });
      }
      if (backupPlayerId) {
        player = await ResourcesStore.getPlayer({
          id: backupPlayerId,
        });
      }
      return player;
    } catch (err) {
      return returnErrorMessage(err, 'getPlayer failed');
    }
  };

  initPlayerValues = ({ isPrimary, prefix, eventName }) => {
    if (!isPrimary && !isEmpty(this.stagesEvents[prefix]?.backup_player)) {
      return this.stagesEvents[prefix]?.backup_player;
    }
    if (!isEmpty(this.stagesEvents[prefix]?.stagePlayer) && isPrimary) {
      return this.stagesEvents[prefix]?.stagePlayer;
    }

    return {
      player_type: PLAYER_TYPES.DEFAULT,
      playable_type: PLAYER_DESTINY_TYPES.EVENT,
      aws_hls_stream_stack_id: null,
      aws_hls_stack_name: urlSlug(eventName),
      aws_hls_stream_stack_encoding_profile: 'HD-1080p',
    };
  };

  @action deletePlayer = async ({ id, prefix, onSuccess, onError }) => {
    try {
      this.isPlayersLoading = true;
      await ResourcesStore.deletePlayer({ id });

      if (id === this.stagesEvents[prefix]?.backup_player?.id)
        this.stagesEvents[prefix].backup_player = null;

      if (
        id === this.stagesEvents[prefix].stagePlayer?.id &&
        this.stagesEvents[prefix].stagePlayer
      ) {
        this.stagesEvents[prefix].stagePlayer = this.stagesEvents[
          prefix
        ].backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      console.debug('[deletePlayer] failed', error);
      if (onError) onError();
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action submitPlayer = async ({
    isPrimary,
    values,
    prefix,
    onSuccess,
    onError,
  }) => {
    try {
      this.isPlayersLoading = true;
      let finalPlayer = null;

      if (!isPrimary && !isEmpty(this.stagesEvents[prefix].backup_player)) {
        finalPlayer = this.stagesEvents[prefix]?.backup_player;
      } else if (
        isPrimary &&
        !isEmpty(this.stagesEvents[prefix]?.stagePlayer) &&
        Object.keys(this.stagesEvents[prefix]?.stagePlayer).length > 1
      ) {
        finalPlayer = this.stagesEvents[prefix]?.stagePlayer;
      }

      const playerRole = !isPrimary ? 'BackupPlayer' : 'Player';
      let resultPlayer;

      if (finalPlayer) {
        resultPlayer = await ResourcesStore.updatePlayer({
          id: values.id,
          payload: values,
        });
      } else {
        const payload = {
          ...values,
          playable_id: this.stagesEvents[prefix].eventId,
          player_role: playerRole,
        };
        resultPlayer = await ResourcesStore.createPlayer({
          payload,
        });
      }

      if (!isPrimary && this.stagesEvents[prefix]?.stagePlayer) {
        this.stagesEvents[prefix].backup_player = {
          ...resultPlayer,
        };
      } else if (isPrimary && this.stagesEvents[prefix]?.stagePlayer) {
        this.stagesEvents[prefix].stagePlayer = {
          ...resultPlayer,
        };
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      console.debug('[submitPlayer] failed', error);
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action updateActivePlayer = async ({ values, onSuccess, onError }) => {
    this.isActivePlayerLoading = true;
    try {
      const { event_id, prefix, ...newValues } = values;

      const data = await ResourcesStore.updateEvent({
        id: event_id,
        payload: newValues,
        onError,
      });
      this.stagesEvents[prefix].use_backup_player = data.use_backup_player;
      await onSuccess?.(data);
      return null;
    } catch (error) {
      console.debug('[updateActivePlayer] failed', error);
      return null;
    } finally {
      this.isActivePlayerLoading = false;
    }
  };

  // ---- PLAYER HELPERS END ----

  @action getUnstagedEvents = async ({ orgId, page, per }) => {
    try {
      const { results } = await ResourcesStore.getUnstagedEvents({
        orgId,
        page,
        per,
      });
      this.unlinkedStagesEvents = (results || []).map(item => ({
        value: item,
        label: item.name,
      }));
    } catch (error) {
      console.debug('[getUnlinkedEvents] failed', error);
    }
  };

  @action setupDefaultValues = () => {
    if (this.isEdit) {
      const manipulateEventPlayerInfo = {};
      Object.keys(this.stagesEvents).forEach(elem => {
        manipulateEventPlayerInfo[
          `${elem}use_backup_player`
        ] = this.stagesEvents[elem].use_backup_player;
      });

      this.defaultFormValues = {
        ...this.stagesGroup,
        organization: this.organizationSelectOptions.find(
          org => org.value === this.stagesGroup?.organization?.id,
        ),
        events: this.stagesGroup?.events?.map(ev => ({
          label: ev.name,
          value: ev,
        })),
        ...manipulateEventPlayerInfo,
      };
    } else {
      this.defaultFormValues = {
        ...this.stagesGroup,
        organization: this.organizationSelectOptions.find(
          org => org.value === this.stagesGroup?.organization?.id,
        ),
        events: this.stagesGroup?.events?.map(ev => ({
          label: ev.name,
          value: ev,
        })),
      };
    }
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new StagesStore();
