import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const PaneContainer = styled(Box)``;

export const Pane = forwardRef(
  ({ children, background, noPaddings, boxShadow, sx, ...props }, ref) => {
    return (
      <PaneContainer
        ref={ref}
        sx={{
          background: background || (theme => theme.colors.box),
          p: noPaddings ? 0 : '30px',
          borderRadius: '4px',
          ...(boxShadow && { boxShadow }),
          ...sx,
        }}
        {...props}
      >
        {children}
      </PaneContainer>
    );
  },
);
