import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import HelpIcon from '@mui/icons-material/Help';
import styled from '@emotion/styled';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PanelCard from '@pages/dashboard/components/PanelCard';

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
  margin-bottom: 15px;
`;

function BottomPanel() {
  return (
    <Box>
      <Description> Help and resources </Description>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12} key="support">
          <PanelCard
            cardTitle="Support"
            icon={<QuestionAnswerIcon sx={{ fontSize: 50 }} color="primary" />}
            description="Contact us if you have any questions"
            path={`mailto:${process.env.REACT_APP_EMAIL}`}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} key="faq">
          <PanelCard
            cardTitle="FAQs"
            icon={<HelpIcon sx={{ fontSize: 50 }} color="success" />}
            description="Visit our FAQs for documentation and answers to frequently asked questions"
            path={process.env.REACT_APP_FAQ_URL}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} key="video-lessons">
          <PanelCard
            cardTitle="Video Lessons"
            icon={<SubscriptionsIcon sx={{ fontSize: 50 }} color="error" />}
            description="For more information check out our YouTube channel"
            path={process.env.REACT_APP_YOUTUBE_URL}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BottomPanel;
