import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
`;

export default ButtonContainer;
