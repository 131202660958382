import React from 'react';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import { Loader } from '@components/Loader';

const useStyle = makeStyles(theme => ({
  materialBtnRoot: {
    height: '100%',
    width: '100%',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: theme.fontWeights.semibold,
    fontFamily: theme.fonts.main,
    padding: props => props.padding,
    color: props => props.color,
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& .MuiButton-startIcon': {
      transform: 'scale(0.85)',
    },
  },
}));

const SIZE_TABLE = theme => ({
  s: {
    height: '34px',
    fontWeight: theme.fontWeights.semibold,
    fontSize: theme.fontSizes.tiny,
    padding: '0 15px 0 15px',
  },
  m: {
    height: '36px',
  },
  l: {
    height: '39px',
  },
});

const Container = styled('div')`
  background-color: ${props =>
    props.disabled ? props.theme.colors.textSecondary : props.bgcolor};
  border-radius: ${props => (props.borderradius ? props.borderradius : '4px')};
  height: ${props => props.height || get(props.sizes, 'height')};
  padding: ${props => (props.btnpadding ? '0 15px' : 'unset')};
`;

const Label = styled('div')`
  padding-top: 2px;
`;

const RegularButton = ({
  color,
  disabled,
  label,
  iconLeft,
  size,
  fontWeight,
  isLoading,
  buttonType,
  iconRight,
  btnPadding,
  borderRadius,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const properSizes = SIZE_TABLE(theme)[size];
  const classes = useStyle({ color, fontWeight, ...properSizes });
  return (
    <Container
      sizes={properSizes}
      disabled={disabled}
      btnpadding={btnPadding}
      borderradius={borderRadius}
      {...props}
    >
      <LoadingButton
        type={buttonType === 'submit' ? 'submit' : 'button'}
        classes={{ root: classes.materialBtnRoot }}
        disabled={disabled}
        startIcon={iconLeft}
        endIcon={iconRight}
        onClick={onClick}
        loading={isLoading}
        loadingIndicator={<Loader stroke="#fff" width="50px" />}
      >
        <Label>{label}</Label>
      </LoadingButton>
    </Container>
  );
};

export default RegularButton;
