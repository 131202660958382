import React, { useCallback, useState } from 'react';
import {
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  Badge,
  Stack,
  Box,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Container, Title } from '@components/Filters/style';
import { observer } from 'mobx-react';

const Filters = ({ children, active = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = useCallback(
    ev => {
      ev.stopPropagation();
      setAnchorEl(anchorEl ? null : ev.currentTarget);
    },
    [anchorEl],
  );

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box>
        <Badge color="primary" invisible={!active} variant="dot">
          <Button
            startIcon={<FilterListIcon />}
            variant="outlined"
            color="dark"
            onClick={handleButtonClick}
          >
            Filter
          </Button>
        </Badge>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 2],
              },
            },
          ]}
          placement="bottom-start"
        >
          <Paper sx={{ width: '300px' }}>
            <Container>
              <Title>Filters</Title>
              <Stack gap="10px" mt="10px">
                {children}
              </Stack>
            </Container>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default observer(Filters);
