import React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCplusToast } from './ToastContext';

export const CIRCLE_RADIUS = 12;
const STROKE_WIDTH = 2;

const animateKeyframes = keyframes({
  to: {
    strokeDashoffset: 0,
  },
});

const CircleWrapper = styled('svg', {
  shouldForwardProp: prop => !['isRunning'].includes(prop),
})(({ duration, isRunning }) => ({
  '& circle': {
    strokeDasharray: 2 * Math.PI * (CIRCLE_RADIUS - STROKE_WIDTH / 2),
    strokeDashoffset: 2 * Math.PI * (CIRCLE_RADIUS - STROKE_WIDTH / 2),
    animation: `${animateKeyframes} ${duration /
      1000}s 750ms ease-out forwards`,
    animationPlayState: isRunning ? 'running' : 'paused',
  },
}));

const CloseButton = ({ color }) => {
  const toast = useCplusToast();

  return (
    <Box
      sx={{
        position: 'relative',
        width: CIRCLE_RADIUS * 2,
        height: CIRCLE_RADIUS * 2,
        cursor: 'pointer',
      }}
      onClick={toast.closeToast}
    >
      <CircleWrapper
        height={CIRCLE_RADIUS * 2}
        width={CIRCLE_RADIUS * 2}
        duration={toast.duration}
        isRunning={toast.running}
      >
        <circle
          cx={CIRCLE_RADIUS}
          cy={CIRCLE_RADIUS}
          r={CIRCLE_RADIUS - STROKE_WIDTH / 2}
          stroke={color}
          strokeWidth={STROKE_WIDTH}
          fillOpacity="0"
        />
      </CircleWrapper>
      <CloseIcon
        sx={{
          position: 'absolute',
          top: '50%',
          fontSize: CIRCLE_RADIUS * 1.2,
          left: '50%',
          color,
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );
};

export default CloseButton;
