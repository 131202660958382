import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { Field, useForm, useFormState } from 'react-final-form';
import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import urlSlug from 'url-slug';
import TextInputWithLimit from '@components/formFields/TextInputWithLimit';
import CKEditor from '@components/_form/CKEditor/CKEditorInput';
import { Pane } from '@pages/_common/Pane';
import DatePickerInput from '@components/formFields/DatePickerInput';
import { FieldGroup } from '@components/_form/FieldGroup';
import EventPreviewLinks from '@components/EventPreviewLinks';
import EventSlugs from '@components/formFields/EventSlugs';
import { constructBaseUrl } from '@utils/otherUtils';
import { TIME_ZONES } from '@constants';
import { inject, observer } from 'mobx-react';
import BasicSkeleton from '@components/formSkeletons/BasicSkeleton';
import AutocompleteInput from '@components/formFields/AutocompleteInput';
import FormActions from '@components/FormActions';

const timezones = TIME_ZONES.map(tz => ({ label: tz, value: tz }));

export const orgAutocompleteOptions = {
  isOptionEqualToValue: (option, value) => option.value.id === value?.value.id,
  renderOption: (props, option) => (
    <li {...props} key={option.value.id}>
      {option.label}
    </li>
  ),
};

const EventMainSection = ({
  eventStore: {
    initialMainSectionLoading,
    event,
    isEdit,
    isCreate,
    organizations,
  },
}) => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  const form = useForm();

  const baseUrl = useMemo(
    () => constructBaseUrl(formValues.organization_id?.value.domain),
    [formValues.organization_id],
  );

  if (initialMainSectionLoading) return <BasicSkeleton />;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Pane>
          <FormActions
            title="Event details"
            primaryAction={form.submit}
            secondaryAction={isEdit && form.reset}
            primaryActionLabel={isEdit ? 'SAVE' : 'CREATE'}
            secondaryActionLabel="DISCARD CHANGES"
            sx={{ pb: '30px' }}
          />
          <Box maxWidth="600px" width="100%">
            <FieldGroup noPadding>
              <Field
                name="organization_id"
                label="Organization"
                required
                options={organizations}
                component={AutocompleteInput}
                {...orgAutocompleteOptions}
              />
            </FieldGroup>
            <FieldGroup>
              <Field
                label="Event name"
                name="name"
                required
                limit={100}
                component={TextInputWithLimit}
                parse={v => v}
              />
            </FieldGroup>
            <FieldGroup>
              {isEdit && (
                <EventPreviewLinks slugs={event.slugs} baseUrl={baseUrl} />
              )}
              {isCreate && (
                <EventPreviewLinks
                  suggested={
                    formValues.slugs?.length
                      ? null
                      : urlSlug(formValues.name || '')
                  }
                  baseUrl={baseUrl}
                />
              )}
            </FieldGroup>
            <FieldGroup>
              <Field
                label="Set your event URL:"
                placeholder="Type event slug"
                placeholderBelowInput="HINT: Type event slug and hit enter after each one"
                name="slugs"
                required={isEdit}
                normalizer={urlSlug}
                baseUrl={baseUrl}
                component={EventSlugs}
              />
            </FieldGroup>

            <Stack direction="row" spacing={2}>
              <FieldGroup>
                {isEdit ? (
                  <DatePickerInput
                    disabled
                    label="Planned start date"
                    type="dateOnly"
                    input={{
                      value:
                        formValues.agenda_start_datetime ||
                        formValues.planned_start_date,
                    }}
                    meta={{}}
                    sx={{ padding: '4px 0 4px' }}
                  />
                ) : (
                  <Field
                    name="planned_start_date"
                    label="Planned start date"
                    dateOnly
                    component={DatePickerInput}
                    sx={{ padding: '4px 0 4px' }}
                  />
                )}
              </FieldGroup>
              <FieldGroup>
                <Field
                  name="time_zone"
                  label="Time zone (optional)"
                  options={timezones}
                  component={AutocompleteInput}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </FieldGroup>
            </Stack>
            <FieldGroup sx={{ width: '50%' }}>
              <Field
                name="sunset_date"
                label="Sunset date (optional)"
                dateOnly
                component={DatePickerInput}
              />
            </FieldGroup>
            <FieldGroup>
              <Field
                label="Event description"
                name="description"
                component={CKEditor}
                parse={v => v}
              />
            </FieldGroup>
            <FieldGroup>
              <Field
                label="Optin box text"
                name="optin_box_text"
                component={CKEditor}
                parse={v => v}
              />
            </FieldGroup>
          </Box>
        </Pane>
      </Grid>
    </Grid>
  );
};

export default inject('eventStore')(observer(EventMainSection));
