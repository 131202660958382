import { useCallback, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import navigationConfig from '@app/navigationConfig';

function getSavedCollapsableSections() {
  const str =
    localStorage.getItem('cplus:collapsedSections') || '{"Main": true}';
  return JSON.parse(str);
}

function saveCollapsableSections(sections) {
  localStorage.setItem('cplus:collapsedSections', JSON.stringify(sections));
}

export function useCollapsableSideBar() {
  const [openedSections, setOpenedSections] = useState(
    getSavedCollapsableSections(),
  );
  const { pathname } = useLocation();

  const toggleSection = useCallback((sectionName, value) => {
    setOpenedSections(prev => ({
      ...prev,
      [sectionName]: value ?? !prev[sectionName],
    }));
  }, []);

  useEffect(() => {
    const sec = navigationConfig.find(section =>
      section.pages.find(page =>
        matchPath(pathname, { path: page.href.split('?')[0] }),
      ),
    );

    if (sec) toggleSection(sec.title, true);
  }, [pathname]);

  useEffect(() => {
    saveCollapsableSections(openedSections);
  }, [openedSections]);

  return { toggleSection, openedSections };
}
