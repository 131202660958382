import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTheme } from '@mui/styles';
import {
  composeValidators,
  required,
  mustBeValidEmail,
} from '@app/formValidators';
import TextInput from '@components/_form/TextInput';
import PasswordInput from '@components/_form/PasswordInput';
import { Button, BUTTON_TYPES } from '@app/components/Buttons/Button';

const FieldWrapper = styled(Box)`
  width: 100%;
  max-width: 400px;
`;

export const FullWidthForm = styled('form')`
  width: 100%;
`;

const SignInForm = ({ onSubmit, isLoading, theme, isCplusLogin }) => {
  const customTextInputStyles = useMemo(
    () => ({
      padding: '10px 10px 8px',
      fontSize: theme.fontSizes.body,
      borderRadius: isCplusLogin ? '20px' : '3px',
      backgroundColor: '#fff',
      outline: 'none',
    }),
    [isCplusLogin, theme],
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitError }) => (
        <FullWidthForm onSubmit={handleSubmit}>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            flexDirection="column"
          >
            <FieldWrapper>
              <Field
                label="Email"
                name="email"
                fieldType="text"
                customStyle={customTextInputStyles}
                validate={composeValidators(required, mustBeValidEmail)}
                component={TextInput}
              />
            </FieldWrapper>
            <FieldWrapper mt="8px">
              <Field
                label="Password"
                name="password"
                customStyle={customTextInputStyles}
                validate={required}
                component={PasswordInput}
              />
            </FieldWrapper>
            {submitError && (
              <FieldWrapper mt={4}>
                {submitError.map(err => (
                  <Box
                    mb="2px"
                    color={theme.colors.error}
                    fontSize={theme.fontSizes.tiny}
                    key={err}
                  >
                    {err.replace(/^\w/, c => c.toUpperCase())}
                  </Box>
                ))}
              </FieldWrapper>
            )}
            <Box
              display="flex"
              width="100%"
              mt="12px"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                isLoading={isLoading}
                label="LOGIN"
                type={BUTTON_TYPES.PRIMARY}
                onClick={handleSubmit}
                size="l"
                buttonType="submit"
                btnPadding="0 15px"
                borderRadius="20px"
              />
            </Box>
          </Box>
        </FullWidthForm>
      )}
    />
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withTheme(SignInForm);
