import { action, extendObservable } from 'mobx';
import { API, APIRoutes } from '@api';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  exploreItems: null,
  isLoading: 0,
  isDeletingId: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
};

export class ExploreItemsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getExploreItems = async ({
    page,
    search,
    sort,
    silent,
    event_id,
    agenda_item_id,
    active,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getExploreItems({
        page,
        search,
        sort,
        event_id,
        agenda_item_id,
        active,
      });

      this.exploreItems = {
        ...res,
        search,
        sort,
        event_id,
        agenda_item_id,
        active,
        page,
      };
    } catch (err) {
      console.debug('[getExploreItems] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action deleteExploreItem = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteExploreItem({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteExploreItem] failed', err);
      onError?.();
    } finally {
      this.isDeletingId = null;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return [
      'search',
      'page',
      'sort',
      'event_id',
      'agenda_item_id',
      'active',
    ].every(param => params[param] === this.exploreItems?.[param]);
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
  };

  @action handleExploreItemSwitch = async ({ id, payload }) => {
    try {
      const response = await API.put(
        `${APIRoutes.EXPLORE_ITEMS}/${id}`,
        payload,
      );

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new ExploreItemsPageStore();
