import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@app/api';
import axios from 'axios';
import routes from '@routes';
import routerStore from '@stores/routerStore';

const initialState = {
  formType: null,
  isLoading: false,
  initialLoading: true,
  chatMessage: {},
  chatRoom: null,
  defaultFormValues: {},
};
export class ChatMessageStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action getHelpResources = async ({ id }) => {
    try {
      this.initialLoading = true;
      await this.getChatMessage({ id });
      await this.getChatRoom();
      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[ChatMessageEditPageStore getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action getChatMessage = async ({ id }) => {
    try {
      this.chatMessage = await ResourcesStore.getChatMessage(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.debug('[getChatMessage] failed', err);
      }
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.chatMessages);
      }
      throw err;
    }
  };

  @action getChatRoom = async () => {
    try {
      this.chatRoom = await ResourcesStore.getChatRoom(
        { id: this.chatMessage.chat_room_id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.debug('[getChatMessage] failed', err);
      }
      throw err;
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateChatMessage(...props);
    }

    return null;
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = { ...this.chatMessage };
  };

  @action deleteChatMessage = async props => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteChatMessage(props);
    } catch (err) {
      console.debug('[deleteChatMessage] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateChatMessage = async ({ id, values, onSuccess, ...props }) => {
    this.isLoading = true;

    try {
      const updatedChatMessage = await ResourcesStore.updateChatMessage({
        id,
        payload: values,
        ...props,
      });

      onSuccess?.(updatedChatMessage);
      this.chatMessage = updatedChatMessage;
      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateChatMessage] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ChatMessageStore();
