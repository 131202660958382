import {
  BigCplusLogo,
  Card,
  CardContent,
  CplusContainer,
  PageContent,
} from '@pages/signIn';
import React from 'react';
import { Box } from '@mui/material';
import ExpiredForm from '@pages/expired/ExpiredForm';
import CplusLogo from '@assets/images/cplus-logo-large-white.png';
import { Logo } from '@app/components/Logo';
import { inject, observer } from 'mobx-react';
import { isJackEthos } from '@styles/_theme';

const Expired = ({ authStore: { setNewPassword, isLoading, logout } }) => {
  const logoutHandler = () => {
    logout();
  };

  if (!isJackEthos) {
    return (
      <Box display="flex" sx={{ height: '100vh', width: '100%' }}>
        <CplusContainer pt="5%">
          <Box mb="50px">
            <BigCplusLogo src={CplusLogo} />
          </Box>
          <ExpiredForm
            onSubmit={setNewPassword}
            isCplusLogin
            isLoading={isLoading}
            handleSignOut={logoutHandler}
          />
        </CplusContainer>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      sx={{ height: '100vh', width: '100%', backgroundColor: 'black' }}
    >
      <PageContent>
        <Box mb={3}>
          <Logo />
        </Box>
        <Card>
          <CardContent>
            <ExpiredForm
              onSubmit={setNewPassword}
              isLoading={isLoading}
              handleSignOut={logoutHandler}
            />
          </CardContent>
        </Card>
      </PageContent>
    </Box>
  );
};

export default inject('authStore')(observer(Expired));
