import { action, observable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LS_KEYS } from '@constants';
import {
  API,
  APIRoutes,
  setAuthenticationToken,
  setClient,
  setUid,
} from '@api';
import { profileStore } from '@stores';
import { ROLES_ACCESS_LIST, WILDCARD_ACCESS } from '@app/authManager';
import { FORM_ERROR } from 'final-form';
import routerStore from '@stores/routerStore';
import subscriptionStore from '@stores/subscriptionStore';
import uniq from 'lodash/uniq';
import routes from '@routes';

export class AuthStore {
  @observable isLoading = false;

  @observable authenticationToken = '';

  @observable previousRoute = null;

  isAuthenticated = () => !!localStorage.getItem(LS_KEYS.AUTH_TOKEN);

  @action isAllowed = ({ currentPath, checkPermissionPath }) => {
    const { userRole } = profileStore;

    if (!userRole) {
      if (process.env.REACT_APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('[authStore.isAllowed] user has no role yet');
      }
      return false;
    }

    if (checkPermissionPath) {
      return !!ROLES_ACCESS_LIST[userRole].find(
        path => path === checkPermissionPath || path === WILDCARD_ACCESS,
      );
    }

    if (currentPath) {
      return !!ROLES_ACCESS_LIST[userRole].find(
        path =>
          path === WILDCARD_ACCESS ||
          matchPath(currentPath, { path, exact: false }),
      );
    }

    if (process.env.REACT_APP_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(
        '[authStore.isAllowed] function called with wront parameters',
      );
    }

    return false;
  };

  @action
  checkIfUserIs = (rolesList = []) => {
    const { userRole } = profileStore;

    return rolesList.find(role => role === userRole);
  };

  @action
  signUp = async ({ payload, onSuccess }) => {
    this.isLoading = true;
    try {
      const resp = await API.post(APIRoutes.SIGN_UP, payload);
      if (onSuccess) {
        onSuccess(resp);
      }
    } catch (error) {
      return { [FORM_ERROR]: error.response?.data?.errors };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action
  updateBillingDetails = async ({ data, onSuccess }) => {
    try {
      const resp = await API.patch(APIRoutes.SIGN_UP_UPDATE_USER, {
        billing_info_attributes: { ...data },
      });

      profileStore.updateProfileBillingInfo(resp?.data?.billing_info);
      if (onSuccess) {
        onSuccess(resp);
      }
    } catch (error) {
      console.debug(error);
      toast.cError('Saving billing details failed');
    }
  };

  @action
  resendSignUpEmail = async (email, isLite) => {
    try {
      await API.post(APIRoutes.SIGN_UP_RESEND_EMAIL, {
        email,
        redirect_url: `${process.env.REACT_APP_CMS_URL}sign-up/${
          isLite ? 'lite' : 'free'
        }?step=billingDetails`,
      });
    } catch (error) {
      toast.cError(
        'Could not resend verification email. Please contact administrator.',
      );
    }
  };

  @action
  signIn = async ({ email, password }, onSuccess, onError) => {
    this.isLoading = true;
    try {
      const {
        data: { data, info },
        headers: { 'access-token': token, client, uid },
      } = await API.post(APIRoutes.SIGN_IN, { email, password });

      setAuthenticationToken(token);
      setUid(uid);
      setClient(client);

      this.authenticationToken = token;

      if (info.password_expired) {
        routerStore.push(routes.auth.expired);
        return null;
      }

      await profileStore.getProfile();
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (e) {
      if (onError) {
        onError(e.response?.data?.errors);
      }
      return { [FORM_ERROR]: e.response?.data?.errors };
    } finally {
      this.isLoading = false;
    }

    return null;
  };

  @action setNewPassword = async values => {
    this.isLoading = true;
    try {
      await API.put(APIRoutes.EXPIRED, values);

      await profileStore.getProfile();

      if (this.previousRoute) {
        routerStore.replace(
          `${this.previousRoute.pathname}${this.previousRoute.search}`,
        );
        this.previousRoute = null;
      } else {
        routerStore.replace(routes.main.dashboard);
      }

      return null;
    } catch (err) {
      return {
        [FORM_ERROR]: uniq(err.response.data?.errors?.password || []),
        current_password: err.response.data?.errors?.current_password,
      };
    } finally {
      this.isLoading = false;
    }
  };

  @action forgotPassword = async values => {
    try {
      const response = await API.post(APIRoutes.RESET_PASSWORD, {
        email: values.email,
        redirect_url: APIRoutes.REDIRECT_URL,
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  @action resetPassword = async ({ headers, ...values }) => {
    try {
      if (headers) {
        API.defaults.headers = {
          ...headers,
        };
      }
      return await API.put(APIRoutes.RESET_PASSWORD, values);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  @action
  logout = async () => {
    this.isLoading = true;
    await API.delete(APIRoutes.SIGN_OUT);
    this.clearStore();
    this.authenticationToken = '';

    localStorage.removeItem(LS_KEYS.UID);
    localStorage.removeItem(LS_KEYS.CLIENT);
    localStorage.removeItem(LS_KEYS.AUTH_TOKEN);

    subscriptionStore.clearStore();
    routerStore.push(routes.auth.signIn);

    this.isLoading = false;
  };

  @action clearStore = async () => {
    await profileStore.clearStore();
  };
}

export default new AuthStore();
