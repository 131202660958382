import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { CancelToken } from '@api';
import axios from 'axios';

const initialState = {
  isLoading: 0,
  logs: null,
  eventsSelectOptions: [],
  cancelToken: null,
};

const paramList = [
  'resource',
  'log_action',
  'show_sign_in',
  'event_id',
  'page',
];

class LogsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getLogs = async ({ silent, ...params }) => {
    try {
      if (!silent) this.isLoading += 1;
      this.cancelToken?.cancel();
      this.cancelToken = CancelToken.source();
      const res = await ResourcesStore.getLogs(params, {
        cancelToken: this.cancelToken.token,
      });

      this.logs = {
        ...res,
        ...params,
      };
    } catch (e) {
      if (!axios.isCancel(e)) console.debug('[getLogs] failed', e);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = params => {
    return paramList.every(
      paramName => params[paramName] === this.logs?.[paramName],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions.replace(
        resp.results.sort((a, b) => {
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
          return 0;
        }),
      );
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new LogsStore();
