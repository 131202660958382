import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextInput from '@components/_form/onboarding/TextInput';
import PasswordChecker from '@pages/signup/components/PasswordChecker';
import Checkbox from '@components/_form/onboarding/Checkbox';
import {
  composeValidators,
  required,
  mustBeValidEmail,
  passwordsMustMatch,
  passwordMustBeStrength,
} from '@app/formValidators';
import Link from '@components/Link';

const FieldWrapper = styled(Box)`
  width: 100%;
  margin-top: 10px !important;
`;

const FullWidthForm = styled('form')`
  width: 100%;
`;

export const SubmitBtn = styled(Box)`
  display: flex;
  background-color: ${({ disabled }) => (disabled ? 'lightgrey' : '#60ddff')};
  color: ${({ disabled }) => (disabled ? '#000' : '#2a2e45')};
  border-radius: 5px;
  padding: 12px 30px;
  margin-top: 15px !important;
  font-size: 14px;
  justify-content: center;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  }
`;

const TermsLink = styled(Link)`
  text-decoration: none;
  color: #60ddff;
`;

const SignUpForm = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <FullWidthForm onSubmit={handleSubmit}>
          <Box
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <FieldWrapper>
              <Field
                label="First name"
                name="first_name"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Last name"
                name="last_name"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Email address"
                name="email"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required, mustBeValidEmail)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Create a password"
                name="password"
                fieldType="password"
                component={TextInput}
                validate={composeValidators(required, passwordMustBeStrength)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Confirm password"
                name="password_confirmation"
                fieldType="password"
                component={TextInput}
                validate={composeValidators(required, passwordsMustMatch)}
              />
            </FieldWrapper>
          </Box>
          <PasswordChecker />
          <FieldWrapper mt="25px !important">
            <Field
              label="terms_and_conditions_accepted"
              name="terms_and_conditions_accepted"
              type="checkbox"
              component={Checkbox}
              validate={composeValidators(required)}
              checkboxContent={
                <Box color="#979bb1">
                  I agree to the{' '}
                  <TermsLink
                    target="_blank"
                    href="https://cplus.live/privacy-policy"
                  >
                    Privacy Policy
                  </TermsLink>{' '}
                  and{' '}
                  <TermsLink
                    target="_blank"
                    href="https://cplus.live/terms-conditions"
                  >
                    Terms of Service
                  </TermsLink>
                </Box>
              }
            />
          </FieldWrapper>
          <Box display="flex" justifyContent="flex-end">
            <SubmitBtn onClick={handleSubmit}>CREATE ACCOUNT</SubmitBtn>
          </Box>
        </FullWidthForm>
      )}
    />
  );
};

export default SignUpForm;
