import React from 'react';
import { action, extendObservable, computed } from 'mobx';
import { CancelToken } from '@app/api';
import ResourcesStore from '@stores/resourcesStore';
import modalStore from '@stores/modalStore';
import routerStore from '@stores/routerStore';
import routes from '@app/routes';
import CloningBannerOptionsModal from '@app/components/modals/CloningBannerOptionsModal';
import { BannerCloneService } from '@app/CloningServices/BannerCloneService';
import { FORM_TYPES } from '@constants';

const initialState = {
  isLoading: false,
  banner: {},
  defaultFormValues: {},
  events: [],
  cloneRunning: false,
  phase: '',
  formType: null,
};

export class BannerStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      this.updateBanner(...props);
    }
    if (this.isCreate) {
      this.createBanner(...props);
    }

    return null;
  };

  @action getHelpResources = async ({ id }) => {
    this.isLoading = true;
    try {
      if (id) {
        await this.getBanner({ id });
      }
      await Promise.allSettled([this.getEventSelectOptions()]);
      this.setupDefaultFormValues();
    } catch (error) {
      console.debug('[getHelpResources] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.banner,
      event_id: this.events.find(ev => ev.value === this.banner.event_id),
    };
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.events = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getBanner = async ({ id }) => {
    try {
      this.banner = await ResourcesStore.getBanner(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.banners);
      }
      console.debug('[getBanner] failed', err);
      throw err;
    }
  };

  @action createBanner = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);
      this.banner = await ResourcesStore.createBanner({
        payload,
        onSuccess,
      });
    } catch (error) {
      console.debug('[createBanner] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    return {
      ...values,
      event_id: values.event_id.value,
    };
  };

  @action updateBanner = async ({ id, values, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);
      const res = await ResourcesStore.updateBanner({
        id,
        payload,
        onSuccess,
        onError,
      });
      this.banner = res;
      this.setupDefaultFormValues();
    } catch (error) {
      console.debug('[updateBanner] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteBanner = async ({ id, onSuccess }) => {
    this.isLoading = true;
    await ResourcesStore.deleteBanner({ id, onSuccess });
    this.isLoading = false;
  };

  @action handleCloneBanner = () => {
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'bannerCloneModal',
      content: (
        <CloningBannerOptionsModal
          onClone={this.cloneProcess}
          events={this.events}
        />
      ),
    });
  };

  @action cloneProcess = async values => {
    this.cloneRunning = true;
    this.phase = 'Banner';

    const preparedBanner = await BannerCloneService.prepareBanner(
      this.banner.id,
    );

    const res = await ResourcesStore.createBanner({
      payload: {
        ...preparedBanner,
        event_id: values.event_id,
      },
      requestOptions: {
        headers: {
          bypass_uploaders_validation: true,
        },
      },
    });

    this.defaultFormValues = {};
    routerStore.push(`${routes.banner(res.id)}`);
    modalStore.closeModal();

    this.cloneRunning = false;
  };

  @action clearStore = () => {
    modalStore.killModal('bannerCloneModal');
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new BannerStore();
