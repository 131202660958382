import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Image from '@components/Image';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResetPasswordFrom from '@pages/resetPassword/components/ResetPasswordFrom';
import { JackEthosContainer } from '@pages/signIn/index';
import { isJackEthos } from '@styles/_theme';
import { toast } from 'react-toastify';
import { MOBILE_BREAKPOINT } from '@styles/breakpoints';
import { Logo } from '@components/Logo';
import CplusBg from '@assets/images/Cplus_login_bg.jpeg';
import CplusLogo from '@assets/images/cplus-logo-large-white.png';

export const PageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Card = styled(Box)`
  width: 100%;
  padding: 25px 20px;
  background: ${({ theme }) => theme.colors.box};
  border-radius: 4px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 500px;
  }
`;

export const CardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CplusContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url(${CplusBg});
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: unset;
    justify-content: center;
  }
`;

export const BigCplusLogo = styled(Image)`
  width: 350px;
`;

const Message = styled(Box)`
  text-align: center;
  max-width: 80%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

const ResetPassword = ({
  authStore: { forgotPassword, isAuthenticated, isLoading },
  history,
}) => {
  const [succesMessage, setSuccesMessage] = useState('');
  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/');
    }
  }, [history]);

  const handleOnPasswordReset = async val => {
    try {
      const response = await forgotPassword(val);
      setSuccesMessage(response.data?.message);
    } catch (error) {
      toast.cError(error.response.data.errors[0]);
    }
  };

  // CON-1367 - requested to change only on Cplus
  if (!isJackEthos) {
    return (
      <PageContent>
        <CplusContainer pt="15%">
          <Box mb="50px">
            <BigCplusLogo src={CplusLogo} />
          </Box>
          {!succesMessage ? (
            <ResetPasswordFrom
              isLoading={isLoading}
              onSubmit={handleOnPasswordReset}
              isCplusReset
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <Message>{succesMessage}</Message>
            </Box>
          )}
        </CplusContainer>
      </PageContent>
    );
  }
  return (
    <JackEthosContainer>
      <PageContent>
        <Box mb="12px">
          <Logo />
        </Box>
        <Card>
          <CardContent>
            {!succesMessage ? (
              <ResetPasswordFrom
                isLoading={isLoading}
                onSubmit={handleOnPasswordReset}
              />
            ) : (
              <Box display="flex" justifyContent="center">
                <Message>{succesMessage}</Message>
              </Box>
            )}
          </CardContent>
        </Card>
      </PageContent>
    </JackEthosContainer>
  );
};

export default inject('authStore')(observer(ResetPassword));
