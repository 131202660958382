import { action, extendObservable } from 'mobx';
import { prepareSelectOptions } from '@utils/formUtils';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  eventsUpcoming: null,
  eventsLive: null,
  eventsPast: null,
  organizationSelectOptions: [],
  isDeletingId: null,
};

export class EventsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getEvents = async ({
    page,
    search,
    per,
    organization,
    sort,
    silent,
    scope,
  }) => {
    try {
      if (!silent) this.isLoading += 1;

      const result = await ResourcesStore.getEvents({
        page,
        search,
        per,
        organization,
        sort: sort || 'agenda_start_datetime DESC',
        scope,
      });

      if (scope === 'upcoming') {
        this.eventsUpcoming = { ...result, search, organization, sort, page };
      } else if (scope === 'live') {
        this.eventsLive = { ...result, search, organization, sort, page };
      } else if (scope === 'past') {
        this.eventsPast = { ...result, search, organization, sort, page };
      }
    } catch (err) {
      console.debug('[getEvents] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageUpcomingSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'organization'].every(
      param => params[param] === this.eventsUpcoming?.[param],
    );
  };

  @action matchPagePastSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'organization'].every(
      param => params[param] === this.eventsPast?.[param],
    );
  };

  @action matchPageLiveSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'organization'].every(
      param => params[param] === this.eventsLive?.[param],
    );
  };

  @action deleteEvent = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteEvent({ id, onError });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteEvent] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
        sort: 'name ASC',
      });

      this.organizationSelectOptions = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      );
    } catch (err) {
      console.debug('[getOrganizationsSelectOptions] failed', err);
    }
  };
}

export default new EventsPageStore();
