import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import Input from '@components/Input';
import { styled } from '@mui/material/styles';
import { FormError } from '@components/_form/FormError';

const Label = styled(Box)`
  opacity: 0.7;
  padding-bottom: 6px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding-left: ${props => (props.labelPL ? props.labelPL : '0')};
`;

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: ${props => (props.editing ? 'flex' : 'inline-block')};
`;

const styles = () => ({
  textFieldWhite: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  inputWhite: {
    color: 'white',
  },
});

const TextInput = ({
  meta,
  label,
  input,
  width,
  classes,
  inputProps,
  labelColor,
  styledWhite,
  disabledIcons,
  autocomplete,
  markAsRequired,
  customStyle,
  disabled,
  fieldType,
  onBlur,
  labelPL,
  ignorePristineTouched,
  ...props
}) => {
  const extendedOnBlur = ev => {
    if (onBlur) onBlur(ev);
    input.onBlur(ev);
  };

  return (
    <Box>
      <Box position="relative" width="100%">
        {label && (
          <Label htmlFor={label} labelPL={labelPL}>
            {label}
            {markAsRequired && (
              <Box ml={1} display="inline-block" color="red">
                *
              </Box>
            )}
          </Label>
        )}
        <FieldWrapper>
          <Input
            id={label}
            onChange={input.onChange}
            onBlur={extendedOnBlur}
            onFocus={input.onFocus}
            value={input.value}
            autoComplete={autocomplete}
            type={fieldType || 'text'}
            disabled={disabled}
            sx={{
              color: 'white',
              fontWeight: 'normal',
              fontSize: '18px',
              border: 0,
              borderBottom: '2px solid #55596f',
              padding: '0px 10px 4px 0',
              opacity: disabled && '1!important',
              backgroundColor: 'transparent',

              '&:focus': {
                outline: 'none',
              },

              ...customStyle,
            }}
            {...props}
          />
        </FieldWrapper>
      </Box>
      {meta &&
        (meta.touched || !meta.pristine || ignorePristineTouched) &&
        (meta.error || meta.submitError) && (
          <Box mt={1}>
            <FormError
              meta={meta}
              ignorePristineTouched={ignorePristineTouched}
            />
          </Box>
        )}
    </Box>
  );
};

export default withStyles(styles)(TextInput);
