import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { Loader } from '@components/Loader';

const GenericLoadingPage = ({ suspended }) => {
  return (
    !suspended && (
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Loader width="150px" />
      </Box>
    )
  );
};

export default observer(GenericLoadingPage);
