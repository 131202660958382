import { action, extendObservable, computed } from 'mobx';
import { prepareSelectOptions } from '@utils/formUtils';
import { CancelToken } from '@app/api';
import { FORM_TYPES } from '@constants';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: false,
  multiMarketGroups: null,
  organizationSelectOptions: [],
  unlinkedMultiMarketEvents: [],
  multiMarketGroup: null,
  defaultFormValues: {},
  formType: null,
};
export class MultiMarketGroupStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }
    this.getResources({ id });
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateGroup(...props);
    }
    if (this.isCreate) {
      return this.createGroup(...props);
    }
    return null;
  };

  @action getGroups = async ({ orgId, page }) => {
    try {
      const resp = await ResourcesStore.getMultiMarketGroups(
        {
          orgId,
          page,
        },
        { cancelToken: this.__cancelToken?.token },
      );
      this.multiMarketGroups = resp;
    } catch (error) {
      console.debug('[getMultiMarketGroups] failed', error);
    }
  };

  @action getGroup = async ({ id }) => {
    try {
      const resp = await ResourcesStore.getMultiMarketGroup(
        { groupId: id },
        { cancelToken: this.__cancelToken?.token },
      );
      this.multiMarketGroup = resp;
    } catch (error) {
      console.debug('[getMultiMarketGroup] failed', error);
    }
  };

  @action createGroup = async ({ values, onSuccess }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);

      const res = await ResourcesStore.createMultiMarketEventsGroup({
        payload,
      });

      await onSuccess(res);
    } catch (err) {
      console.debug('[createMultiMarketEventsGroup] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateGroup = async ({ id, values, onSuccess }) => {
    try {
      this.isLoading = true;
      const payload = this.prepareValuesToSubmit(values);

      const { data } = await ResourcesStore.updateMultiMarketEventsGroup({
        id,
        payload,
      });

      await onSuccess(data);
      this.multiMarketGroup = data;
      this.setupDefaultValues();
    } catch (err) {
      console.debug('[updateMultiMarketEventsGroup] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteMultiMarket = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteMultiMarketEventsGroup({ id, onSuccess });
    } catch (error) {
      console.debug('[deleteMultiMarketEventsGroup] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action prepareValuesToSubmit = values => {
    return {
      name: values.name,
      image: values.image,
      thumbnail: values.thumbnail,
      organization_id: values.organization.value,
      event_ids: values.events.map(item => item.value?.id),
    };
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });

      this.organizationSelectOptions = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      ).sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (error) {
      console.debug('[getOrganizations] failed', error);
    }
  };

  @action getResources = async ({ id }) => {
    try {
      this.isLoading = true;
      if (this.isEdit) {
        await this.getGroup({ id });
      }
      await this.getOrganizationsSelectOptions();

      this.setupDefaultValues();
    } catch (error) {
      console.debug('[getResources] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getUnlinkedEvents = async ({ orgId, page, per }) => {
    try {
      const { results } = await ResourcesStore.getUnlinkedMultiMarketEvents({
        orgId,
        page,
        per,
      });
      this.unlinkedMultiMarketEvents = (results || []).map(item => ({
        value: item,
        label: item.name,
      }));
    } catch (error) {
      console.debug('[getUnlinkedEvents] failed', error);
    }
  };

  @action setupDefaultValues = () => {
    this.defaultFormValues = {
      ...this.multiMarketGroup,
      organization: this.organizationSelectOptions.find(
        org => org.value === this.multiMarketGroup?.organization?.id,
      ),
      events: this.multiMarketGroup?.events?.map(ev => ({
        label: ev.name,
        value: ev,
      })),
    };
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new MultiMarketGroupStore();
