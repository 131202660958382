import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({ input, handleSubmit }) => {
  return (
    <InputBase
      placeholder="Search"
      type="text"
      endAdornment={
        <IconButton sx={{ p: '5px' }} onClick={handleSubmit}>
          <SearchIcon />
        </IconButton>
      }
      sx={theme => ({
        width: '300px',
        borderRadius: '4px',
        border: theme.searchBar.border,
        backgroundColor: theme.searchBar.background,
        '& .MuiInputBase-input': {
          py: '5px',
          px: '10px',
          lineHeight: '1.75',
          height: 'unset',
          fontSize: '0.875rem',
        },

        transition: theme.transitions.create('border'),

        '&.Mui-focused': {
          border: theme.searchBar.borderFocused,
        },
      })}
      {...input}
    />
  );
};

export default SearchInput;
