import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  chatAbuses: null,
  chatMessage: null,
  eventsSelectOptions: [],
  isDeletingId: null,
};

export class ChatAbusesPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getChatAbuses = async ({
    page,
    search,
    message,
    silent,
    sort,
    event_id,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getChatAbuses({
        page,
        search,
        message,
        sort,
        event_id,
      });

      this.chatAbuses = {
        ...res,
        search,
        message,
        sort,
        page,
        event_id,
      };
    } catch (err) {
      console.debug('[getChatAbuses] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action removeChatMessage = () => {
    this.chatMessage = null;
  };

  @action getChatMessage = async ({ msg }) => {
    try {
      this.chatMessage = await ResourcesStore.getChatMessage({ id: msg });
    } catch (err) {
      console.debug('[getChatMessage] failed', err);
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'message', 'event_id'].every(
      param => params[param] === this.chatAbuses?.[param],
    );
  };

  @action deleteChatAbuse = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;

      await ResourcesStore.deleteChatAbuse({
        id,
      });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteChatAbuse] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };
}

export default new ChatAbusesPageStore();
