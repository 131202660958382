import React, { useEffect } from 'react';
import {
  Button,
  Avatar,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Box,
} from '@mui/material';
import hs from 'humanize-string';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import { KeyboardArrowLeft, PhoneIphone } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  boldCell,
  centeredSx,
  tableCell,
  tableRow,
} from '@pages/analytics/components/Sidebar/Attendee/style';
import Card, { CardTitle } from '@components/Card';
import { Content, TitleBox } from '@pages/analytics/components/Sidebar/style';
import { Loader } from '@components/Loader';
import { moment } from '@utils/date';
import {
  getEventKey,
  translateEventDictionary,
  getMoreDetails,
  getEventTitleDetails,
  IconOfSubtype,
} from '@utils/otherUtils';
import { COUNTRY_CODES } from '@constants';

const Attendee = ({
  analyticsStore: {
    selectedAttendeeId,
    selectedAttendee,
    resetAttendee,
    attendeeDetailsLoading,
    fetchAttendee,
  },
}) => {
  const theme = useTheme();
  useEffect(() => {
    const init = async () => {
      await fetchAttendee();
    };
    init();
  }, []);

  if (!selectedAttendeeId || !selectedAttendee) return null;

  const handleCustomRf = el => {
    if (el?.label?.toLowerCase() === 'country') {
      return COUNTRY_CODES[el.value] || el.value || 'no data';
    }

    return el.value || 'no data';
  };

  return (
    <>
      <TitleBox>
        <Button
          sx={{ justifyContent: 'flex-start', padding: '10px' }}
          size="small"
          onClick={resetAttendee}
        >
          <KeyboardArrowLeft />
          <span style={{ marginLeft: '3px' }}>Back</span>
        </Button>
      </TitleBox>
      <Content>
        {attendeeDetailsLoading && <Loader width="60px" />}
        <Avatar
          src={selectedAttendee.user_avatar_cropped || null}
          alt="avatar"
          sx={{ width: '9vw', height: '9vw', margin: '30px auto 15px auto' }}
        >
          {!selectedAttendee.user_avatar_cropped
            ? selectedAttendee.user_name.charAt(0)
            : null}
        </Avatar>
        <Typography sx={centeredSx} variant="h3">
          {selectedAttendee.user_name}
        </Typography>
        <Typography sx={centeredSx}>{selectedAttendee.user_email}</Typography>
        <Typography sx={centeredSx}>
          {selectedAttendee.member_id &&
            `Member id: ${selectedAttendee.member_id}`}
        </Typography>
        <Table sx={{ width: '85%', margin: '20px auto' }}>
          <TableHead>
            <TableRow>
              <td>
                <Typography variant="caption">Overview</Typography>
              </td>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={tableRow}>
              <TableCell sx={boldCell}>Location</TableCell>
              <TableCell sx={tableCell}>
                {selectedAttendee.user_location}
              </TableCell>
            </TableRow>
            <TableRow sx={tableRow}>
              <TableCell sx={boldCell}>Device</TableCell>
              <TableCell sx={tableCell}>
                <Box display="flex">
                  {selectedAttendee.user_is_mobile ? (
                    <>
                      <PhoneIphone />
                      <span style={{ margin: 'auto' }}>
                        {selectedAttendee.user_devices.join(', ')}
                      </span>
                    </>
                  ) : (
                    'Desktop'
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow sx={tableRow}>
              <TableCell sx={boldCell}>Browser</TableCell>
              <TableCell sx={tableCell}>
                {selectedAttendee.user_browser}
              </TableCell>
            </TableRow>
            <TableRow sx={tableRow}>
              <TableCell sx={boldCell}>Mode</TableCell>
              <TableCell sx={tableCell}>
                {startCase(selectedAttendee.attendance_mode)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {selectedAttendee.custom_registration_fields.length > 0 ? (
          <Table sx={{ width: '85%', margin: '20px auto' }}>
            <TableHead>
              <TableRow>
                <td>
                  <Typography variant="caption">Custom fields</Typography>
                </td>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedAttendee.custom_registration_fields.map((el, key) => (
                <TableRow sx={tableRow} key={key}>
                  <TableCell sx={boldCell}>{hs(el.label)}</TableCell>
                  <TableCell sx={tableCell}>{handleCustomRf(el)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
        {!isEmpty(selectedAttendee.actions) && (
          <Card margin="15px 7px">
            <CardTitle>Actions</CardTitle>
            {selectedAttendee.actions.map((action, index) => (
              <Card
                margin="10px 0"
                key={`action_${index}`}
                sx={{ background: '#f2f5f7', border: 'none' }}
                style={{ position: 'relative' }}
              >
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography
                    sx={{ marginRight: '10px', color: '#99a7b9' }}
                    variant="caption"
                  >
                    {moment(action.created_at).format('HH:mm')}
                  </Typography>
                  <Box>
                    <Typography
                      sx={{ fontWeight: '700', display: 'block' }}
                      variant="caption"
                    >
                      <span>
                        {translateEventDictionary(
                          getEventKey(
                            action.event_subtype,
                            action.event_subtype_action,
                          ),
                        )}
                      </span>
                      <span style={{ color: '#59BACC' }}>
                        &nbsp;
                        {getEventTitleDetails(
                          getEventKey(
                            action.event_subtype,
                            action.event_subtype_action,
                          ),
                          action.trackable_resource,
                        )}
                      </span>
                    </Typography>
                    <Typography sx={{ display: 'block' }} variant="caption">
                      {getMoreDetails(
                        action.event_subtype,
                        action.trackable_resource,
                        action.event_subtype_action,
                      )}
                    </Typography>
                  </Box>
                </Box>
                {IconOfSubtype[action.event_subtype]
                  ? IconOfSubtype[action.event_subtype](theme)
                  : null}
              </Card>
            ))}
          </Card>
        )}
      </Content>
    </>
  );
};

Attendee.propTypes = {
  analyticsStore: PropTypes.object.isRequired,
};

export default inject('analyticsStore')(observer(Attendee));
