import React from 'react';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import { useCplusTableContext } from './Table';
import TableRow from './TableRow';

const TableCollapsableRow = ({ isLoading, id, children }) => {
  const values = useCplusTableContext();
  return (
    <TableRow>
      <TableCell colSpan={values.colCount} sx={{ p: 0 }}>
        {!isLoading && (
          <Collapse in={values.uncollapsedRows.includes(id)} timeout={300}>
            {children}
          </Collapse>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableCollapsableRow;
