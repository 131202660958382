import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const WarningModalContent = ({
  onConfirm,
  onCancel,
  msg,
  title,
  cancelText,
  confirmText,
}) => {
  return (
    <Paper elevation={0} sx={{ width: '500px', maxWidth: '95vw' }}>
      <Box
        sx={theme => ({
          bgcolor: theme.baseModal.headerBackground,
          p: '30px',
          borderBottom: theme.baseModal.border,
          borderRadius: '4px 4px 0 0',
        })}
      >
        <Typography variant="h3" sx={{ fontWeight: 'fontWeightMedium' }}>
          {title || 'Are you sure?'}
        </Typography>
      </Box>
      <Box
        sx={theme => ({
          bgcolor: theme.baseModal.bodyBackground,
          p: '30px',
          borderRadius: '0 0 4px 4px',
        })}
      >
        <Typography variant="body1">
          {msg || 'You cannot undo this action.'}
        </Typography>
        <Stack sx={{ pt: '30px' }} direction="row" gap="8px">
          <Button
            variant="outlined"
            size="large"
            color="dark"
            onClick={onCancel}
            sx={{ flex: 1 }}
          >
            {cancelText || 'CANCEL'}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="red"
            onClick={onConfirm}
            sx={{ flex: 1 }}
          >
            {confirmText || 'CONFIRM'}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default WarningModalContent;
