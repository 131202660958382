import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { Field, Form } from 'react-final-form';
import { useLocation, useHistory } from 'react-router';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Title, Description } from '@pages/signup/components/AccountCreate';
import TextInput from '@components/_form/onboarding/TextInput';
import Select from '@components/_form/onboarding/Select';
import { composeValidators, required } from '@app/formValidators';
import { COUNTRY_CODES } from '@constants';
import { setAuthenticationToken, setClient, setUid } from '@api';
import routes from '@routes';

const FullWidthForm = styled('form')`
  width: 100%;
`;

const FieldWrapper = styled(Box)`
  width: 100%;
  margin-top: 10px !important;
`;

const SectionTitle = styled(Box)`
  display: flex;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px !important;
`;

const COUNTRY_SELECT_OPTIONS = Object.keys(COUNTRY_CODES).map(key => ({
  value: key,
  label: COUNTRY_CODES[key],
}));

const BillingDetails = ({ authStore: { updateBillingDetails } }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { 'access-token': token, client, uid } = useMemo(
    () => qs.parse(search),
    [search],
  );

  useEffect(() => {
    if ((token, client, uid)) {
      setAuthenticationToken(token);
      setUid(uid);
      setClient(client);
    }
  }, []);

  const handleSubmit = data => {
    updateBillingDetails({
      data,
      onSuccess: () => {
        history.replace(`${routes.auth.signUp('lite')}?step=paymentMethod`);
      },
    });
  };

  return (
    <>
      <Title>Billing details</Title>
      <Description>
        This is the contact or department who receives the invoices or any
        billing communications.
      </Description>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit: sbmt }) => (
          <FullWidthForm onSubmit={sbmt}>
            <FieldWrapper>
              <Field
                label="First name"
                name="first_name"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Last name"
                name="last_name"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Email address"
                name="email"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Company/organisation name (optional)"
                name="company_name"
                fieldType="text"
                component={TextInput}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="VAT number (optional)"
                name="vat_number"
                fieldType="text"
                component={TextInput}
              />
            </FieldWrapper>
            <SectionTitle>Billing address</SectionTitle>
            <FieldWrapper>
              <Field
                label="Address"
                name="address"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="App/Suite (optional)"
                name="suite"
                fieldType="text"
                component={TextInput}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="City"
                name="city"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="State"
                name="state"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Zip/Postal code"
                name="postal_code"
                fieldType="text"
                component={TextInput}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Field
                label="Country/Region"
                name="country_code"
                selectOptions={COUNTRY_SELECT_OPTIONS}
                component={Select}
                validate={composeValidators(required)}
              />
            </FieldWrapper>
            <Box display="flex" justifyContent="flex-end" mt="15px">
              <Button variant="contained" type="submit" size="large">
                NEXT
              </Button>
            </Box>
          </FullWidthForm>
        )}
      />
    </>
  );
};

export default inject('authStore')(observer(BillingDetails));
