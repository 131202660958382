import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTheme, Box, Button } from '@mui/material';
import {
  AccessTime,
  OndemandVideo,
  Devices,
  GetApp,
} from '@mui/icons-material';
import Card, { CardTitle } from '@components/Card';
import * as Styled from '@pages/analytics/components/Sidebar/Overview/style';
import { Content } from '@pages/analytics/components/Sidebar/style';
import WorldMap from '@components/WorldMap';
import Badge from '@components/Badge/Badge';
import RangeCard from '@components/RangeCard';
import DoughnutCard from '@components/DoughnutCard';
import { formatDuration, getDuration } from '@utils/date';
import { percentage } from '@utils/math';
import CardMap from '@components/CardMap';
import ActiveUsersCard from '@pages/analytics/components/ActiveUsersCard';
import { LoaderCover } from '@pages/analytics/components/EventStats/style';
import { Loader } from '@components/Loader';

const Overview = ({
  analyticsStore: {
    countersCharts,
    dataKey,
    generateReport,
    pdfLoadingProgress,
    selectedDate,
    fetchingCounters,
    isLive,
    maxConcurrentAttendees,
  },
}) => {
  const theme = useTheme();
  const stats = useMemo(
    () => countersCharts[dataKey] || countersCharts[selectedDate],
    [countersCharts, dataKey, selectedDate],
  );

  const isLoadingPdf = useMemo(() => pdfLoadingProgress !== 100, [
    pdfLoadingProgress,
  ]);

  const browserSplit = useMemo(
    () =>
      stats?.browser_split.length
        ? stats.browser_split.data.slice().sort((a, b) => b.value - a.value)
        : [],
    [stats?.browser_split],
  );

  if (fetchingCounters && !isLive) {
    return (
      <LoaderCover>
        <Loader width="100px" />
      </LoaderCover>
    );
  }

  if (!stats) {
    return null;
  }

  const markers = stats.location.data.reduce((d, curr) => {
    d.push({
      ...curr,
      name: `${curr.name}, ${curr.count}`,
    });
    return d;
  }, []);

  return (
    <>
      <Content>
        <Card margin="8px 0">
          <ActiveUsersCard />
        </Card>
        <Card margin="8px 0">
          <Box display="flex" justifyContent="space-between">
            <Styled.Header>Max concurrent user </Styled.Header>
          </Box>
          <Styled.ViewersAmount>{maxConcurrentAttendees}</Styled.ViewersAmount>
        </Card>
        <Card margin="8px 0">
          <Box display="flex" justifyContent="space-between">
            <Styled.Header>Total event attendance</Styled.Header>
          </Box>
          <Styled.ViewersAmount>
            {stats.users.value.toLocaleString('en')}
          </Styled.ViewersAmount>
          {!!percentage(stats.users) && (
            <Styled.AudiencePercentage>
              <Badge
                style={{
                  background: theme.colors.successBackground,
                  color: theme.colors.success,
                }}
              >
                {percentage(stats.users) || '0'} %
              </Badge>
              <Styled.AudiencePercentageText>
                of total registered for the event
              </Styled.AudiencePercentageText>
            </Styled.AudiencePercentage>
          )}
        </Card>

        <Card margin="8px 0">
          <Styled.Header>Location</Styled.Header>
          <CardMap markers={markers} />
          <Styled.MapContainer>
            <WorldMap markers={markers} markerSize={9} />
          </Styled.MapContainer>
        </Card>

        <RangeCard
          header={formatDuration(stats.avg_view_time.value)}
          percentage={percentage(stats.avg_view_time)}
          description="Average audience view time"
          icon={AccessTime}
          type="avg_view_time"
        />
        <RangeCard
          header={getDuration(stats.hours_streamed.value).hours()}
          description="Total aggregate hours streamed"
          icon={OndemandVideo}
          type="hours_streamed"
        />
        <RangeCard
          header={`${percentage(stats.audience_on_desktop)}%`}
          percentage={percentage(stats.audience_on_desktop)}
          description="Audience on desktop"
          icon={Devices}
          type="audience_on_desktop"
        />
        {browserSplit.length > 0 && (
          <DoughnutCard
            name={<Styled.Header>Browser split</Styled.Header>}
            tableHeader={{
              action: 'Browser',
              counter: 'Count',
              percent: 'Share',
            }}
            data={browserSplit}
          />
        )}
      </Content>
      <Styled.ButtonBox>
        <CardTitle color="white">Download data</CardTitle>
        <Button
          endIcon={
            <Styled.TranslateAnimation animate={isLoadingPdf}>
              <GetApp />
            </Styled.TranslateAnimation>
          }
          onClick={isLoadingPdf ? undefined : generateReport}
          variant="contained"
          fullWidth
          sx={{ fontWeight: 'fontWeightBold', justifyContent: 'space-between' }}
        >
          <span>
            {isLoadingPdf ? `${pdfLoadingProgress}%` : 'Export PDF overview'}
          </span>
        </Button>
      </Styled.ButtonBox>
    </>
  );
};

Overview.propTypes = {
  analyticsStore: PropTypes.object.isRequired,
};

export default inject('analyticsStore')(observer(Overview));
