import { serialize } from 'object-to-formdata';

export const manipulateAssignmentsArray = (values, initialValues) => {
  const reconstructedGroups = (values.group_item_assignments || []).map(
    grp => ({
      agenda_item_id: grp.agenda_item_id,
      group_id: grp.value,
      id: (initialValues.assignments || []).find(
        asg =>
          asg.agenda_item_id === grp.agenda_item_id &&
          asg.group_id === grp.value,
      )?.id,
    }),
  );

  const reconstructedAis = (values.agenda_item_assignments || [])
    .filter(
      ai => !reconstructedGroups.find(rg => rg.agenda_item_id === ai.value),
    )
    .map(ai => ({
      agenda_item_id: ai.value,
      group_id: null,
      id: (initialValues.assignments || []).find(
        asg => asg.agenda_item_id === ai.value && !asg.group_id,
      )?.id,
    }));

  const combinedReconstructed = [...reconstructedAis, ...reconstructedGroups];

  const removedAssignments = (initialValues.assignments || []).filter(
    beginReg =>
      !combinedReconstructed.find(newAsg => newAsg.id === beginReg.id),
  );

  const removedAssignmentsMapped = removedAssignments.map(asg => ({
    id: asg.id,
    _destroy: 1,
  }));

  return [...removedAssignmentsMapped, ...combinedReconstructed];
};

export const assignmentsSetupDefaultFormValues = ({
  events,
  item,
  agendas,
  groups,
}) => {
  return {
    event_id: events?.find(opt => opt.value === item.event_id),
    agenda_item_assignments:
      agendas?.filter(ai => item.agenda_item_assignments?.includes(ai.value)) ||
      [],
    group_item_assignments:
      groups?.filter(gr => item.group_item_assignments?.includes(gr.value)) ||
      [],
  };
};

export const assignmentsPrepareValues = ({ values, initialValues }) => {
  return {
    assignments_attributes: manipulateAssignmentsArray(values, initialValues),
    event_id: values.event_id.value,
    group_item_assignments: undefined,
    agenda_item_assignments: undefined,
    assignments: undefined,
  };
};

export const validateAssignmentsForm = values => {
  const errors = {};
  if (!values.event_id) errors.event_id = 'This field is required';

  return errors;
};

export const serializeAssignments = (form, assignments_attributes) =>
  serialize(
    { assignments_attributes },
    { indices: true, allowEmptyArrays: true },
    form,
  );
