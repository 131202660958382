import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas:
    'header header'
    'side-menu main';
`;

export const Main = styled('main', {
  shouldForwardProp: prop => !['onlyMainSection', 'autoHeight'].includes(prop),
})`
  grid-area: main;
  position: relative;
  ${({ autoHeight, onlyMainSection, theme }) =>
    autoHeight
      ? 'height: auto;'
      : `
  height: calc(
    100vh -
      ${onlyMainSection ? 0 : theme.header.height}px
  );`}
  overflow-y: auto;
`;
