import React from 'react';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';

const TableActionsCell = ({ children, ...props }) => {
  return (
    <TableCell {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap="10px"
        onClick={e => e.stopPropagation()}
      >
        {children}
      </Stack>
    </TableCell>
  );
};

export default TableActionsCell;
