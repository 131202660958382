import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  groups: null,
  agendaItems: [],
  isDeletingId: null,
};

export class GroupsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getAgendaItems = async eventId => {
    try {
      this.agendaItems = await ResourcesStore.getAgendaItemsSelectOptions({
        agendaType: 'breakout_room',
        eventId,
      });
    } catch (e) {
      console.debug('[getBreakoutsSelectOptions] failed', e);
    }
  };

  @action getGroups = async ({
    page,
    search,
    agenda_item_id,
    sort,
    silent,
    event_id,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getGroups({
        page,
        search,
        agenda_item_id,
        sort,
        event_id,
      });
      this.groups = {
        ...res,
        page: res.page_number.toString(),
        sort,
        search,
        agenda_item_id,
        event_id,
      };
    } catch (err) {
      console.debug('[getGroups] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'agenda_item_id', 'event_id'].every(
      param => params[param] === this.groups?.[param],
    );
  };

  @action deleteGroup = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteGroupItem({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteGroupItem] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getEventSelectOptions = async () => {
    const resp = await ResourcesStore.getEventSelectOptions();
    this.eventsSelectOptions = resp.results.sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
      return 0;
    });
  };
}

export default new GroupsPageStore();
