import { action, observable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

export class GdprStore {
  @observable isLoading = false;

  @observable isUsersLoading = false;

  @observable isProcedureWorking = false;

  @observable eventSelectOptions = [];

  @observable users = null;

  @action getEventSelectOptions = async () => {
    try {
      this.isLoading = true;

      const { results } = await ResourcesStore.getEventSelectOptions();
      this.eventSelectOptions = results;
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action gdprProcedudure = async ({ eventIds }) => {
    try {
      this.isProcedureWorking = true;

      await ResourcesStore.gdprProcedudure(eventIds);
    } catch (err) {
      console.debug('[gdprProcedudure] failed', err);
      throw err;
    } finally {
      this.isProcedureWorking = false;
    }
  };

  @action getUsersToView = async ({ events }) => {
    try {
      this.isUsersLoading = true;

      const { users } = await ResourcesStore.getUsersToView({ events });
      this.users = users;
    } catch (err) {
      console.debug('[getUsersToView] failed', err);
    } finally {
      this.isUsersLoading = false;
    }
  };

  @action clearEvent = () => {
    this.eventSelectOptions = [];
  };

  @action clearUsers = () => {
    this.users = null;
  };
}

export default new GdprStore();
