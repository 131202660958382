import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Button, Grid, Typography } from '@mui/material';
import qs from 'query-string';
import routes from '@routes';
import {
  PastEventCard,
  UpcomingEventCard,
} from '@pages/dashboard/components/EventCard';

export const STATUS = {
  PAST: 'past',
  UPCOMING: 'upcoming',
  LIVE: 'live',
};

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
  margin-bottom: 15px;
`;

function EventsBox({ eventsList, title, status }) {
  return (
    <Box>
      <InfoContainer>
        <Title>{title}</Title>
        <Button
          component={Link}
          to={`${routes.main.events}?${qs.stringify({ tab: status })}`}
        >
          View all
        </Button>
      </InfoContainer>
      <Grid container spacing={2}>
        {(eventsList || []).map((event, idx) => {
          return (
            <Grid item md={4} sm={6} xs={12} key={`event-card-${event.name}`}>
              {status === STATUS.PAST ? (
                <PastEventCard key={idx} event={event} />
              ) : (
                <UpcomingEventCard key={idx} event={event} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default EventsBox;
