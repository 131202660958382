import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';

const NavigationSection = ({ toggleSection, opened, section }) => {
  return (
    <ListItemButton
      onClick={() => toggleSection(section.title)}
      sx={{
        '& .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      }}
    >
      <ListItemText
        sx={theme => ({
          color: theme.sidebar.sectionColor,
          fontWeight: 'fontWeightBold',
          my: 0,
        })}
        primary={section.title}
        disableTypography
      />
      {opened ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );
};

export default NavigationSection;
