export default ({ theme }) => ({
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary[100],
      },
    },
    variants: [
      {
        props: {
          variant: 'rectangular',
        },
        style: {
          borderRadius: '5px',
        },
      },
    ],
  },
});
