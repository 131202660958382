import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, FormHelperText } from '@mui/material';
import FreeSoloInput from '@components/formFields/FreeSoloInput';

const EventSlugs = ({
  baseUrl,
  label,
  required,
  placeholderBelowInput,
  ...props
}) => {
  return (
    <Box width="100%">
      <FormLabel required={required}>{label}</FormLabel>
      <Box display="flex" alignItems="flex-start" width="100%">
        <Box width="fit-content">
          <Box fontSize="12px" mr="5px" mt="5px">
            {baseUrl}/
          </Box>
        </Box>
        <Box flex="1">
          <FreeSoloInput {...props} />
          <FormHelperText>{placeholderBelowInput}</FormHelperText>
        </Box>
      </Box>
    </Box>
  );
};

EventSlugs.defaultProps = {
  required: false,
};

EventSlugs.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default EventSlugs;
