import { API, APIRoutes, CancelToken } from '@app/api';

class UploadAdapter {
  constructor(props) {
    this.loader = props;
    this.cancelTokenSource = null;
  }

  async upload() {
    const file = await this.loader.file;

    const formData = new FormData();
    formData.append('data', file);

    this.cancelTokenSource = CancelToken.source();

    const { data } = await API.post(APIRoutes.UPLOAD_FILE, formData, {
      cancelToken: this.cancelTokenSource.token,
    });

    return {
      default: data.asset.url_content,
    };
  }

  abort() {
    this.cancelTokenSource.cancel?.();
  }
}

export function UploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new UploadAdapter(loader);
  };
}
