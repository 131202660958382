import React, { useReducer, memo, useEffect } from 'react';
import {
  Devices,
  FilterList,
  HighlightOff,
  LocationOn,
  BrightnessAuto,
  Sort,
} from '@mui/icons-material';
import {
  FiltersWrapper,
  SelectWrapper,
} from '@pages/analytics/components/Sidebar/Audience/style';
import { COUNTRY_CODES } from '@constants';
import { Button, Chip, MenuItem, Select, Tooltip, Box } from '@mui/material';

export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export const ATTENDANCE_MODE = {
  VIRTUAL: 'virtual',
  HYBRID: 'hybrid',
};

const ORDERS = {
  ALPHABETICAL: 'Alphabetical',
  ENGAGEMENT: 'Engagement',
};
const DEFAULT_FILTERS = {
  LOCATIONS: { name: 'Location', value: 'country_list' },
  DEVICES: { name: 'Devices', value: 'devices' },
  SORT: { name: 'Sort', value: 'sort' },
  ATTENDED: { name: 'Mode', value: 'attended' },
};

const FILTER_TYPES = {
  FILTER: 'FILTER',
  SORT: 'SORT',
};

const Icon = ({ filter }) => {
  switch (filter) {
    case DEFAULT_FILTERS.LOCATIONS.value:
      return <LocationOn />;
    case DEFAULT_FILTERS.DEVICES.value:
      return <Devices />;
    case DEFAULT_FILTERS.SORT.value:
      return <Sort />;
    case DEFAULT_FILTERS.ATTENDED.value:
      return <BrightnessAuto />;
    default:
      return <FilterList />;
  }
};

const valuesToFilters = (values = [], name) => {
  if (name === 'country') {
    return values.map(e => ({
      name: COUNTRY_CODES[e] || e,
      value: e,
    }));
  }

  return values.map(e => ({ name: e, value: e }));
};

const mapAdditionalFilters = (filters = {}) =>
  Object.keys(filters)?.reduce(
    (acc, name) => ({
      ...acc,
      [name]: {
        values: valuesToFilters(filters[name]?.values, name),
        expanded: false,
        selected: [],
        name: filters[name]?.name,
        type: FILTER_TYPES.FILTER,
        action(users = []) {
          return users.filter(({ filtered_fields }) =>
            this.selected.includes(filtered_fields[name]),
          );
        },
      },
    }),
    {},
  );

const mapCountryFilters = (filters = []) => ({
  values: filters?.map(code => ({
    name: COUNTRY_CODES[code],
    value: code,
  })),
  expanded: false,
  selected: [],
  name: DEFAULT_FILTERS.LOCATIONS.name,
  type: FILTER_TYPES.FILTER,
  action(users = []) {
    return users.filter(({ user_country }) =>
      this.selected.includes(user_country),
    );
  },
});

const mapSelects = (obj = {}) => {
  return Object.values(obj).map(item => (
    <MenuItem key={item.value} value={item.value}>
      {item.name || 'Not set'}
    </MenuItem>
  ));
};

const filterReducer = (state, { field, selected, expanded }) => ({
  ...state,
  [field]: {
    ...state[field],
    selected: selected || state[field].selected,
    expanded: expanded === undefined ? state[field].expanded : expanded,
  },
});

const defaultValues = {
  [DEFAULT_FILTERS.DEVICES.value]: {
    values: valuesToFilters(Object.values(DEVICES)),
    selected: [],
    expanded: false,
    name: DEFAULT_FILTERS.DEVICES.name,
    type: FILTER_TYPES.FILTER,
    action(users = []) {
      if (this.selected.length === 2) return users;
      return users.filter(({ user_is_mobile }) => {
        if (this.selected.includes(DEVICES.MOBILE)) return user_is_mobile;
        return !user_is_mobile;
      });
    },
  },
  [DEFAULT_FILTERS.ATTENDED.value]: {
    values: valuesToFilters(Object.values(ATTENDANCE_MODE)),
    selected: [],
    expanded: false,
    name: DEFAULT_FILTERS.ATTENDED.name,
    type: FILTER_TYPES.FILTER,
    action(users = []) {
      if (this.selected.length === 2) return users;
      return users.filter(({ user_attended_mode }) => {
        return this.selected.includes(user_attended_mode);
      });
    },
  },
  [DEFAULT_FILTERS.SORT.value]: {
    values: valuesToFilters(Object.values(ORDERS)),
    selected: ORDERS.ENGAGEMENT,
    expanded: false,
    name: DEFAULT_FILTERS.SORT.name,
    type: FILTER_TYPES.SORT,
    action(users) {
      let sorted;
      if (this.selected === ORDERS.ENGAGEMENT) {
        sorted = users
          .slice()
          .sort((prev, next) => next.actions_count - prev.actions_count);
      }
      if (this.selected === ORDERS.ALPHABETICAL) {
        sorted = users.slice().sort((prev, next) => {
          const lowerA = prev.user_name.toLowerCase();
          const lowerB = next.user_name.toLowerCase();

          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        });
      }
      return sorted;
    },
  },
};

const initialState = attendees => ({
  ...defaultValues,
  country_list: mapCountryFilters(attendees?.country_list),
  ...mapAdditionalFilters(attendees?.filters),
});

const Filters = ({ users, setUsers, attendees }) => {
  const [state, dispatch] = useReducer(filterReducer, initialState(attendees));

  useEffect(() => {
    const filters = Object.values(state).filter(
      filter => filter?.selected?.length > 0,
    );
    const filtered = filters.reduce((u, filter) => filter.action(u), users);
    setUsers(filtered);
  }, [state, users, setUsers]);

  return (
    <>
      <FiltersWrapper>
        {Object.keys(state).map(field => (
          <SelectWrapper key={field}>
            <Tooltip title={state[field].name} placement="left">
              <Button
                variant="outlined"
                color="lightDark"
                sx={{ width: 'fit-content' }}
                onClick={() => dispatch({ field, expanded: true })}
                endIcon={<Icon filter={field} />}
              >
                {Object.values(DEFAULT_FILTERS).find(f => f.value === field)
                  ?.name || field}
              </Button>
            </Tooltip>
            <Select
              labelId={`${field}-label`}
              value={state[field].selected}
              open={state[field].expanded}
              onClick={() =>
                dispatch({ field, expanded: !state[field].expanded })
              }
              onChange={({ target }) => {
                dispatch({
                  field,
                  selected: target.value,
                  expanded: false,
                });
              }}
              multiple={state[field].type === FILTER_TYPES.FILTER}
              renderValue={() => null}
              MenuProps={{ sx: { marginTop: '-25px' } }}
              sx={{ visibility: 'hidden', height: 0, width: '100%' }}
            >
              {state[field]?.values ? mapSelects(state[field]?.values) : null}
            </Select>
          </SelectWrapper>
        ))}
      </FiltersWrapper>
      <Box m="15px 0 0" width="100%">
        {Object.keys(state).map(
          field =>
            state[field].type === FILTER_TYPES.FILTER &&
            state[field].selected.map(val => (
              <Chip
                key={`${field}-${val}`}
                label={
                  state[field].values.find(e => e.value === val).name ||
                  'Not set'
                }
                sx={{ m: 2 }}
                deleteIcon={<HighlightOff />}
                onDelete={() =>
                  dispatch({
                    field,
                    selected: state[field].selected.filter(f => f !== val),
                  })
                }
              />
            )),
        )}
      </Box>
    </>
  );
};

export default memo(Filters);
