import React from 'react';
import { Field, Form } from 'react-final-form';
import SearchInput from './SearchInput';

const SearchBar = ({ onSubmit, value, disabled }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ search: value }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="search"
              disabled={disabled}
              component={SearchInput}
              handleSubmit={handleSubmit}
            />
          </form>
        );
      }}
    />
  );
};

export default SearchBar;
