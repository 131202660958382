export default () => ({
  MuiSelect: {
    styleOverrides: {
      select: {
        '&.MuiInput-input': {
          '&:focus': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
  },
});
