import { action, extendObservable } from 'mobx';
import profileStore from '@stores/profileStore';
import ResourcesStore from '@stores/resourcesStore';
import { FEATURE_SET_DICTIONARY } from '@constants';
import { CancelToken } from '@api';

const initialState = {
  anySubscriptionPlans: true,
  contextEvent: null,
  fieldDisableStatus: [],
  noSubPlan: false,
  selectedManagedOrg: undefined,
  selectedOrgFeatures: [],
  selectedOrgPlanAvailable: true,
};

export class SubscriptionStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @action setContextEvent = async (evObj, evId) => {
    if (evObj) {
      this.contextEvent = evObj;
    }

    if (evId) {
      try {
        this.__cancelToken = CancelToken.source();
        this.contextEvent = await ResourcesStore.getEvent({
          id: evId,
          cancelToken: this.__cancelToken.token,
        });
      } catch (err) {
        throw new Error(`(unable to set event context) ${err}`);
      }
    }
    this.handleSubscriptions();
  };

  @action resetContextEvent = () => {
    this.__cancelToken?.cancel();
    this.contextEvent = null;
    this.handleSubscriptions();
  };

  @action setSelectedOrganization = e => {
    this.selectedManagedOrg = e.target?.value;
    this.handleOrgFeatureSets();
  };

  @action setProfileOrg = orgId => {
    this.selectedManagedOrg = orgId;
    this.handleOrgFeatureSets();
  };

  @action handleOrgFeatureSets = () => {
    const { profile } = profileStore || {};

    const { managed_organizations_details: managedOrgDetails } = profile || {};

    if (this.selectedManagedOrg) {
      const planDetails = managedOrgDetails?.find(
        org => org.id === this.selectedManagedOrg,
      )?.subscription_plan_details;

      if (!planDetails) {
        this.selectedOrgPlanAvailable = false;
        this.selectedOrgFeatures = [];
        return;
      }

      let availableFeatures = [];

      Object.entries(FEATURE_SET_DICTIONARY).forEach(entry => {
        const key = entry[0];
        const val = entry[1];

        if (planDetails?.features_keys.includes(key)) {
          availableFeatures = [...availableFeatures, ...val];
        }
      });

      this.selectedOrgPlanAvailable = true;
      this.selectedOrgFeatures = availableFeatures;
    }
  };

  @action handleSubscriptions = () => {
    const { profile } = profileStore || {};

    const evFeatureSets = this.contextEvent?.subscription_plan
      ? this.contextEvent?.features_keys
      : null;
    const orgId = this.contextEvent?.organization_id;

    const { managed_organizations_details: managedOrgDetails } = profile || {};

    // org sub plan of current event
    const eventOrgSubPlanDetails = managedOrgDetails?.find(
      org => org.id === orgId,
    )?.subscription_plan_details;

    if (evFeatureSets || eventOrgSubPlanDetails) {
      this.anySubscriptionPlans = true;
      const mainFS =
        evFeatureSets || eventOrgSubPlanDetails?.features_keys || [];

      let availableFeatures = [];

      Object.entries(FEATURE_SET_DICTIONARY).forEach(entry => {
        const key = entry[0];
        const val = entry[1];

        if (mainFS.includes(key)) {
          availableFeatures = [...availableFeatures, ...val];
        }
      });

      // if event or org sub plan
      this.noSubPlan = false;
      this.fieldDisableStatus = availableFeatures;
      return;
    }

    // if no sub plans
    this.noSubPlan = true;
    this.fieldDisableStatus = [];
  };

  // dont put "@action". With action and usage in render function, method dependencies won't be tracked
  resolveDisableField = fieldName => {
    const forceShow = this.noSubPlan || profileStore.isSuperAdmin;
    if (Array.isArray(fieldName)) {
      return (
        !forceShow &&
        !this.fieldDisableStatus.some(r => fieldName.indexOf(r) >= 0)
      );
    }

    return !forceShow && !this.fieldDisableStatus.includes(fieldName);
  };

  // dont put "@action". With action and usage in render function, method dependencies won't be tracked
  resolveOrgDisableField = fieldName => {
    const forceShow =
      this.noSubPlan ||
      !this.selectedOrgPlanAvailable ||
      profileStore.isSuperAdmin ||
      !this.selectedManagedOrg;

    if (Array.isArray(fieldName)) {
      return (
        !forceShow &&
        !this.selectedOrgFeatures.some(r => fieldName.indexOf(r) >= 0)
      );
    }
    return !forceShow && !this.selectedOrgFeatures.includes(fieldName);
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new SubscriptionStore();
