import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@api';

const initialState = {
  isLoading: false,
  user: null,
  initialLoading: true,
  organizations: [],
  userManagedOrgs: [],
  defaultFormValues: {},
  formType: null,
};

export class UserEditPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateUser(...props);
    }
    if (this.isCreate) {
      return this.createUser(...props);
    }

    return null;
  };

  @action getUser = async ({ id }) => {
    try {
      this.user = await ResourcesStore.getUser({ id });
    } catch (err) {
      console.debug('[getUser] failed', err);
      throw err;
    }
  };

  @action updateUser = async ({ id, values, onSuccess }) => {
    try {
      this.isLoading = true;
      const res = await ResourcesStore.updateUser({
        id,
        payload: this.prepareValuesToSubmit(values),
      });

      this.user = res;
      await onSuccess?.(res);
      this.setupDefaultFormValues();
      return null;
    } catch (err) {
      console.debug('updateUser] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createUser = async ({ id, values, onSuccess }) => {
    try {
      this.isLoading = true;
      const res = await ResourcesStore.createUser({
        id,
        payload: this.prepareValuesToSubmit(values),
      });

      await onSuccess?.(res);
      return null;
    } catch (err) {
      console.debug('createUser] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteUser = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteUser({ id });

      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteUser] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    try {
      this.initialLoading = true;
      const promises = [this.getOrganizations()];

      if (id) {
        promises.push(this.getUser({ id }));
      }

      await Promise.all(promises);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed');
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.userManagedOrgs = (this.user?.managed_organizations_details || []).map(
      org => {
        return { label: org.name, value: org.id };
      },
    );

    if (this.isEdit) {
      this.defaultFormValues = {
        ...this.user,
        managed_organizations_ids: this.userManagedOrgs,
      };
    } else {
      this.defaultFormValues = {};
    }
  };

  @action getOrganizations = async () => {
    try {
      const { results } = await ResourcesStore.getOrganizations({
        page: '1',
        per: '-1',
      });

      this.organizations = results
        .map(org => ({
          label: org.name,
          value: org,
        }))
        .sort((a, b) => {
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
          return 0;
        });
    } catch (err) {
      console.debug('[getOrganizations] failed', err);
      throw err;
    }
  };

  @action prepareValuesToSubmit = values => {
    const {
      managed_organizations_ids: managedOrganizations,
      ...allValues
    } = values;

    return {
      ...allValues,
      managed_organization_ids: (managedOrganizations || []).map(
        org => org.value,
      ),
    };
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new UserEditPageStore();
