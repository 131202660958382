import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const CplusToastContext = createContext(null);

export const CplusToastProvider = ({ children, duration, closeToast }) => {
  const [running, setRunning] = useState(true);
  const timer = useRef({
    timeout: null,
    startTime: Date.now(),
    durationLeft: duration,
  });

  useEffect(() => {
    timer.current.timeout = setTimeout(closeToast, timer.current.durationLeft);
    return () => clearTimeout(timer.current.timeout);
  }, []);

  const pause = useCallback(() => {
    setRunning(false);
    clearTimeout(timer.current.timeout);
    timer.current.durationLeft -= Date.now() - timer.current.startTime;
  }, []);

  const play = useCallback(() => {
    setRunning(true);
    timer.current.startTime = Date.now();
    timer.current.timeout = setTimeout(closeToast, timer.current.durationLeft);
  }, []);

  const value = useMemo(
    () => ({
      running,
      pause,
      play,
      duration,
      closeToast,
    }),
    [running, pause, play],
  );

  return (
    <CplusToastContext.Provider value={value}>
      {children}
    </CplusToastContext.Provider>
  );
};

export const useCplusToast = () => {
  const context = useContext(CplusToastContext);

  if (!context) throw new Error('No cplustoastcontext');

  return context;
};
