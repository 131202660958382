import _sum from 'lodash/sum';

export const percentageFromArrayValues = values => {
  const sum = _sum(values);
  return values.reduce((prev, next) => [...prev, (next / sum) * 100], []);
};

export const percentage = obj => {
  if (!obj.total) return 0;

  const { value, total } = obj;
  return Math.round((value / total) * 100);
};
