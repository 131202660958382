import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  products: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
  isDeletingId: null,
};

export class ProductsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getProducts = async ({
    page,
    search,
    silent,
    sort,
    event_id,
    agenda_item_id,
  }) => {
    if (!silent) this.isLoading += 1;
    const res = await ResourcesStore.getProducts({
      page,
      search,
      sort,
      ...(!agenda_item_id && { event_id }),
      agenda_item_id,
    });
    this.products = {
      ...res,
      sort,
      search,
      event_id,
      agenda_item_id,
    };

    if (!silent) this.isLoading -= 1;
  };

  @action matchPageSearchQuery = (params = {}) => {
    if (
      params.search === this.products?.search &&
      parseInt(params.page, 10) === this.products?.page_number &&
      params.sort === this.products?.sort &&
      params.event_id === this.products?.event_id &&
      params.agenda_item_id === this.products?.agenda_item_id
    )
      return true;

    return false;
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    this.isLoading += 1;
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
    this.isLoading -= 1;
  };

  @action deleteProduct = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteProduct({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[deleteProduct] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new ProductsPageStore();
