import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { TopBar } from '@components/TopBar';
import { SideBar } from '@components/SideBar';
import routes from '@routes';
import { Main, Grid } from './style';

const onlyMainSectionRoutes = [
  routes.auth.signIn,
  routes.auth.createPassword,
  routes.auth.expired,
  routes.notFound,
  routes.auth.resetPassword,
  routes.auth.signUp(),
  routes.main.analytics_report(),
];

const no100vh = [routes.main.analytics_report()];

const Page = ({ children }) => {
  const onlyMainSection = useRouteMatch({
    path: onlyMainSectionRoutes,
    exact: false,
  });
  const no100vhSection = useRouteMatch({
    path: no100vh,
    exact: false,
  });

  return (
    <Grid>
      {!onlyMainSection && <TopBar />}
      {!onlyMainSection && <SideBar />}
      <Main
        autoHeight={no100vhSection}
        id="main-container"
        onlyMainSection={onlyMainSection}
      >
        {children}
      </Main>
    </Grid>
  );
};

export default observer(Page);
