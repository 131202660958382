import { Loader } from '@components/Loader';
import { FieldGroup } from '@components/_form/FieldGroup';
import Fade from '@mui/material/Fade';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import { Box, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { CloningLayer } from '@components/CloningLayer';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Assignments,
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
  validateAssignmentsForm,
} from '@components/Assignments';

const defaultFormValues = {
  ...assignmentsSetupDefaultFormValues({}),
};

const CloningExploreItemOptionsModal = ({
  modalStore: { closeModal },
  exploreItemStore: { cloneRunning },
  onClone,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          borderRadius: '4px',
          background: '#fff',
          padding: '40px',
          width: '650px',
          position: 'relative',
        }}
      >
        <Fade in={Boolean(cloneRunning)} timeout={150}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <CloningLayer />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              {cloneRunning === 'running' ? (
                <>
                  <Loader width="50px" />
                  <h2>Clone in progress...</h2>
                </>
              ) : (
                <>
                  <CheckIcon
                    sx={theme => ({
                      fontSize: '96px',
                      marginBottom: '35px',
                      fill: theme.colors.success,
                    })}
                  />
                  <h2>Clone is finished you will be redirected shortly </h2>
                </>
              )}
            </Box>
          </Box>
        </Fade>
        <h1>Cloning explore item options</h1>
        <Box display="flex" flexDirection="column" mt="20px">
          <Form
            initialValues={defaultFormValues}
            onSubmit={values =>
              onClone({
                ...values,
                ...assignmentsPrepareValues({
                  values,
                  initialValues: defaultFormValues,
                }),
              })
            }
            validate={values => {
              const errors = {
                ...validateAssignmentsForm(values),
              };

              return errors;
            }}
            mutators={arrayMutators}
          >
            {({ handleSubmit, submitError }) => {
              return (
                <>
                  <FieldGroup noPadding>
                    <Assignments
                      types={{
                        [ASSIGNMENT_TYPE.EVENT]: true,
                        [ASSIGNMENT_TYPE.AGENDA]: true,
                      }}
                      requiredEvent
                      expanded
                    />
                  </FieldGroup>
                  {submitError && (
                    <>
                      <Box sx={{ color: 'red.main', marginTop: '20px' }}>
                        {submitError.msg}.
                      </Box>
                      <Box sx={{ color: 'red.main' }}>
                        Copy an error log here:{' '}
                        <Button
                          color="red"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(submitError.errors);
                          }}
                        >
                          COPY
                        </Button>
                      </Box>
                    </>
                  )}
                  <Stack direction="row" gap="15px" mt="20px">
                    <Button
                      onClick={closeModal}
                      variant="outlined"
                      color="dark"
                      sx={{ flex: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ flex: 1 }}
                    >
                      Clone
                    </Button>
                  </Stack>
                </>
              );
            }}
          </Form>
        </Box>
      </Box>
    </Box>
  );
};

CloningExploreItemOptionsModal.propTypes = {
  onClone: PropTypes.func.isRequired,
};

export default inject(
  'modalStore',
  'exploreItemStore',
)(observer(CloningExploreItemOptionsModal));
