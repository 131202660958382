import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

const TableHeadCell = ({
  children,
  onSort,
  direction,
  active,
  sortingParam,
  sortingKey,
  ...props
}) => {
  const isActive =
    sortingKey && sortingParam?.match(new RegExp(`^${sortingKey} (ASC|DESC)$`));

  return (
    <TableCell
      sx={{ fontWeight: 'fontWeightBold', lineHeight: 1.1 }}
      component="th"
      sortDirection={active ? direction : false}
      {...props}
    >
      {sortingKey ? (
        <TableSortLabel
          active={Boolean(isActive)}
          direction={isActive ? isActive[1].toLowerCase() : undefined}
          onClick={() => onSort(sortingKey)}
        >
          {children}
        </TableSortLabel>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default TableHeadCell;
