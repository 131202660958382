import { action, extendObservable } from 'mobx';
import { prepareSelectOptions } from '@utils/formUtils';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  multiMarketGroups: null,
  organizations: [],
  isDeletingId: null,
};

export class MultiMarketGroupsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getGroups = async ({
    organization_id,
    page,
    silent,
    sort,
    search,
  }) => {
    if (!silent) this.isLoading += 1;
    try {
      const res = await ResourcesStore.getMultiMarketGroups({
        organization_id,
        page,
        sort,
      });

      this.multiMarketGroups = {
        ...res,
        organization_id,
        sort,
        page,
        search,
      };
    } catch (err) {
      console.debug('[getGroups] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'organization_id'].every(
      p => this.multiMarketGroups?.[p] === params[p],
    );
  };

  @action deleteGroup = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteMultiMarketEventsGroup({ id });
      await onSuccess();
    } catch (err) {
      console.debug('[deleteGroup] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getOrganizationsSelectOptions = async () => {
    try {
      const { results: orgs } = await ResourcesStore.getOrganizations({
        per: '-1',
        deleted: false,
      });

      this.organizations = prepareSelectOptions(
        orgs,
        el => el.name,
        el => el.id,
      );
    } catch (err) {
      console.debug('[getOrganizationsSelectOptions] failed', err);
    }
  };
}

export default new MultiMarketGroupsPageStore();
