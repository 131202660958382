import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import Fade from '@mui/material/Fade';

const Layer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
  background: ${({ theme }) => `${theme.palette.materialBlue}7f`};
`;

const Wrapper = styled(Box, {
  shouldForwardProp: prop => !['zIndexStack'].includes(prop),
})`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndexStack }) => 1200 + zIndexStack};
`;

const ContentWrapper = styled(Box)`
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

const Modal = ({
  modalContent,
  opened,
  removeModal,
  closeModal,
  zIndexStack,
  modalKey,
  disableBackgroundClose,
}) => {
  const handleEnd = () => {
    if (!opened) {
      removeModal({ key: modalKey });
    }
  };

  return (
    <Fade in={opened} timeout={300} onExited={handleEnd}>
      <Wrapper zIndexStack={zIndexStack}>
        <Layer onClick={disableBackgroundClose ? undefined : closeModal} />
        <ContentWrapper>{modalContent}</ContentWrapper>
      </Wrapper>
    </Fade>
  );
};

export default observer(Modal);
