import { inject, observer } from 'mobx-react';
import Modal from '@components/modals/Modal';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Box } from '@mui/material';

const ModalsController = ({
  modalStore: { modalsArray, removeContent, closeModal },
}) => {
  const elementRef = useRef(document.querySelector('#cplusModals'));

  return ReactDOM.createPortal(
    <Box>
      {modalsArray.map((modal, i) => (
        <Modal
          key={modal.key}
          modalKey={modal.key}
          modalContent={modal.content}
          opened={modal.opened}
          removeModal={removeContent}
          closeModal={closeModal}
          disableBackgroundClose={modal.disableBackgroundClose}
          zIndexStack={modalsArray.length - i}
        />
      ))}
    </Box>,
    elementRef.current,
  );
};

export default inject('modalStore')(observer(ModalsController));
