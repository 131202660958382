import { styled } from '@mui/material/styles';

const Input = styled('input')`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: block;
  width: 100%;
  appearance: none;
`;

export default Input;
