import React from 'react';
import moment from 'moment';
import { action, extendObservable, computed, toJS } from 'mobx';
import urlSlug from 'url-slug';
import isNull from 'lodash/isNull';
import ResourcesStore from '@stores/resourcesStore';
import modalStore from '@stores/modalStore';
import subscriptionStore from '@stores/subscriptionStore';
import { WarningModalContent } from '@app/components/WarningModalContent';
import { CancelToken } from '@app/api';
import routerStore from '@stores/routerStore';
import routes from '@routes';
import {
  AGENDA_TYPES,
  FORM_TYPES,
  PLAYER_DESTINY_TYPES,
  PLAYER_TYPES,
} from '@constants';

const initialState = {
  formType: null,
  isAgendaItemLoading: true,
  isThemeLoading: true,
  isProductsLoading: false,
  isPlayerLoading: true,
  externalDefaultState: null,
  defaultFormValues: {},
  eventsSelectOptions: [],
  agendaItem: null,
  event: null,
  organization: null,
  agendaThemeFields: null,
  speakers: [],
  documents: [],
  products: [],
  player: null,
  backup_player: null,
  initialLoading: false,
  isDocumentsLoading: false,
  isProductsInitialLoading: false,
  isSpeakersLoading: false,
  defaultPlayerFormValues: {},
  isPlayersLoading: false,
};

export class AgendaItemStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @computed get isNotInitializedType() {
    return this.formType === null;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    this.getResources({ id });
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      this.prepareAndUpdateAgendaItem(...props);
    }
    if (this.isCreate) {
      this.createAgendaItem(...props);
    }

    return null;
  };

  @action getResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      if (this.isEdit) {
        await this.getAgendaItem({ id });
        this.getSpeakers();
        this.getDocuments();
        this.getProducts();
        const playerId = this.agendaItem.player?.id;
        const backupPlayerId = this.agendaItem.backup_player?.id;
        this.getPlayer({ playerId, backupPlayerId });
      } else {
        await this.getEventSelectOptions();
        this.isAgendaItemLoading = false;
      }
      this.getTheme();
      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getResources] failed');
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    if (this.isEdit) {
      this.defaultFormValues = {
        ...this.agendaItem,
        agenda_type: this.agendaItem?.agenda_type,
        event_id: this.eventsSelectOptions.find(
          ev => ev.value === this.agendaItem.event_id,
        ),
      };
    } else {
      if (this.externalDefaultState) {
        this.defaultFormValues = {
          ...this.externalDefaultState,
          event_id: this.eventsSelectOptions.find(
            ev => ev.value === this.externalDefaultState.event_id,
          ),
        };

        this.externalDefaultState = null;
        return;
      }

      this.defaultFormValues = {
        agenda_type: AGENDA_TYPES.AGENDA_ITEM,
        start_date: moment()
          .utc()
          .toISOString(),
        end_date: moment()
          .add(1, 'hour')
          .utc()
          .toISOString(),
        locked: true,
        show_external_icon_on_breakout: true,
      };
    }
  };

  @action getAgendaItem = async ({ id }) => {
    try {
      this.isAgendaItemLoading = true;
      const ai = await ResourcesStore.getAgendaItem(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
      this.agendaItem = this.manipulateAgendaObject(ai);

      await Promise.allSettled([
        this.getEventContext(),
        this.getEventSelectOptions(),
      ]);

      this.setupDefaultFormValues();
      this.isAgendaItemLoading = false;
    } catch (error) {
      routerStore.push(routes.main.agenda);
      console.debug('[getAgendaItem] failed', error);
    }
  };

  @action getEventContext = async () => {
    await subscriptionStore.setContextEvent(null, this.agendaItem.event_id);

    this.event = toJS(subscriptionStore.contextEvent);

    const org = await ResourcesStore.getOrganization({
      id: this.event.organization_id,
    });
    this.organization = org;
  };

  @action getTheme = async () => {
    try {
      this.isThemeLoading = true;
      const data = await ResourcesStore.getAgendaThemeFields({
        cancelToken: this.__cancelToken?.token,
      });
      data.push(
        ...[{ name: 'player_placeholder_image', type: 'ImageUploader' }],
      );
      this.agendaThemeFields = data.reduce(
        (o, k) => ({ ...o, [k.name]: k.type }),
        {},
      );
      this.isThemeLoading = false;
    } catch (error) {
      console.debug('[getTheme] failed', error);
    }
  };

  @action getSpeakers = async () => {
    try {
      this.speakers = this.agendaItem.speakers;
    } catch (err) {
      console.debug('[getSpeakers] failed', err);
    }
  };

  @action createSpeaker = async (values, form) => {
    try {
      this.isSpeakersLoading = true;
      const res = await ResourcesStore.createSpeaker({
        parentId: this.event.id,
        payload: { ...values, agenda_item_ids: [this.agendaItem.id] },
      });
      this.speakers = [...this.speakers, res];
      form.restart();
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action unpinSpeaker = async speaker => {
    try {
      this.isSpeakersLoading = true;
      const modifiedSpeaker = {
        ...speaker,
        agenda_item_ids: speaker.agenda_item_ids.filter(
          id => id !== this.agendaItem.id,
        ),
      };

      await ResourcesStore.updateSpeaker({
        id: modifiedSpeaker.id,
        payload: modifiedSpeaker,
      });

      this.speakers = this.speakers.filter(
        spk => spk.id !== modifiedSpeaker.id,
      );
    } catch (err) {
      console.debug('[unpinSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action addExistingSpeaker = async (values, form) => {
    try {
      this.isSpeakersLoading = true;
      const res = await ResourcesStore.updateSpeaker({
        id: values.speaker.id,
        payload: {
          ...values.speaker,
          agenda_item_ids: [
            ...values.speaker.agenda_item_ids,
            this.agendaItem.id,
          ],
        },
      });
      form.restart();
      this.speakers = [...this.speakers, res];
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action getDocuments = async () => {
    try {
      this.documents = this.agendaItem.agenda_item_documents;
    } catch (err) {
      console.debug('[getDocuments] failed', err);
    }
  };

  @action createDocument = async (values, form) => {
    try {
      this.isDocumentsLoading = true;
      const res = await ResourcesStore.createDocument({
        parentId: this.event.id,
        payload: {
          ...values,
          agenda_item_ids: [this.agendaItem.id],
        },
      });

      this.documents = [...this.documents, res];
      form.restart();
      return {};
    } catch (err) {
      console.debug('[createDocument] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isDocumentsLoading = false;
    }
  };

  @action deleteDocumentModal = ({ id }) => {
    modalStore.openModal({
      content: (
        <WarningModalContent
          onCancel={modalStore.closeModal}
          onConfirm={() => {
            modalStore.closeModal();
            this.deleteDocument({ id });
          }}
        />
      ),
    });
  };

  @action deleteDocument = async ({ id }) => {
    try {
      this.isDocumentsLoading = true;
      await ResourcesStore.deleteDocument({ id });
      this.documents = this.documents.filter(doc => doc.id !== id);
    } catch (err) {
      console.debug('[deleteDocument] failed', err);
    } finally {
      this.isDocumentsLoading = false;
    }
  };

  @action getProducts = async () => {
    try {
      this.isProductsInitialLoading = true;
      const { results } = await ResourcesStore.getEntityProducts(
        {
          id: this.agendaItem.id,
          type: 'agenda_item',
          per: '-1',
        },
        { cancelToken: this.__cancelToken?.token },
      );
      this.products = results;

      this.isProductsInitialLoading = false;
    } catch (err) {
      console.debug('[getProducts] failed', err);
    }
  };

  @action createProduct = async (values, form) => {
    try {
      this.isProductsLoading = true;
      const res = await ResourcesStore.createProduct({
        type: 'agenda_item',
        parentId: this.agendaItem.id,
        payload: values,
      });

      this.products = [...this.products, res];
      form.restart();
    } catch (err) {
      console.debug('[createProduct] failed', err);
    } finally {
      this.isProductsLoading = false;
    }
  };

  @action deleteProduct = async ({ id }) => {
    try {
      this.isProductsLoading = true;
      await ResourcesStore.deleteProduct({ id });
      this.products = this.products.filter(p => p.id !== id);
    } catch (err) {
      console.debug('[deleteProduct] failed', err);
    } finally {
      this.isProductsLoading = false;
    }
  };

  @action getPlayer = async ({ playerId, backupPlayerId }) => {
    try {
      this.isPlayersLoading = true;
      if (playerId) {
        this.player = await ResourcesStore.getPlayer({
          id: playerId,
        });
      }
      if (backupPlayerId) {
        this.backup_player = await ResourcesStore.getPlayer({
          id: backupPlayerId,
        });
      }
    } catch (err) {
      console.debug('[getPlayer] failed', err);
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action submitPlayer = async ({ values, onSuccess, onError, isPrimary }) => {
    try {
      this.isPlayersLoading = true;
      const finalPlayer = !isPrimary ? this.backup_player : this.player;
      const playerRole = !isPrimary ? 'BackupPlayer' : 'Player';
      let resultPlayer;

      if (finalPlayer) {
        resultPlayer = await ResourcesStore.updatePlayer({
          id: values.id,
          payload: values,
        });
      } else {
        const payload = {
          ...values,
          playable_id: this.agendaItem.id,
          player_role: playerRole,
        };
        resultPlayer = await ResourcesStore.createPlayer({
          payload,
        });
      }

      if (!isPrimary) {
        this.backup_player = resultPlayer;
      } else {
        this.player = resultPlayer;
        if (this.backup_player) {
          this.getPlayer({ backupPlayerId: this.backup_player.id });
        }
        this.agendaItem.player = this.player;
        this.agendaItem.backup_player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      console.debug('[submitPlayer] failed', error);
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action deletePlayer = async ({ id, onSuccess, onError }) => {
    try {
      this.isPlayersLoading = true;

      await ResourcesStore.deletePlayer({ id });

      if (id === this.backup_player?.id) this.backup_player = null;

      if (id === this.player?.id) {
        this.player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      console.debug('[deletePlayer] failed', error);
      if (onError) onError();
    } finally {
      this.isPlayersLoading = false;
    }
  };

  initPlayerValues = ({ isPrimary }) => {
    if (!isNull(this.player) && isPrimary) return this.player;
    if (!isNull(this.backup_player) && !isPrimary) return this.backup_player;
    return {
      player_type: PLAYER_TYPES.DEFAULT,
      playable_type: PLAYER_DESTINY_TYPES.AI,
      aws_hls_stream_stack_id: null,
      aws_hls_stack_name: urlSlug(this.agendaItem?.description),
      aws_hls_stream_stack_encoding_profile: 'HD-1080p',
    };
  };

  @action manipulateAgendaObject = ai => {
    return {
      ...ai,
      agenda_item_custom_theme: {
        ...ai.agenda_item_custom_theme,
        player_placeholder_image: ai.player_placeholder_image,
      },
    };
  };

  @action createAgendaItem = async ({ payload, ...props }) => {
    this.isAgendaItemLoading = true;

    const result = await ResourcesStore.createAgendaItem({
      payload: {
        ...payload,
        event_id: payload.event_id.value,
        agenda_type: 'agenda_item',
      },
      ...props,
    });

    this.isAgendaItemLoading = false;

    return result;
  };

  @action prepareAndUpdateAgendaItem = async ({ payload, ...props }) => {
    const {
      player_placeholder_image: playerPlaceholderImage,
      ...theme
    } = payload.agenda_item_custom_theme;

    const { agenda_end_datetime: eventAgendaEndDatetime } = this.event;

    const {
      start_date: agendaStartDate,
      end_date: agendaEndDate,
    } = this.agendaItem;

    const anyTimeChanged =
      !moment(agendaStartDate).isSame(moment(payload.start_date)) ||
      !moment(agendaEndDate).isSame(moment(payload.end_date));

    const preparedPayload = {
      ...payload,
      player_placeholder_image: playerPlaceholderImage,
      agenda_item_custom_theme: theme,
      // delete speakers from AI when changing event
      event_speaker_ids:
        payload.event_id.value !== this.defaultFormValues.event_id.value
          ? []
          : undefined,
      speakers: undefined,
      document_ids: undefined,
      product_ids: undefined,
      event_id: payload.event_id.value,
    };

    if (!moment().isAfter(moment(eventAgendaEndDatetime)) || !anyTimeChanged) {
      this.updateAgendaItem({ payload: preparedPayload, ...props });
    } else {
      modalStore.openModal({
        content: (
          <WarningModalContent
            msg="Warning: editing this item may skew post-event analytics, please refrain from editing times post-event where possible."
            onCancel={modalStore.closeModal}
            onConfirm={() => {
              modalStore.closeModal();
              this.updateAgendaItem({ payload: preparedPayload, ...props });
            }}
          />
        ),
      });
    }
  };

  @action updateAgendaItem = async ({ payload, ...props }) => {
    this.isAgendaItemLoading = true;
    try {
      const ai = await ResourcesStore.updateAgendaItem({
        id: this.agendaItem.id,
        payload,
        ...props,
      });

      this.agendaItem = this.manipulateAgendaObject(ai);
      await this.getEventContext();
      this.setupDefaultFormValues();
      this.getSpeakers();
      this.getDocuments();
      this.getProducts();
      const playerId = this.agendaItem.player?.id;
      const backupPlayerId = this.agendaItem.backup_player?.id;

      this.getPlayer({ playerId, backupPlayerId });
    } catch (err) {
      console.debug('[updateAgendaItem] failed', err);
    } finally {
      this.isAgendaItemLoading = false;
    }
  };

  @action deleteAgendaItem = async ({ id, ...props }) => {
    try {
      await ResourcesStore.deleteAgendaItem({ id, ...props });
    } catch (err) {
      console.debug('[deleteAgendaItem] failed', err);
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const { results } = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getSpeakersSelectOptions = async ({ eventId }) => {
    try {
      const { results } = await ResourcesStore.getSpeakers(
        {
          per: '-1',
          event: eventId,
        },
        { cancelToken: this.__cancelToken?.token },
      );
      return results;
    } catch (e) {
      return [];
    }
  };

  @action reorderResource = type => async ({ startIndex, endIndex }) => {
    try {
      let finalArray = [];
      if (type === 'speakers') {
        finalArray = this.speakers;
      }
      if (type === 'documents') {
        finalArray = this.documents;
      }
      if (type === 'products') {
        finalArray = this.products;
      }
      const result = finalArray;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const ids = result.map(resource => resource.id);

      if (type === 'speakers') {
        this.speakers = result;
        await ResourcesStore.reorderSpeakers({
          agenda_id: this.agendaItem.id,
          speaker_ids: ids,
        });
      }
      if (type === 'documents') {
        this.documents = result;
        await ResourcesStore.reorderDocuments({
          agenda_id: this.agendaItem.id,
          documents_ids: ids,
        });
      }
      if (type === 'products') {
        this.products = result;
        await ResourcesStore.reorderProducts({
          agenda_id: this.agendaItem.id,
          products_ids: ids,
        });
      }
    } catch (err) {
      console.debug('[reorderResource] failed', err);
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    subscriptionStore.resetContextEvent();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new AgendaItemStore();
