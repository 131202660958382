import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  isDeletingId: null,
  isVotingStatsLoading: 0,
  ratings: null,
  singleRatingVote: [],
  votingStats: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
};

export class RatingsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getRatings = async ({
    page,
    sort,
    search,
    silent,
    active,
    event_id,
    agenda_item_id,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getRatings({
        page,
        sort,
        search,
        active,
        ...(!agenda_item_id && { event_id }),
        agenda_item_id,
      });

      this.ratings = {
        ...res,
        results: this.groupVotes(res.results),
        page: res.page_number.toString(),
        sort,
        search,
        active,
        event_id,
        agenda_item_id,
      };
    } catch (err) {
      console.debug('[getRatings] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action groupVotes = votes => {
    const allScores = [1, 2, 3, 4, 5];
    return votes.map(vote => {
      const copy = [...vote.vote_stats];

      allScores.forEach(sc => {
        if (!vote.vote_stats.find(v => sc === v.score)) {
          copy.push({ score: sc, votes_count: 0 });
        }
      });

      return {
        ...vote,
        vote_stats: copy.sort((a, b) => a.score - b.score),
      };
    });
  };

  @action deleteRating = async props => {
    try {
      const { id } = props;
      this.isDeletingId = id;
      await ResourcesStore.deleteRating(props);
    } catch (err) {
      console.debug('[deleteRating] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'event_id', 'active'].every(
      param => params[param] === this.ratings?.[param],
    );
  };

  @action handleRatingSwitch = async ({ id, payload, onSuccess, onError }) => {
    try {
      const result = await ResourcesStore.updateRating({
        id,
        payload,
        onSuccess,
        onError,
      });
      return result;
    } catch (err) {
      console.debug('[handleRatingSwitch] failed', err);
      return Promise.reject(err);
    }
  };

  @action getVotingStats = async ({ voteId, q_score }) => {
    this.isVotingStatsLoading += 1;

    this.votingStats = await ResourcesStore.getRatingsVotesStats({
      voteId,
      q_score,
    });

    this.isVotingStatsLoading -= 1;
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    this.isLoading += 1;
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
    this.isLoading -= 1;
  };
}

export default new RatingsPageStore();
