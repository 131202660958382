import React from 'react';
import navigationConfig from '@app/navigationConfig';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { inject, observer } from 'mobx-react';
import { useCollapsableSideBar } from '@utils/useCollapsableSideBar';
import NavigationListItem from './NavigationListItem';
import NavigationSection from './NavigationSection';

const Navigation = ({
  profileStore: { userRole },
  subscriptionStore: { resolveOrgDisableField },
}) => {
  const { toggleSection, openedSections } = useCollapsableSideBar();

  const filtered = navigationConfig.filter(section => {
    if (!section.allowed.includes(userRole)) return false;

    if (
      section.pages.every(
        page =>
          (page.allowed && !page.allowed.includes(userRole)) ||
          (page.featureSet && resolveOrgDisableField(page.featureSet)),
      )
    )
      return false;

    return true;
  });

  return filtered.map(section => (
    <React.Fragment key={section.title}>
      <NavigationSection
        toggleSection={toggleSection}
        opened={openedSections[section.title]}
        section={section}
      />
      <Collapse
        in={Boolean(openedSections[section.title])}
        timeout="auto"
        unmountOnExit
      >
        <List sx={{ pt: 0, pb: '15px' }}>
          {section.pages.map(page => (
            <NavigationListItem {...page} key={page.title} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  ));
};

export default inject(
  'profileStore',
  'subscriptionStore',
)(observer(Navigation));
