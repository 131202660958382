import { action, extendObservable } from 'mobx';
import { API, APIRoutes } from '@api';

const initialState = {
  clientSecret: null,
  isLoading: false,
  errorMessage: null,
};
export class PaymentStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getClientSecret = async () => {
    this.isLoading = true;
    try {
      const { data } = await API.post(APIRoutes.SETUP_PAYMENT_METHOD);
      this.clientSecret = data.client_secret;
    } catch (error) {
      console.debug(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action setErrorMessage = msg => {
    this.errorMessage = msg;
  };

  @action clearStore = async () => {
    this.clientSecret = null;
    this.isLoading = false;
    this.errorMessage = null;
  };
}

export default new PaymentStore();
