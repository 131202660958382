import React from 'react';
import ReferenceLinkRouter from '@components/ReferenceLinkRouter';
import { Box, Tooltip } from '@mui/material';
import routes from '@routes';
import truncate from 'lodash/truncate';

const OrganizationLinkTooltip = ({ orgName, orgId }) => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: '400px',
          },
        },
      }}
      title={orgName}
      placement="top"
    >
      <Box width="fit-content">
        <ReferenceLinkRouter to={routes.organization(orgId)}>
          {truncate(orgName, { length: 25 })}
        </ReferenceLinkRouter>
      </Box>
    </Tooltip>
  );
};

export default OrganizationLinkTooltip;
