import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatDate, DATE_FORMATS } from '@utils/date';
import {
  DaySelectedLine,
  DaySelector,
} from '@components/analytics/DayButton/style';

const DayButton = ({ day, global, setSelectedDate, selectedDate }) => {
  const isActive = useMemo(() => {
    if (global) {
      return selectedDate === day;
    }
    return selectedDate === formatDate(day, DATE_FORMATS.URL);
  }, [day, selectedDate, global]);

  const selectDay = useCallback(() => {
    setSelectedDate(day, global);
  }, [day, global]);

  return (
    <DaySelector
      as="button"
      type="button"
      onClick={selectDay}
      active={isActive ? 1 : 0}
    >
      {global ? 'Global' : formatDate(day)}
      <DaySelectedLine />
    </DaySelector>
  );
};

DayButton.defaultProps = {
  selectedDate: null,
  global: false,
  day: null,
};
DayButton.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  day: (props, propName, componentName) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!props.global && !props[propName]) {
      return new Error(
        `${componentName} property error: ${propName} is required`,
      );
    }
    return null;
  },
  selectedDate: PropTypes.string,
  global: PropTypes.bool,
};

export default memo(DayButton);
