export default ({ theme }) => ({
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        backgroundColor: theme.palette.primary[100],
      },
      deleteIcon: {
        color: theme.palette.primary[700],
        '&:hover': {
          color: theme.palette.primary[900],
        },
      },
    },
  },
});
