import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { inject, observer } from 'mobx-react';
import { useRouteMatch, Link } from 'react-router-dom';

const NavigationListItem = ({
  href,
  title,
  icon: Icon,
  subscriptionStore: { resolveOrgDisableField },
  profileStore: { userRole },
  featureSet,
  allowed,
}) => {
  const isSelected = useRouteMatch({ path: href.split('?')[0], exact: false });

  if (
    (featureSet && resolveOrgDisableField(featureSet)) ||
    (allowed && !allowed.includes(userRole))
  )
    return null;

  return (
    <ListItemButton
      sx={{
        py: '4px',
        px: '16px',
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          borderLeft: '5px solid',
          borderLeftColor: 'primary.main',
          pl: '11px',

          '& .MuiTypography-root': {
            color: 'primary.main',
          },

          '& .MuiListItemIcon-root': {
            color: 'primary.main',
          },
        },
      }}
      component={Link}
      to={href}
      selected={Boolean(isSelected)}
    >
      <ListItemIcon
        sx={theme => ({
          minWidth: 'unset',
          mr: '15px',
          color: theme.sidebar.iconColor,
        })}
      >
        <Icon sx={{ fontSize: '24px' }} />
      </ListItemIcon>
      <ListItemText
        sx={{
          '& .MuiTypography-root': {
            fontWeight: 'fontWeightMedium',
          },
        }}
        primary={title}
      />
    </ListItemButton>
  );
};

export default inject(
  'subscriptionStore',
  'profileStore',
)(observer(NavigationListItem));
