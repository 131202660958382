import React from 'react';
import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import {
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';
import modalStore from '@stores/modalStore';
import CloningPollOptionsModal from '@components/modals/CloningPollOptionsModal';
import { FORM_ERROR } from 'final-form';
import routerStore from '@stores/routerStore';
import routes from '@routes';
import assignmentsStore from '@stores/assignmentsStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@app/api';

const initialState = {
  isLoading: false,
  poll: null,
  defaultFormValues: {
    ...assignmentsSetupDefaultFormValues({}),
  },
  cloneRunning: false,
  initialLoading: true,
  formType: null,
};

export class PollPageStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updatePoll(...props);
    }
    if (this.isCreate) {
      return this.createPoll(...props);
    }

    return null;
  };

  @action getPoll = async ({ id }) => {
    try {
      const res = await ResourcesStore.getPoll(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
      this.poll = { ...res, poll_question: res.poll_questions[0] };
    } catch (err) {
      console.debug('[getPoll] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.polls);
      }
      throw err;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      question_id: this.poll.poll_question.id,
      question: this.poll.poll_question.question,
      hide_results: this.poll.poll_question.hide_results,
      event_id: this.poll.event_id,
      assignments: this.poll.assignments,
      active_now: this.poll.active_now,
      answers: this.poll.poll_question.poll_answers.map(item => ({
        id: item.id,
        answer: item.answer,
        poll_answer_gradient_top: item.poll_answer_custom_theme
          ? item.poll_answer_custom_theme.poll_answer_gradient_top
          : null,
        poll_answer_gradient_bottom: item.poll_answer_custom_theme
          ? item.poll_answer_custom_theme.poll_answer_gradient_bottom
          : null,
      })),
      ...assignmentsSetupDefaultFormValues({
        item: this.poll,
        events: assignmentsStore.events.list,
        agendas: assignmentsStore.agendaItems.list,
        groups: assignmentsStore.groups.list,
      }),
    };
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      const promises = [
        assignmentsStore.getResources({
          types: {
            [ASSIGNMENT_TYPE.EVENT]: true,
            [ASSIGNMENT_TYPE.AGENDA]: true,
          },
        }),
      ];

      if (id) {
        promises.push(this.getPoll({ id }));
      }

      await Promise.all(promises);

      if (id) {
        this.setupDefaultFormValues();
      }
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action updatePoll = async ({ id, values, onSuccess }) => {
    this.isLoading = true;

    try {
      const payload = {
        id,
        active_now: values.active_now,
        poll_questions: [
          {
            id: values.question_id,
            question: values.question,
            multiple_choice: values.multiple_choice,
            hide_results: values.hide_results,
            poll_answers: values.answers.map(answer => ({
              id: answer.id ? answer.id : null,
              answer: answer.answer,
              poll_answer_custom_theme: {
                poll_answer_gradient_top: answer.poll_answer_gradient_top
                  ? answer.poll_answer_gradient_top
                  : null,
                poll_answer_gradient_bottom: answer.poll_answer_gradient_bottom
                  ? answer.poll_answer_gradient_bottom
                  : null,
              },
            })),
          },
        ],
        ...assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }),
      };

      const updPoll = await ResourcesStore.updatePoll({
        id,
        payload,
      });

      await onSuccess?.(updPoll);
      this.poll = { ...updPoll, poll_question: updPoll.poll_questions[0] };
      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updatePoll] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createPoll = async ({ values, onSuccess }) => {
    this.isLoading = true;
    try {
      const payload = {
        active_now: values.active_now || false,
        poll_questions: [
          {
            id: values.question_id,
            question: values.question,
            multiple_choice: values.multiple_choice || false,
            hide_results: values.hide_results || false,
            poll_answers: (values.answers || []).map(answer => ({
              id: answer.id ? answer.id : null,
              answer: answer.answer,
              poll_answer_custom_theme: {
                poll_answer_gradient_top: answer.poll_answer_gradient_top
                  ? answer.poll_answer_gradient_top
                  : null,
                poll_answer_gradient_bottom: answer.poll_answer_gradient_bottom
                  ? answer.poll_answer_gradient_bottom
                  : null,
              },
            })),
          },
        ],
        ...assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }),
      };

      const createdPoll = await ResourcesStore.createPoll({
        payload,
      });

      await onSuccess?.(createdPoll);

      return null;
    } catch (err) {
      console.debug('[createPoll] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deletePoll = async ({ id, onSuccess }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deletePoll({ id, onSuccess });
    } catch (err) {
      console.debug('[deletePoll] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action handleClonePoll = async () => {
    assignmentsStore.getResources({ types: { [ASSIGNMENT_TYPE.EVENT]: true } });
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'pollCloneModal',
      content: (
        <CloningPollOptionsModal
          onClone={this.cloneProcess}
          events={assignmentsStore.events.list}
        />
      ),
    });
  };

  @action cloneProcess = async ({ values }) => {
    this.cloneRunning = 'running';

    const preparedPayload = {
      clone: {
        ...values,
        poll_id: this.poll.id,
      },
    };

    try {
      const clonedData = await ResourcesStore.clonePoll({
        payload: preparedPayload,
        pollId: this.poll.id,
      });

      if (!clonedData.success) {
        throw JSON.stringify(clonedData.errors);
      }

      this.cloneRunning = 'success';

      setTimeout(() => {
        routerStore.push(routes.poll(clonedData.poll_id));
        modalStore.closeModal();
        this.cloneRunning = false;
      }, 2000);

      return null;
    } catch (err) {
      this.cloneRunning = false;
      return {
        [FORM_ERROR]: {
          msg: 'Cloning failed, please contact an administrator',
          errors: JSON.stringify({ payload: preparedPayload, error: err }),
        },
      };
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    modalStore.killModal('pollCloneModal');
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new PollPageStore();
