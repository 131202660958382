import React from 'react';
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import styled from '@emotion/styled';
import IconBox from '@pages/dashboard/components/IconBox';
import routes from '@routes';

const Title = styled(Typography)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.heading};
`;

const Subtitle = styled(Typography)`
  display: flex;
  text-align: center;
  max-width: 500px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.section};
`;

function CreateEvent() {
  return (
    <Paper
      elevation={0}
      sx={{ display: 'flex', justifyContent: 'center', padding: '50px 0' }}
    >
      <Stack spacing={7}>
        <Title> Welcome to Conference+ </Title>
        <Subtitle>
          Thank you for chosing Conference+, the best way to host your next
          conference.
        </Subtitle>
        <Divider />
        <IconBox
          icon={<EventIcon sx={{ fontSize: 40 }} color="primary" />}
          title="Events"
          description="Create, view and manage your C+ events"
        />
        <IconBox
          icon={<DashboardIcon sx={{ fontSize: 40 }} color="success" />}
          title="Dashboard"
          description="Your overview of all live and upcoming events"
        />
        <IconBox
          icon={<PeopleIcon sx={{ fontSize: 40 }} color="error" />}
          title="Users"
          description="Add admin members and manage conference attendees"
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{ padding: '10px 40px' }}
            component={Link}
            to={`${routes.event('create')}`}
          >
            Create your first event
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}

export default CreateEvent;
