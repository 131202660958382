export const hexToRgba = (hex, opacity = 1) => {
  let c;

  if (!hex) return null;
  if (hex === 'transparent') return 'rgba(0,0,0,1)';
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ',',
    )},${opacity})`;
  }
  // eslint-disable-next-line no-console
  console.warn('Bad Hex:', hex);
  return null;
};

export const hidingScrollbarFormula = () => {
  return `
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }`;
};

export const animationFadeIn = () => {
  return `
    animation-name: fadeIn;
    animation-duration: 0.3s;

    @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }`;
};

export const customScroll = (customColor, { forceScrollColor, scrollBckg }) => {
  return `
      ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      background-color: ${hexToRgba(scrollBckg || customColor || '#fff', 0.3)};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${hexToRgba(
        forceScrollColor || customColor || '#fff',
        0.65,
      )};
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    `;
};

export const isWhite = hexColor => {
  return ['#FFF', '#FFFFFF', '#fff', '#ffffff'].includes(hexColor);
};

export const hideColOnMobile = size => `
  @media screen and (max-width: ${size}px){
    display: none;
  }
`;

export const imgSizeWidthHiddenOnMobile = size => `
  width: 50px;
  @media screen and (max-width: ${size}px){
    display: none;
  }
`;

export const widthHiddenOnMobile = (size, width, alignCenter) => `
  width: ${width}px;
  text-align: ${alignCenter ? 'center' : 'left'};
  @media screen and (max-width: ${size}px){
    display: none;
  }
`;

export const smallerCheckboxSpace = size => `
  width: 20px; padding: 7px 10px 6px 10px;
  & > span {
    padding-left: 9px!important;
    @media screen and (max-width: ${size}px){
      padding-left: 0px!important;
    }
  };
`;

export const smallerColCheckboxSpace = size => `
  @media screen and (max-width: ${size}px){
    & > div {
      padding-left: 4px!important;
    }
  };
`;

export const ellipsisInMiddle = (string, maxLength = 10) => {
  if (!string) return string;
  if (string.length > maxLength) {
    return `${string.substring(
      0,
      Math.floor(string.length / 2 - (string.length - maxLength) / 2),
    )}...${string.substring(
      Math.floor(string.length / 2 + (string.length - maxLength) / 2),
    )}`;
  }

  return string;
};

export const colorIsLight = color => {
  if (!color) return true;
  if (color[0] === '#') {
    color = color.slice(1);
  }
  if (color.length === 3) {
    color = color
      .split('')
      .map(hex => hex + hex)
      .join('');
  }

  const rgbArray = [
    parseInt(color.slice(0, 2), 16),
    parseInt(color.slice(2, 4), 16),
    parseInt(color.slice(4, 6), 16),
  ];
  // Counting the perceptive luminance
  // human eye favors green color...
  const a =
    1 - (0.299 * rgbArray[0] + 0.587 * rgbArray[1] + 0.114 * rgbArray[2]) / 255;

  return a < 0.5;
};

// aaa bb cc => Aaa bb cc
export const capitalizeFirstLetter = string => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const sxDatePickerFix = theme => ({
  '& .MuiOutlinedInput-root': {
    '& .MuiInputAdornment-root': {
      '&.MuiInputAdornment-sizeSmall': {
        '& .MuiButtonBase-root': {
          padding: '4px',
          marginRight: '-8px',
          '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(20),
          },
        },
      },
    },
  },
  '& .MuiInput-root': {
    '&.Mui-disabled:before': {
      borderBottomStyle: 'dotted',
    },
    '& .MuiInputAdornment-root': {
      '&.MuiInputAdornment-sizeSmall': {
        '& .MuiButtonBase-root': {
          padding: '4px',
          marginRight: '-2px',
          '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(20),
          },
        },
      },
      '&.MuiInputAdornment-sizeMedium': {
        '& .MuiButtonBase-root': {
          padding: '4px',
          marginRight: '-2px',
          '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(24),
          },
        },
      },
    },
  },
});
