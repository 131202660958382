import React from 'react';
import Box from '@mui/material/Box';
import FieldTooltip from '@components/FieldTooltip';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { WarningModalContent } from '@components/WarningModalContent';
import { inject, observer } from 'mobx-react';

const SwitchInput = ({
  width,
  label,
  label0,
  infoText,
  switchOnRight,
  required,
  input,
  disabled,
  meta,
  onChange,
  warnBeforeEnable,
  handleOtherFields,
  reversedSwitch,
  modalStore: { openModal, closeModal },
  notAvailableInSP,
}) => {
  const {
    onChange: formOnChange,
    checked: switchCheckedkVal,
    ...inputP
  } = input;

  const handleWarn = val => {
    openModal({
      content: (
        <WarningModalContent
          onConfirm={async () => {
            formOnChange(val);
            if (handleOtherFields) {
              handleOtherFields();
            }
            closeModal();
          }}
          onCancel={closeModal}
          msg={warnBeforeEnable}
        />
      ),
    });
  };

  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  let edge = 'start';
  if (switchOnRight) edge = 'end';
  if (label0) edge = false;

  return (
    <Box sx={{ flexBasis: width || '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {(label0 || switchOnRight) && (
          <FormLabel
            sx={{ flexShrink: 1 }}
            error={Boolean(error)}
            required={required}
          >
            {label0 || label}
          </FormLabel>
        )}
        <Switch
          sx={{
            flexShrink: 0,
            opacity: disabled ? 0.5 : 1,
          }}
          id={`switch-${input.name}`}
          edge={edge}
          disabled={disabled}
          checked={reversedSwitch ? !switchCheckedkVal : switchCheckedkVal}
          onClick={() => onChange && onChange(input)}
          onChange={(_, val) => {
            const switchChecked = reversedSwitch ? val === false : val === true;
            const switchValue = reversedSwitch ? !val : val;

            if (warnBeforeEnable && switchChecked) {
              handleWarn(switchValue);
              return;
            }

            if (typeof onChange === 'function') {
              onChange(switchValue);
            }

            formOnChange(switchValue);
            if (handleOtherFields) {
              handleOtherFields();
            }
          }}
          {...inputP}
        />
        {!switchOnRight && (
          <FormLabel
            sx={{ flexShrink: 1 }}
            error={Boolean(error)}
            required={required}
          >
            {label}
          </FormLabel>
        )}

        <FieldTooltip
          infoText={infoText}
          sx={{ ml: '10px' }}
          notAvailableInSP={notAvailableInSP}
        />
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default inject('modalStore')(observer(SwitchInput));
