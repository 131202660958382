import React, { useMemo, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import routes from '@routes';
import { Title, Description } from '@pages/signup/components/AccountCreate';

const ResendBtn = styled(Box)`
  display: flex;
  background-color: ${({ disabled }) => (disabled ? 'lightgrey' : '#151b31')};
  color: ${({ disabled }) => (disabled ? '#000' : '#60ddff')};
  border-radius: 5px;
  padding: 12px 30px;
  margin-top: 15px !important;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  }
`;

const ConfirmEmail = ({
  authStore: { resendSignUpEmail },
  profileStore: { profile },
}) => {
  const [btnLock, setBtnLock] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (profile?.created_via_saas_api) {
      if (window.location.pathname === routes.auth.signUp('lite')) {
        history.replace(`${window.location.pathname}?step=billingDetails`);
      } else {
        history.replace(routes.main.dashboard);
      }
      return;
    }
    if (profile) {
      history.replace(routes.main.dashboard);
    }
  }, [profile]);

  const { email } = useMemo(() => qs.parse(search), [search]);
  const isLite = useMemo(
    () => window.location.pathname === routes.auth.signUp('lite'),
    [window.location.pathname],
  );

  const handleResend = () => {
    setBtnLock(true);
    resendSignUpEmail(email, isLite);

    setTimeout(() => {
      setBtnLock(false);
    }, 30000);
  };

  return (
    <>
      <Title>Confirm your email address</Title>
      <Description>We&apos;ve sent a confirmation email to:</Description>
      <Description>{email}</Description>
      <Description>
        Check your email and click on the confirmation link to continue
      </Description>
      <Box display="flex" justifyContent="center">
        <ResendBtn
          disabled={btnLock}
          {...(!btnLock && { onClick: handleResend })}
        >
          RESEND EMAIL
        </ResendBtn>
      </Box>
    </>
  );
};

export default inject('authStore', 'profileStore')(observer(ConfirmEmail));
