import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Box } from '@mui/material';
import RegularButton from '@components/Buttons/components/RegularButton';
import TextButton from '@components/Buttons/components/TextButton';

export const BUTTON_TYPES = {
  BLANK: 'blank',
  TEXT_PRIMARY: 'text_primary',
  TEXT_SECONDARY: 'text_secondary',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const Wrapper = styled(Box)`
  margin-left: ${props => props.ml};
  cursor: pointer;
  width: ${props => props.width || 'fit-content'};
`;

export const Button = ({ type, wrapperStyle, ml, ...props }) => {
  const theme = useTheme();
  const buttonProps = useMemo(() => {
    let additionalProps;

    switch (type) {
      case BUTTON_TYPES.TEXT_PRIMARY:
        additionalProps = {
          color: theme.colors.primary,
        };
        break;
      case BUTTON_TYPES.TEXT_SECONDARY:
        additionalProps = {
          color: theme.colors.secondary,
        };
        break;
      case BUTTON_TYPES.PRIMARY:
        additionalProps = {
          bgcolor: theme.colors.primary,
          color: theme.colors.textWhite,
        };
        break;
      case BUTTON_TYPES.SECONDARY:
        additionalProps = {
          bgcolor: theme.colors.secondary,
          color: theme.colors.textWhite,
        };
        break;
      default:
        additionalProps = {
          bgcolor: theme.colors.box,
          color: theme.colors.text,
        };
        break;
    }

    return { ...additionalProps, ...props };
  }, [props]);

  if ([BUTTON_TYPES.TEXT_PRIMARY, BUTTON_TYPES.TEXT_SECONDARY].includes(type)) {
    return (
      <Wrapper ml={ml} {...wrapperStyle}>
        <TextButton {...buttonProps} />
      </Wrapper>
    );
  }

  return (
    <Wrapper ml={ml} {...wrapperStyle}>
      <RegularButton {...buttonProps} />
    </Wrapper>
  );
};
