import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  attendances: null,
  eventsSelectOptions: [],
  selectedRows: [],
  isDeletingId: null,
};
export class AttendancesPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action setSelectedRows = val => {
    this.selectedRows = val;
  };

  @action batchUpdateStatuses = async payload => {
    try {
      await ResourcesStore.batchUpdateStatuses(payload);
    } catch (err) {
      console.debug('[batchUpdateStatuses] failed', err);
    }
  };

  @action updateAttendance = async ({ id, payload, onSuccess }) => {
    try {
      const res = await ResourcesStore.updateAttendance({
        id,
        payload,
        onSuccess,
      });
      const idx = this.attendances.results.findIndex(x => x.id === res.id);
      if (idx !== -1) {
        this.attendances.results[idx] = res;
      }
      return res;
    } catch (err) {
      console.debug('[updateAttendance] failed', err);
      return Promise.reject(err);
    }
  };

  @action recalculateAttendances = async event_id => {
    try {
      await ResourcesStore.postRecalculateAttendances(event_id);
      return true;
    } catch (err) {
      console.debug('[recalculateAttendances] failed', err);
      return false;
    }
  };

  @action getAttendances = async ({
    page,
    search,
    event_id,
    status,
    silent,
    sort,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      this.setSelectedRows([]);
      const res = await ResourcesStore.getAttendances({
        page,
        search,
        event_id,
        status,
        sort,
      });

      this.attendances = {
        ...res,
        page: res.page_number.toString(),
        search,
        event_id,
        status,
        sort,
      };
    } catch (err) {
      console.debug('[getAttendances] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'event_id', 'status'].every(
      param => params[param] === this.attendances?.[param],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();

      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAttendancesExport = async ({ search, event, status }) => {
    try {
      this.attendancesCsv = await ResourcesStore.getAttendancesExport({
        search,
        event,
        status,
      });
    } catch (err) {
      console.debug('[getAttendancesExport] failed', err);
    }
  };

  @action deleteAttendance = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteAttendance({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[getAttendancesExport] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new AttendancesPageStore();
