import routes from '@routes';
import BangMask from '@assets/stickers/bang-mask.png';
import Bang from '@assets/stickers/bang.png';
import BoomMask from '@assets/stickers/boom-mask.png';
import Boom from '@assets/stickers/boom.png';
import CoolMask from '@assets/stickers/cool-mask.png';
import Cool from '@assets/stickers/cool.png';
import LolMask from '@assets/stickers/lol-mask.png';
import Lol from '@assets/stickers/lol.png';
import LoveMask from '@assets/stickers/love-mask.png';
import Love from '@assets/stickers/love.png';
import NoLoveMask from '@assets/stickers/nolove-mask.png';
import NoLove from '@assets/stickers/nolove.png';
import OmgMask from '@assets/stickers/omg-mask.png';
import Omg from '@assets/stickers/omg.png';
import WowMask from '@assets/stickers/wow-mask.png';
import Wow from '@assets/stickers/wow.png';
import YeahMask from '@assets/stickers/yeah-mask.png';
import Yeah from '@assets/stickers/yeah.png';
import { envMappingType } from '@styles/_theme';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const COUNTRY_CODES = countries.getNames('en', { select: 'official' });

export const LS_KEYS = {
  ID: 'id',
  AUTH_TOKEN: 'auth-token',
  CLIENT: 'client',
  UID: 'uid',
  PROFILE: 'profile',
};

export const PLAYER_TYPES = {
  DEFAULT: 'social_live',
  AWS_HLS_STREAM: 'aws_hls_stream',
};

export const FORM_TYPES = { CREATE: 1, EDIT: 2 };

export const PLAYER_OPTIONS_TYPES = [
  {
    label: 'External player',
    value: PLAYER_TYPES.DEFAULT,
  },
  {
    label: `${envMappingType} Video Player`,
    value: PLAYER_TYPES.AWS_HLS_STREAM,
  },
];

export const IMAGE_TYPES = {
  BIG: 'big',
  SMALL: 'small',
};

export const QUESTION_STATUSES = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const QUESTION_STATUSES_SELECT_OPTIONS = Object.keys(
  QUESTION_STATUSES,
).map(key => ({
  label: key,
  value: QUESTION_STATUSES[key],
}));

export const ATTENDANCE_STATUSES = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  REQUESTED: 'requested',
  INVITED: 'invited',
};

export const ATTENDANCE_STATUSES_OPTIONS = [
  { label: 'Accepted', value: ATTENDANCE_STATUSES.ACCEPTED },
  { label: 'Declined', value: ATTENDANCE_STATUSES.DECLINED },
  { label: 'Requested', value: ATTENDANCE_STATUSES.REQUESTED },
  { label: 'Invited', value: ATTENDANCE_STATUSES.INVITED },
];

export const ATTENDANCE_MODES = {
  VIRTUAL: 'virtual',
  HYBRID: 'hybrid',
};

export const ATTENDANCE_MODES_OPTIONS = [
  { label: 'Virtual', value: ATTENDANCE_MODES.VIRTUAL },
  { label: 'In-person', value: ATTENDANCE_MODES.HYBRID },
];

export const DOCUMENT_TYPES = {
  NOT_SET: 'not_set',
  REPORT: 'report',
  PODCAST: 'podcast',
  PRESENTATION: 'presentation',
  VIDEO: 'video',
  WEBSITE: 'website',
};

export const THUMB_ORIENTATION = {
  NOT_SET: 'not_set',
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

export const Q_AND_A_MODERATION_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const MAPPING_TYPES_OPTIONS = [
  {
    label: `${envMappingType} Breakouts`,
    value: 'agoraio',
  },
  {
    label: `${envMappingType} Spaces`,
    value: 'spatial_chat',
  },
  {
    label: `Player`,
    value: 'player',
  },
  {
    label: 'External URL',
    value: 'not_set_or_url',
  },
  {
    label: 'Zoom',
    value: 'zoom',
  },
];

export const MAPPING_TYPES = {
  CUSTOM: 'not_set_or_url',
  ZOOM: 'zoom',
  AGORAIO: 'agoraio',
  SPATIALCHAT: 'spatial_chat',
  PLAYER: 'player',
};

export const PUBLIC_MAPPING_TYPES_OPTIONS = MAPPING_TYPES_OPTIONS.filter(
  x => x.value !== 'agoraio' && x.value !== 'spatial_chat',
);

export const TRANSLATION_LANG = {
  English: 'en',
  German: 'de',
  French: 'fr',
  Turkish: 'tr',
  Slovenian: 'sl',
  Romanian: 'ro',
  Portuguese: 'pt',
  Hungarian: 'hu',
  Italian: 'it',
  Greek: 'el',
  Croatian: 'hr',
  Bulgarian: 'bg',
  Czech: 'cs',
  Spanish: 'es',
  Japanese: 'ja',
  Hebrew: 'he',
  Ukrainian: 'uk',
  Polish: 'pl',
  Slovak: 'sk',
  Albanian: 'sq',
  Macedonian: 'mk',
  Dutch: 'nl',
  Russian: 'ru',
  Arabic: 'ar',
};

export const LANG_SELECT_OPTIONS = [
  ['None', null],
  ...Object.entries(TRANSLATION_LANG),
].map(entry => ({
  label: entry[0],
  value: entry[1],
}));

export const DOCUMENT_TYPES_OPTIONS = Object.keys(DOCUMENT_TYPES).map(key => ({
  label: key,
  value: DOCUMENT_TYPES[key],
}));

export const THUMB_ORIENTATION_OPTIONS = Object.keys(THUMB_ORIENTATION).map(
  key => ({
    label: key,
    value: THUMB_ORIENTATION[key],
  }),
);

export const Q_AND_A_MODERATION_STATUS_OPTIONS = Object.keys(
  Q_AND_A_MODERATION_STATUS,
).map(key => ({
  label: key,
  value: Q_AND_A_MODERATION_STATUS[key],
}));

export const AGENDA_TYPES = {
  AGENDA_ITEM: 'agenda_item',
  BREAKOUT_ROOM: 'breakout_room',
};

export const AGENDA_TYPES_SELECT_OPTIONS = [
  { label: 'Agenda item', value: AGENDA_TYPES.AGENDA_ITEM },
  { label: 'Breakout room', value: AGENDA_TYPES.BREAKOUT_ROOM },
];

export const THEME_FIELDS_TYPES = {
  COLOR: 'color',
  INTEGER: 'integer',
  FONT_CONFIG: 'font_config',
  TEXT: 'text',
  IMAGE: 'ImageUploader',
  BOOL: 'boolean',
  RICH_TEXT: 'rich_text',
  SELECT: 'select',
};

// Key is a regexp, value can be html
export const TRANSLATIONS_TOOLTIPS = {
  // prettier-ignore
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  'dt\..*':
    // eslint-disable-next-line max-len
    'For date formatting visit this site <a href="https://devhints.io/datetime#momentjs-format" target="_blank">https://devhints.io/datetime#momentjs-format</a>'
};

export const BANNED_THEME_FIELDS = [
  'created_at',
  'updated_at',
  'bottom_img',
  'sidebar_event_tile_bckg',
  'sidebar_event_tile_text',
  'sidebar_scroll',
  'sidebar_active_event',
  'chat_emotes_enabled',
  'qa_reply_highlight_color',
  'spaces_talking_color',
  'resource_bckg_color',
  'event_listing_status_title_color',
  'event_listing_buttons_bg',
  '_header_title_alignment',
  '_header_title_style',
  'event_brief_separator',
  'version',
  'back_button_color',
  'presenters_tab_text_color',
  'spaces_exit_button_color',
  // fields that are moved to settings,
  'show_event_name_header',
  'hide_tabbed_sections_bar_unauthorised_page',
  'hide_event_header_date',
  'hide_header_logo',
  'hide_tabbed_sections_bar',
  'hide_date_and_time',
  'event_brief_text_first',
  'show_breakout_rooms_on_event_brief',
  'agenda_tab_visible',
  'speaker_tab_visible',
  'sponsor_tab_visible',
  'show_play_button_on_agenda',
  'allow_collapsing_agenda_days',
  'collapse_non_current_agenda_days',
  'show_hybrid_placeholder_on_mobile',
  'header_title_uppercase_style',
  'hide_event_brief_details',
  'dates_uppercase_style',
  'sponsor_avatar_shadow',
  'hide_video_reaction_count',
  'hide_icons',
  'hide_agenda_info_icon',
  'hide_event_name_on_brief',
];

// not an object to maintain order of fields
export const THEME_GROUPS = [
  ['Fonts', ['header_font_family', 'body_font_family']],
  [
    'Header',
    [
      'header_text_primary',
      'header_bg',
      'header_icons',
      'header_custom_title',
      'use_lite_settings_light_logo',
      'header_gradient1',
      'header_gradient2',
      'header_logo',
      'header_image',
      'header_logo_width_percent',
    ],
  ],

  [
    'Event landing',
    [
      'event_reg_login_title_color',
      'landing_page_body',
      'event_brief_header_color',
      'event_brief_bg',
      'event_brief_gradient_top',
      'event_brief_gradient_bottom',
      'event_brief_tabs_highlight_color',
      'event_brief_tabs_highlight_color_gradient1',
      'event_brief_tabs_highlight_color_gradient2',
      'event_brief_tabs_separator_color',
      'event_brief_tabs_text_color',
      'event_brief_text',
      'event_brief_text_secondary',
      'landing_page_bckg_img',
      'landing_page_form_bottom_img',
      'event_brief_header_text',
      'event_brief_bg_image',
      'event_brief_speaker_popup_color',
      'event_brief_accent_color',
      'event_brief_detail_color',
      'event_brief_tile_background',
      'event_brief_time_color',
    ],
  ],
  [
    'General',
    [
      'body1',
      'text_primary',
      'text_secondary',
      'no_content_text',
      'disclaimer_text',
      'disclaimer_text_mobile',
      'buttons_text_color',
      'buttons_bg',
      'buttons_bg_gradient1',
      'buttons_bg_gradient2',
      'alternative_buttons_bg',
      'alternative_buttons_text_color',
      'hyperlink_color',
      'tooltip_bckg_color',
      'tooltip_text_color',
      'scrolls_color',
      'icons',
      'header_profile_border',
      'callout_box_outline',
      'callout_box_outline_gradient1',
      'callout_box_outline_gradient2',
      'event_listing_status_title_color',
      'event_listing_buttons_bg',
      'custom_default_avatar',
      'global_message_avatar',
      'speakers_popup_header_img',
    ],
  ],
  [
    'Agenda',
    [
      'main_gradient1',
      'main_gradient2',
      'agenda_timeline',
      'agenda_timeline_dots',
      'ai_bckg_active',
      'agenda_active_time_color',
      'agenda_inactive_background_color',
      'agenda_inactive_time_color',
      'selected_agenda_outline',
      'selected_agenda_outline_gradient1',
      'selected_agenda_outline_gradient2',
      'breakout_item_bckg',
      'breakout_item_bckg_active',
      'breakout_item_text',
      'breakout_item_active_text_color',
      'selected_breakout_outline',
      'day_breaker_color',
      'agenda_accent_color',
    ],
  ],
  [
    'Chat',
    [
      'main_right_col_gradient1',
      'main_right_col_gradient2',
      'chat_msg_bg',
      'chat_msg_bg_active',
      'chat_msg_moderator_bg',
      'chat_primary',
      'chat_text',
      'chat_avatar',
      'default_avatar_detail_color',
      'chat_input_bckg',
      'chat_input_text',
      'chat_time_color',
      'sidebar_tab_separator',
      'sidebar_tab_highlight_gradient1',
      'sidebar_tab_highlight_gradient2',
      'chat_panel_highlight_color',
      'chat_panel_text_color',
      'pinned_chat_bg',
      'pinned_chat_text',
      'reactions_bckg_color',
      'reaction_dots_color',
    ],
  ],
  [
    'Q&A',
    [
      'qa_question_bg_color',
      'qa_question_text_color',
      'qa_reply_text_color',
      'qa_reply_bg_color',
    ],
  ],
  [
    'Documents & products',
    ['product_accent', 'document_name_text_color', 'label_text'],
  ],

  [
    'Polls',
    [
      'poll_bckg',
      'poll_text',
      'poll_title',
      'poll_answer_text',
      'poll_answer_bckg',
    ],
  ],
  [
    'Tabs',
    [
      'tabbed_sections_bg',
      'tabs_body_color_1',
      'tabs_highlight_color',
      'tabs_highlight_color_gradient1',
      'tabs_highlight_color_gradient2',
      'tabs_text_color',
      'tabs_separator_color',
      'agenda_details_title_color',
      'agenda_details_text_color',
      'agenda_details_body_color',
      'sponsors_tab_text_color',
      'tabs_time_color',
      'speaker_popup_primary',
      'speaker_tile_bg',
      'presenters_tab_text_color',
      'tabs_accent_color',
      'detail_color',
    ],
  ],
  [
    'Reactions',
    [
      'reaction_overlay_color_1',
      'reaction_overlay_color_2',
      'reaction_overlay_color_3',
      'reaction_overlay_color_4',
      '_stream_reaction_icon_pos',
      'button_active_reaction_bg',
      'button_reaction_bg',
    ],
  ],
  ['Ratings', ['rating_bg_color', 'rating_text_color', 'rating_star_color']],
  [
    'Spaces',
    [
      'spaces_moderator_bg_color',
      'spaces_moderator_border_color',
      'spaces_user_border_color',
      'spaces_user_bg_color',
      'spaces_other_users_border_color',
      'spaces_other_users_bg_color',
      'spaces_group_bg_color',
      'spaces_group_border_color',
      'spaces_slider_elements_color',
      'spaces_slider_ball_color',
      'spaces_tooltip_bg',
      'spaces_tooltip_text_color',
      'spaces_exit_button_color',
    ],
  ],
  [
    'Mobile',
    [
      'mobile_menu_text_color',
      'mobile_menu_bg',
      'mobile_menu_burger_icon_color',
      'mobile_menu_burger_icon_bg',
    ],
  ],
  [
    'Event series',
    [
      'featured_event_accent',
      'event_series_bg_gradient_1',
      'event_series_bg_gradient_2',
      'event_series_tile',
      'event_listing_custom_title',
      'event_image',
      'image_below_event_series',
      'event_image_thumbnail',
      'sidebar_tab_active_background_color',
      'sidebar_tab_inactive_background_color',
      'sidebar_background_color',
      'sidebar_gradient1',
      'sidebar_gradient2',
      'sidebar_tab_text_active',
      'sidebar_tab_text_inactive',
      'selected_page_title_color',
      'event_series_tile_text',
    ],
  ],
  ['Stickers', null],
  [
    'Player',
    [
      'plyr_bckg_button_color',
      'plyr_icon_color',
      'bottom_img_file',
      'bottom_img_mobile_file',
      'hybrid_player_image',
      'player_placeholder_image',
    ],
  ],
  ['Rich text', ['terms_and_conditions_disclaimer', 'disclaimer']],
];

export const CHAT_ROOM_TYPES = {
  DEFAULT: 'default',
  SINGLE_GROUP: 'single_group',
  AGENDA_ITEM: 'agenda_item',
  GROUP: 'group',
  PRIVATE: 'private',
};

export const ASSOCIATION_CHAIN_MAPPER = {
  AgendaItem: id => routes.agendaItems(id),
  Event: id => routes.event(id),
  User: id => routes.user(id),
  Attendance: id => routes.attendance(id),
  ChatMessage: id => routes.chatMessage(id),
  Rating: id => routes.rating(id),
  Banner: id => routes.banner(id),
  ExploreItem: id => routes.exploreItems(id),
  Product: id => routes.product(id),
  Sponsor: id => routes.sponsor(id),
  Player: id => routes.player(id),
  Document: id => routes.document(id),
  Poll: id => routes.poll(id),
  ChatQuestion: id => routes.chatQuestion(id),
  event_custom_theme: eventId => `${routes.event(eventId)}?&tab=theme`,
};

export const ASSOCIATION_CHAIN_DICTIONARY = {
  event_custom_theme: 'EventCustomTheme',
};

export const AWS_ENCODING_OPTIONS = ['HD-1080p', 'HD-720p', 'SD-540p'].map(
  el => ({
    label: el,
    value: el,
  }),
);

export const AWS_REGION_OPTIONS = [
  ['Europe (Ireland)', 'eu-west-1'],
  ['Europe (Frankfurt)', 'eu-central-1'],
  ['Europe (London)', 'eu-west-2'],
  ['Europe (Stockholm)', 'eu-north-1'],
  ['US East (N. Virginia)', 'us-east-1'],
  ['US West (Oregon)', 'us-west-2'],
  ['Asia Pacific (Seoul)', 'ap-northeast-2'],
  ['Asia Pacific (Sydney)', 'ap-southeast-2'],
  ['Asia Pacific (Tokyo)', 'ap-northeast-1'],
].map(el => ({
  label: `${el[0]} (${el[1]})`,
  value: el[1],
}));

export const VISIBILITY_SCOPES = [
  { label: 'Sign up only', value: 'sign_up' },
  { label: 'Sign in only', value: 'sign_in' },
  { label: 'Sign up and sign in', value: 'sign_up_and_sign_in' },
];

export const LOGIN_METHODS = [
  { label: 'With Email', value: 'with_email' },
  { label: 'With Member ID', value: 'with_member_id' },
  { label: 'With Email or Member ID', value: 'with_email_or_member_id' },
  { label: 'With Email & Member ID', value: 'with_email_and_member_id' },
];

export const PASSWORD_EXPIRED_ERROR =
  'Your password is expired. Please renew your password.';

export const SPACES_DEFAULT_BACKGROUNDS = [
  { label: 'Not selected', value: 'not_selected' },
  { label: 'Blue', value: 'blue' },
  { label: 'Red', value: 'red' },
  { label: 'Orange', value: 'orange' },
];

export const SPACES_EVENT_DEFAULT_BACKGROUNDS = [
  { label: 'Not selected', value: 'not_selected' },
  { label: 'Blue', value: 'blue' },
  { label: 'Red', value: 'red' },
  { label: 'Orange', value: 'orange' },
];

export const TIME_ZONES = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Algiers',
  'Africa/Bissau',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/El_Aaiun',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Khartoum',
  'Africa/Lagos',
  'Africa/Maputo',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Nuuk',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Johns',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
];

export const FEATURE_SET_DICTIONARY = {
  agora: ['agoraio'],
  addevent: ['add_event_calendar'],
  banners: ['banners_menu', routes.main.banners, routes.banner()],
  batch_user_submission: ['batch_user_submission'],
  breakouts: [
    'event_custom_theme.show_breakout_rooms_on_event_brief',
    'ai_br_type_switch',
  ],
  c_native_player: [
    'players_menu',
    'aws_hls_stream',
    routes.main.players,
    routes.player(),
  ],
  custom_registration_fields: ['add_RF'],
  chat_groups: [
    'chat_group_enabled',
    'chat_rooms_menu',
    routes.main.chatRooms,
    routes.chatRoom(),
  ],
  chat_images: ['chat_images'],
  chat_post_event: ['chat_post_event'],
  chat_stickers: ['chat_stickers'],
  chat_selfie: ['chat_selfie'],
  custom_no_of_devices: ['number_of_allowed_devices'], // todo
  daybreakers: ['daybreakers'],
  domain_settings: ['domain_settings'],
  eventsforce: ['any_eventsforce_field'],
  event_listing_button: ['event_listing_button'],
  feed_reactions: ['event_reactions_enabled'],
  help_url: ['help_url'],
  hybrid: ['any_hybrid_field'],
  hidden_events: ['hide_event_for_unregistered'],
  hubspot: ['any_hubspot_field'],
  inteprefy: ['interprefy'],
  linked_events: ['any_linked_events_field'],
  login_method: ['login_method'],
  logo_hide: ['logo_hide'],
  mailchimp: ['mailchimp'],
  multi_market_events: [
    'multi_market_menu',
    'multi_market_events_group_id',
    routes.main.multiMarket,
    routes.multiMarket(),
  ],
  marketo: ['any_marketo_field'],
  networking: [
    'networking_available',
    'hide_network_signup_form',
    'form_preticked_networking',
    'opt_in_networking',
  ],
  one_to_one_chat: ['one_to_one_chat'],
  products: [
    'product_panel_enabled',
    'products_menu',
    routes.main.products,
    routes.product(),
  ],
  public_events: ['public'],
  post_event_lock: ['event_locked'],
  recaptcha: ['recaptcha_enabled'],
  slate_images: [
    'show_slate_image',
    'slate_image',
    'end_show_slate_image',
    'end_slate_image',
    'technical_show_slate_image',
    'technical_slate_image',
  ],
  show_add_profile_picture: ['show_add_profile_picture'],
  show_login_form: ['show_login_form'],
  show_registration_form: ['show_registration_form'],
  spaces: ['spaces'],
  stripe: ['stripe'],
  sso: ['sso_enabled'],
  user_joined_chat_msg: ['user_joined_chat'],
  q_a: [
    'q_and_a_enabled',
    'q_and_a_moderated',
    'q_and_a_private_mode',
    'q_and_a_menu',
    routes.main.chatQuestions,
    routes.chatQuestion(),
  ],
  custom_terms_and_privacy: [
    'reg_terms_and_conditions_url',
    'reg_privacy_policy_url',
  ],
  stages: ['stages_menu', routes.main.stages, routes.stage()],
};

export const FEATURE_SET_BANNABLE_ROUTES = [
  routes.main.chatQuestions,
  routes.chatQuestion(),
  routes.main.products,
  routes.product(),
  routes.main.multiMarket,
  routes.multiMarket(),
  routes.main.banners,
  routes.banner(),
  routes.main.players,
  routes.player(),
  routes.main.chatRooms,
  routes.chatRoom(),
  routes.main.stages,
  routes.stage(),
];

export const MARKETO_SYNC_FREQUENCY = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '1 hour', value: 60 },
  { label: '4 hours', value: 240 },
  { label: '24 hours', value: 1440 },
];

export const ITALICS_UNAVAILABLE = [
  'Changa',
  'Comfortaa',
  'Crimson Pro',
  'Dancing Script',
  'Dosis',
  'EB Garamond',
  'Exo',
  'Faustina',
  'Fira Code',
  'Heebo',
  'Hepta Slab',
  'Inconsolata',
  'Inter',
  'Josefin Sans',
  'Jura',
  'Kreon',
  'Lemonada',
  'Literata',
  'Lora',
  'Manrope',
  'Manuale',
  'Markazi Text',
  'Maven Pro',
  'Muli',
  'Orbitron',
  'Oswald',
  'Playfair Display',
  'Podkova',
  'Quicksand',
  'Roboto Slab',
  'Rokkitt',
  'Rosario',
  'Ruda',
  'Spartan',
  'Vollkorn',
  'Work Sans',
  'Yanone Kaffeesatz',
];

export const EVENT_PUBLIC_WARN_MSG = `Making your event public limits some of the features available to you.
  You will not collect attendee data, therefore, networking, chatrooms, breakout allocations and Spaces will not function,
  and your analytics will be largely anonymised. If you wish to give everyone access whilst retaining all features and analytics,
  we recommend using auto accept instead.`;

export const NETOWRKING_NOT_SET = 'null';

export const NETWORKING_ENABLE_OPTIONS = [
  { label: 'Not set', value: NETOWRKING_NOT_SET },
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];
export const ADDEVENT_CALENDAR_REMINDER = [
  { label: '15 minutes', value: '15' },
  { label: '30 minutes', value: '30' },
  { label: '60 minutes', value: '60' },
];

export const ADDEVENT_CALENDAR_DEFAULT_VALUES = {
  reminder: '30',
  email: 'mailman@cplus.live',
};

export const ROLES_LABELS = [
  { value: 'user', label: 'User' },
  { value: 'moderator', label: 'Moderator' },
  { value: 'event_manager', label: 'Event manager' },
  { value: 'editor', label: 'Editor' },
  { value: 'analyst', label: 'Analyst' },
  { value: 'admin', label: 'Admin' },
  { value: 'lite_shared_org_admin', label: 'Lite shared org admin' },
];

export const STICKER_NAMES = [
  'Bang',
  'Boom',
  'Cool',
  'Lol',
  'Love',
  'No love',
  'Omg',
  'Wow',
  'Yeah',
];

export const STICKER_SELECT_OPTIONS = [
  {
    label: 'Bang',
    value: { image: Bang, mask: BangMask },
  },
  {
    label: 'Boom',
    value: { image: Boom, mask: BoomMask },
  },
  {
    label: 'Cool',
    value: { image: Cool, mask: CoolMask },
  },
  {
    label: 'Lol',
    value: { image: Lol, mask: LolMask },
  },
  {
    label: 'Love',
    value: { image: Love, mask: LoveMask },
  },
  {
    label: 'No love',
    value: { image: NoLove, mask: NoLoveMask },
  },
  {
    label: 'Omg',
    value: { image: Omg, mask: OmgMask },
  },
  {
    label: 'Wow',
    value: { image: Wow, mask: WowMask },
  },
  {
    label: 'Yeah',
    value: { image: Yeah, mask: YeahMask },
  },
];
export const BUTTON_ICON_TYPES = {
  CREATE: 'create',
  DELETE: 'delete',
  EXPORT: 'export',
  BACK: 'back',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
};
export const PLAYER_LABELS = {
  social_live: 'External player',
  aws_hls_stream: `${envMappingType} Video Player`,
};

export const ORG_FIELD_TYPES = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Select',
    value: 'select',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
];

export const STRIPE_CURRENCIES = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'STD',
  'SZL',
  'THB',
  'TJS',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
];
export const ALL_FILE_EXTENSIONS =
  // eslint-disable-next-line max-len
  '.pdf,.wav,.mp3,.mp4,.3gp,.avi,.mpeg,.txt,.jpg,.gif,.jpeg,.png,.key,.pez,.gslides,.ppt,.pptx,.numbers,.stc,.sxc,.ots,.gsheet,.csv,.xlsx,.xls,.xlsx,.txt,.gdoc,.doc,.docx,.pages,.odt,.zip';

export const MAILER_TYPE = {
  REMINDER: 'remind',
  ACCESS_CONFIRMATION: 'attendance_accepted',
  SIGN_UP_CONFIRMATION: 'attendance_requested',
  CUSTOM: 'custom',
};

export const MAILER_TYPE_LABELS = {
  remind: 'Reminder',
  attendance_accepted: 'Access confirmation',
  attendance_requested: 'Sign up confirmation',
  custom: 'Custom',
};

export const MAILER_TYPE_INFO = {
  REMINDER:
    // eslint-disable-next-line max-len
    'Send a reminder email to your attendees before the event day (please select the audience you would like to target). These can be scheduled for a specific time and date.',
  ACCESS_CONFIRMATION:
    // eslint-disable-next-line max-len
    'Send a confirmation email to users whose registration has been accepted, this means they can now access the event page. Once set up, this will automatically be sent to users as they are moved to accepted state.',
  SIGN_UP_CONFIRMATION:
    // eslint-disable-next-line max-len
    'Send an email to users who sign up on the platform confirming that their registration has been received. Once set up, this will automatically be sent to all users who request to attend.',
  CUSTOM:
    'Send a custom email out to the selected audience, for example, pre-event information.',
};

export const MAILER_TYPE_OPTIONS = Object.entries(MAILER_TYPE).map(
  ([key, value]) => ({
    label:
      key[0].toUpperCase() +
      key
        .slice(1)
        .replace(/_|-|\./g, ' ')
        .toLowerCase(),
    value,
    info: MAILER_TYPE_INFO[key],
  }),
);

export const MAILER_AUDIENCE_LABELS = {
  user_list: 'LIST OF USERS',
  all_attendances: 'ALL',
  accepted_attendances: 'ACCEPTED',
  requsted_attendances: 'REQUESTED',
};

export const MAILER_AUDIENCE_INFO = {
  SELECTED_REGISTRATIONS: 'Send your email out to selected registered users.',
  ALL_REGISTRATIONS:
    'Send your email out to all users who have registered for your event, whether they have accepted, requested or denied status.',
  ACCEPTED_REGISTRATIONS:
    'Send your email out to those whose registrations have been accepted, this means they have access to the event page.',
  REQUESTED_REGISTRATIONS:
    // eslint-disable-next-line max-len
    'Send your email out to those who have registered for your event, but who have not yet been accepted, this means they cannot access the event page yet.',
};

export const MAILER_AUDIENCE = {
  SELECTED_REGISTRATIONS: 'user_list',
  ALL_REGISTRATIONS: 'all_attendances',
  ACCEPTED_REGISTRATIONS: 'accepted_attendances',
  REQUESTED_REGISTRATIONS: 'requsted_attendances',
};

export const MAILER_AUDIENCE_OPTIONS = Object.entries(MAILER_AUDIENCE).map(
  ([key, value]) => ({
    label:
      key[0].toUpperCase() +
      key
        .slice(1)
        .replace(/_|-|\./g, ' ')
        .toLowerCase(),
    value,
    info: MAILER_AUDIENCE_INFO[key],
  }),
);

export const MAILER_STATUS = {
  ENQUEUED: 'enqueued',
  PROCESSING: 'processing',
  EXECUTED: 'executed',
};

export const MAILER_BUTTON_COPY = {
  enqueued: 'DEACTIVATE',
  processing: 'DEACTIVATE',
  executed: 'SENT',
};

export const HLS_STATUSES = {
  CREATED: 'CREATE_COMPLETE',
  CREATING: 'CREATE_IN_PROGRESS',
  STARTING: 'STARTING',
  IDLE: 'IDLE',
  STOPPING: 'STOPPING',
  RUNNING: 'RUNNING',
};
export const DEFAULT_TAB = 'tab';
export const PLAYER_DESTINY_TYPES = {
  EVENT: 'Event',
  AI: 'AgendaItem',
  GROUP: 'Group',
};

export const ACTIVE_PLAYER_OPTIONS = [
  { label: 'Primary', value: false },
  { label: 'Backup', value: true },
];

export const PUBLISHING_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
};

export const PLAN_TYPES = {
  LIGHT: 1,
  FULL: 2,
  ENTERPRISE: 3,
};

export const PLAN_ACTION = {
  PAY: 1,
  REDIRECT: 2,
};

export const PLANS_CARDS_CONFIG = [
  {
    title: 'C+ Light',
    description: 'Great for lorem ipsum',
    price: 900,
    key: PLAN_TYPES.LIGHT,
    paymentType: 'monthly',
    info: [
      'Unlimited live events',
      '1 admin user account',
      'Up to 5,000 attendees',
    ],
    action: PLAN_ACTION.PAY,
  },
  {
    title: 'C+ Full',
    description: 'Great for lorem ipsum',
    price: 1500,
    key: PLAN_TYPES.FULL,
    paymentType: 'permament',
    info: [
      'Everything in C+ Light',
      '3 admin user accounts',
      'Up to 10,000 attendees',
    ],
    action: PLAN_ACTION.REDIRECT,
    actionDetails: {
      href: 'https://cplus.live?contact=true',
    },
  },
  {
    title: 'C+ Enterprise',
    description: 'The complete package',
    price: 3000,
    key: PLAN_TYPES.ENTERPRISE,
    paymentType: 'permament',
    info: [
      'Everything in C+ Full',
      'Unlimited admin user accounts',
      'Dedicated support',
    ],
    action: PLAN_ACTION.REDIRECT,
    actionDetails: {
      href: 'https://cplus.live?contact=true',
    },
  },
];
