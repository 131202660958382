import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-final-form';
import Icon from '@mdi/react';
import { mdiCloudUpload, mdiWindowClose } from '@mdi/js';
import prettyBytes from 'pretty-bytes';
import { ellipsisInMiddle } from '@utils/stylingUtils';
import { getFilenameFromUrl } from '@utils/fileUtils';
import { useTheme, Box, Stack, Button } from '@mui/material';
import Cropper from '@components/Cropper';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FieldTooltip from '@components/FieldTooltip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { inject, observer } from 'mobx-react';
import { capitalize } from '@utils/otherUtils';
import {
  Container,
  Dropzone,
  DropzoneText,
  IconWrapper,
  Preview,
  PreviewImage,
  PreviewImgWrapper,
  Size,
  Title,
} from './style';

const FileUploadInput = ({
  meta,
  input: { onChange, value, name },
  label,
  required,
  additionalInfo,
  aspectRatio,
  specificImageExtractor,
  crop,
  disabled,
  cropPropsSetter,
  maxFileSize,
  acceptExtensions,
  infoText,
  ignorePristineTouched,
  fileType = 'image',
  stackDirection = 'row',
  modalStore: { openModal, closeModal },
  notAvailableInSP,
}) => {
  const [choosen, setChoosen] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const form = useForm();
  const theme = useTheme();

  const { url: initPreviewUrl } = useMemo(
    () =>
      typeof specificImageExtractor === 'function'
        ? specificImageExtractor(value)
        : value,
    [specificImageExtractor, value],
  );

  const maxSize = maxFileSize || 50000000; // 50 Mb limit

  const updateState = useCallback(
    (file, croppingSettings, croppedPreviewImage) => {
      const imgValue = file || null;
      cropPropsSetter?.(form, null);
      setChoosen(null);

      if (croppingSettings) {
        closeModal();
        onChange(imgValue);
        setChoosen(croppedPreviewImage);
        cropPropsSetter(form, croppingSettings);
      } else {
        onChange(imgValue);
        setChoosen(imgValue);
      }
    },
    [onChange, setChoosen, cropPropsSetter],
  );

  const handleCropping = file => {
    openModal({
      content: (
        <Cropper
          image={file}
          onAcceptCallback={updateState}
          onRejectCallback={closeModal}
          aspectRatio={aspectRatio}
        />
      ),
    });
  };

  const handleChange = useCallback(
    ([file] = []) => {
      if (file && file.size > maxSize) {
        setUploadError(
          `File is larger than ${capitalize(prettyBytes(maxFileSize)).replace(
            /\s/g,
            '',
          )}`,
        );
        return;
      }

      setUploadError(null);

      if (crop && file) {
        handleCropping(file);
        return;
      }

      updateState(file);
    },
    [crop, updateState, maxSize, setUploadError],
  );

  const handleFormUpdates = () => {
    if (initPreviewUrl && fileType !== 'image') {
      setChoosen({
        name: getFilenameFromUrl(initPreviewUrl),
      });

      return;
    }

    if (initPreviewUrl) {
      setChoosen(null);
      return;
    }

    if (!(value instanceof File) && !initPreviewUrl) {
      setChoosen(null);
    }
  };

  useEffect(() => {
    handleFormUpdates();
  }, [value, initPreviewUrl, fileType]);

  const onDropRejected = rejectedFiles => {
    if (rejectedFiles[0]?.errors[0]?.code === 'file-too-large') {
      if (maxFileSize && maxFileSize < 1000000) {
        setUploadError(
          `File is larger than ${capitalize(prettyBytes(maxFileSize)).replace(
            /\s/g,
            '',
          )}`,
        );
        return;
      }
      setUploadError(rejectedFiles[0]?.errors[0]?.message);
      return;
    }
    if (rejectedFiles[0]?.errors[0]?.code === 'file-invalid-type') {
      setUploadError('Incorrect file extension!');
    }
  };

  const onDropAccepted = useCallback(
    acceptedFiles => {
      setUploadError(null);
      handleChange(acceptedFiles);
    },
    [handleChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: maxFileSize, // 50 Mb limit
    noClick: true,
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
  });

  const choosenImageBlob = useMemo(() => {
    if (fileType !== 'image' || !choosen) return null;

    if (crop) return choosen;

    return URL.createObjectURL(choosen);
  }, [choosen, fileType, crop]);

  const error =
    (meta.touched || !meta.pristine || ignorePristineTouched) &&
    (meta.error || meta.submitError);

  return (
    <Box position="relative">
      {label && (
        <FormLabel required={required} error={Boolean(error || uploadError)}>
          {label}
        </FormLabel>
      )}
      <FieldTooltip
        infoText={infoText}
        corner
        sx={{ top: '5px' }}
        notAvailableInSP={notAvailableInSP}
      />

      <Stack direction={stackDirection} gap="20px" mt="10px">
        <Container
          alignItems="flex-start"
          flex="1 0 0"
          order={stackDirection === 'row' ? 1 : 2}
        >
          <Box flex="1">
            {!choosen && !initPreviewUrl ? (
              <Box>
                <Dropzone
                  {...getRootProps()}
                  active={isDragActive}
                  disabled={disabled}
                >
                  <input
                    {...getInputProps()}
                    disabled={disabled}
                    accept={acceptExtensions || 'image/*'}
                  />
                  <Icon
                    color={
                      isDragActive
                        ? theme.palette.primary.main
                        : theme.palette.grey[600]
                    }
                    path={mdiCloudUpload}
                    size={2}
                  />
                  <DropzoneText active={isDragActive}>
                    Drag and drop
                  </DropzoneText>
                </Dropzone>
                {uploadError && (
                  <FormHelperText error>{uploadError}</FormHelperText>
                )}
                {error && <FormHelperText error>{error}</FormHelperText>}
              </Box>
            ) : (
              <Box>
                {(choosenImageBlob || initPreviewUrl) && fileType === 'image' && (
                  <Preview>
                    <PreviewImgWrapper label={label}>
                      <PreviewImage
                        label={label}
                        src={choosenImageBlob || initPreviewUrl}
                      />
                      {!disabled && (
                        <IconWrapper
                          onClick={() => handleChange([])}
                          position="corner"
                        >
                          <Icon
                            color={theme.colors.text}
                            path={mdiWindowClose}
                            size={0.6}
                          />
                        </IconWrapper>
                      )}
                    </PreviewImgWrapper>
                  </Preview>
                )}
                {choosen && fileType !== 'image' && (
                  <Preview>
                    <IconWrapper onClick={() => handleChange([])}>
                      <Icon
                        color={theme.colors.text}
                        path={mdiWindowClose}
                        size={0.6}
                      />
                    </IconWrapper>
                    <Title>{ellipsisInMiddle(choosen?.name, 20)}</Title>
                    {choosen?.size && <Size>{prettyBytes(choosen?.size)}</Size>}
                  </Preview>
                )}
                {uploadError && (
                  <FormHelperText error>{uploadError}</FormHelperText>
                )}
                {error && <FormHelperText error>{error}</FormHelperText>}
              </Box>
            )}
          </Box>
        </Container>
        <Box flex="1 0 0" order={stackDirection === 'row' ? 2 : 1}>
          <Button
            component="label"
            htmlFor={name}
            variant="contained"
            disabled={disabled}
            startIcon={<CloudUploadIcon />}
          >
            UPLOAD
            <input
              id={name}
              hidden
              type="file"
              value=""
              disabled={disabled}
              accept={acceptExtensions || 'image/*'}
              onChange={e => handleChange(e.target.files)}
            />
          </Button>
          {additionalInfo && (
            <FormHelperText
              component="div"
              error={Boolean(error || uploadError)}
            >
              {additionalInfo}
            </FormHelperText>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default inject('modalStore')(observer(FileUploadInput));
