import ResourcesStore from '@stores/resourcesStore';
import { getFileFromLink } from '@utils/fileUtils';

export class DocumentCloneService {
  static prepareDocument = async docId => {
    const doc = await ResourcesStore.getDocument({ id: docId });

    const imgs = this.fetchDocumentImages(doc);
    const { id, ...strippedDoc } = doc;

    return {
      ...strippedDoc,
      custom_thumbnail: await imgs.custom_thumbnail,
      file: await imgs.file,
    };
  };

  static fetchDocumentImages = doc => {
    return {
      custom_thumbnail: getFileFromLink(doc.custom_thumbnail?.url),
      file: getFileFromLink(doc.file?.url),
    };
  };
}
