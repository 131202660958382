import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Tabs } from '@mui/material';
import * as Styled from '@pages/analytics/components/Sidebar/style';
import Overview from '@pages/analytics/components/Sidebar/Overview';
import Audience from '@pages/analytics/components/Sidebar/Audience';
import Attendee from '@pages/analytics/components/Sidebar/Attendee';
import Player from '@pages/analytics/components/Player';

export const TABS = {
  OVERVIEW: 'OVERVIEW',
  AUDIENCE: 'AUDIENCE',
};

const AnalyticsSidebar = ({
  analyticsStore: {
    selectedTab,
    selectTab,
    selectedAttendeeId,
    player,
    setVideoPlayer,
    isPlaying,
    setIsPlaying,
    videoPip,
    toggleVideoPip,
    isPlayerLoading,
  },
}) => {
  const selectTabHandler = useCallback((_e, tab) => {
    selectTab(tab);
  }, []);

  return (
    <Styled.Wrapper width={[1, 1 / 4]}>
      <Player
        mb={2}
        url={player?.url}
        type={player?.type}
        loading={isPlayerLoading}
        setPlayerInstance={setVideoPlayer}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        videoPip={videoPip}
        toggleVideoPip={toggleVideoPip}
        show={videoPip}
      />
      <Styled.ScrollBox>
        <Styled.TitleBox>
          <Tabs
            value={selectedTab}
            onChange={selectTabHandler}
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{ width: '100%' }}
          >
            <Styled.Tab label={TABS.OVERVIEW} value={TABS.OVERVIEW} />
            <Styled.Tab label={TABS.AUDIENCE} value={TABS.AUDIENCE} />
          </Tabs>
        </Styled.TitleBox>
        {selectedTab === TABS.OVERVIEW && <Overview />}
        {selectedTab === TABS.AUDIENCE && selectedAttendeeId && <Attendee />}
        {selectedTab === TABS.AUDIENCE && !selectedAttendeeId && <Audience />}
      </Styled.ScrollBox>
    </Styled.Wrapper>
  );
};

AnalyticsSidebar.propTypes = {
  analyticsStore: PropTypes.shape({
    selectedEvent: PropTypes.object,
  }).isRequired,
};

export default inject('analyticsStore')(observer(AnalyticsSidebar));
