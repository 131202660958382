import {
  LogsPage,
  RatingFormPage,
  RatingsVotes,
  RatingsPage,
  BannersPage,
  BannerFormPage,
  ExploreItemsPage,
  ExploreItemFormPage,
  AnalyticsPage,
  AnalyticsReportPage,
  AgendaFormPage,
  AgendaPage,
  BreakoutFormPage,
  BreakoutPage,
  AttendanceFormPage,
  AttendanceExportPage,
  AttendancesPage,
  ChatAbusesPage,
  ChatMessagesPage,
  ChatQuestionFormPage,
  ChatQuestionsPage,
  ChatRoomFormPage,
  ChatRoomsPage,
  ChatMessageFormPage,
  DocumentFormPage,
  DocumentsPage,
  GlobalMessageFormPage,
  GlobalMessagesPage,
  EventFormPage,
  EventRefreshPage,
  EventsPage,
  GdprPage,
  GdprViewUsersPage,
  GroupFormPage,
  GroupsPage,
  MultiMarketGroupFormPage,
  MultiMarketGroupsPage,
  OrganizationEditPage,
  OrganizationsPage,
  PlayerFormPage,
  PlayersPage,
  PollAnswerUsersPage,
  PollFormPage,
  PollsPage,
  ProductsPage,
  ProductFormPage,
  ProfileFormPage,
  ReportsPage,
  SpeakerFormPage,
  SpeakersPage,
  SponsorFormPage,
  SponsorsPage,
  TranslationFormPage,
  TranslationsPage,
  UserFormPage,
  UsersPage,
  EmailFormPage,
  EventPayment,
  StagesPage,
  StagesForm,
} from '@app/lazyLoadedPages';
import routes from '@routes';
import DashboardPage from '@pages/dashboard/DashboardPage';
import GenericLandingPage from '@pages/genericLandingPage';
import UnauthorizedPage from '@pages/unauthorized/index';

const config = [
  {
    Component: DashboardPage,
    exact: true,
    path: routes.main.dashboard,
    secure: true,
  },
  {
    Component: GenericLandingPage,
    exact: true,
    path: '/',
    secure: true,
  },
  {
    Component: UnauthorizedPage,
    exact: true,
    path: routes.unauthorized,
    secure: true,
  },
  {
    Component: LogsPage,
    exact: false,
    path: routes.main.logs,
    secure: true,
  },
  {
    Component: RatingsPage,
    exact: true,
    path: routes.main.ratings,
    secure: true,
  },
  {
    Component: RatingFormPage,
    exact: true,
    path: routes.rating(),
    secure: true,
  },
  {
    Component: RatingsVotes,
    exact: true,
    path: routes.main.ratingsVotes,
    secure: true,
  },
  {
    Component: BannerFormPage,
    exact: true,
    path: routes.banner(),
    secure: true,
  },
  {
    Component: BannersPage,
    exact: true,
    path: routes.main.banners,
    secure: true,
  },
  {
    Component: ExploreItemFormPage,
    exact: true,
    path: routes.exploreItems(),
    secure: true,
  },
  {
    Component: ExploreItemsPage,
    exact: true,
    path: routes.main.exploreItems,
    secure: true,
  },
  {
    Component: AnalyticsPage,
    exact: true,
    path: routes.main.analytics(),
    secure: true,
  },
  {
    Component: AnalyticsReportPage,
    exact: true,
    path: routes.main.analytics_report(),
    secure: true,
  },
  {
    Component: AgendaFormPage,
    exact: true,
    path: routes.agendaItems(),
    secure: true,
  },
  {
    Component: AgendaPage,
    exact: true,
    path: routes.main.agenda,
    secure: true,
  },
  {
    Component: BreakoutFormPage,
    exact: true,
    path: routes.breakoutRooms(),
    secure: true,
  },
  {
    Component: BreakoutPage,
    exact: true,
    path: routes.main.breakout,
    secure: true,
  },
  {
    Component: AttendanceFormPage,
    exact: true,
    path: routes.attendance(),
    secure: true,
  },
  {
    Component: AttendanceExportPage,
    exact: true,
    path: routes.main.attendances_export,
    secure: true,
  },
  {
    Component: AttendancesPage,
    exact: true,
    path: routes.main.attendances,
    secure: true,
  },
  {
    Component: ChatAbusesPage,
    exact: true,
    path: routes.main.chatAbuses,
    secure: true,
  },
  {
    Component: ChatMessagesPage,
    exact: true,
    path: routes.main.chatMessages,
    secure: true,
  },
  {
    Component: ChatMessageFormPage,
    exact: true,
    path: routes.chatMessage(),
    secure: true,
  },
  {
    Component: ChatQuestionsPage,
    exact: true,
    path: routes.main.chatQuestions,
    secure: true,
  },
  {
    Component: ChatQuestionFormPage,
    exact: true,
    path: routes.chatQuestion(),
    secure: true,
  },
  {
    Component: ChatRoomFormPage,
    exact: true,
    path: routes.chatRoom(),
    secure: true,
  },
  {
    Component: ChatRoomsPage,
    exact: true,
    path: routes.main.chatRooms,
    secure: true,
  },
  {
    Component: DocumentFormPage,
    exact: true,
    path: routes.document(),
    secure: true,
  },
  {
    Component: DocumentsPage,
    exact: true,
    path: routes.main.documents,
    secure: true,
  },
  {
    Component: GlobalMessageFormPage,
    exact: true,
    path: routes.globalMessage(),
    secure: true,
  },
  {
    Component: GlobalMessagesPage,
    exact: true,
    path: routes.main.globalMessages,
    secure: true,
  },
  {
    Component: EventFormPage,
    exact: true,
    path: routes.event(),
    secure: true,
  },
  {
    Component: EventRefreshPage,
    exact: true,
    path: routes.main.eventRefresh,
    secure: true,
  },
  {
    Component: EventsPage,
    exact: true,
    path: routes.main.events,
    secure: true,
  },
  {
    Component: GdprPage,
    exact: true,
    path: routes.main.gdpr.eventSelect,
    secure: true,
  },
  {
    Component: GdprViewUsersPage,
    exact: true,
    path: routes.main.gdpr.viewUsers,
    secure: true,
  },

  {
    Component: GroupFormPage,
    exact: true,
    path: routes.group(),
    secure: true,
  },
  {
    Component: GroupsPage,
    exact: true,
    path: routes.main.groups,
    secure: true,
  },
  {
    Component: MultiMarketGroupFormPage,
    exact: true,
    path: routes.multiMarket(),
    secure: true,
  },
  {
    Component: MultiMarketGroupsPage,
    exact: true,
    path: routes.main.multiMarket,
    secure: true,
  },
  {
    Component: OrganizationEditPage,
    exact: true,
    path: routes.organization(),
    secure: true,
  },
  {
    Component: OrganizationsPage,
    exact: true,
    path: routes.main.organizations,
    secure: true,
  },
  {
    Component: PlayerFormPage,
    exact: true,
    path: routes.player(),
    secure: true,
  },
  {
    Component: PlayersPage,
    exact: true,
    path: routes.main.players,
    secure: true,
  },
  {
    Component: PollFormPage,
    exact: true,
    path: routes.poll(),
    secure: true,
  },
  {
    Component: PollsPage,
    exact: true,
    path: routes.main.polls,
    secure: true,
  },
  {
    Component: PollAnswerUsersPage,
    exact: true,
    path: routes.main.pollAnswerUsers,
    secure: true,
  },
  {
    Component: ProductFormPage,
    exact: true,
    path: routes.product(),
    secure: true,
  },
  {
    Component: ProductsPage,
    exact: true,
    path: routes.main.products,
    secure: true,
  },
  {
    Component: ProfileFormPage,
    exact: true,
    path: routes.main.profile,
    secure: true,
  },
  {
    Component: ReportsPage,
    exact: true,
    path: routes.main.reports(),
    secure: true,
  },
  {
    Component: SpeakerFormPage,
    exact: true,
    path: routes.presenter(),
    secure: true,
  },
  {
    Component: SpeakersPage,
    exact: true,
    path: routes.main.presenters,
    secure: true,
  },
  {
    Component: SponsorFormPage,
    exact: true,
    path: routes.sponsor(),
    secure: true,
  },
  {
    Component: SponsorsPage,
    exact: true,
    path: routes.main.sponsors,
    secure: true,
  },
  {
    Component: TranslationFormPage,
    exact: true,
    path: routes.translation(),
    secure: true,
  },
  {
    Component: TranslationsPage,
    exact: true,
    path: routes.main.translations,
    secure: true,
  },
  {
    Component: UserFormPage,
    exact: true,
    path: routes.user(),
    secure: true,
  },
  {
    Component: UsersPage,
    exact: true,
    path: routes.main.users,
    secure: true,
  },
  {
    Component: EmailFormPage,
    exact: true,
    path: routes.email(),
    secure: true,
  },
  {
    Component: EventPayment,
    exact: false,
    path: routes.main.eventPayment(),
  },
  {
    Component: StagesPage,
    exact: true,
    path: routes.main.stages,
    secure: true,
  },
  {
    Component: StagesForm,
    exact: true,
    path: routes.stage(),
    secure: true,
  },
];

export default config;
