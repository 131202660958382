import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  background: ${props =>
    get(props, 'style.background') || props.theme.colors.error};
  border-radius: 4px;
  width: ${props => (props.ellipsis ? 'auto' : 'fit-content')};
  height: 24px;
  padding: 0 8px;
  white-space: nowrap;
`;

const Label = styled(Box)`
  color: ${props => get(props, 'style.color') || '#fff'};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const Badge = ({ children, style, ellipsis }) => {
  return (
    <Container ellipsis={ellipsis} style={style}>
      <Label style={style}>{children}</Label>
    </Container>
  );
};

export default Badge;
