/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { ErrorOutline, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Loader } from '@components/Loader';
import {
  Wrapper,
  MuteButton,
  NoVideoPlaceholder,
} from '@pages/analytics/components/Player/style';

const playerOptions = {
  vimeo: {
    autoplay: true,
    background: true,
    controls: false,
  },
};

const Player = ({
  isPlaying,
  setIsPlaying,
  loading,
  mb,
  setPlayerInstance,
  url,
  videoPip,
  show,
}) => {
  const playerWrapper = useRef(null);
  const player = useRef(null);
  const [playerHeight, setPlayerHeight] = useState(0);
  const [muted, setMuted] = useState(true);
  const [showMute, setShowMute] = useState(false);
  const [error, setError] = useState(0);
  const [theUrl, setUrl] = useState(url);

  useEffect(() => {
    setUrl(url);
  }, [url]);

  useEffect(() => {
    const width = playerWrapper.current.offsetWidth;

    setPlayerHeight((width / 16) * 9);
    if (setPlayerInstance && typeof setPlayerInstance === 'function') {
      setPlayerInstance(player.current);
    }

    return () => {
      setError(0);
    };
  }, []);

  const errorHandler = useCallback(() => {
    setError(i => i + 1);
    if (error >= 10) setUrl(null);
  }, []);

  const onPlayHandler = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onEndHandler = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onPauseHandler = useCallback(() => {
    setIsPlaying(false);
  }, []);

  if (!theUrl) {
    return (
      <div ref={playerWrapper}>
        <Wrapper mb={mb} show={show} h={playerHeight}>
          <NoVideoPlaceholder height={playerHeight}>
            <ErrorOutline style={{ fontSize: 100 }} />
            Video was not provided.
          </NoVideoPlaceholder>
        </Wrapper>
      </div>
    );
  }

  return (
    <div
      ref={playerWrapper}
      onMouseEnter={() => setShowMute(true)}
      onMouseLeave={() => setShowMute(false)}
    >
      <Wrapper mb={mb} show={show} h={playerHeight} small={videoPip}>
        <MuteButton
          type="button"
          onClick={() => setMuted(m => !m)}
          show={showMute}
        >
          {muted ? <VolumeUp color="primary" /> : <VolumeOff color="primary" />}
        </MuteButton>
        {loading && <Loader width="60px" />}
        {!loading && !!theUrl && (
          <>
            <ReactPlayer
              ref={player}
              options={playerOptions}
              width="100%"
              height={playerHeight}
              url={theUrl}
              playing={isPlaying}
              volume={1}
              onPlay={onPlayHandler}
              onEnded={onEndHandler}
              onPause={onPauseHandler}
              onError={errorHandler}
              controls={false}
              muted={muted}
              onMouseEnter={e => e.preventDefault()}
            />
          </>
        )}
      </Wrapper>
    </div>
  );
};

Player.defaultProps = {
  mb: 0,
  url: null,
  setPlayerInstance: null,
};

Player.propTypes = {
  mb: PropTypes.number,
  url: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  setPlayerInstance: PropTypes.func,
  isPlaying: PropTypes.bool.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
};

export default memo(Player);
