import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  isDeletingId: null,
  breakouts: null,
  eventsSelectOptions: [],
};

export class BreakoutRoomsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getBreakouts = async ({
    page,
    search,
    event_id,
    sort,
    silent,
    type,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getAgendaItems({
        page,
        search,
        event: event_id,
        sort,
        agenda_type: 'breakout_room',
      });
      this.breakouts = {
        ...res,
        page: res.page_number.toString(),
        sort,
        search,
        event_id,
        type,
      };
    } catch (err) {
      console.debug('[getBreakouts] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'event_id', 'type'].every(
      param => params[param] === this.breakouts?.[param],
    );
  };

  @action deleteBreakout = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteAgendaItem({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteBreakout] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      const { results } = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };
}

export default new BreakoutRoomsPageStore();
