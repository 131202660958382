import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export default styled(Card)`
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  margin: ${({ margin }) => margin};
  padding: 15px;
  background-color: white;
  box-shadow: none !important;
  page-break-inside: avoid;
  position: relative;
`;

export const CardTitle = styled('div')`
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-bottom: 10px;
  color: ${({ color }) => color || '#000'};
`;
