import { Box } from '@mui/material';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';

const Error = styled(Box)`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  white-space: pre-line;
  margin-top: 10px !important;
`;

export const FormError = ({
  meta,
  'data-testid': dataTestId,
  ignorePristineTouched,
  ...props
}) => (
  <>
    {(!meta.pristine || meta.touched || ignorePristineTouched) &&
      (meta.error || !isEmpty(meta.submitError)) && (
        <Error data-testid={dataTestId || 'form-error'} {...props}>
          {meta.error || meta.submitError}
        </Error>
      )}
  </>
);
