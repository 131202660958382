import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { ROLES } from '@app/authManager';

const initialState = {
  isLoading: null,
  isLoadingBilling: false,
  isLoadingPaymentMethods: false,
  invoices: [],
  paymentMethods: [],
  profile: null,
  defaultFormValues: {},
};

export class ProfileStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get userRole() {
    if (!this.profile) return null;
    const { _role: profileRole } = this.profile;

    const userRole = Object.values(ROLES).find(role => role === profileRole);

    if (!userRole) {
      if (process.env.REACT_APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('[profileStore.role] user role not found');
      }
    }

    return userRole;
  }

  @computed get isModerator() {
    return this.userRole === ROLES.MODERATOR;
  }

  @action getFormattedRoleName = () => {
    return (
      this.userRole
        ?.split('_')
        .join(' ')
        .replace(/^\w/, c => c.toUpperCase()) || ''
    );
  };

  @action updateProfileBillingInfo = billingInfo => {
    this.profile.billing_info = billingInfo;
  };

  @action getProfile = async () => {
    try {
      this.profile = await ResourcesStore.getProfile();
    } catch (err) {
      console.debug('[getProfile] failed', err);
    } finally {
      this.isLoading = null;
    }
  };

  @action updateProfile = async ({ values, onSuccess }) => {
    this.isLoading = 'profile';
    try {
      const res = await ResourcesStore.updateProfile({
        payload: values,
      });

      await onSuccess?.(res);

      this.profile = res;
      this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateProfile] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = null;
    }
  };

  @action updatePassword = async ({ values, onSuccess }) => {
    this.isLoading = 'password';
    try {
      const res = await ResourcesStore.updateProfile({
        payload: values,
      });

      await onSuccess?.(res);
      return null;
    } catch (err) {
      console.debug('[updatePassword] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = null;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = { ...this.profile };
  };

  @action getHelpResources = async () => {
    try {
      await Promise.all([this.getProfile()]);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    }
  };

  @action getInvoices = async () => {
    try {
      const res = await ResourcesStore.getInvoices();
      this.invoices = res;
    } catch (error) {
      console.debug('[getInvoices] failed', error);
    }
  };

  @action getPaymentMethods = async ({ noLoading } = {}) => {
    try {
      if (!noLoading) this.isLoadingPaymentMethods = true;
      const res = await ResourcesStore.getPaymentMethods();
      this.paymentMethods = res;
    } catch (error) {
      console.debug('[getPaymentMethods] failed', error);
    } finally {
      if (!noLoading) this.isLoadingPaymentMethods = false;
    }
  };

  @action deletePaymentMethod = async ({ id }) => {
    try {
      this.isLoadingPaymentMethods = true;
      await ResourcesStore.deletePaymentMethod({
        id,
      });

      await this.getPaymentMethods({ noLoading: true });
    } catch (err) {
      console.debug('[deletePaymentMethod] failed', err);
    } finally {
      this.isLoadingPaymentMethods = false;
    }
  };

  @action getBillingResources = async () => {
    this.isLoadingBilling = true;
    try {
      await Promise.all([this.getInvoices(), this.getPaymentMethods()]);
    } catch (err) {
      console.debug('[getBillingResources] failed', err);
    } finally {
      this.isLoadingBilling = false;
    }
  };

  @action clearStore = async () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ProfileStore();
