import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from '@mui/material';
import { PageTitle } from '@components/PageTitle';

const UnauthroizedPage = ({ profileStore: { userRole: role } }) => {
  const userRole = useMemo(() => {
    const r = role;
    if (r) {
      return r.split('_').join(' ');
    }

    return '';
  });

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <PageTitle>Unauthorized access {!userRole && '🙄'}</PageTitle>
      {userRole && (
        <Box>You are not allowed to visit that page as {userRole} 🙄</Box>
      )}
    </Box>
  );
};

export default inject('profileStore')(observer(UnauthroizedPage));
