import { API } from '@api';
import { toast } from 'react-toastify';
import { percentage } from '@utils/math';

export const downloadBlob = async ({
  url,
  onProgress = () => {},
  postParameters,
}) => {
  onProgress(0);
  try {
    const { data, headers } = await API({
      url,
      data: postParameters,
      responseType: 'blob',
      method: postParameters ? 'post' : 'get',
      onDownloadProgress: ({ loaded, srcElement }) => {
        const total = srcElement.getResponseHeader('content-length');
        onProgress(percentage({ value: loaded, total }));
      },
    });
    const oURL = window.URL.createObjectURL(data);
    const link = window.document.createElement('a');
    link.href = oURL;
    link.setAttribute('download', getFilenameFromHeaders(headers));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    toast.cError('Something went wrong. Could not download file.');
    console.debug(e);
  } finally {
    onProgress(100);
  }
};

export const getFilenameFromUrl = url => {
  let filename;
  try {
    const filenameRe = /(.+\/)(.*\.\w+)(.*)/;
    [, , filename] = url.match(filenameRe);
  } catch (e) {
    filename = 'cloned-file.jpg';
  }
  return filename;
};

export const getFileFromLink = async link => {
  if (!link) return Promise.resolve(null);
  const resp = await fetch(link);

  if (resp.status !== 200) {
    return Promise.resolve(null);
  }

  const blobData = await resp.blob();

  return Promise.resolve(
    new File([blobData], getFilenameFromUrl(link), { type: blobData.type }),
  );
};

export const getFilenameFromHeaders = headers =>
  headers['content-disposition'].match(/filename="(.+)"/)[1];

export const cropPreviewImage = (image, crop) => {
  const canvas = document.createElement('canvas');

  canvas.width = Math.ceil(crop.width);
  canvas.height = Math.ceil(crop.height);

  const ctx = canvas.getContext('2d');
  ctx.imageSmoothingEnabled = false;
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height,
  );

  const croppedBase64 = canvas.toDataURL(null, 1);

  return croppedBase64;
};

export const createBlobFile = async (fileUrl, name) => {
  const response = await fetch(fileUrl);
  const data = await response.blob();
  const metadata = {
    type: 'image/png',
  };
  const file = new File([data], `${name}.png`, metadata);

  return file;
};
