import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  players: null,
  isDeletingId: null,
  eventsSelectOptions: [],
};

export class PlayersPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getPlayers = async ({
    page,
    search,
    silent,
    player_type,
    event_id,
  }) => {
    try {
      if (!silent) this.isLoading += 1;
      const res = await ResourcesStore.getPlayers({
        page,
        search,
        event_id,
        player_type,
      });
      this.players = {
        ...res,
        player_type,
        event_id,
        search,
      };
    } catch (err) {
      console.debug('[getPlayers] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    if (
      params.search === this.players?.search &&
      params.player_type === this.players?.player_type &&
      parseInt(params.page, 10) === this.players?.page_number &&
      params.event_id === this.players?.event_id
    )
      return true;

    return false;
  };

  @action getEventSelectOptions = async () => {
    try {
      const { results } = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action deletePlayer = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deletePlayer({
        id,
        onSuccess,
      });
    } catch (err) {
      console.debug('[deletePlayer] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new PlayersPageStore();
