import React from 'react';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import NoSubscriptionPlanTooltip from '@components/NoSubscriptionPlanTooltip';
import NotAvailableInStagesTooltip from '@components/NotAvailableInStagesTooltip';

const cornerStyle = {
  position: 'absolute',
  right: 0,
  top: 0,
  cursor: 'pointer',
};

const FieldTooltip = ({
  infoText,
  corner,
  sx,
  notAvailableInSP,
  notAvailableInStages,
}) => {
  if (!infoText && !notAvailableInSP && !notAvailableInStages) return null;

  return (
    <Box
      sx={{
        ...(corner && cornerStyle),
        flexShrink: 0,
        display: 'flex',
        gap: '5px',
        marginRight: '4px',
        ...sx,
      }}
    >
      {notAvailableInStages && <NotAvailableInStagesTooltip />}
      {notAvailableInSP && <NoSubscriptionPlanTooltip />}
      {infoText && (
        <Tooltip title={infoText} placement="top-end">
          <InfoOutlinedIcon
            sx={theme => ({
              fontSize: theme.typography.pxToRem(14),
              display: 'block',
              color: 'primary.main',
              pointerEvents: 'all',
            })}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default FieldTooltip;
