import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { inject, observer } from 'mobx-react';
import { WarningModalContent } from '@components/WarningModalContent';
import TableIconButton from '@components/TableIconButton';

const DeleteIconButton = ({
  modalStore: { openModal, closeModal },
  onClick,
  warn,
  ...props
}) => {
  const onWarnAccept = () => {
    closeModal();
    onClick();
  };

  const handleClickMiddleware = e => {
    e.stopPropagation();
    if (warn) {
      openModal({
        content: (
          <WarningModalContent onCancel={closeModal} onConfirm={onWarnAccept} />
        ),
      });
      return;
    }
    onClick();
  };
  return (
    <TableIconButton
      sx={theme => ({
        backgroundColor: theme.palette.grey[200],
        color: theme.colors.text,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      })}
      onClick={handleClickMiddleware}
      {...props}
    >
      <DeleteIcon />
    </TableIconButton>
  );
};

export default inject('modalStore')(observer(DeleteIconButton));
