import { action, extendObservable } from 'mobx';
import groupBy from 'lodash/groupBy';
import qs from 'query-string';
import forEach from 'lodash/forEach';
import sortedUniq from 'lodash/sortedUniq';
import isEmpty from 'lodash/isEmpty';
import { routerStore } from '@stores';
import { API, APIRoutes, CancelToken } from '@api';
import { DATE_FORMATS, formatDate, moment } from '@utils/date';
import routes from '@routes';
import { TABS } from '@pages/analytics/components/Sidebar';
import { getItem, KEYS, setItem } from '@utils/storage';
import DayButton from '@components/analytics/DayButton';
import { downloadBlob } from '@utils/fileUtils';

const MARGIN_TIME = 5 * 60 * 1000; /* ms - 15 minutes */

const ENABLED_CSVS = [
  'networking_connection',
  'global_chat_messages',
  'direct_chat_messages',
  'chatrooms',
  'chat_reactions',
  'video_reactions',
  'poll_votes',
  'questions_posted',
  'question_votes',
  'location',
  'document_downloads',
  'poll_votes_chart',
  'link_clicks',
  'ratings_chart',
];

const initialState = {
  agendaItems: [],
  agendaItemsByDay: {},
  autoRefreshIntervalTimeout: 5000,
  selectedAgendaItem: {},
  dataKey: null,
  event: {},
  eventDays: [],
  events: [],
  allEvents: [],
  eventError: null,
  eventLoading: false,
  eventAttendees: [],
  selectedEvent: {}, // selected event for dropdow,
  selectedEventId: null,
  selectedDate: null,
  selectedTimestamp: null,
  selectedTimeRange: {},
  countersCharts: {},
  stats: {},
  statsLoading: false,
  statsError: null,
  initialized: false,
  selectedTab: TABS.OVERVIEW,
  searchParams: {},
  statsRequest: null,
  countersRequest: null,
  attendeesRequest: null,
  startDate: null,
  endDate: null,
  selectedAttendeeId: null,
  selectedAttendee: null,
  attendeeLoading: false,
  attendeeDetailsLoading: false,
  eventStartTime: null,
  eventEndTime: null,
  selectedAttendeeLoading: false,
  autoRefreshInterval: null,
  agendaTimeout: null,
  videoPlayer: null,
  fetchingCounters: false,
  isPlaying: true,
  isLive: false,
  autoRefresh: false,
  globalData: false,
  queryParams: {},
  showScrubber: false,
  videoPip: false,
  pdfLoadingProgress: 100,
  csvLoadingProgress: 100,
  loadingSingleCSVs: [],
  autoRefreshDebounce: null,
  isPlayerLoading: false,
  enabledCSVs: ENABLED_CSVS,
  maxConcurrentAttendees: 0,
};

export class AnalyticsStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action initAnalyticsPage = async (eventId, queryParams) => {
    this.initialized = false;
    this.eventLoading = true;
    this.selectedEventId = eventId;
    this.searchParams = { ...queryParams };
    this.selectedTab = queryParams.tab || TABS.OVERVIEW;
    this.selectedAttendeeId = queryParams.attendee;
    this.queryParams = queryParams;
    this.autoRefreshIntervalTimeout =
      parseInt(getItem(KEYS.AUTOREFRESH_INTERBAL), 10) || 5000;

    const selectFirst = !eventId;

    try {
      await this.fetchEventsForDropdown();
      let selectedEvent;

      if (selectFirst) {
        [selectedEvent] = this.events;
      } else {
        selectedEvent = this.events.find(
          event => event.value === this.selectedEventId,
        );
        if (!selectedEvent) {
          // event which exceeds eventsUpTo10daysFromToday
          selectedEvent = this.allEvents.find(
            event => event.value === this.selectedEventId,
          );
          if (selectedEvent) {
            this.events = [selectedEvent, ...this.events];
          }
        }
      }

      await this.selectEvent(selectedEvent, queryParams.day);
    } catch (error) {
      this.setEventError(error);
    } finally {
      this.eventLoading = false;
      this.initialized = true;
    }
  };

  @action generateReport = async () => {
    this.pdfLoadingProgress = 0;
    await downloadBlob({
      url: APIRoutes.GENERATE_REPORT(this.selectedEventId),
      onProgress: value => {
        this.pdfLoadingProgress = value;
      },
    });
  };

  @action generateCSV = async () => {
    this.csvLoadingProgress = 0;
    await downloadBlob({
      url: APIRoutes.GENERATE_CSV(this.selectedEventId),
      onProgress: value => {
        this.csvLoadingProgress = value;
      },
    });
  };

  @action generateLiveAttendeesCSV = async () => {
    this.csvLoadingProgress = 0;
    await downloadBlob({
      url: APIRoutes.GENERATE_LIVE_ATTENDANCE_CSV(this.selectedEventId),
      onProgress: value => {
        this.csvLoadingProgress = value;
      },
    });
  };

  @action generateSingleCSV = async type => {
    const current = {
      type,
      progress: 0,
    };
    this.loadingSingleCSVs.push(current);

    await downloadBlob({
      url: APIRoutes.SINGLE_CSV(this.selectedEventId, type),
      onProgress: value => {
        const currentCSV = this.loadingSingleCSVs.find(a => a.type === type);
        if (value < 100) {
          currentCSV.progress = value;
        } else {
          currentCSV.progress = null;
        }
      },
    });

    this.loadingSingleCSVs.filter(a => a.type !== type);
  };

  @action toggleVideoPip = () => {
    this.videoPip = !this.videoPip;
  };

  @action setIsPlaying = isPlaying => {
    this.isPlaying = isPlaying;
  };

  @action setVideoPlayer = player => {
    this.videoPlayer = player;
  };

  @action selectDay = day => {
    this.resetSelectedData();
    this.selectedDate = day;
    this.dataKey = day;
    if (day) {
      this.selectedTimestamp = null;
      this.globalData = false;
      this.setPlayerData();
    }
  };

  @action fetchEventsForDropdown = async () => {
    try {
      if (!this.events.length) {
        const {
          data: { results },
        } = await API.get(APIRoutes.EVENTS_FOR_DROPDOWN);

        this.allEvents = results;
        this.events = this.eventsUpTo10daysFromToday(results);
      }
    } catch (error) {
      this.setEventError(error);
    }
  };

  @action setEventError = error => {
    this.eventError = error;
  };

  @action resetAttendee = () => {
    delete this.searchParams.attendee;
    this.selectedAttendeeId = null;
    this.selectedAttendee = null;

    routerStore.replace(
      `${routes.main.analytics(this.selectedEventId)}?${qs.stringify(
        this.searchParams,
      )}`,
    );
  };

  @action eventDropdownChangeHandler = event => {
    this.isLive = false;
    this.initialized = false;
    this.resetAttendee();
    this.selectedDate = formatDate(
      event.agenda_start_datetime,
      DATE_FORMATS.URL,
    );
    this.searchParams.day = this.selectedDate;
    let redirectPath = routes.main.analytics(event.value);
    if (Object.keys(this.searchParams).length) {
      redirectPath = `${redirectPath}?${qs.stringify(this.searchParams)}`;
    }

    routerStore.replace(redirectPath);
  };

  @action selectEvent = async (event, day) => {
    if (this.autoRefresh) {
      this.stopAutoRefresh();
    }
    this.eventLoading = true;
    this.selectedDate = day;
    this.agendaItemsByDay = {};
    this.stats = {};
    this.selectedAgendaItem = {};
    this.dataKey = day;

    try {
      if (event) {
        this.selectedEvent = event;
        this.selectedEventId = event.value;

        if (this.searchParams.aid) {
          delete this.searchParams.aid;
          routerStore.replace(
            `${routes.main.analytics(this.selectedEventId)}?day=${DayButton}`,
          );
        }

        await this.fetchEvent();
        this.setPlayerData();
        await this.fetchAgendaItems();
        if (!isEmpty(this.queryParams)) {
          await this.fetchAnalytics();
        }
      }
    } catch (error) {
      this.setEventError(error);
    } finally {
      this.eventLoading = false;
      this.initialized = true;
    }
  };

  @action checkIfEventIsLive = () => {
    try {
      const isLive = moment().isBetween(
        moment(this.event.agenda_start_datetime),
        moment(this.event.agenda_end_datetime),
      );

      if (isLive) {
        this.isLive = true;
        this.videoPip = false;
      } else {
        this.isLive = false;
        this.videoPip = true;
      }
    } catch (error) {
      this.setEventError(error);
    }
  };

  @action fetchEvent = async (eventId = null) => {
    this.eventLoading = true;

    try {
      const { data } = await API.get(
        APIRoutes.EVENT(this.selectedEventId || eventId),
      );
      this.event = data;
      this.checkIfEventIsLive();
    } catch (error) {
      this.setEventError(error);
    } finally {
      this.eventLoading = false;
    }
  };

  @action fetchStats = async (
    refresh = false,
    eventId = this.selectedEventId,
  ) => {
    if (!refresh) this.statsLoading = true;
    if (this.statsRequest) {
      this.statsRequest.cancel();
    }
    try {
      this.statsRequest = CancelToken.source();
      const payload = {
        eventId,
      };

      if (refresh) {
        payload.from = Date.now();
      }
      const {
        data: { results: stats },
      } = await API.get(APIRoutes.EVENT_STATS_20(payload), {
        cancelToken: this.statsRequest.token,
      });

      this.stats = stats;
    } catch (error) {
      this.statsError = error;
    } finally {
      this.statsLoading = false;
      this.statsRequest = null;
    }
  };

  @action fetchCountersCharts = async (timestamp, eventId) => {
    if (this.countersRequest) {
      this.countersRequest.cancel();
    }
    const evId = this.selectedEventId || eventId;
    if (evId) {
      try {
        const fromAgendaItem = !isEmpty(this.selectedAgendaItem);
        const payload = {
          eventId: evId,
        };

        if (timestamp) {
          this.dataKey = timestamp;
          payload.ts = timestamp;
        } else if (fromAgendaItem) {
          this.dataKey = this.selectedAgendaItem.id;
          payload.agendaId = this.selectedAgendaItem.id;
        }

        if (this.isLive) {
          payload.from = Date.now();
        }

        this.countersRequest = CancelToken.source();

        const {
          data: { results },
        } = await API.get(APIRoutes.EVENT_COUNTERS_CHARTS(payload), {
          cancelToken: this.countersRequest.token,
        });

        if (timestamp || fromAgendaItem) {
          this.countersCharts = {
            ...this.countersCharts,
            ...results,
          };
          this.selectedTimestamp = timestamp;
        } else {
          this.selectedTimestamp = null;
          this.countersCharts = results;
        }
      } catch (error) {
        this.statsError = error;
      }
    }
  };

  @action fetchAgendaItems = async (selectedEventId = null) => {
    const eventId = this.selectedEventId || selectedEventId;
    if (eventId) {
      try {
        const {
          data: { results: agendaItems },
        } = await API.get(`${APIRoutes.AGENDA_ITEMS}?event_id=${eventId}`);

        if (this.queryParams.aid) {
          const agendaItem = agendaItems.find(
            ai => ai.id === this.queryParams.aid,
          );

          this.selectedAgendaItem = {
            name: agendaItem.description,
            id: agendaItem.id,
            sDate: new Date(agendaItem.start_date).getTime(),
            eDate: new Date(agendaItem.end_date).getTime(),
          };
          this.dataKey = agendaItem.id;
        }

        const agendaItemsDates = sortedUniq(
          agendaItems.map(item => {
            const [day] = item.start_date.split('T');
            return day;
          }),
        );

        const agendaItemsGrouped = groupBy(agendaItems, ai => {
          return ai.start_date.split('T')[0];
        });

        const globalStart =
          Math.min(
            ...(agendaItems || []).map(ai => new Date(ai.start_date).getTime()),
          ) - MARGIN_TIME;
        const globalEnd =
          Math.max(
            ...(agendaItems || []).map(ai => new Date(ai.end_date).getTime()),
          ) + MARGIN_TIME;
        const agendaItemsByDay = {
          global: {
            items: agendaItems,
            start: moment(globalStart),
            end: moment(globalEnd),
          },
        };
        forEach(agendaItemsGrouped, (values, key) => {
          const startEventDate =
            Math.min(
              ...(agendaItemsGrouped[key] || []).map(ai =>
                new Date(ai.start_date).getTime(),
              ),
            ) - MARGIN_TIME;
          const endEventDate =
            Math.max(
              ...(agendaItemsGrouped[key] || []).map(ai =>
                new Date(ai.end_date).getTime(),
              ),
            ) + MARGIN_TIME;

          agendaItemsByDay[key] = {
            items: agendaItemsGrouped[key],
            start: moment(startEventDate),
            end: moment(endEventDate),
          };
        });

        this.eventDays = agendaItemsDates;
        this.agendaItemsByDay = agendaItemsByDay;

        if (this.selectedDate) {
          this.setPlayerData();
        }

        if (!this.selectedDate && this.eventDays && !selectedEventId) {
          const firstDay = formatDate(this.eventDays[0], DATE_FORMATS.URL);
          this.selectDay(firstDay);
          routerStore.replace(
            `${routes.main.analytics(this.selectedEventId)}?day=${firstDay}`,
          );
        }

        // this.agendaItems = agendaItemsByDay;
        this.agendaItems = agendaItems;
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
        this.eventError = error;
      }
    }
  };

  @action fetchAttendees = async (timestamp = null, eventId = null) => {
    const evId = this.selectedEventId || eventId;
    if (this.attendeesRequest) {
      this.attendeesRequest.cancel();
    }
    if (evId) {
      try {
        const fromAgendaItem = !isEmpty(this.selectedAgendaItem);
        const payload = {
          eventId: evId,
          startDate: this.startDate,
          endDate: this.endDate,
        };

        if (timestamp) {
          this.selectedTimestamp = timestamp;
          payload.ts = timestamp;
          this.dataKey = timestamp;
        } else if (fromAgendaItem) {
          this.selectedTimestamp = null;
          payload.agendaId = this.selectedAgendaItem.id;
          this.dataKey = this.selectedAgendaItem.id;
        } else {
          this.selectedTimestamp = null;
        }

        if (this.isLive) {
          payload.from = Date.now();
        }

        this.attendeesRequest = CancelToken.source();

        const {
          data: { results },
        } = await API.get(APIRoutes.EVENT_ATTENDEES_20(payload), {
          cancelToken: this.attendeesRequest.token,
        });

        if (timestamp || fromAgendaItem) {
          this.eventAttendees = {
            ...this.eventAttendees,
            ...results,
          };
        } else {
          this.eventAttendees = results;
        }
      } catch (error) {
        console.log({ error }); // eslint-disable-line no-console
        this.eventError = error;
      }
    }
  };

  @action reset = () => {
    this.stopAutoRefresh();
    Object.keys(initialState).forEach(key => {
      this[key] = initialState[key];
    });
  };

  @action selectTab = tab => {
    this.selectedTab = tab;
    this.searchParams.tab = tab;
    this.resetAttendee();
  };

  @action setSelectedDate = (date, isGlobal) => {
    const day = isGlobal ? date : formatDate(date, DATE_FORMATS.URL);
    this.selectDay(day);
    this.searchParams.day = day;
    this.dataKey = day;
    routerStore.push(
      `${routes.main.analytics(this.selectedEventId)}?${qs.stringify(
        this.searchParams,
      )}`,
    );
  };

  @action setPlayerData = () => {
    try {
      if (
        !isEmpty(this.agendaItemsByDay) &&
        !isEmpty(this.event) &&
        this.selectedDate
      ) {
        this.isPlayerLoading = true;
        let playerData = {};
        if (this.selectedDate !== 'global') {
          const { items: agendaItems } = this.agendaItemsByDay[
            this.selectedDate
          ];
          const agendaItemsPlayer = agendaItems.filter(
            ai => !isEmpty(ai.player),
          );

          if (!agendaItemsPlayer.length) {
            const { player } = this.event;
            if (player) {
              playerData = {
                url: player.url,
                type: player.player_type,
              };
            }
          } else {
            const [agendaItem] = agendaItemsPlayer;
            const { player } = agendaItem;
            playerData = {
              url: player?.url,
              type: player?.player_type,
            };
          }
        } else {
          const { player } = this.event;
          playerData = {
            url: player?.url,
            type: player?.player_type,
          };
        }
        this.player = playerData;
      }
    } catch (error) {
      console.log({ error }); // eslint-disable-line no-console
    } finally {
      this.isPlayerLoading = false;
    }
  };

  @action fetchAttendee = async (init = true) => {
    if (init) this.attendeeDetailsLoading = true;
    try {
      const { data } = await API.get(
        APIRoutes.USER_STATS(this.selectedEventId, this.selectedAttendeeId),
      );
      this.selectedAttendee = { ...data };
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    } finally {
      this.attendeeDetailsLoading = false;
    }
  };

  @action selectAttendee = async id => {
    this.selectedAttendeeId = id;
    this.searchParams.attendee = id;
    routerStore.replace(
      `${routes.main.analytics(this.selectedEventId)}?${qs.stringify(
        this.searchParams,
      )}`,
    );
  };

  @action startAutoRefresh = () => {
    clearInterval(this.autoRefreshInterval);
    this.autoRefresh = true;
    this.autoRefreshDebounce = setTimeout(() => {
      this.autoRefreshInterval = setInterval(() => {
        if (!this.fetchingCounters) {
          this.fetchAnalytics();
        }
        if (this.selectedAttendeeId) {
          this.fetchAttendee(false);
        }
      }, this.autoRefreshIntervalTimeout);
    }, this.autoRefreshIntervalTimeout);
  };

  @action stopAutoRefresh = () => {
    this.autoRefresh = false;
    clearInterval(this.autoRefreshInterval, 500);
    clearTimeout(this.autoRefreshDebounce);
  };

  @action fetchAnalytics = async (timestamp = null) => {
    this.fetchingCounters = true;
    try {
      if (this.isLive || isEmpty(this.stats)) {
        await this.fetchStats(true);
      }
      await this.fetchCountersCharts(timestamp);
      await this.fetchAttendees(timestamp);
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    } finally {
      this.fetchingCounters = false;
      if (this.isLive && !this.autoRefresh) {
        this.startAutoRefresh();
      }
    }
  };

  // @action setEventRefreshment = (agendaItem, setItem = true) => {
  //   if (setItem) this.selectedAgendaItem = agendaItem;

  //   const distanceToAgendaItem = moment(agendaItem.start_time).diff(
  //     new Date(),
  //     'milliseconds',
  //     true,
  //   );

  //   console.log(distanceToAgendaItem);

  //   if (agendaItem.live) {
  //     this.isLive = true;
  //     this.fetchAnalytics(this.timestamp, this.live);
  //   } else if (distanceToAgendaItem > 0) {
  //     this.isLive = false;
  //     this.agendaTimeout = setTimeout(() => {
  //       this.setSelectedAgendaItem(agendaItem);
  //     }, distanceToAgendaItem);
  //   } else {
  //     this.isLive = false;
  //   }
  // };

  // @action setSelectedAgendaItem = agendaItem => {
  //   clearTimeout(this.agendaTimeout);
  //   console.log('@@@@setSelectedAgendaItem');

  //   const currentAgendaItem =
  //     agendaItem ||
  //     this.agendaItems.find(item => item.live) ||
  //     this.agendaItems[0];

  //   if (agendaItem || this.selectedAgendaItem)
  //     this.setEventRefreshment(currentAgendaItem);
  //   else this.setEventRefreshment(currentAgendaItem, false);

  //   const nextAgendaItemIndex =
  //     this.agendaItems.indexOf(this.selectedAgendaItem) + 1;

  //   const nextAgendaItem =
  //     this.agendaItems.length > nextAgendaItemIndex
  //       ? this.agendaItems[nextAgendaItemIndex]
  //       : null;

  //   const currentAgendaItemEnds = currentAgendaItem.end_date;
  //   const resetTime = moment(currentAgendaItemEnds).diff(
  //     new Date(),
  //     'milliseconds',
  //     true,
  //   );

  //   if (!nextAgendaItem) {
  //     this.agendaTimeout = setTimeout(() => {
  //       clearInterval(this.autoRefreshInterval);
  //     }, resetTime);
  //     return;
  //   }

  //   const nextAgendaItemStarts = nextAgendaItem.start_date;

  //   const itemsGap = moment(nextAgendaItemStarts).diff(
  //     currentAgendaItemEnds,
  //     'minutes',
  //     true,
  //   );

  //   this.agendaTimeout = setTimeout(() => {
  //     if (itemsGap > 30) {
  //       clearInterval(this.autoRefreshInterval);
  //     } else {
  //       this.setSelectedAgendaItem(nextAgendaItem);
  //     }
  //   }, resetTime);
  // };

  @action scrollVideo = (time, position) => {
    const eventDay = this.agendaItemsByDay[this.selectedDate];
    const agendaItems = [];

    eventDay.items.forEach(ai => {
      const start = moment(ai.start_date).unix();
      const end = moment(ai.end_date).unix();

      if (time >= start && time <= end) {
        agendaItems.push(true);
        if (ai.agenda_type === 'agenda_item') {
          const videoDuration = this.videoPlayer.getDuration();
          const seekTo = (videoDuration * position) / 100;
          this.videoPlayer.seekTo(seekTo);
        } else {
          this.isPlaying = false;
        }
      }

      this.isPlaying = agendaItems.length;
    });
  };

  @action selectTimeRange = (start, end) => {
    this.resetSelectedData();
    this.selectedTimeRange = {
      start,
      end,
    };
  };

  @action resetSelectedData = () => {
    this.selectedAgendaItem = {};
    delete this.searchParams.aid;
    this.dataKey = this.selectedDate;
    this.selectedTimestamp = null;

    if (
      this.initialized &&
      (!this.countersCharts[this.dataKey] || !this.eventAttendees[this.dataKey])
    ) {
      this.fetchAnalytics();
    }
    routerStore.replace(
      `${routes.main.analytics(this.selectedEventId)}?${qs.stringify(
        this.searchParams,
      )}`,
    );
  };

  @action selectAgendaItem = async ai => {
    try {
      this.selectedAgendaItem = ai;
      this.searchParams.aid = ai.id;
      this.dataKey = ai.id;
      this.selectedTimestamp = null;

      routerStore.replace(
        `${routes.main.analytics(this.selectedEventId)}?${qs.stringify(
          this.searchParams,
        )}`,
      );
      this.fetchAnalytics();
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }
  };

  @action setAutoRefreshInterval = ms => {
    this.autoRefreshIntervalTimeout = ms;
    this.stopAutoRefresh();
    this.startAutoRefresh();
    setItem(KEYS.AUTOREFRESH_INTERBAL, ms);
  };

  @action setMaxConcurrentAttendees = val => {
    this.maxConcurrentAttendees = val;
  };

  eventsUpTo10daysFromToday = events => {
    const dateAfter10days = moment().add(10, 'day');
    return events.filter(e =>
      moment(dateAfter10days).isAfter(e.agenda_start_datetime),
    );
  };
}

export default new AnalyticsStore();
