import Link from '@components/Link';
import ColumnDateFormatted from '@components/ColumnDateFormatted';
import React from 'react';
import OrganizationLinkTooltip from '@components/OrganizationLinkTooltip';

const ColName = ({ rowData }) => rowData.name;

const ColLink = ({ rowData }) => (
  <Link href={rowData.frontend_url} target="_blank">
    {rowData.frontend_url}
  </Link>
);
const ColOrgName = ({ rowData }) => (
  <OrganizationLinkTooltip
    orgName={rowData.organization_name}
    orgId={rowData.organization_id}
  />
);

const ColAttendance = ({ rowData }) =>
  rowData.users_count + rowData.users_requested_count;

const ColStartDate = ({ rowData }) => (
  <ColumnDateFormatted
    date={rowData.agenda_start_datetime || rowData.planned_start_date}
  />
);

export const COLUMNS = () => [
  {
    label: 'Name',
    cellContent: ColName,
    key: 'name',
    sortingKey: 'name',
  },
  { label: 'Link', cellContent: ColLink, key: 'frontend_url' },
  {
    label: 'Organization',
    sortingKey: 'organization_name',
    cellContent: ColOrgName,
    key: 'organization_name',
  },
  {
    label: 'Attendance',
    cellContent: ColAttendance,
    key: 'attendance',
  },
  {
    label: 'Start date',
    cellContent: ColStartDate,
    sortingKey: 'agenda_start_datetime',
    key: 'agenda_start_datetime',
  },
];
