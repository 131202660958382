import React from 'react';
import CplusContainer from './CplusContainer';
import { CplusToastProvider } from './ToastContext';

const CplusContextWrapper = ({ duration, children, type, close }) => {
  return (
    <CplusToastProvider duration={duration} closeToast={close}>
      <CplusContainer type={type}>{children}</CplusContainer>
    </CplusToastProvider>
  );
};

export default CplusContextWrapper;
