import { styled } from '@mui/material/styles';

export const CropperBackgroundLayer = styled('div')`
  position: fixed;
  overflow-y: hidden;
  left: 0;
  top: 0;
  width: calc(100% - 2 * 100px);
  height: 100%;
  padding: 100px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const CropperMainContent = styled('div')`
  box-sizing: border-box;
  padding: 40px;
  width: 95vw;
  border-radius: 20px;
  box-shadow: 3px 3px 15px 2px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  background: white;
`;

export const TopBarWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const ActionButtonWrapper = styled('div')`
  display: flex;
`;

export const WarningMessage = styled('h3')`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
  font-size: 28px;
`;

export const CropperTitle = styled('h1')``;

export const CroppperImageWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.07);
`;
