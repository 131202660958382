import { useTheme, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import Input from '@components/Input';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import { FormError } from '@components/_form/FormError';
import { Button, BUTTON_TYPES } from '@components/Buttons/Button';
import EyeOn from '@app/assets/svgs/EyeOn';
import EyeOff from '@app/assets/svgs/EyeOff';
import FieldTooltip from '@components/FieldTooltip';

const Label = styled(Box)`
  opacity: 0.7;
  padding-bottom: 6px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding-left: 0;
`;

const PwdVisibilityContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 3px;
  justify-content: space-between;
`;

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: ${props => (props.editing ? 'flex' : 'inline-block')};
`;

const ShowPwdWrapper = styled(Box)`
  padding-top: 5px;
  right: 20px;
  cursor: pointer;
`;

const PwdVisibilityText = styled(Box)`
  color: grey;
  font-size: 10px;
  padding-left: 10px;
  cursor: pointer;
`;

const styles = () => ({
  textFieldWhite: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  inputWhite: {
    color: 'white',
  },
});

const PasswordInput = ({
  meta,
  label,
  input,
  autocomplete,
  customStyle,
  markAsRequired,
  onEdit,
  onBlur,
  ignorePristineTouched,
  infoText,
  ...props
}) => {
  const theme = useTheme();
  const [showPwd, setShowPwd] = useState(false);

  const extendedOnBlur = ev => {
    if (onBlur) onBlur(ev);
    input.onBlur(ev);
  };

  return (
    <>
      <Box position="relative" width="100%">
        {label && (
          <Label htmlFor={label}>
            {label}
            {markAsRequired && (
              <Box ml={1} display="inline-block" color="red">
                *
              </Box>
            )}
          </Label>
        )}
        <FieldWrapper editing={onEdit}>
          <Input
            id={label}
            onChange={input.onChange}
            onBlur={extendedOnBlur}
            onFocus={input.onFocus}
            value={input.value}
            autoComplete={autocomplete}
            type={!showPwd ? 'password' : 'text'}
            sx={{
              borderRadius: '3px',
              border: `1px solid ${theme.colors.border2}`,
              padding: '6px 10px 4px',
              fontFamily: theme.fonts.main,
              fontSize: theme.fontSizes.tiny,
              fontWeight: theme.fontWeights.semibold,
              lineHeight: '24px',
              '::-webkit-input-placeholder': {
                color: theme.colors.textPlaceholder,
              },

              ...customStyle,
            }}
            {...props}
          />

          <PwdVisibilityContainer>
            {meta &&
              (meta.touched || !meta.pristine || ignorePristineTouched) &&
              (meta.error || meta.submitError) && (
                <Box>
                  <FormError
                    meta={meta}
                    ignorePristineTouched={ignorePristineTouched}
                  />
                </Box>
              )}
            <Box display="flex" alignItems="center">
              <ShowPwdWrapper onClick={() => setShowPwd(!showPwd)}>
                {showPwd ? (
                  <EyeOn fill={theme.colors.textSecondary} />
                ) : (
                  <EyeOff fill={theme.colors.textSecondary} />
                )}
              </ShowPwdWrapper>
              {onEdit && (
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  size="s"
                  label="Edit"
                  height="36px"
                  ml={3}
                  iconLeft={<CreateIcon />}
                  onClick={onEdit}
                />
              )}
              <PwdVisibilityText
                color={theme.colors.textSecondary}
                onClick={() => setShowPwd(!showPwd)}
              >
                {showPwd ? 'Hide password' : 'Show password'}
              </PwdVisibilityText>
            </Box>
          </PwdVisibilityContainer>
        </FieldWrapper>
        {infoText && <FieldTooltip infoText={infoText} corner />}
      </Box>
    </>
  );
};

export default withStyles(styles)(PasswordInput);
