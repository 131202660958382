import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.section};
`;

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const Icon = styled(Box)`
  display: flex;
  align-items: center;
  margin: 0 15px;
`;

function IconBox({ description, icon, title }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Icon>{icon}</Icon>
      <Box>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
    </Box>
  );
}

export default IconBox;
