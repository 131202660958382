export default {
  auth: {
    signIn: '/sign-in',
    signUp: (type = null) =>
      type !== null ? `/sign-up/${type}` : '/sign-up/:type?',
    expired: '/expired',
    resetPassword: '/reset-password',
    createPassword: '/create-password',
  },
  main: {
    agenda: '/agenda',
    analytics: (eventId = null) =>
      eventId !== null ? `/analytics/${eventId}` : '/analytics/:eventId?',
    analytics_report: (eventId = null) =>
      eventId !== null
        ? `/analytics_report/${eventId}`
        : '/analytics_report/:eventId?',
    events: '/events',
    dashboard: '/dashboard',
    organizations: '/organizations',
    players: '/players',
    users: '/users',
    documents: '/resources',
    products: '/products',
    images: '/images',
    presenters: '/presenters',
    profile: '/profile',
    chatMessages: '/chat-messages',
    chatRooms: '/chat-rooms',
    chatAbuses: '/chat-abuses',
    chatQuestions: '/chat-questions',
    chatQuestionsQueue: '/chat-questions-queue',
    polls: '/polls',
    pollAnswerUsers: '/poll-answer-users',
    attendances: '/attendances',
    attendances_export: '/attendances-export',
    sponsors: '/sponsors',
    reports: search => `/reports${search ? `?${search}` : ''}`,
    gdpr: {
      eventSelect: '/gdpr',
      viewUsers: '/gdpr-users',
    },
    exploreItems: '/explore',
    banners: '/banners',
    globalMessages: '/global-messages',
    eventRefresh: '/event-refresh',
    translations: '/translations',
    multiMarket: '/multi-market',
    ratings: '/ratings',
    ratingsVotes: '/ratings-votes',
    logs: '/logs',
    breakout: '/breakout',
    groups: '/group',
    eventPayment: (step = null, eventId) =>
      step ? `/event-payment/${step}?eventId=${eventId}` : `/event-payment`,
    stages: '/stages',
  },
  multiMarket: (id = null) =>
    id ? `/multi-market/${id}` : '/multi-market/:id',
  exploreItems: (id = null) => (id ? `/explore/${id}` : '/explore/:id'),
  agendaItems: (id = null) => (id ? `/agenda/${id}` : '/agenda/:id'),
  breakoutRooms: (id = null) => (id ? `/breakout/${id}` : '/breakout/:id'),
  group: (id = null) => (id ? `/group/${id}` : '/group/:id'),
  rating: (id = null) => (id ? `/ratings/${id}` : '/ratings/:id'),
  banner: (id = null) => (id ? `/banners/${id}` : '/banners/:id'),
  attendance: (id = null) => (id ? `/attendances/${id}` : '/attendances/:id'),
  translation: (id = null) =>
    id ? `/translations/${id}` : '/translations/:id',
  globalMessage: (id = null) =>
    id ? `/global-message/${id}` : '/global-message/:id',
  event: (id = null) => (id ? `/events/${id}` : '/events/:id'),
  organization: (id = null) =>
    id ? `/organizations/${id}` : '/organizations/:id',
  player: (id = null) => (id ? `/players/${id}` : '/players/:id'),
  user: (id = null) => (id ? `/users/${id}` : '/users/:id'),
  document: (id = null) => (id ? `/resources/${id}` : '/resources/:id'),
  product: (id = null) => (id ? `/products/${id}` : '/products/:id'),
  presenter: (id = null) => (id ? `/presenters/${id}` : '/presenters/:id'),
  poll: (id = null) => (id ? `/polls/${id}` : '/polls/:id'),

  sponsor: (id = null) => (id ? `/sponsors/${id}` : '/sponsors/:id'),
  chatMessage: (id = null) =>
    id ? `/chat-messages/${id}` : '/chat-messages/:id',
  chatRoom: (id = null) => (id ? `/chat-rooms/${id}` : '/chat-rooms/:id'),
  chatQuestion: (id = null) =>
    id ? `/chat-questions/${id}` : '/chat-questions/:id',
  email: (id = null) => (id ? `/emails/${id}` : '/emails/:id'),
  stage: (id = null) => (id ? `/stages/${id}` : '/stages/:id'),
  unauthorized: '/unauthorized',
  notFound: '/404',
  emailVerificationError: '/email-verification-error',
};
