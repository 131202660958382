import React from 'react';
import { Box, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import CloseButton, { CIRCLE_RADIUS } from './CloseButton';
import { useCplusToast } from './ToastContext';

const CplusContainer = ({ children, type }) => {
  const theme = useTheme();
  const toast = useCplusToast();

  return (
    <Box
      onMouseEnter={toast.pause}
      onMouseLeave={toast.play}
      sx={{
        background: theme.toast[type].backgroundColor,
        boxSizing: 'border-box',
        borderRadius: '5px',
        boxShadow: `${theme.shadows[3]}, 0px 0px 5px ${alpha(
          theme.toast[type].borderColor,
          0.7,
        )}`,
        position: 'relative',
        padding: `15px ${30 + CIRCLE_RADIUS * 2}px 15px 15px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 15 * 2 + CIRCLE_RADIUS * 2 + 4,
        border: `2px solid ${theme.toast[type].borderColor}`,
        wordBreak: 'break-word',
      }}
    >
      {children}
      <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
        <CloseButton color={theme.toast[type].closeButtonColor} />
      </Box>
    </Box>
  );
};

export default CplusContainer;
