import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Doughnut } from 'react-chartjs-2';
import { Typography, useTheme } from '@mui/material';
import Card from '@components/Card';
import CardTable from '@components/CardTable';
import { formatPollString } from '@utils/otherUtils';
import CardOptions from '@components/CardOptions';
import * as Styled from '@components/DoughnutCard/style';

const isData = data => {
  if (data.length === 0) return false;
  let areEmpty = 0;
  data?.forEach(a => {
    areEmpty += a?.value || 0;
  });
  if (areEmpty === 0) return false;
  return true;
};

const DoughnutCard = ({
  name,
  margin,
  tableHeader,
  data,
  nolimit = false,
  noDoughnat = false,
  isRating,
  type,
  horizontal = false,
  forPrint,
}) => {
  const theme = useTheme();

  const stats = useMemo(() => {
    const lastItem = { label: 'Others', value: 0 };
    if (data.length > 6 && !nolimit) {
      for (let i = 4; i < data.length; i += 1) {
        lastItem.value += data[i].value;
      }
      return [...data.slice(0, 4), lastItem];
    }
    return data;
  }, [data]);

  const values = useMemo(
    () => (isData(stats) ? stats.map(el => el.value) : [100]),
    [stats],
  );

  const labels = useMemo(
    () =>
      isData(stats)
        ? stats.map((el, index) => formatPollString(el.label, index + 1))
        : ['No data'],
    [stats],
  );

  const dataForDoughnut = useMemo(
    () => ({
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: isData(stats)
            ? theme.analytics.dounghts
            : [theme.palette.dark[300]],
          borderWidth: 3,
          borderColor: theme.palette.background.paper,
        },
      ],
    }),
    [values, stats, theme, labels],
  );

  const options = useMemo(() => {
    const opts = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      cutoutPercentage: 75,
    };

    if (!isData(stats)) return { ...opts, events: [] };
    return opts;
  }, [stats]);
  if (stats.length === 0) {
    return null;
  }
  return (
    <Card margin={margin} style={{ position: 'relative' }}>
      <Styled.Header>
        <Typography variant="h6">{name}</Typography>
      </Styled.Header>
      <CardOptions type={type} />
      <Box display="flex" flexDirection={horizontal ? 'row' : 'column'}>
        {!noDoughnat && (
          <Styled.ChartWrapper forPrint={forPrint}>
            <Doughnut data={dataForDoughnut} options={options} />
          </Styled.ChartWrapper>
        )}
        {stats.length > 0 ? (
          <CardTable
            tableHeader={tableHeader}
            data={data}
            nolimit={nolimit}
            isRating={isRating}
          />
        ) : (
          <Typography align="center" variant="body1">
            No data
          </Typography>
        )}
      </Box>
    </Card>
  );
};

DoughnutCard.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  margin: PropTypes.string,
  tableHeader: PropTypes.shape({
    action: PropTypes.string,
    counter: PropTypes.string,
    percent: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRating: PropTypes.bool,
  noDoughnat: PropTypes.bool,
  nolimit: PropTypes.bool,
  horizontal: PropTypes.bool,
  forPrint: PropTypes.bool,
};

DoughnutCard.defaultProps = {
  margin: '0',
  isRating: false,
  noDoughnat: false,
  nolimit: false,
  horizontal: false,
  forPrint: false,
};

export default DoughnutCard;
