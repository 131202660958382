import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

export const Title = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
