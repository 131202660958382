export const titleCell = {
  padding: '5px 0',
  border: 'none',
};

export const centeredSx = { margin: '0 auto', width: 'fit-content' };
export const tableCell = { padding: '10px 0', float: 'right', border: 'none' };
export const boldCell = { ...titleCell, fontWeight: '700' };
export const tableRow = {
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
};
