import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';

export const useSearch = params => {
  const history = useHistory();
  const { pathname } = useLocation();
  return useCallback(
    ({ search }) => {
      history.push(
        `${pathname}?${qs.stringify({ ...params, page: 1, search })}`,
      );
    },
    [history, pathname, params],
  );
};
