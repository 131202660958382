import MuiButtonBase from './ButtonBase';
import MuiCard from './Card';
import MuiCardHeader from './CardHeader';
import MuiChip from './Chip';
import MuiUseMediaQuery from './UseMediaQuery';
import MuiSkeleton from './Skeleton';
import MuiButton from './Button';
import MuiLoadingButton from './LoadingButton';
import MuiSwitch from './Switch';
import MuiTextField from './TextField';
import MuiFormLabel from './FormLabel';
import MuiInputLabel from './InputLabel';
import MuiSelect from './Select';
import MuiInput from './Input';

function injectTheme(props) {
  return component => {
    if (typeof component === 'function') {
      return component(props);
    }

    return component;
  };
}

const components = theme => {
  const inject = injectTheme({ theme });
  return {
    components: {
      ...inject(MuiButtonBase),
      ...inject(MuiCard),
      ...inject(MuiCardHeader),
      ...inject(MuiChip),
      ...inject(MuiUseMediaQuery),
      ...inject(MuiSkeleton),
      ...inject(MuiButton),
      ...inject(MuiLoadingButton),
      ...inject(MuiSwitch),
      ...inject(MuiTextField),
      ...inject(MuiFormLabel),
      ...inject(MuiInputLabel),
      ...inject(MuiSelect),
      ...inject(MuiInput),
    },
  };
};

export default components;
