export default ({ theme }) => ({
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary[700],
          },
        },
      },
      {
        props: { variant: 'contained', color: 'red' },
        style: {
          backgroundColor: theme.palette.red.main,
          color: theme.palette.red.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.red.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'red' },
        style: {
          borderColor: theme.palette.red.main,
          color: theme.palette.red.main,
          '&:hover': {
            backgroundColor: `${theme.palette.red.main}0F`,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'dark' },
        style: {
          borderColor: theme.palette.dark.dark,
          color: theme.palette.dark.dark,
          '&:hover': {
            backgroundColor: `${theme.palette.dark.dark}0F`,
          },
        },
      },
      {
        props: { variant: 'text', color: 'dark' },
        style: {
          color: theme.palette.dark.dark,
          '&:hover': {
            backgroundColor: `${theme.palette.dark.dark}0F`,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'green' },
        style: {
          backgroundColor: theme.palette.green[700],
          '&:hover': {
            backgroundColor: theme.palette.green[600],
          },
        },
      },
    ],
  },
});
