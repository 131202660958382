import React, { useCallback, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import CreateIcon from '@mui/icons-material/Create';
import { usePagination } from '@utils/usePagination';
import { useSorting } from '@utils/useSorting';
import routes from '@routes';
import { useListing } from '@utils/useListing';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import {
  TablePagination,
  TableHeadCell,
  TableHeadRow,
  TableContainer,
  TableActionsCell,
  TableBody,
  TableRow,
  Table,
} from '@components/Table';
import DeleteIconButton from '@components/DeleteIconButton';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InnerHeader from './InnerHeader';

const excludeParams = ['tab'];

const EventsPage = ({
  eventsPageStore: {
    getEvents,
    isLoading,
    deleteEvent,
    isDeletingId,
    matchPageUpcomingSearchQuery,
    matchPageLiveSearchQuery,
    matchPagePastSearchQuery,
    eventsUpcoming,
    eventsLive,
    eventsPast,
  },
  columns,
  scope,
}) => {
  const getEventsParametrized = useCallback(
    param => getEvents({ ...param, scope }),
    [getEvents],
  );

  const currentResource = useMemo(() => {
    switch (scope) {
      case 'upcoming':
        return { list: eventsUpcoming, matcher: matchPageUpcomingSearchQuery };
      case 'live':
        return { list: eventsLive, matcher: matchPageLiveSearchQuery };
      case 'past':
        return { list: eventsPast, matcher: matchPagePastSearchQuery };
      default:
        return null;
    }
  }, [scope, eventsLive, eventsPast, eventsUpcoming]);

  const { params, isParamsMatching } = useListing(
    getEventsParametrized,
    currentResource?.matcher,
    { excludeParams },
  );

  const handlePageChange = usePagination(params);

  const handleSorting = useSorting(params);

  const currentList = currentResource?.list;

  const { results: eventsList, page_number: page, count } = currentList || {};

  const handleDelete = async ({ id }) => {
    await deleteEvent({
      id,
      onSuccess: async () => {
        toast.cSuccess('Event deleted!');
        await getEvents({ ...params, silent: true, scope });
      },
      onError: () => toast.cError('Deleting event failed'),
    });
  };

  const cols = useMemo(() => columns(), []);

  return (
    <>
      <InnerHeader />
      <TableContainer>
        <Table
          isLoading={isLoading || !isParamsMatching}
          colCount={cols.length + 1}
          noContent={count === 0}
        >
          <TableHead>
            <TableHeadRow>
              {cols.map(col => (
                <TableHeadCell
                  key={col.key}
                  sortingParam={params.sort}
                  sortingKey={col.sortingKey}
                  onSort={handleSorting}
                >
                  {col.label}
                </TableHeadCell>
              ))}
              <TableHeadCell />
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {eventsList?.map(item => (
              <TableRow
                key={`row-${item.id}`}
                isLoading={item.id === isDeletingId}
              >
                {cols.map(col => (
                  <TableCell key={`row-${item.id}-${col.key}`}>
                    <col.cellContent rowData={item} />
                  </TableCell>
                ))}
                <TableActionsCell>
                  <Button
                    component={Link}
                    variant="contained"
                    startIcon={<CreateIcon />}
                    to={`${routes.event(item.id)}`}
                  >
                    Edit
                  </Button>
                  <DeleteIconButton
                    warn
                    onClick={() => handleDelete({ id: item.id })}
                  />
                </TableActionsCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={count}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default inject('eventsPageStore')(observer(EventsPage));
