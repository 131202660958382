import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import some from 'lodash/some';
import { MAILER_TYPE } from '@constants';

const initialState = {
  isLoading: false,
  email: null,
  defaultFormValues: {},
  isLoadingUsers: false,
  users: null,
  emailTranslationJson: {},
  event: null,
  initialLoading: true,
};

export class EmailStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getEmail = async ({ id }) => {
    try {
      this.isLoading = true;
      this.email = await ResourcesStore.getEmail({ id });
    } catch (err) {
      console.debug('[getEmail] failed', err);
      throw err;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateEmail = async ({ id, values, onSuccess }) => {
    this.isLoading = true;

    try {
      await ResourcesStore.updateEmail({ id, payload: values, onSuccess });
    } catch (err) {
      console.debug('[updateProduct] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteEmail = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteEmail({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteEmail] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action cancelEmail = async ({ emailId, eventId, onSuccess }) => {
    this.isLoading = true;

    await ResourcesStore.cancelEmail({ id: emailId, eventId, onSuccess });

    this.isLoading = false;
  };

  @action getUserSelectOptions = async ({ search, id }) => {
    this.isLoadingUsers = true;
    try {
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
      });

      this.users = users;
      return this.users;
    } catch (err) {
      console.debug('[getUserSelectOptions] failed', err);
      return [];
    } finally {
      this.isLoadingUsers = false;
    }
  };

  @action getDefaultEmailTranslation = async (lang = 'en') => {
    try {
      const results = await ResourcesStore.getJsonTranslation({
        lang,
      });

      const mailingTranslationKeys = [];
      // eslint-disable-next-line no-unused-vars
      Object.entries(MAILER_TYPE).forEach(([key, value]) =>
        mailingTranslationKeys.push(`mailers:${value.toLowerCase()}`),
      );
      const defaultEmailTranslations = pickBy(results, (value, key) =>
        some(mailingTranslationKeys, str => includes(key, str)),
      );
      Object.keys(defaultEmailTranslations)
        .filter(key => key.startsWith(`mailers:`))
        .forEach(key => {
          defaultEmailTranslations[key.replace(`mailers:`, ``)] =
            defaultEmailTranslations[key];
          delete defaultEmailTranslations[key];
        });

      this.emailTranslationJson = defaultEmailTranslations;
    } catch {
      if (process.env.REACT_APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Try runing www server first');
      }
    }
  };

  @action getHelpResources = async ({ id }) => {
    try {
      this.initialLoading = true;

      const promises = [
        this.getEmail({ id }),
        this.getDefaultEmailTranslation(),
      ];

      await Promise.allSettled(promises);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[EmailStore getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.email,
    };
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new EmailStore();
