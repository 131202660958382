import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '@utils/stripeUtils';
import StripeForm from '@pages/signup/components/payments/StripeForm';
import { Loader } from '@components/Loader';
import { Title, Description } from '@pages/signup/components/AccountCreate';

const PaymentMethod = ({
  paymentStore: {
    clientSecret,
    getClientSecret,
    isLoading,
    errorMessage,
    setErrorMessage,
  },
}) => {
  useEffect(() => {
    getClientSecret();
  }, []);

  const tabShadow = '0px 10px 28px -20px var(--colorPrimary)';

  const appearance = {
    theme: 'stripe',
    rules: {
      '.Input': {
        backgroundColor: 'transparent',
        border: 0,
        borderBottom: '1px solid #878DA4',
        borderRadius: 0,
        paddingLeft: 0,
        outline: 'none',
        color: '#fff',
      },
      '.Input:focus': {
        outline: 'none',
        borderBottom: '1px solid #fff',
        boxShadow: 0,
      },
      '.Input--invalid': {
        boxShadow: 0,
      },
      '.Input::placeholder': { color: '#878DA4' },
      '.Tab': {
        border: '2px solid var(--p-colorBackgroundDeemphasize10)',
      },
      '.Tab:focus': {
        boxShadow: tabShadow,
      },
      '.Tab--selected': {
        boxShadow: tabShadow,
      },
      '.Tab--selected:focus': {
        boxShadow: tabShadow,
      },
      '.Label': {
        color: '#878DA4',
      },
    },
    variables: {
      colorPrimary: 'red',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (errorMessage) {
    return (
      <>
        <Title>Payment error</Title>
        <Description>
          There was an error while processing the credit card. Please try again.
        </Description>
        <Description color="red">Error: {errorMessage}</Description>
        <Box display="flex" justifyContent="flex-end" mt="15px">
          <Button
            size="large"
            variant="contained"
            onClick={() => setErrorMessage(null)}
          >
            RETRY
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Title>Payment method</Title>
      {isLoading && <Loader width="100px" />}

      {clientSecret && !isLoading && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm />
        </Elements>
      )}
    </>
  );
};

export default inject('paymentStore', 'authStore')(observer(PaymentMethod));
