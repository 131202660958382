import React from 'react';
import { action, extendObservable, computed } from 'mobx';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@app/api';
import ResourcesStore from '@stores/resourcesStore';
import { modalStore, assignmentsStore, routerStore } from '@stores';
import CloningSpeakerOptionsModal from '@components/modals/CloningSpeakerOptionsModal';
import { SpeakerCloneService } from '@app/CloningServices/SpeakerCloneService';
import routes from '@routes';
import {
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';

const initialState = {
  isLoading: false,
  initialLoading: true,
  speaker: {},
  defaultFormValues: {
    ...assignmentsSetupDefaultFormValues({}),
  },
  cloneRunning: false,
  phase: '',
  formType: null,
};

export class SpeakerStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateSpeaker(...props);
    }
    if (this.isCreate) {
      return this.createSpeaker(...props);
    }

    return null;
  };

  @action getSpeaker = async ({ id }) => {
    try {
      this.speaker = await ResourcesStore.getSpeaker(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getSpeaker] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.presenters);
      }
      throw err;
    }
  };

  @action updateSpeaker = async ({ values, onSuccess, ...props }) => {
    this.isLoading = true;

    try {
      const preparedValues = this.prepareValuesToSubmit(values);

      const onS = vals => {
        if (onSuccess) onSuccess(vals);
        this.speaker = vals;
        this.setupDefaultValues();
      };

      await ResourcesStore.updateSpeaker({
        id: this.speaker.id,
        payload: preparedValues,
        onSuccess: onS,
        ...props,
      });
    } catch (err) {
      console.debug('[updateSpeaker] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action createSpeaker = async ({ values, ...props }) => {
    this.isLoading = true;
    try {
      const preparedValues = this.prepareValuesToSubmit(values);

      await ResourcesStore.createSpeaker({
        payload: preparedValues,
        parentId: preparedValues.event_id,
        ...props,
      });
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteSpeaker = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteSpeaker({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteSpeaker] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;

    try {
      await Promise.all([
        this.isEdit && this.getSpeaker({ id }),
        assignmentsStore.getResources({
          types: {
            [ASSIGNMENT_TYPE.EVENT]: true,
            [ASSIGNMENT_TYPE.AGENDA]: true,
          },
        }),
      ]);

      this.setupDefaultValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultValues = () => {
    this.defaultFormValues = {
      ...this.speaker,
      ...assignmentsSetupDefaultFormValues({
        item: {
          ...this.speaker,
          agenda_item_assignments: this.speaker.agenda_item_ids,
        },
        events: assignmentsStore.events.list,
        agendas: assignmentsStore.agendaItems.list,
        groups: assignmentsStore.groups.list,
      }),
    };
  };

  @action prepareValuesToSubmit = values => {
    const { assignments_attributes, event_id } =
      assignmentsPrepareValues({
        values,
        initialValues: this.defaultFormValues,
      }) || {};
    return {
      ...values,
      event_id,
      agenda_item_ids:
        assignments_attributes.map(ai => ai.agenda_item_id) || [],
      account_url_facebook: values.account_url_facebook
        ? values.account_url_facebook
        : '',
      account_url_linkedin: values.account_url_linkedin
        ? values.account_url_linkedin
        : '',
      account_url_twitter: values.account_url_twitter
        ? values.account_url_twitter
        : '',
      account_url_youtube: values.account_url_youtube
        ? values.account_url_youtube
        : '',
    };
  };

  @action handleCloneSpeaker = () => {
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'speakerCloneModal',
      content: <CloningSpeakerOptionsModal onClone={this.cloneProcess} />,
    });
  };

  @action cloneProcess = async ({ values }) => {
    this.cloneRunning = true;
    this.phase = 'Presenter';
    try {
      const preparedSpeaker = await SpeakerCloneService.prepareSpeaker(
        this.speaker.id,
      );

      const { assignments_attributes, event_id } =
        assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }) || {};

      const res = await ResourcesStore.createSpeaker({
        parentId: event_id,
        payload: {
          ...preparedSpeaker,
          event_id,
          agenda_item_ids:
            assignments_attributes.map(ai => ai.agenda_item_id) || [],
        },
        requestOptions: {
          headers: {
            bypass_uploaders_validation: true,
          },
        },
      });

      this.defaultFormValues = {};
      routerStore.push(`${routes.presenter(res.id)}`);
      modalStore.closeModal();
    } catch (err) {
      console.debug('[cloneProcess] failed', err);
    } finally {
      this.cloneRunning = false;
      this.phase = '';
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    modalStore.killModal('speakerCloneModal');
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new SpeakerStore();
