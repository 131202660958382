import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  organizations: null,
};

export class OrganizationsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getOrganizations = async ({ page, search, sort, silent }) => {
    try {
      if (!silent) this.isLoading += 1;

      const result = await ResourcesStore.getOrganizations({
        page,
        search,
        sort,
      });

      this.organizations = {
        ...result,
        sort,
        search,
        page,
      };
    } catch (err) {
      console.debug('[getOrganizations] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['page', 'search', 'sort'].every(
      key => this.organizations?.[key] === params[key],
    );
  };
}

export default new OrganizationsPageStore();
