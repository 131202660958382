import React, { memo, useMemo, useEffect } from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { VectorMap } from 'react-jvectormap';
import '@vendor/jvectormap.css';

const WorldMap = ({ markers, markerSize }) => {
  const theme = useTheme();

  useEffect(() => {
    // remove map tooltip placeholder
    return () => {
      const mapTooltipPlaceholder = Array.from(
        document.querySelectorAll('.jvectormap-tip'),
      );
      mapTooltipPlaceholder.forEach((tt, index) => {
        if (index !== 0) {
          tt.remove();
        }
      });
    };
  }, [markers]);

  const options = useMemo(
    () => ({
      map: 'world_mill',
      regionStyle: {
        initial: {
          fill:
            theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.3)'
              : '#e3eaef',
        },
      },
      backgroundColor: 'transparent',
      containerStyle: {
        width: '100%',
        height: '100%',
      },
      markerStyle: {
        initial: {
          r: markerSize || 3,
          fill: theme.palette.primary.main,
          'fill-opacity': 1,
          stroke: '#fff',
          'stroke-width': 7,
          'stroke-opacity': 0.4,
        },
        hover: {
          stroke: '#fff',
          'fill-opacity': 1,
          'stroke-width': 1.5,
        },
      },
      zoomOnScroll: false,
      markers,
    }),
    [markers, theme],
  );

  return (
    <VectorMap
      {...options}
      onRegionTipShow={(e, el) => {
        el.css('z-index', 9999);
      }}
    />
  );
};

export const MarkerInterface = PropTypes.shape({
  latLng: PropTypes.arrayOf(PropTypes.number),
  name: PropTypes.string.isRequired,
});

WorldMap.propTypes = {
  markers: PropTypes.arrayOf(MarkerInterface).isRequired,
};

export default memo(WorldMap);
