import React from 'react';
import { action, computed, extendObservable } from 'mobx';
import isNull from 'lodash/isNull';
import ResourcesStore from '@stores/resourcesStore';
import subscriptionStore from '@stores/subscriptionStore';
import authStore from '@stores/authStore';
import profileStore from '@stores/profileStore';
import breakoutRoomStore from '@stores/breakoutRoomStore';
import agendaItemStore from '@stores/agendaItemStore';
import {
  manipulateRegistrationFieldsArray,
  prepareSelectOptions,
  mapRfErrors,
} from '@utils/formUtils';
import { API, APIRoutes, CancelToken } from '@api';
import routes from '@routes';
import set from 'lodash/set';
import modalStore from '@stores/modalStore';
import CloningEventOptionsModal from '@app/components/modals/CloningEventOptionsModal';
import routerStore from '@stores/routerStore';
import { FORM_ERROR } from 'final-form';
import {
  MARKETO_SYNC_FREQUENCY,
  ADDEVENT_CALENDAR_DEFAULT_VALUES,
  FORM_TYPES,
  AGENDA_TYPES,
  STICKER_SELECT_OPTIONS,
  PLAYER_TYPES,
  PLAYER_DESTINY_TYPES,
  MAILER_TYPE,
  PUBLISHING_STATUS,
} from '@constants';
import axios from 'axios';
import moment from 'moment';
import { ROLES } from '@app/authManager';
import urlSlug from 'url-slug';
import { createBlobFile } from '@utils/fileUtils';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import some from 'lodash/some';

const initialState = {
  // utils
  formType: null,
  isLoading: false,
  isDeletingAgendaId: null,
  isDeletingEmailId: null,
  initialMainSectionLoading: true,
  initialSettingsSectionLoading: true,
  isLinkedEventsLoading: false,
  initialSpeakersSectionLoading: true,
  initialDocumentsSectionLoading: true,
  initialProductsSectionLoading: true,
  initialAgendaSectionLoading: true,
  initialLinkedEventSectionLoading: true,
  initialStickersSectionLoading: true,
  initialPlayerSectionLoading: true,
  isStickersLoading: false,
  isPlayersLoading: false,
  isEmailPreviewLoading: false,
  initialEmailsSectionLoading: true,
  cloneRunning: false,

  // requests promises
  promises: {},

  // form
  defaultFormValues: {},

  event: null,
  organizations: [],
  eventThemeFields: null,
  eventThemeGallery: [],
  availablefonts: [],
  multiMarketGroups: [],
  currentOrganization: null,
  player: null,
  backup_player: null,
  linkedEvents: [],
  unlinkedEvents: [],
  documents: [],
  products: [],
  speakers: [],
  agendaItems: [],
  stickers: [],
  emails: [],
  emailTranslationJson: {},
};

const resolveDependencies = async dependencies => {
  const result = await Promise.all(dependencies);

  const err = result.find(item => item !== null);
  if (err) {
    throw new Error(err);
  }
};

const returnErrorMessage = (err, message) => {
  if (axios.isCancel(err)) {
    return 'Request canceled';
  }
  return message;
};

export class EventStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @computed get isNotInitializedType() {
    return this.formType === null;
  }

  @action setupForm = (id, stagesGroupMainEvent = false) => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      if (stagesGroupMainEvent) {
        this.getMainStageEventHelpResources({ id });
      } else {
        this.getHelpResources({ id });
      }
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateEvent(...props);
    }
    if (this.isCreate) {
      return this.createEvent(...props);
    }

    return null;
  };

  @action getHelpResources = async ({ id }) => {
    // all promises are stored in this.promises.
    // Start setting up sections as soon as all promises
    // required for that section was called (you can await them in the setup function)

    try {
      const sectionPromises = [];

      if (this.isEdit) {
        this.promises.getEvent = this.getEvent({ id });
        this.promises.getDocuments = this.getDocuments({ id });
        this.promises.getProducts = this.getProducts({ id });
        this.promises.getSpeakers = this.getSpeakers({ id });
        this.promises.getAgendaItems = this.getAgendaItems({ id });
        this.promises.getStickers = this.getStickers({ id });
        this.promises.getEmails = this.getEmails({ id });
        this.promises.getDefaultEmailTranslation = this.getDefaultEmailTranslation();
        sectionPromises.push(this.setupStickersSection());
        sectionPromises.push(this.setupAgendaSection());
        sectionPromises.push(this.setupEmailsSection());
      }
      this.promises.getOrganizations = this.getOrganizations();
      this.promises.getThemeFields = this.getThemeFields();
      this.promises.getThemeGallery = this.getThemeGallery();

      if (this.isEdit) {
        await resolveDependencies([this.promises.getEvent]);

        const orgId = this.event.organization_id;
        const playerId = this.event.player?.id;
        const backupPlayerId = this.event.backup_player?.id;

        this.promises.getPlayer = this.getPlayer({ playerId, backupPlayerId });
        sectionPromises.push(this.setupPlayerSection());

        this.promises.getMultiMarketGroups = this.getMultiMarketGroups({
          orgId,
        });
        this.promises.getCurrentOrg = this.getCurrentOrg({ orgId });
        this.promises.getLinkedEvents = this.getLinkedEvents({ orgId });
        this.promises.getUnlinkedEvents = this.getUnlinkedEvents({
          orgId,
          per: '-1',
          page: '1',
        });
        sectionPromises.push(this.setupLinkedEventsSection());
      }

      sectionPromises.push(this.setupMainSection());
      sectionPromises.push(this.setupSpeakersSection());
      sectionPromises.push(this.setupDocumentsSection());
      sectionPromises.push(this.setupProductsSection());

      await resolveDependencies(sectionPromises);
    } catch (err) {
      console.debug(
        '[getHelpResources] failed - form failed to initialize',
        err,
      );
    }
  };

  @action getMainStageEventHelpResources = async ({ id }) => {
    // all promises are stored in this.promises.
    // Start setting up sections as soon as all promises
    // required for that section was called (you can await them in the setup function)

    try {
      const sectionPromises = [];

      if (this.isEdit) {
        this.promises.getEvent = this.getEvent({ id });
        // this.promises.getDocuments = this.getDocuments({ id });
        this.promises.getAgendaItems = this.getAgendaItems({ id });
        this.promises.getStickers = this.getStickers({ id });
        sectionPromises.push(this.setupStickersSection());
        sectionPromises.push(this.setupAgendaSection());
      }
      this.promises.getOrganizations = this.getOrganizations();
      this.promises.getThemeFields = this.getThemeFields();
      this.promises.getThemeGallery = this.getThemeGallery();

      if (this.isEdit) {
        await resolveDependencies([this.promises.getEvent]);

        const orgId = this.event.organization_id;
        const playerId = this.event.player?.id;
        const backupPlayerId = this.event.backup_player?.id;

        this.promises.getPlayer = this.getPlayer({ playerId, backupPlayerId });
        sectionPromises.push(this.setupPlayerSection());

        this.promises.getMultiMarketGroups = this.getMultiMarketGroups({
          orgId,
        });
        this.promises.getCurrentOrg = this.getCurrentOrg({ orgId });
        this.promises.getLinkedEvents = this.getLinkedEvents({ orgId });
        this.promises.getUnlinkedEvents = this.getUnlinkedEvents({
          orgId,
          per: '-1',
          page: '1',
        });
        sectionPromises.push(this.setupLinkedEventsSection());
      }

      sectionPromises.push(this.setupMainSection());
      await resolveDependencies(sectionPromises);
    } catch (err) {
      console.debug(
        '[getMainStageEventHelpResources] failed - form failed to initialize',
        err,
      );
    }
  };

  // section setups

  @action setupMainSection = async () => {
    try {
      this.initialMainSectionLoading = true;
      this.initialSettingsSectionLoading = true;

      await resolveDependencies(
        [
          this.isEdit && this.promises.getEvent,
          this.promises.getOrganizations,
          this.promises.getThemeFields,
          this.promises.getThemeGallery,
        ].filter(Boolean),
      );

      this.setupDefaultFormValues();
      this.initialMainSectionLoading = false;

      if (this.isEdit) {
        await resolveDependencies([
          this.promises.getMultiMarketGroups,
          this.promises.getCurrentOrg,
        ]);
      }

      this.initialSettingsSectionLoading = false;

      return null;
    } catch (err) {
      return `setupMainSection - ${err.message}`;
    }
  };

  @action setupLinkedEventsSection = async () => {
    try {
      this.initialLinkedEventSectionLoading = true;

      await resolveDependencies([
        this.promises.getEvent,
        this.promises.getLinkedEvents,
        this.promises.getUnlinkedEvents,
      ]);

      this.initialLinkedEventSectionLoading = false;

      return null;
    } catch (err) {
      return `setupLinkedEventsSection - ${err.message}`;
    }
  };

  @action setupStickersSection = async () => {
    try {
      this.initialStickersSectionLoading = true;

      await resolveDependencies([this.promises.getStickers]);

      this.initialStickersSectionLoading = false;

      return null;
    } catch (err) {
      return `setupStickersSection - ${err.message}`;
    }
  };

  @action setupAgendaSection = async () => {
    try {
      this.initialAgendaSectionLoading = true;

      await resolveDependencies([
        this.promises.getAgendaItems,
        this.promises.getEvent,
      ]);

      this.initialAgendaSectionLoading = false;

      return null;
    } catch (err) {
      return `setupAgendaSection - ${err.message}`;
    }
  };

  @action setupSpeakersSection = async () => {
    try {
      this.initialSpeakersSectionLoading = true;

      if (this.isEdit) {
        await resolveDependencies([this.promises.getSpeakers]);
      }

      this.initialSpeakersSectionLoading = false;

      return null;
    } catch (err) {
      return `setupSpeakersSection - ${err.message}`;
    }
  };

  @action setupDocumentsSection = async () => {
    try {
      this.initialDocumentsSectionLoading = true;

      if (this.isEdit) {
        await resolveDependencies([this.promises.getDocuments]);
      }

      this.initialDocumentsSectionLoading = false;

      return null;
    } catch (err) {
      return `setupDocumentsSection - ${err.message}`;
    }
  };

  @action setupProductsSection = async () => {
    try {
      this.initialProductsSectionLoading = true;

      if (this.isEdit) {
        await resolveDependencies([this.promises.getProducts]);
      }

      this.initialProductsSectionLoading = false;

      return null;
    } catch (err) {
      return `setupProductsSection - ${err.message}`;
    }
  };

  @action setupPlayerSection = async () => {
    try {
      this.initialPlayerSectionLoading = true;

      await resolveDependencies([this.promises.getPlayer]);
      this.initialPlayerSectionLoading = false;

      return null;
    } catch (err) {
      return `setupPlayerSection - ${err.message}`;
    }
  };

  @action setupEmailsSection = async () => {
    try {
      this.initialEmailsSectionLoading = true;

      await resolveDependencies([this.promises.getEmails]);

      this.initialEmailsSectionLoading = false;

      return null;
    } catch (err) {
      return `setupEmailsSection - ${err.message}`;
    }
  };

  // requests

  @action getEvent = async ({ id }) => {
    try {
      const ev = await ResourcesStore.getEvent(
        { id },
        { cancelToken: this.__cancelToken.token },
      );

      this.event = this.manipulateEventObject(ev);
      subscriptionStore.setContextEvent(this.event);

      return null;
    } catch (err) {
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.events);
      }
      return returnErrorMessage(err, 'getEvent failed');
    }
  };

  @action getOrganizations = async () => {
    try {
      const orgs = await ResourcesStore.getOrganizationSelectOptions(
        {
          additionalResponseFields: ['domain', 'sso_enabled'],
          additionalResourceFields: ['organization_registration_fields'],
        },
        {
          cancelToken: this.__cancelToken.token,
        },
      );

      this.organizations = orgs;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getOrganizations failed');
    }
  };

  @action getThemeFields = async () => {
    try {
      const data = await ResourcesStore.getEventThemeFields({
        cancelToken: this.__cancelToken.token,
      });
      // move fields to theme manually
      data.push(
        ...[
          { name: 'event_listing_custom_title', type: 'rich_text' },
          { name: 'header_custom_title', type: 'rich_text' },
          { name: 'event_image', type: 'ImageUploader' },
          { name: 'event_image_thumbnail', type: 'ImageUploader' },
          { name: 'player_placeholder_image', type: 'ImageUploader' },
        ],
      );

      this.eventThemeFields = data.reduce((o, k) => {
        return { ...o, [k.name]: k.type };
      }, {});

      this.availablefonts = data.find(r => r.type === 'font_config')?.options;

      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getThemeFields failed');
    }
  };

  @action getThemeGallery = async () => {
    try {
      const data = await ResourcesStore.getEventThemeGallery({
        cancelToken: this.__cancelToken.token,
      });

      this.eventThemeGallery = data;

      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getThemeGallery failed');
    }
  };

  @action getMultiMarketGroups = async ({ orgId }) => {
    try {
      const { results } = await ResourcesStore.getMultiMarketGroups(
        { orgId },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.multiMarketGroups = [
        { label: 'None', value: null },
        ...prepareSelectOptions(
          results,
          el => {
            const evNames = el.events.map(item => item.name);

            return `${el.name}: ${evNames.join(', ')}`;
          },
          el => el.id,
        ),
      ];
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getMultiMarketGroups failed');
    }
  };

  @action getCurrentOrg = async ({ orgId }) => {
    try {
      this.currentOrganization = await ResourcesStore.getOrganization(
        {
          id: orgId,
        },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getCurrentOrg failed');
    }
  };

  @action getLinkedEvents = async ({ orgId }) => {
    try {
      const { results } = await ResourcesStore.getLinkedEvents(
        { orgId },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.linkedEvents = results;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getLinkedEvents failed');
    }
  };

  @action getUnlinkedEvents = async ({ orgId, page, per }) => {
    try {
      const { results } = await ResourcesStore.getUnlinkedEvents(
        {
          orgId,
          page,
          per,
        },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.unlinkedEvents = results;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getUnlinkedEvents failed');
    }
  };

  @action getDocuments = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getEntityDocuments(
        {
          id,
          type: 'event',
          per: -1,
        },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.documents = results;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getDocuments failed');
    }
  };

  @action getProducts = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getEntityProducts(
        {
          id,
          type: 'event',
          per: '-1',
        },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.products = results;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getProducts failed');
    }
  };

  @action getSpeakers = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getEntitySpeakers(
        {
          id,
          type: 'event',
          per: '-1',
        },
        {
          cancelToken: this.__cancelToken?.token,
        },
      );
      this.speakers = results;
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getSpeakers failed');
    }
  };

  @action getAgendaItems = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getAgendaItems({
        event: id,
        sort: 'start_date ASC',
      });

      this.agendaItems = results;

      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getAgendaItems failed');
    }
  };

  @action getStickers = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getEntityStickers({
        id,
      });
      this.stickers = results;

      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getStickers failed');
    }
  };

  @action getPlayer = async ({ playerId, backupPlayerId }) => {
    try {
      if (playerId) {
        this.player = await ResourcesStore.getPlayer({
          id: playerId,
        });
      }
      if (backupPlayerId) {
        this.backup_player = await ResourcesStore.getPlayer({
          id: backupPlayerId,
        });
      }
      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getPlayer failed');
    }
  };

  // end requests
  // utils

  @action setupDefaultFormValues = () => {
    if (this.isEdit) {
      if (!this.event.event_custom_theme) {
        this.event.event_custom_theme = {};
      }
      if (!this.event.event_custom_theme.body_font_family) {
        this.event.event_custom_theme.body_font_family = 'DEFAULT';
      }
      if (!this.event.event_custom_theme.header_font_family) {
        this.event.event_custom_theme.header_font_family = 'DEFAULT';
      }

      const referencedOrganization = this.organizations.find(
        el => el.value.id === this.event.organization_id,
      );

      if (!this.event.marketo_sync_frequency) {
        this.event.marketo_sync_frequency = MARKETO_SYNC_FREQUENCY[2].value;
      }
      const {
        addevent_calendar_organizer_email,
        addevent_calendar_reminder,
      } = this.event;

      this.event.addevent_calendar_organizer_email = !addevent_calendar_organizer_email
        ? this.event.help_url || ADDEVENT_CALENDAR_DEFAULT_VALUES.email
        : addevent_calendar_organizer_email;

      this.event.addevent_calendar_reminder = !addevent_calendar_reminder
        ? ADDEVENT_CALENDAR_DEFAULT_VALUES.reminder
        : addevent_calendar_reminder;

      this.defaultFormValues = {
        ...this.event,
        organization_id: referencedOrganization,
        time_zone: this.event.time_zone
          ? { label: this.event.time_zone, value: this.event.time_zone }
          : null,
      };
    }

    if (this.isCreate) {
      const { managed_organizations_details: managedOrgs } =
        profileStore.profile || {};

      this.defaultFormValues = {
        default_show_agenda_column: false,
        default_show_chat_column: false,
        chat_enabled: true,
        show_user_join_chat: true,
        allow_chat_post_event: true,
        chat_emotes_enabled: true,
        allow_reporting_messages: true,
        hide_event_chat_attendees: true,
        hybrid_mode_enabled: false,
        allow_users_to_select_attendance_mode: false,
        hide_attendees_mode: false,
        chat_mode: 'global',
        login_method: 'with_email',
        planned_start_date: moment()
          .utc()
          .toISOString(),
        document_panel_enabled: true,
        product_panel_enabled: false,
        recaptcha_enabled: false,
        organization_id: authStore.checkIfUserIs([ROLES.ADMIN])
          ? { label: managedOrgs[0].name, value: managedOrgs[0] }
          : null,
        play_event_video_between_ais: false,
        form_preticked_networking: true,
        agenda_times_and_dates_locked_post_event: true,
        spatial_event_default_bg: 'not_selected',
        hide_browse_events: true,
        hide_registration_form: true,
        hide_login_form: true,
        show_profile_pic_modal: false,
        platform_lite_settings: true,
        show_help_button: false,
        stay_on_event_brief_after_logging_out: true,
        event_custom_theme: {
          hide_agenda_info_icon: false,
          hide_tabbed_sections_bar: false,
          hide_icons: false,
          hide_header_logo: true,
          hide_tabbed_sections_bar_unauthorised_page: true,
          hide_date_and_time: true,
          show_breakout_rooms_on_event_brief: true,
          hide_event_brief_details: true,
          hide_event_name_on_brief: true,
          header_logo_width_percent: 10,
          body_font_family: 'DEFAULT',
          header_font_family: 'DEFAULT',
          _stream_reaction_icon_pos: 'left',
          show_hybrid_placeholder_on_mobile: false,
        },
        marketo_sync_frequency: MARKETO_SYNC_FREQUENCY[2].value,
        addevent_calendar_reminder: ADDEVENT_CALENDAR_DEFAULT_VALUES.reminder,
        addevent_calendar_organizer_email:
          ADDEVENT_CALENDAR_DEFAULT_VALUES.email,
      };
    }
  };

  initPlayerValues = ({ isPrimary }) => {
    if (!isNull(this.backup_player) && !isPrimary) return this.backup_player;
    if (!isNull(this.player) && isPrimary) return this.player;

    return {
      player_type: PLAYER_TYPES.DEFAULT,
      playable_type: PLAYER_DESTINY_TYPES.EVENT,
      aws_hls_stream_stack_id: null,
      aws_hls_stack_name: urlSlug(this.event?.name),
      aws_hls_stream_stack_encoding_profile: 'HD-1080p',
    };
  };

  @action manipulateEventObject = ev => {
    const {
      event_listing_custom_title: eventListingCustomTitle,
      header_custom_title: headerCustomTitle,
      event_image: eventImage,
      event_image_thumbnail: eventImageThumbnail,
      player_placeholder_image: playerPlaceholderImage,
      hide_browse_events: hideBrowseEvents,
      hide_registration_form: hideRegistrationForm,
      hide_login_form: hideLoginForm,
      allow_chat_post_event: allowChatPostEvent,
      hide_event_chat_attendees: hideEventChatAttendees,
      default_show_agenda_column: defaultShowAgendaColumn,
      default_show_chat_column: defaultShowChatColumn,
      price_cents,
      ...event
    } = ev;

    const {
      hide_header_logo: hideHeaderLogo,
      hide_date_and_time: hideDateAndTime,
      hide_tabbed_sections_bar_unauthorised_page: hideTabbedSectionsBar,
      event_brief_text_first: eventBriefTextFirst,
      hide_event_brief_details: hideEventBriefDetails,
      hide_event_name_on_brief: hideEventNameOnBrief,
      ...themeRest
    } = ev.event_custom_theme;

    return {
      ...event,
      hide_browse_events: !hideBrowseEvents,
      hide_registration_form: !hideRegistrationForm,
      hide_login_form: !hideLoginForm,
      allow_chat_post_event: !allowChatPostEvent,
      hide_event_chat_attendees: !hideEventChatAttendees,
      default_show_agenda_column: !defaultShowAgendaColumn,
      default_show_chat_column: !defaultShowChatColumn,
      price_cents: (price_cents / 100).toString(),
      event_custom_theme: {
        ...themeRest,
        event_listing_custom_title: eventListingCustomTitle,
        header_custom_title: headerCustomTitle,
        event_image: eventImage,
        event_image_thumbnail: eventImageThumbnail,
        player_placeholder_image: playerPlaceholderImage,
        hide_event_header_date: !event.event_custom_theme
          ?.hide_event_header_date,
        hide_header_logo: !hideHeaderLogo,
        hide_date_and_time: !hideDateAndTime,
        hide_tabbed_sections_bar_unauthorised_page: !hideTabbedSectionsBar,
        event_brief_text_first: !eventBriefTextFirst,
        hide_event_brief_details: !hideEventBriefDetails,
        hide_event_name_on_brief: !hideEventNameOnBrief,
      },
    };
  };

  @action prepareToSubmit = values => {
    const {
      event_listing_custom_title,
      header_custom_title,
      event_image,
      event_image_thumbnail,
      player_placeholder_image,
      hide_event_header_date,
      hide_header_logo,
      hide_date_and_time,
      hide_tabbed_sections_bar_unauthorised_page,
      event_brief_text_first,
      hide_event_brief_details,
      hide_event_name_on_brief,
      ...themeRest
    } = values.event_custom_theme;

    const {
      registration_fields,
      hide_browse_events,
      hide_registration_form,
      hide_login_form,
      allow_chat_post_event,
      hide_event_chat_attendees,
      default_show_agenda_column,
      default_show_chat_column,
      price_cents,

      // omitted values from event object
      sponsor_ids,
      linked_events_group_id,
      api_partner,

      ...newValues
    } = values;

    return {
      ...newValues,
      allow_chat_post_event: !allow_chat_post_event,
      slugs: values.slugs?.length ? values.slugs : [urlSlug(values.name)],
      hide_event_chat_attendees: !hide_event_chat_attendees,
      hide_browse_events: !hide_browse_events,
      hide_registration_form: !hide_registration_form,
      hide_login_form: !hide_login_form,
      default_show_agenda_column: !default_show_agenda_column,
      default_show_chat_column: !default_show_chat_column,
      event_listing_custom_title,
      header_custom_title,
      event_image,
      event_image_thumbnail,
      player_placeholder_image,
      event_custom_theme: {
        ...themeRest,
        hide_event_header_date: !hide_event_header_date,
        hide_header_logo: !hide_header_logo,
        hide_date_and_time: !hide_date_and_time,
        hide_tabbed_sections_bar_unauthorised_page: !hide_tabbed_sections_bar_unauthorised_page,
        event_brief_text_first: !event_brief_text_first,
        hide_event_brief_details: !hide_event_brief_details,
        hide_event_name_on_brief: !hide_event_name_on_brief,
      },
      organization_id: newValues.organization_id?.value.id,
      chat_moderator_id:
        newValues.chat_mode === 'global'
          ? null
          : newValues.chat_moderator_id || null,

      registration_fields_attributes: manipulateRegistrationFieldsArray(
        registration_fields,
        this.defaultFormValues.registration_fields,
      ),
      time_zone: newValues.time_zone ? newValues.time_zone.value : null,
      price_cents: price_cents ? Number(price_cents) * 100 : null,
    };
  };

  // endutils
  // actions

  @action updateEvent = async ({ id, values, onSuccess, onError }) => {
    this.isLoading = true;
    try {
      const preparedValues = this.prepareToSubmit(values);

      const data = await ResourcesStore.updateEvent({
        id,
        payload: preparedValues,
        onError,
      });
      this.event = this.manipulateEventObject(data);
      this.setupDefaultFormValues();
      await onSuccess?.(data);
      subscriptionStore.setContextEvent(data);
      return null;
    } catch (e) {
      const error_msgs = e?.response?.data?.errors;
      if (error_msgs) {
        return mapRfErrors(error_msgs);
      }
      return null;
    } finally {
      this.isLoading = false;
    }
  };

  @action createEvent = async ({ values, onSuccess, onError }) => {
    this.isLoading = true;
    try {
      const preparedValues = this.prepareToSubmit(values);

      const res = await ResourcesStore.createEvent({
        type: 'event',
        payload: preparedValues,
        onError,
      });

      await onSuccess?.(res);

      return null;
    } catch (e) {
      const error_msgs = e?.response?.data?.errors;
      // HACK: for RFs errors msgs - e.g 'registration_fields[0].field_name': 'BAD' have to be converted to registration_fields: [{field_name: 'BAD'}]
      if (error_msgs) {
        const error_msgs_to_return = {};
        Object.keys(error_msgs).forEach(key => {
          set(error_msgs_to_return, key, error_msgs[`${key}`]);
        });

        return error_msgs_to_return;
      }
      return null;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteEvent = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteEvent({ id, onError });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteEvent] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action reorderResource = type => async ({ startIndex, endIndex }) => {
    try {
      let finalArray = [];
      if (type === 'speakers') {
        finalArray = this.speakers;
      }
      if (type === 'documents') {
        finalArray = this.documents;
      }
      if (type === 'products') {
        finalArray = this.products;
      }
      const result = finalArray;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const ids = result.map(resource => resource.id);

      if (type === 'documents') {
        this.documents = result;
        await ResourcesStore.reorderDocuments({
          event_id: this.event.id,
          documents_ids: ids,
        });
      }
      if (type === 'products') {
        this.products = result;
        await ResourcesStore.reorderProducts({
          event_id: this.event.id,
          products_ids: ids,
        });
      }
      if (type === 'speakers') {
        this.speakers = result;
        await ResourcesStore.reorderSpeakers({
          event_id: this.event.id,
          speaker_ids: ids,
        });
      }
    } catch (err) {
      console.debug('[reorderResource] failed', err);
    }
  };

  @action deleteDocument = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteDocument({ id });
      this.documents = this.documents.filter(doc => doc.id !== id);
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteDocument] failed', err);
      await onError?.();
    } finally {
      this.isLoading = false;
    }
  };

  @action createDocument = async ({ values, form, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      const res = await ResourcesStore.createDocument({
        parentId: this.event.id,
        payload: values,
      });

      this.documents = [...this.documents, res];
      form.restart();
      onSuccess?.();
      return null;
    } catch (err) {
      console.debug('[createDocument] failed', err);
      onError?.();
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createProduct = async ({ values, form, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      const res = await ResourcesStore.createProduct({
        type: 'event',
        parentId: this.event.id,
        payload: values,
      });

      this.products = [...this.products, res];
      form.restart();
      onSuccess?.();
      return null;
    } catch (err) {
      console.debug('[createProduct] failed', err);
      onError?.();
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action createSpeaker = async ({ values, form, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      const res = await ResourcesStore.createSpeaker({
        parentId: this.event.id,
        payload: values,
      });
      this.speakers = [...this.speakers, res];
      form.restart();
      onSuccess?.();
      return null;
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
      onError?.();
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteProduct = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteProduct({ id });
      this.products = this.products.filter(p => p.id !== id);
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteProduct] failed', err);
      await onError?.();
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteSpeaker = async ({ id, onSuccess, onError }) => {
    try {
      this.isLoading = true;
      await ResourcesStore.deleteSpeaker({ id });
      this.speakers = this.speakers.filter(s => s.id !== id);
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteSpeaker] failed', err);
      await onError?.();
    } finally {
      this.isLoading = false;
    }
  };

  @action createLinkedEventsGroup = async ({ values, onSuccess, onError }) => {
    if (values.secondEventId) {
      try {
        this.isLinkedEventsLoading = true;
        const payload = {
          organization_id: this.event.organization_id,
          event_ids: [values.secondEventId, this.event.id],
        };

        await ResourcesStore.createLinkedEventsGroup({
          payload,
          onSuccess,
        });

        const orgId = this.event.organization_id;

        this.promises.getLinkedEvents = this.getLinkedEvents({ orgId });
        this.promises.getUnlinkedEvents = this.getUnlinkedEvents({
          orgId,
          page: '1',
          per: '-1',
        });

        await resolveDependencies([
          this.promises.getLinkedEvents,
          this.promises.getUnlinkedEvents,
        ]);
      } catch (err) {
        console.debug('[createLinkedEventsGroup] failed', err);
        onError();
      } finally {
        this.isLinkedEventsLoading = false;
      }
    }
  };

  @action deleteLinkedEventsGroup = async ({ id, onSuccess }) => {
    try {
      this.isLinkedEventsLoading = true;
      const event_id = { unlink_event_id: this.event.id };

      await ResourcesStore.deleteLinkedEventsGroup({
        id,
        event_id,
        onSuccess,
      });

      const orgId = this.event.organization_id;

      this.promises.getLinkedEvents = this.getLinkedEvents({ orgId });
      this.promises.getUnlinkedEvents = this.getUnlinkedEvents({
        orgId,
        page: '1',
        per: '-1',
      });

      await resolveDependencies([
        this.promises.getLinkedEvents,
        this.promises.getUnlinkedEvents,
      ]);
    } catch (err) {
      console.debug('[deleteLinkedEventsGroup] failed', err);
    } finally {
      this.isLinkedEventsLoading = false;
    }
  };

  @action updateLinkedEventsGroup = async ({ values, onSuccess }) => {
    if (values) {
      try {
        this.isLinkedEventsLoading = true;

        const { id } = values;
        const payload = {
          event_ids: [...values.event_ids, this.event.id],
        };

        await ResourcesStore.updateLinkedEventsGroup({
          id,
          payload,
          onSuccess,
        });

        const orgId = this.event.organization_id;

        this.promises.getLinkedEvents = this.getLinkedEvents({ orgId });
        this.promises.getUnlinkedEvents = this.getUnlinkedEvents({
          orgId,
          page: '1',
          per: '-1',
        });

        await resolveDependencies([
          this.promises.getLinkedEvents,
          this.promises.getUnlinkedEvents,
        ]);
      } catch (err) {
        console.debug('[updateLinkedEventsGroup] failed', err);
      } finally {
        this.isLinkedEventsLoading = false;
      }
    }
  };

  @action generateApiPartner = async () => {
    const res = await ResourcesStore.generateApiPartner(this.event.id);
    this.event.api_partner = res;
  };

  @action updateApiPartner = async ({ payload, onSuccess, onError }) => {
    try {
      if (this.event.api_partner === null) {
        await this.generateApiPartner();
      }
      const res = await ResourcesStore.updateApiPartner({
        eventId: this.event.id,
        apiPartnerId: this.event.api_partner.id,
        payload,
        onSuccess,
      });

      this.event.api_partner = res;
    } catch (err) {
      console.debug('[updateApiPartner] failure', err);
      if (onError) onError();
    }
  };

  @action getUsersList = async ({ search, id }) => {
    try {
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
        event_id: this.event?.id,
        accepted_only: this.isEdit,
      });

      return users;
    } catch (err) {
      console.debug('[getUsersList] failed');
      return [];
    }
  };

  @action getEmailPreview = async ({ email_id }) => {
    try {
      this.isEmailPreviewLoading = true;
      const data = await ResourcesStore.getEmailView({
        email_id,
      });
      this.previewedEmail = data;
    } catch (error) {
      console.debug('[getEmailPreview] failed', error);
    } finally {
      this.isEmailPreviewLoading = false;
    }
  };

  @action changePublishedStatus = async ({ onSuccess, onError }) => {
    try {
      this.isLoading = true;
      let result = null;
      if (this.event.publishing_status === PUBLISHING_STATUS.PUBLISHED) {
        result = await ResourcesStore.unpublishEvent({ id: this.event.id });
      } else {
        result = await ResourcesStore.publishEvent({ id: this.event.id });
      }
      this.event = this.manipulateEventObject(result);
      this.setupDefaultFormValues();
      subscriptionStore.setContextEvent(result);
      onSuccess?.();
    } catch (err) {
      // potentially redirect to pay for event
      console.debug('[changePublishedStatus] failed', err);
      onError?.();
    } finally {
      this.isLoading = false;
    }
  };

  // EMAIL REQUESTS

  @action getDefaultEmailTranslation = async (lang = 'en') => {
    try {
      const results = await ResourcesStore.getJsonTranslation({
        lang,
      });

      const mailingTranslationKeys = [];
      Object.entries(MAILER_TYPE).forEach(([, value]) =>
        mailingTranslationKeys.push(`mailers:${value.toLowerCase()}`),
      );
      const defaultEmailTranslations = pickBy(results, (value, key) =>
        some(mailingTranslationKeys, str => includes(key, str)),
      );
      Object.keys(defaultEmailTranslations)
        .filter(key => key.startsWith(`mailers:`))
        .forEach(key => {
          defaultEmailTranslations[key.replace(`mailers:`, ``)] =
            defaultEmailTranslations[key];
          delete defaultEmailTranslations[key];
        });

      this.emailTranslationJson = defaultEmailTranslations;
    } catch {
      if (process.env.REACT_APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Try runing www server first');
      }
    }
  };

  @action getEmails = async ({ id }) => {
    try {
      const { results } = await ResourcesStore.getEntityEmails({
        event: id,
      });

      this.emails = results;

      return null;
    } catch (err) {
      return returnErrorMessage(err, 'getEmails failed');
    }
  };

  @action createEmail = async ({ payload, onSuccess, onError }) => {
    try {
      await ResourcesStore.createEmail({ payload });
      await onSuccess?.();
      await this.getEmails({ id: this.event?.id });
    } catch (err) {
      console.debug('[createEmail] failed', err);
      await onError?.();
    }
  };

  @action deleteEmail = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingEmailId = id;
      await ResourcesStore.deleteEmail({ id });
      await onSuccess?.();
    } catch (err) {
      await onError?.();
      console.debug('[deleteEmail] failed', err);
    } finally {
      this.isDeletingEmailId = null;
    }
  };

  @action testEmailShort = async ({ values, emailId, onSuccess, onError }) => {
    try {
      await ResourcesStore.testEmail({
        mailerId: emailId,
        userId: values.user_id,
      });
      await onSuccess?.();
    } catch (err) {
      console.debug('[testEmailShort] failed', err);
      await onError?.();
    }
  };

  @action cancelEmail = async ({ id, onSuccess, onError }) => {
    try {
      await ResourcesStore.cancelEmail({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[cancelEmail] failed', err);
      await onError?.();
    }
  };

  @action activateEmail = async ({ id, onSuccess, onError }) => {
    try {
      await ResourcesStore.activateEmail({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[cancelEmail] failed', err);
      await onError?.();
    }
  };

  @action performEmail = async ({ values, id, onSuccess, onError }) => {
    try {
      await ResourcesStore.publishEmail({
        id,
        values,
      });
      await onSuccess?.();
      await this.getEmails({ id: this.event?.id });
    } catch (err) {
      console.debug('[performEmail] failed', err);
      await onError?.();
    }
  };

  @action getAllUserSelectOptions = async ({ search, id }) => {
    try {
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
      });

      this.users = users;
      return this.users;
    } catch (err) {
      console.debug('[getUserSelectOptions] failed', err);
      return [];
    }
  };
  // END EMAIL REQUESTS

  @action deleteAgenda = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingAgendaId = id;
      await ResourcesStore.deleteAgendaItem({ id });
      this.agendaItems.replace(this.agendaItems.filter(ai => ai.id !== id));
      await onSuccess?.();
    } catch (err) {
      await onError?.();
      console.debug('[deleteAgenda] failed', err);
    } finally {
      this.isDeletingAgendaId = null;
    }
  };

  @action createAgendaItem = async ({ values, onSuccess, onError }) => {
    try {
      await ResourcesStore.createAgendaItem({
        payload: values,
      });
      await onSuccess?.();
      await this.getAgendaItems({ id: this.event?.id });
    } catch (err) {
      console.debug('[createAgendaItem] failed', err);
      await onError?.();
    }
  };

  @action turnAgendaItemLive = async ({ id, payload }) => {
    try {
      const response = await API.put(
        `${APIRoutes.AGENDA_ITEMS}/${id}`,
        payload,
      );

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  @action setExternalState = async ({ state, type }) => {
    if (type === AGENDA_TYPES.BREAKOUT_ROOM) {
      breakoutRoomStore.externalDefaultState = state;
      return;
    }

    if (type === AGENDA_TYPES.AGENDA_ITEM) {
      agendaItemStore.externalDefaultState = state;
    }
  };

  @action createSticker = async ({ values, onSuccess, type }) => {
    try {
      this.isStickersLoading = true;

      const payload = { ...values, event_id: this.event.id };

      if (type === 'default') {
        const selectedSticker = STICKER_SELECT_OPTIONS.find(
          item => item.label === values.sticker,
        );
        if (!selectedSticker) return;

        payload.image = await createBlobFile(
          selectedSticker.value.image,
          values.sticker,
        );
        payload.mask = await createBlobFile(
          selectedSticker.value.mask,
          values.sticker,
        );
      }

      await ResourcesStore.createSticker({ payload });
      await onSuccess?.();
      await this.getStickers({ id: this.event.id });
    } catch (err) {
      console.debug('[createSticker] failed', err);
    } finally {
      this.isStickersLoading = false;
    }
  };

  @action deleteSticker = async ({ id, onSuccess, onError }) => {
    try {
      this.isStickersLoading = true;
      await ResourcesStore.deleteSticker({
        id,
        eventId: this.event.id,
      });
      await onSuccess?.();
      await this.getStickers({ id: this.event.id });
    } catch (err) {
      console.debug('[deleteSticker] failed', err);
      await onError?.();
    } finally {
      this.isStickersLoading = false;
    }
  };

  @action submitPlayer = async ({ values, onSuccess, onError, isPrimary }) => {
    try {
      this.isPlayersLoading = true;
      const finalPlayer = !isPrimary ? this.backup_player : this.player;
      const playerRole = !isPrimary ? 'BackupPlayer' : 'Player';
      let resultPlayer;

      if (finalPlayer) {
        resultPlayer = await ResourcesStore.updatePlayer({
          id: values.id,
          payload: values,
        });
      } else {
        const payload = {
          ...values,
          playable_id: this.event.id,
          player_role: playerRole,
        };
        resultPlayer = await ResourcesStore.createPlayer({
          payload,
        });
      }

      if (!isPrimary) {
        this.backup_player = resultPlayer;
      } else {
        this.player = resultPlayer;
        if (this.backup_player) {
          this.getPlayer({ backupPlayerId: this.backup_player.id });
        }
        this.event.player = this.player;
        this.event.backup_player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      console.debug('[submitPlayer] failed', error);
    } finally {
      this.isPlayersLoading = false;
    }
  };

  @action deletePlayer = async ({ id, onSuccess, onError }) => {
    try {
      this.isPlayersLoading = true;

      await ResourcesStore.deletePlayer({ id });

      if (id === this.backup_player?.id) this.backup_player = null;

      if (id === this.player?.id) {
        this.player = this.backup_player;
      }

      if (onSuccess) onSuccess();
    } catch (err) {
      console.debug('[deletePlayer] failed', err);
      if (onError) onError();
    } finally {
      this.isPlayersLoading = false;
    }
  };

  // end actions

  // clone
  @action handleCloneEvent = async () => {
    modalStore.openModal({
      content: <CloningEventOptionsModal onClone={this.cloneProcess} />,
      name: 'eventCloneModal',
      disableBackgroundClose: true,
    });
  };

  @action cloneProcess = async ({ values }) => {
    this.cloneRunning = 'running';

    const preparedPayload = {
      clone: {
        ...values,
        event_id: this.event.id,
      },
    };

    try {
      const clonedData = await ResourcesStore.cloneEvent({
        payload: preparedPayload,
        eventId: this.event.id,
      });

      if (!clonedData.success) {
        throw JSON.stringify(clonedData.errors);
      }
      this.cloneRunning = 'success';
      setTimeout(() => {
        modalStore.closeModal({
          onCloseCallback: () => {
            routerStore.push(routes.event(clonedData.event_id));
            this.cloneRunning = false;
          },
        });
      }, 2000);

      return {};
    } catch (err) {
      this.cloneRunning = false;
      return {
        [FORM_ERROR]: {
          msg: 'Cloning failed, please contact an administrator',
          errors: err,
        },
      };
    }
  };

  // end clone

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    modalStore.killModal('eventCloneModal');
    subscriptionStore.resetContextEvent();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new EventStore();
