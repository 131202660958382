import React from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import CplusToastContextWrapper from './CplusToastContextWrapper';

const CreatedToast = ({ duration, closeToast, text, cb1, cb2 }) => {
  const theme = useTheme();

  return (
    <CplusToastContextWrapper
      duration={duration}
      type="success"
      close={closeToast}
    >
      <Typography sx={{ color: theme.toast.success.textColor }}>
        {text}
      </Typography>
      <Stack direction="row" spacing="10px" mt="10px">
        <Button variant="contained" size="small" onClick={cb1}>
          Edit it!
        </Button>
        <Button variant="contained" size="small" onClick={cb2}>
          Add another
        </Button>
      </Stack>
    </CplusToastContextWrapper>
  );
};

export default CreatedToast;
