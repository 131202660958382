import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircle from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #979bb1;
  margin-top: 15px !important;
`;

const Item = styled(Box)`
  display: flex;
  margin-top: 5px !important;
  align-items: center;
`;
const IconWrapper = styled(Box)`
  display: flex;
`;
const Title = styled(Box)``;
const Text = styled(Box)`
  display: flex;
  padding-left: 5px;
`;

const PasswordChecker = () => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const validations = useMemo(() => {
    const pwd = formValues.password || '';
    return {
      length: pwd.length >= 8,
      lowercase: pwd.match(/[a-z]/),
      uppercase: pwd.match(/[A-Z]/),
      number: pwd.match(/\d/),
      special: pwd.match(/\W/),
    };
  }, [formValues]);

  const renderIcon = validation => {
    if (validation) {
      return (
        <IconWrapper color="lightgreen">
          <CheckCircle style={{ fontSize: 14 }} />
        </IconWrapper>
      );
    }
    return (
      <IconWrapper>
        <FiberManualRecordIcon style={{ fontSize: 14 }} />
      </IconWrapper>
    );
  };

  return (
    <Wrapper>
      <Title>Passwords must:</Title>
      <Item>
        {renderIcon(validations.length)}
        <Text>Be a minimum of 8 characters</Text>
      </Item>
      <Item>
        {renderIcon(validations.lowercase)}
        <Text>Include at least one lowercase letter (a-z)</Text>
      </Item>
      <Item>
        {renderIcon(validations.uppercase)}
        <Text>Include at least one uppercase letter (A-Z)</Text>
      </Item>
      <Item>
        {renderIcon(validations.number)}
        <Text>Include at least one number (0-9)</Text>
      </Item>
      <Item>
        {renderIcon(validations.special)}
        <Text>Include at least one special character (e.g. @, #, $)</Text>
      </Item>
    </Wrapper>
  );
};

export default PasswordChecker;
