import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, FormLabel } from '@mui/material';

const EventReferenceLink = styled('a')`
  color: inherit;
  display: block;
  width: fit-content;
  font-size: 12px;

  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }

  span {
    font-weight: bold;
    font-style: italic;
  }
`;

const EventPreviewLinks = memo(({ slugs = [], baseUrl, suggested }) => {
  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
      <FormLabel>
        {suggested || !slugs.length
          ? 'Suggested event URL (to change it, enter slug in field below)'
          : 'Event URL for attendee access:'}
      </FormLabel>
      <Box mt="5px">
        {slugs.length ? (
          slugs.map(slug => (
            <EventReferenceLink
              href={`${baseUrl}/${slug}`}
              target="_blank"
              key={`reference-link_${slug}`}
            >
              {baseUrl}/{slug}
            </EventReferenceLink>
          ))
        ) : (
          <EventReferenceLink>
            {baseUrl}/{suggested || '<slug>'}
          </EventReferenceLink>
        )}
      </Box>
    </Box>
  );
});

export default EventPreviewLinks;
