import { lazy } from 'react';

export const RatingFormPage = lazy(() =>
  import('@pages/ratings/pages/RatingForm/RatingFormPage'),
);
export const RatingsVotes = lazy(() =>
  import('@pages/ratings/pages/RatingVotes/ratingsVotes'),
);
export const RatingsPage = lazy(() =>
  import('@pages/ratings/pages/RatingListing/RatingsPage'),
);
export const LogsPage = lazy(() =>
  import('@pages/logs/pages/LogsListing/LogsPage'),
);
export const BannersPage = lazy(() =>
  import('@pages/banners/pages/BannerListing/BannersPage'),
);
export const BannerFormPage = lazy(() =>
  import('@pages/banners/pages/BannerForm/BannerFormPage'),
);
export const ExploreItemsPage = lazy(() =>
  import('@pages/exploreItems/pages/ExploreItemsListing/ExploreItemsPage'),
);
export const ExploreItemFormPage = lazy(() =>
  import('@pages/exploreItems/pages/ExploreItemForm/ExploreItemFormPage'),
);
export const AnalyticsPage = lazy(() => import('@pages/analytics'));
export const AnalyticsReportPage = lazy(() => import('@pages/analyticsReport'));
export const OrganizationsPage = lazy(() =>
  import('@pages/organizations/pages/OrganizationsListing/OrganizationsPage'),
);
export const EventsPage = lazy(() =>
  import('@pages/events/pages/EventsListing/EventsPage'),
);
export const AgendaPage = lazy(() =>
  import('@pages/agenda/pages/AgendasListing/AgendasPage'),
);
export const BreakoutPage = lazy(() =>
  import('@pages/breakouts/pages/BreakoutsListing/BreakoutPage'),
);
export const EventFormPage = lazy(() =>
  import('@pages/events/pages/EventForm/EventFormPage'),
);
export const AgendaFormPage = lazy(() =>
  import('@pages/agenda/pages/AgendaForm/AgendaFormPage'),
);
export const BreakoutFormPage = lazy(() =>
  import('@pages/breakouts/pages/BreakoutForm/BreakoutFormPage'),
);
export const OrganizationEditPage = lazy(() =>
  import('@pages/organizations/pages/OrganizationForm/OrganizationFormPage'),
);
export const PlayersPage = lazy(() =>
  import('@pages/players/pages/PlayersListing/PlayersPage'),
);
export const PlayerFormPage = lazy(() =>
  import('@pages/players/pages/PlayerForm/PlayerFormPage'),
);
export const UsersPage = lazy(() =>
  import('@pages/users/pages/UsersListing/UsersPage'),
);
export const UserFormPage = lazy(() =>
  import('@pages/users/pages/UserForm/UserFormPage'),
);
export const DocumentsPage = lazy(() =>
  import('@pages/documents/pages/DocumentsListing/DocumentsPage'),
);
export const DocumentFormPage = lazy(() =>
  import('@pages/documents/pages/DocumentForm/DocumentFormPage'),
);
export const ProductsPage = lazy(() =>
  import('@pages/products/pages/ProductsListing/ProductsPage'),
);
export const ProductFormPage = lazy(() =>
  import('@pages/products/pages/ProductForm/ProductFormPage'),
);
export const ProfileFormPage = lazy(() =>
  import('@pages/profile/pages/ProfileForm/ProfileFormPage'),
);
export const SpeakersPage = lazy(() =>
  import('@pages/speakers/pages/SpeakersListing/SpeakersPage'),
);
export const SpeakerFormPage = lazy(() =>
  import('@pages/speakers/pages/SpeakerForm/SpeakerFormPage'),
);
export const ChatMessagesPage = lazy(() =>
  import('@pages/chatMessages/pages/ChatMessagesListing/ChatMessagesPage'),
);
export const ChatAbusesPage = lazy(() =>
  import('@pages/chatAbuses/pages/ChatAbusesListing/ChatAbusesPage'),
);
export const ChatMessageFormPage = lazy(() =>
  import('@pages/chatMessages/pages/ChatMessageForm/ChatMessageFormPage'),
);
export const ChatQuestionsPage = lazy(() =>
  import('@pages/chatQuestions/pages/ChatQuestionsListing/ChatQuestionsPage'),
);
export const ChatQuestionFormPage = lazy(() =>
  import('@pages/chatQuestions/pages/ChatQuestionForm/ChatQuestionFormPage'),
);
export const ChatRoomsPage = lazy(() =>
  import('@pages/chatRooms/pages/ChatRoomsListing/ChatRoomsPage'),
);
export const PollsPage = lazy(() =>
  import('@pages/polls/pages/PollsListing/PollsPage'),
);
export const PollFormPage = lazy(() =>
  import('@pages/polls/pages/PollForm/PollFormPage'),
);
export const AttendancesPage = lazy(() =>
  import('@pages/attendances/pages/attendancesListing/AttendancesPage'),
);
export const AttendanceFormPage = lazy(() =>
  import('@pages/attendances/pages/attendanceForm/AttendanceFormPage'),
);
export const AttendanceExportPage = lazy(() =>
  import('@pages/attendances/pages/attendanceExport/AttendanceExportPage'),
);
export const SponsorsPage = lazy(() =>
  import('@pages/sponsors/pages/SponsorsListing/SponsorsPage'),
);
export const SponsorFormPage = lazy(() =>
  import('@pages/sponsors/pages/SponsorForm/SponsorFormPage'),
);
export const PollAnswerUsersPage = lazy(() =>
  import('@pages/polls/pages/PollAnswerUsers/PollAnswerUsersPage'),
);
export const ReportsPage = lazy(() => import('@pages/reports'));
export const GdprPage = lazy(() => import('@pages/gdpr/GdprPage'));
export const GdprViewUsersPage = lazy(() => import('@pages/gdpr/GdprListing'));
export const GlobalMessagesPage = lazy(() =>
  import(
    '@pages/globalMessages/pages/GlobalMessagesListing/GlobalMessagesPage'
  ),
);
export const GlobalMessageFormPage = lazy(() =>
  import('@pages/globalMessages/pages/GlobalMessageForm/GlobalMessageFormPage'),
);
export const GroupsPage = lazy(() =>
  import('@pages/groups/pages/GroupsListing/GroupsPage'),
);
export const GroupFormPage = lazy(() =>
  import('@pages/groups/pages/GroupFormPage/GroupFormPage'),
);
export const EventRefreshPage = lazy(() => import('@pages/eventRefresh'));
export const TranslationsPage = lazy(() =>
  import('@pages/translations/pages/TranslationListing/TranslationsPage'),
);
export const TranslationFormPage = lazy(() =>
  import('@pages/translations/pages/TranslationForm/TranslationFormPage'),
);
export const ChatRoomFormPage = lazy(() =>
  import('@pages/chatRooms/pages/ChatRoomForm/ChatRoomFormPage'),
);
export const MultiMarketGroupsPage = lazy(() =>
  import(
    '@pages/multiMarketGroups/pages/MultiMarketGroupsListing/MultiMarketGroupsPage'
  ),
);
export const MultiMarketGroupFormPage = lazy(() =>
  import(
    '@pages/multiMarketGroups/pages/MultiMarketGroupForm/MultiMarketGroupFormPage'
  ),
);
export const EmailFormPage = lazy(() =>
  import('@pages/emails/pages/EmailForm/EmailFormPage'),
);
export const EventPayment = lazy(() =>
  import('@pages/eventPayment/EventPayment'),
);
export const StagesPage = lazy(() =>
  import('@pages/stages/pages/StagesListing/StagesPage'),
);
export const StagesForm = lazy(() =>
  import('@pages/stages/pages/StagesForm/StagesForm'),
);
