import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, BUTTON_TYPES } from '@components/Buttons/Button';
import Link from '@mui/material/Link';

const Container = styled(Box)``;

const BodyText = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes.subtitle};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 22px;
  letter-spacing: 0.3px;
`;

const Bold = styled('span')`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

const ResetPasswordSuccess = ({ message }) => {
  return (
    <Container>
      <BodyText>
        <Bold>{message}</Bold>
      </BodyText>
      <Box
        display="flex"
        width={1}
        mt={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={0.3} height="53px" mt="45px">
          <Link href="/">
            <Button
              label="LOGIN"
              type={BUTTON_TYPES.PRIMARY}
              onClick={() => {}}
              size="l"
              buttonType="submit"
              btnPadding="0 15px"
              borderRadius="20px"
            />
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordSuccess;
