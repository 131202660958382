import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router';
import { Box } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import moment from 'moment';
import routes from '@routes';

@inject('profileStore', 'authStore')
@observer
class GenericLandingPage extends Component {
  state = { time: moment() };

  componentDidMount() {
    const {
      history,
      authStore: { isAllowed },
    } = this.props;
    if (isAllowed({ checkPermissionPath: routes.main.dashboard })) {
      history.push(routes.main.dashboard);
      return;
    }
    this.intervalHandler = setInterval(this.updateTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandler);
  }

  updateTime = () => {
    this.setState({ time: moment() });
  };

  render() {
    const {
      profileStore: { profile },
      theme,
    } = this.props;

    const { first_name: firstName } = profile || {};

    const { time } = this.state;

    return (
      <Box
        display="flex"
        width="100%"
        height="calc(100% - 100px)"
        flexDirection="column"
        alignItems="center"
        mt="100px"
      >
        <Box
          sx={{
            fontSize: '42px',
            marginBottom: '30px',
            fontWeight: theme.fontWeights.bold,
          }}
        >
          Welcome {firstName}
        </Box>
        <Box
          sx={{
            fontSize: theme.fontSizes.heading,
            fontWeight: theme.fontWeights.regular,
          }}
        >
          It is {time.format('dddd, DD-MM-YYYY, HH:mm:ss')}
        </Box>
      </Box>
    );
  }
}

export default withTheme(withRouter(GenericLandingPage));
