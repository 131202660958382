import React from 'react';
import { action, extendObservable, computed } from 'mobx';
import { CancelToken } from '@app/api';
import { FORM_TYPES } from '@constants';
import ResourcesStore from '@stores/resourcesStore';
import { modalStore, assignmentsStore, routerStore } from '@stores';
import CloningSponsorOptionsModal from '@components/modals/CloningSponsorOptionsModal';
import routes from '@routes';
import { SponsorCloneService } from '@app/CloningServices/SponsorCloneService';
import {
  assignmentsPrepareValues,
  assignmentsSetupDefaultFormValues,
  ASSIGNMENT_TYPE,
} from '@components/Assignments';

const initialState = {
  isLoading: false,
  initialLoading: true,
  sponsor: {},
  defaultFormValues: {
    ...assignmentsSetupDefaultFormValues({}),
  },
  cloneRunning: false,
  phase: '',
  formType: null,
};

export class SponsorStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateSponsor(...props);
    }
    if (this.isCreate) {
      return this.createSponsor(...props);
    }

    return null;
  };

  @action getSponsor = async ({ id }) => {
    try {
      this.sponsor = await ResourcesStore.getSponsor(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getSponsor] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.sponsors);
      }
      throw err;
    }
  };

  @action updateSponsor = async ({ values, onSuccess, ...props }) => {
    this.isLoading = true;
    try {
      const onS = returnVal => {
        if (onSuccess) onSuccess(returnVal);
        this.sponsor = returnVal;
        this.setupDefaultFormValues();
      };

      const { assignments_attributes, event_id } =
        assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }) || {};

      const payload = {
        ...values,
        event_id,
        agenda_item_ids:
          assignments_attributes.map(ai => ai.agenda_item_id) || [],
      };

      await ResourcesStore.updateSponsor({
        payload,
        onSuccess: onS,
        ...props,
      });
    } catch (err) {
      console.debug('[updateSponsor] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action createSponsor = async ({ values, ...props }) => {
    this.isLoading = true;
    try {
      const { assignments_attributes, event_id } =
        assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }) || {};
      const payload = {
        ...values,
        event_id,
        agenda_item_ids:
          assignments_attributes.map(ai => ai.agenda_item_id) || [],
      };

      await ResourcesStore.createSponsor({ payload, ...props });
    } catch (err) {
      console.debug('[createSponsor] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteSponsor = async ({ id, onSuccess, onError }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteSponsor({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[deleteSponsor] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;

    try {
      await Promise.all([
        this.isEdit && this.getSponsor({ id }),
        assignmentsStore.getResources({
          types: {
            [ASSIGNMENT_TYPE.EVENT]: true,
            [ASSIGNMENT_TYPE.AGENDA]: true,
          },
        }),
      ]);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.sponsor,
      ...assignmentsSetupDefaultFormValues({
        item: {
          ...this.sponsor,
          agenda_item_assignments: this.sponsor.agenda_item_ids,
        },
        events: assignmentsStore.events.list,
        agendas: assignmentsStore.agendaItems.list,
        groups: assignmentsStore.groups.list,
      }),
    };
  };

  @action handleCloneSponsor = () => {
    modalStore.openModal({
      disableBackgroundClose: true,
      name: 'sponsorCloneModal',
      content: <CloningSponsorOptionsModal onClone={this.cloneProcess} />,
    });
  };

  @action cloneProcess = async values => {
    this.cloneRunning = true;
    this.phase = 'Sponsor';

    try {
      const preparedSponsor = await SponsorCloneService.prepareSponsor(
        this.sponsor.id,
      );

      const { assignments_attributes, event_id } =
        assignmentsPrepareValues({
          values,
          initialValues: this.defaultFormValues,
        }) || {};

      const res = await ResourcesStore.createSponsor({
        payload: {
          ...preparedSponsor,
          event_id,
          agenda_item_ids:
            assignments_attributes.map(ai => ai.agenda_item_id) || [],
        },
        requestOptions: {
          headers: {
            bypass_uploaders_validation: true,
          },
        },
      });

      this.defaultFormValues = {};
      routerStore.push(`${routes.sponsor(res.id)}`);
      modalStore.closeModal();
    } catch (err) {
      console.debug('[cloneProcess] failed', err);
    } finally {
      this.cloneRunning = false;
      this.phase = '';
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    modalStore.killModal('sponsorCloneModal');
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new SponsorStore();
