import React from 'react';
import { useLocation, Redirect, Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { FEATURE_SET_BANNABLE_ROUTES } from '@constants';
import routes from '@routes';

const ProtectedRoute = ({
  subscriptionStore: { resolveOrgDisableField },
  authStore: { isAllowed },
  ...props
}) => {
  const { pathname } = useLocation();

  const allowed =
    isAllowed({ currentPath: pathname }) &&
    !(
      FEATURE_SET_BANNABLE_ROUTES.includes(pathname) &&
      resolveOrgDisableField(pathname)
    );

  return allowed ? <Route {...props} /> : <Redirect to={routes.unauthorized} />;
};

export default inject(
  'authStore',
  'subscriptionStore',
)(observer(ProtectedRoute));
