import React from 'react';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { HeaderWrapper } from '@components/HeaderWrapper';
import { PageTitle } from '@app/components/PageTitle';
import { BUTTON_ICON_TYPES } from '@constants';
import ButtonContainer from '@components/ButtonContainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import BlockIcon from '@mui/icons-material/Block';

const retrieveButtonIcon = type => {
  if (type === BUTTON_ICON_TYPES.CREATE) {
    return <AddIcon />;
  }
  if (type === BUTTON_ICON_TYPES.DELETE) {
    return <DeleteIcon />;
  }

  if (type === BUTTON_ICON_TYPES.BACK) {
    return <ArrowBackIcon />;
  }

  if (type === BUTTON_ICON_TYPES.PUBLISH) {
    return <SendIcon />;
  }

  if (type === BUTTON_ICON_TYPES.UNPUBLISH) {
    return <BlockIcon />;
  }

  return <></>;
};

const InnerPage = ({
  pageTitle,
  headerButtons,
  children,
  Tabs,
  Breadcrumbs,
  noHeader,
}) => {
  return (
    <>
      {!noHeader && (
        <HeaderWrapper
          {...(Breadcrumbs ? { sx: { padding: '30px 40px 0 40px' } } : {})}
        >
          {Breadcrumbs && <Box pb="10px">{Breadcrumbs}</Box>}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: Tabs ? 0 : '40px',
            }}
          >
            <PageTitle>{pageTitle}</PageTitle>
            <ButtonContainer>
              {headerButtons &&
                headerButtons
                  .filter(button => button.hide !== false)
                  .map(button => (
                    <Button
                      {...(button.redirectPath && {
                        component: Link,
                        to: button.redirectPath,
                      })}
                      {...(button.onClick && {
                        onClick: button.onClick,
                      })}
                      key={button.label}
                      variant={button.variant || 'contained'}
                      {...(button.color && { color: button.color })}
                      {...(button.iconPosition === 'end'
                        ? { endIcon: retrieveButtonIcon(button.icon) }
                        : { startIcon: retrieveButtonIcon(button.icon) })}
                      disabled={button.disabled}
                    >
                      {button.label}
                    </Button>
                  ))}
            </ButtonContainer>
          </Box>
          {Tabs && <Box pt="20px">{Tabs}</Box>}
        </HeaderWrapper>
      )}
      <Box
        sx={() => ({
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          p: '0 40px 40px 40px',
        })}
      >
        {children}
      </Box>
    </>
  );
};
export default InnerPage;
