import React, { useCallback, useContext, useEffect, useState } from 'react';
import MuiTable from '@mui/material/Table';

export const CplusTableContext = React.createContext(null);

export const useCplusTableContext = () => {
  const values = useContext(CplusTableContext);
  if (!values) {
    throw new Error(
      'useCplusTableContext can be used only inside CplusTableContext',
    );
  }

  return values;
};

const Table = ({
  isLoading,
  colCount,
  children,
  noContent,
  noContentText,
  uncollapsableRows,
  page,
  ...props
}) => {
  const [uncollapsedRows, setUncollapsedRows] = useState([]);

  const toggleCollapsedRow = useCallback(
    id => {
      if (uncollapsedRows.includes(id)) {
        setUncollapsedRows(prev => prev.filter(pId => pId !== id));
      } else {
        setUncollapsedRows(prev => [...prev, id]);
      }
    },
    [uncollapsedRows],
  );

  useEffect(() => {
    setUncollapsedRows([]);
  }, [page]);

  return (
    <CplusTableContext.Provider
      value={{
        isLoading,
        colCount,
        noContent,
        noContentText,
        uncollapsedRows,
        toggleCollapsedRow,
        uncollapsableRows,
      }}
    >
      <MuiTable {...props}>{children}</MuiTable>
    </CplusTableContext.Provider>
  );
};

export default Table;
