import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: 0,
  translations: {},
  isDeletingId: null,
};

export class TranslationsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getTranslations = async ({ page, search, sort, silent }) => {
    try {
      if (!silent) this.isLoading += 1;

      const res = await ResourcesStore.getTranslations({
        page,
        search,
        sort,
      });

      this.translations = {
        ...res,
        page: res.page_number.toString(),
        search,
        sort,
      };
    } catch (err) {
      console.debug('[getTranslations] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort'].every(
      param => params[param] === this.translations?.[param],
    );
  };

  @action deleteTranslation = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteTranslation({ id, onSuccess, onError });
    } catch (err) {
      console.debug('[deleteTranslation] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };
}

export default new TranslationsPageStore();
