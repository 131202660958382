import { Box } from '@mui/material';
import moment from 'moment';
import React from 'react';

const ColumnDateFormatted = ({ date }) => {
  return date ? (
    <>
      <Box fontWeight="fontWeightBold">{moment(date).format('HH:mm')}</Box>
      <Box>{moment(date).format('MMM DD, YYYY')}</Box>
    </>
  ) : (
    '-'
  );
};

export default ColumnDateFormatted;
