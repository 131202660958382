import { Link } from 'react-router-dom';
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const UnderlinedLink = styled(Link, {
  shouldForwardProp: prop =>
    !['wrap', 'inheritFontSize', 'color'].includes(prop),
})`
  color: ${({ color }) => color || 'inherit'};
  font-size: ${({ theme, inheritFontSize }) =>
    inheritFontSize ? 'inherit' : theme.fontSizes.body};

  &:visited {
    color: inherit;
  }

  ${({ wrap }) =>
    wrap &&
    `white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;`}
`;

const InactiveLink = styled(Box, {
  shouldForwardProp: prop =>
    !['wrap', 'inheritFontSize', 'color'].includes(prop),
})`
  color: ${({ color }) => color || 'inherit'};
  font-size: ${({ theme, inheritFontSize }) =>
    inheritFontSize ? 'inherit' : theme.fontSizes.body};

  ${({ wrap }) =>
    wrap &&
    `white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;`}
`;

const ReferenceLinkRouter = ({ to, children, inheritFontSize, ...rest }) => {
  if (to) {
    return (
      <UnderlinedLink to={to} inheritFontSize={inheritFontSize} {...rest}>
        {children}
      </UnderlinedLink>
    );
  }

  return (
    <InactiveLink inheritFontSize={inheritFontSize} {...rest}>
      {children}
    </InactiveLink>
  );
};

export default ReferenceLinkRouter;
