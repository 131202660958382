import {
  BigCplusLogo,
  Card,
  CardContent,
  CplusContainer,
  PageContent,
} from '@pages/signIn';
import React, { useState, useMemo } from 'react';
import routes from '@routes';
import { useLocation } from 'react-router-dom';
import CreatePasswordForm from '@pages/createPassword/components/CreatePasswordForm';
import { Box } from '@mui/material';
import ResetPasswordSuccess from '@pages/createPassword/components/ResetPasswordSuccess';
import CplusLogo from '@assets/images/cplus-logo-large-white.png';
import { Logo } from '@app/components/Logo';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { isJackEthos } from '@styles/_theme';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const CreatePassword = ({ authStore: { resetPassword, isLoading } }) => {
  const query = useQuery();
  const [showSuccess, setShowSuccess] = useState(false);

  const changePassword = async val => {
    const headers = {
      client: query.get('client'),
      uid: query.get('uid'),
      'access-token': query.get('access-token'),
    };

    try {
      const response = await resetPassword({
        ...val,
        reset_password_token: query.get('reset_password_token'),
        headers,
      });
      setShowSuccess(true);
      return response;
    } catch (error) {
      toast.cError(error.response.data.errors[0]);
    }

    return null;
  };

  if (!isJackEthos) {
    return (
      <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
        <CplusContainer pt="5%">
          <Box mb="50px">
            <BigCplusLogo src={CplusLogo} />
          </Box>
          {!showSuccess ? (
            <CreatePasswordForm
              onSubmit={changePassword}
              isCplusReset
              isLoading={isLoading}
            />
          ) : (
            <ResetPasswordSuccess message="Your password has been successfully updated." />
          )}
        </CplusContainer>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
      <PageContent>
        <Box mb={3}>
          <Logo />
        </Box>
        <Card>
          <CardContent>
            {!showSuccess ? (
              <CreatePasswordForm
                onSubmit={changePassword}
                isLoading={isLoading}
              />
            ) : (
              <ResetPasswordSuccess
                routeSuccess={routes.auth.signIn}
                message="Your password has been successfully updated."
              />
            )}
          </CardContent>
        </Card>
      </PageContent>
    </Box>
  );
};

export default inject('authStore')(observer(CreatePassword));
