import React, { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import routes from '@routes';
import ProfileSkeleton from './ProfileSkeleton';
import ProfileMenu from './ProfileMenu';
import Avatar from './Avatar';
import {
  Container,
  Left,
  Middle,
  Name,
  Role,
  Right,
  IconWrapper,
} from './style';

const Profile = ({ profile, userRole, authStore: { logout } }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const navigateToProfile = useCallback(() => {
    history.push(routes.main.profile);
    setOpen(false);
  }, [history]);

  if (!profile) return <ProfileSkeleton />;

  return (
    <Box sx={{ position: 'relative', zIndex: '100' }}>
      <Container onClick={() => setOpen(true)}>
        {profile && (
          <>
            <Left>
              <Avatar url={profile.avatar?.cropped_chat_thumb?.url} />
            </Left>
            <Middle>
              <Name>
                {profile.first_name} {profile.last_name}
              </Name>
              <Role>{userRole}</Role>
            </Middle>
            <Right>
              <IconWrapper>
                <ExpandMoreIcon sx={{ display: 'block' }} />
              </IconWrapper>
            </Right>
          </>
        )}
      </Container>
      <ProfileMenu
        setOpen={setOpen}
        open={open}
        logout={logout}
        navigateToProfile={navigateToProfile}
      />
    </Box>
  );
};

export default inject('authStore')(observer(Profile));
