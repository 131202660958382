import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import routes from '@routes';
import { PageContent, CplusContainer } from '@pages/signIn';
import AccountCreate from '@pages/signup/components/AccountCreate';
import ConfirmEmail from '@pages/signup/components/ConfirmEmail';
import BillingDetails from '@pages/signup/components/BillingDetails';
import PaymentMethod from '@pages/signup/components/PaymentMethod';
import ReviewDetails from '@pages/signup/components/ReviewDetails';
import Stepper from '@pages/signup/components/Stepper';

const Card = styled(Box)`
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  background-color: #2a2e45;
  box-shadow: 0px 0px 49px -15px rgba(0, 0, 0, 0.75);
  padding: 30px 60px;
  border-radius: 10px;
  margin-bottom: 30px !important;
`;

const STEPS_LITE = [
  'CREATE ACCOUNT',
  'CONFIRM EMAIL',
  'BILLING DETAILS',
  'PAYMENT METHOD',
  'REVIEW DETAILS',
];

const STEPS_FREE = ['CREATE ACCOUNT', 'CONFIRM EMAIL'];

const SignUpPage = () => {
  const location = useLocation();
  const queryParams = useMemo(() => qs.parse(location.search), [
    location?.search,
  ]);

  const isLite = useMemo(
    () => location?.pathname === routes.auth.signUp('lite'),
    [location?.pathname],
  );

  const currentStep = useMemo(() => {
    const step = queryParams?.step;

    if (isLite) {
      switch (step) {
        case 'confirmEmail':
          return 1;
        case 'billingDetails':
          return 2;
        case 'paymentMethod':
          return 3;
        case 'reviewDetails':
          return 4;
        default:
          return 0;
      }
    } else {
      switch (step) {
        case 'confirmEmail':
          return 1;
        default:
          return 0;
      }
    }
  }, [queryParams?.step, isLite]);

  const renderCardContent = currStep => {
    switch (currStep) {
      case 0:
        return <AccountCreate />;
      case 1:
        return <ConfirmEmail />;
      case 2:
        return <BillingDetails />;
      case 3:
        return <PaymentMethod />;
      case 4:
        return <ReviewDetails />;
      default:
        return <AccountCreate />;
    }
  };

  const stepsArr = useMemo(() => (isLite ? STEPS_LITE : STEPS_FREE), [isLite]);

  return (
    <PageContent>
      <CplusContainer overflow="auto">
        <Stepper steps={stepsArr} currentStep={currentStep} />
        <Card>{renderCardContent(currentStep)}</Card>
      </CplusContainer>
    </PageContent>
  );
};

export default SignUpPage;
