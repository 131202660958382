import { action, observable } from 'mobx';
import { API, APIRoutes } from '@api';
import { downloadBlob } from '@utils/fileUtils';

const INITIAL_SCHEMA = {
  only: [
    'id',
    'email',
    'accepted_date',
    'status',
    'mode',
    'created_at',
    'attendance_event_status',
  ],
  include: {
    user: [
      'role_label',
      'email',
      'member_id',
      'title',
      'first_name',
      'last_name',
      'terms_and_conditions_accepted',
      'bio',
      'job_title',
      'company',
      'account_url_twitter',
      'account_url_facebook',
      'account_url_linkedin',
      'account_url_youtube',
      'confirmed_at',
      'networking_opted_in',
    ],
  },
};

export class AttendanceExportPageStore {
  @observable isLoading = false;

  @observable exportFields = [];

  @observable schema = INITIAL_SCHEMA;

  @action getAttendancesExport = async ({
    search,
    eventId,
    status,
    values,
  }) => {
    this.isLoading = true;

    const payload = {
      event_id: eventId,
      status,
      search,
      schema: values,
    };
    await downloadBlob({
      url: APIRoutes.ATTENDANCES.EXPORT_CSV(),
      postParameters: payload,
    });

    this.isLoading = false;
  };

  // available_user_registration_fields
  @action fetchAvailableUserRegistrationFields = async eventId => {
    this.isLoading = true;
    try {
      const {
        data: { fields },
      } = await API.get(APIRoutes.ATTENDANCES.REGISTRATION_FIELDS(eventId));
      this.schema.include.user = [...this.schema.include.user, ...fields];
    } catch (error) {
      console.debug(error);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = () => {
    this.schema = INITIAL_SCHEMA;
  };
}

export default new AttendanceExportPageStore();
