import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import truncate from 'lodash/truncate';
import CplusContextWrapper from './CplusToastContextWrapper';

const ErrorToast = ({ text, closeToast, duration }) => {
  const theme = useTheme();
  return (
    <CplusContextWrapper duration={duration} type="error" close={closeToast}>
      <Typography
        sx={{
          color: theme.toast.error.borderColor,
          fontWeight: 'fontWeightBold',
          mb: '5px',
        }}
      >
        Unexpected error
      </Typography>
      <Typography sx={{ color: theme.toast.error.textColor }}>
        {truncate(text, { length: 100 })}
      </Typography>
      <Button
        size="small"
        variant="contained"
        color="red"
        onClick={() => navigator.clipboard.writeText(text)}
        sx={{ alignSelf: 'start', mt: '10px' }}
      >
        COPY WHOLE ERROR
      </Button>
    </CplusContextWrapper>
  );
};

export default ErrorToast;
