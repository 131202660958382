import { styled } from '@mui/material/styles';

export const Controls = styled('div')`
  position: absolute;
  bottom: -55px;
  left: 0;
  width: 100%;
  height: 55px;
  opacity: 0;
  background-color: #202632;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const ControlButton = styled('button')`
  display: block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 48px;

  svg {
    display: block;
    height: 100%;
  }
`;

export const Wrapper = styled('div', {
  shouldForwardProp: prop => !['show', 'small'].includes(prop),
})`
  max-height: ${({ show, h }) => (show ? `${h}px` : '0')};
  transition: max-height .2s;
  margin-bottom: ${({ mb, show }) => (show ? mb : 0)}px;
  /* margin: ${({ margin }) => margin}px; */
  width: 100%;
  /* min-height: ${({ height }) => height || 485}px; */
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: ${({ small }) => (small ? 0 : 6)}px;

  &:hover {
    ${Controls} {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export const NoVideoPlaceholder = styled('div')`
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;

  svg {
    display: block;
    margin-bottom: 15px;
  }
`;

export const ErrorPlaceholder = styled('div')`
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  color: ${({ theme }) => theme.colors.error};

  svg {
    display: block;
    margin-bottom: 15px;
  }
`;

export const MuteButton = styled('button', {
  shouldForwardProp: prop => !['show'].includes(prop),
})`
  position: absolute;
  bottom: 5%;
  left: 0;
  max-width: ${({ show }) => (show ? '50px' : 0)};
  overflow: hidden;
  width: 50px;
  height: 30px;
  transition: max-width 0.2s;
  border: none;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const ReloadButton = styled('button')`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  margin-top: 20px;
  cursor: pointer;
`;
