import React, { useState, useMemo, useRef } from 'react';
import {
  CroppperImageWrapper,
  WarningMessage,
} from '@components/Cropper/style';
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PropTypes from 'prop-types';
import { cropPreviewImage } from '@utils/fileUtils';
import { Stack, Button, Typography, Box, Paper } from '@mui/material';

const normalizeCrop = crop => {
  const { width, height } = crop;
  let fWidth = Math.floor(width);
  let fHeight = Math.floor(height);

  if (fWidth !== fHeight) {
    if (fWidth > fHeight) {
      fWidth = fHeight;
    } else {
      fHeight = fWidth;
    }
  }

  return { ...crop, width: fWidth, height: fHeight };
};

const defaultCropSettings = {
  x: 0,
  y: 0,
  width: 50,
  unit: '%',
};

const Cropper = ({
  aspectRatio,
  onAcceptCallback,
  onRejectCallback,
  image,
  transformer,
}) => {
  const [crop, setCrop] = useState();
  const imageRef = useRef(null);
  const transformedImage = useMemo(() => {
    try {
      if (image) {
        return transformer(image);
      }
      return null;
    } catch (err) {
      console.warn('[ImageCropper] Transformer failed'); // eslint-disable-line
      return null;
    }
  }, [image, transformer]);

  const onLoaded = e => {
    const { width, height } = e.currentTarget;
    const cr = centerCrop(
      makeAspectCrop(
        {
          ...defaultCropSettings,
        },
        aspectRatio,
        width,
        height,
      ),
      width,
      height,
    );
    setCrop(cr);
  };

  const onAcceptMiddleware = () => {
    if (!imageRef.current) {
      return;
    }
    const { naturalWidth, naturalHeight } = imageRef.current;
    const scaledCrop = {
      x: (crop.x / 100) * naturalWidth,
      y: (crop.y / 100) * naturalHeight,
      width: (crop.width / 100) * naturalWidth,
      height: (crop.height / 100) * naturalHeight,
    };

    const normalizedCrop = normalizeCrop(scaledCrop);

    onAcceptCallback(
      image,
      normalizedCrop,
      cropPreviewImage(imageRef.current, normalizedCrop),
    );
  };

  const onRejectMiddleware = () => {
    setCrop({ ...defaultCropSettings, aspect: aspectRatio });
    onRejectCallback();
  };

  return (
    <Paper elevation={0} sx={{ width: '1200px', maxWidth: '95vw' }}>
      <Box
        sx={theme => ({
          bgcolor: theme.baseModal.headerBackground,
          p: '30px',
          borderBottom: theme.baseModal.border,
          borderRadius: '4px 4px 0 0',
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <Typography variant="h3" sx={{ fontWeight: 'fontWeightMedium' }}>
          Crop image
        </Typography>
        <Stack gap="10px" direction="row">
          <Button onClick={onAcceptMiddleware} variant="contained">
            Accept
          </Button>
          <Button onClick={onRejectMiddleware} variant="contained" color="red">
            Cancel
          </Button>
        </Stack>
      </Box>
      <Box
        sx={theme => ({
          bgcolor: theme.baseModal.bodyBackground,
          p: '30px',
          borderRadius: '0 0 4px 4px',
        })}
      >
        <CroppperImageWrapper>
          {transformedImage ? (
            <ReactCrop
              crop={crop}
              aspect={aspectRatio}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              keepSelection
              style={{ maxHeight: '500px' }}
            >
              <img
                src={transformedImage}
                ref={imageRef}
                alt="croppable"
                onLoad={onLoaded}
              />
            </ReactCrop>
          ) : (
            <WarningMessage>Something went wrong :(</WarningMessage>
          )}
        </CroppperImageWrapper>
      </Box>
    </Paper>
  );
};

Cropper.defaultProps = {
  aspectRatio: 1,
  transformer: URL.createObjectURL,
};

Cropper.propTypes = {
  aspectRatio: PropTypes.number,
  onAcceptCallback: PropTypes.func.isRequired,
  onRejectCallback: PropTypes.func.isRequired,
  transformer: PropTypes.func,
  image: PropTypes.object.isRequired,
};

export default Cropper;
