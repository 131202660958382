import React from 'react';
import { styled } from '@mui/material/styles';
import { Loader } from '@components/Loader';

const LoaderWrapper = styled('div')`
  width: 30px;
  margin: auto;
`;

export const SelectLoader = ({ stroke, width, ...props }) => (
  <LoaderWrapper>
    <Loader stroke={stroke} width={width} {...props} />
  </LoaderWrapper>
);
