export default ({ theme }) => ({
  MuiInput: {
    styleOverrides: {
      root: {
        '&.MuiInput-underline:not(.Mui-disabled)::before': {
          borderBottom: `1px solid ${theme.palette.grey[600]}`,
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled)::before': {
          borderBottom: `2px solid ${theme.palette.grey[700]}`,
        },
      },
    },
  },
});
