import { createTheme } from '@mui/material/styles';
import typography from '@styles/theme/typography';
import { getShared } from '@styles/shared';
import breakpoints from '@styles/theme/breakpoints';
import components from '@styles/theme/components';
import currentTheme from '@styles/_theme';

let theme = createTheme({
  spacing: 4,
  breakpoints,
  typography,
  ...getShared(),
});

theme = createTheme(theme, currentTheme(theme));

const finalTheme = createTheme(theme, components(theme));

export default finalTheme;
