import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import routes from '@routes';
import { Title, Description } from '@pages/signup/components/AccountCreate';

const Section = styled(Box)`
  display: flex;
  border-bottom: 1px solid #878da4;
  color: #fff;
  padding: 20px 0 10px 0;
  margin-bottom: 10px !important;
  font-size: 16px;
  width: 100%;
`;

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 4fr 8fr;
  margin-bottom: 10px;
`;

const GridElem = styled(Box)`
  display: flex;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
  color: #fff;
`;

const ReviewDetails = ({ profileStore: { profile } }) => {
  const history = useHistory();

  const handleContinue = () => {
    history.replace(routes.main.dashboard);
  };

  const {
    email,
    first_name: firstName,
    last_name: lastName,
    billing_info: billingInfo,
  } = useMemo(() => profile || {}, [profile]);

  return (
    <>
      <Title>Review your details</Title>
      <Description>
        Please take a minute to review and confirm your details below.
      </Description>
      <Section sx={{ pt: '10px' }}>Account holder details</Section>
      <GridWrapper>
        <GridElem fontWeight="bold">First name:</GridElem>
        <GridElem>{firstName}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">Last name:</GridElem>
        <GridElem>{lastName}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">Email address:</GridElem>
        <GridElem>{email}</GridElem>
      </GridWrapper>
      <Section>Billing details</Section>
      <GridWrapper>
        <GridElem fontWeight="bold">First name:</GridElem>
        <GridElem>{billingInfo?.first_name}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">Last name:</GridElem>
        <GridElem>{billingInfo?.last_name}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">Email address:</GridElem>
        <GridElem>{billingInfo?.email}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">Company name:</GridElem>
        <GridElem>{billingInfo?.company_name}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">VAT number:</GridElem>
        <GridElem>{billingInfo?.vat_number}</GridElem>
      </GridWrapper>
      <Section>Billing address</Section>
      <GridWrapper>
        <GridElem fontWeight="bold">Address line:</GridElem>
        <GridElem>{billingInfo?.address}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">App/Suite:</GridElem>
        <GridElem>{billingInfo?.suite}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">City:</GridElem>
        <GridElem>{billingInfo?.city}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">State:</GridElem>
        <GridElem>{billingInfo?.state}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">ZIP/Postal code:</GridElem>
        <GridElem>{billingInfo?.postal_code}</GridElem>
      </GridWrapper>
      <GridWrapper>
        <GridElem fontWeight="bold">County/Region:</GridElem>
        <GridElem>{billingInfo?.country_code}</GridElem>
      </GridWrapper>
      <Button
        size="large"
        variant="contained"
        fullWidth
        sx={{ mt: '15px' }}
        onClick={handleContinue}
      >
        CONTINUE
      </Button>
    </>
  );
};

export default inject('profileStore')(observer(ReviewDetails));
