import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import * as Styled from '@components/RangeCard/style';
import CardOptions from '@components/CardOptions';

const RangeCard = ({
  header,
  description,
  color,
  margin,
  percentage,
  icon,
  type,
  hide = false,
  forPrint,
}) => {
  if (hide) {
    return null;
  }
  const showPercentageBar = useMemo(() => typeof percentage === 'number', [
    percentage,
  ]);

  return (
    <Styled.Card margin={margin}>
      <Typography variant="h1" sx={{ paddingRight: '30px' }}>
        {typeof header === 'number' ? header.toLocaleString('en') : header}
      </Typography>
      <CardOptions icon={icon} type={type} color={color} forPrint={forPrint} />

      <Styled.Description>
        <Typography>{description}</Typography>
      </Styled.Description>

      {showPercentageBar && <Styled.Range color={color} value={percentage} />}
    </Styled.Card>
  );
};

RangeCard.defaultProps = {
  color: undefined,
  margin: '0',
  percentage: null,
};

RangeCard.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  color: PropTypes.string,
  margin: PropTypes.string,
  percentage: PropTypes.number,
};

export default memo(RangeCard);
