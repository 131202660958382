import { styled } from '@mui/material/styles';
import { Link as L } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';

export const Header = styled('div')`
  font-size: ${({ theme }) => theme.fontSizes.section};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ViewersAmount = styled('div')`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 5px 0 10px 0;
`;

export const AudiencePercentage = styled(Box)`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  align-items: center;
`;

export const AudiencePercentageText = styled('span')`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const MapContainer = styled('div')`
  height: ${({ height }) => height || '150px'};
`;

export const ButtonBox = styled('div')`
  background: ${({ theme }) => theme.palette.darkBlue};
  bottom: 0;
  padding: 15px;
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
`;

export const XlsDownloadBtn = withTheme(
  withStyles({
    root: {
      background: ({ theme }) => theme.palette.primary.main,
      fontWeight: '900',
      color: '#fff',
      padding: '5px 10px',
      marginTop: '10px',
      '&.Mui-disabled': {
        background: ({ theme }) => theme.palette.primary.main,
        color: '#fff',
      },
    },
  })(Button),
);

export const DownloadButton = withTheme(
  withStyles({
    root: {
      background: ({ theme }) => theme.palette.secondary.main,
      fontWeight: '900',
      color: '#fff',
      padding: '5px 10px',
      marginBottom: '5px',
      justifyContent: 'space-between',
      width: '100%',
      '&.Mui-disabled': {
        background: ({ theme }) => theme.palette.secondary.main,
        color: '#fff',
      },
    },
  })(Button),
);

export const TranslateAnimation = styled('span', {
  shouldForwardProp: prop => !['animate'].includes(prop),
})`
  overflow: hidden;
  svg {
    display: block;
    @keyframes translating {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(100%);
      }
    }
    animation: ${({ animate }) =>
      animate ? 'translating 2s linear infinite' : null};
  }
`;

export const Link = styled(L)`
  text-decoration: none;
`;
