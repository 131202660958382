import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;
`;

export const Left = styled(Box)``;

export const Middle = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 120px;
  margin-left: 10px;
  justify-content: center;
`;

export const Right = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const PictureWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.background};
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
`;

export const Name = styled(Box)`
  color: ${({ theme }) => theme.header.profileName};
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 167px;
  white-space: nowrap;
  line-height: 1;
  margin-bottom: 2px;
`;

export const Role = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.header.roleName};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
`;

export const IconWrapper = styled(Box)`
  color: ${({ theme }) => theme.header.roleName};
`;
