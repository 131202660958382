import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: 0,
  isDeletingId: [],
  chatMessages: null,
  chatRooms: [],
  selectedRows: [],
};

export class ChatMessagesPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getChatMessages = async ({
    page,
    search,
    room,
    silent,
    sort,
    pinned,
    autorefresh,
  }) => {
    if (!silent) this.isLoading += 1;
    this.setSelectedRows([]);

    try {
      const res = await ResourcesStore.getChatMessages({
        page,
        search,
        room,
        sort,
        pinned,
      });

      this.chatMessages = {
        ...res,
        search,
        sort,
        room,
        page,
        autorefresh,
        pinned,
      };
    } catch (err) {
      console.debug('[getChatMessages] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action setSelectedRows = val => {
    this.selectedRows = val;
  };

  @action getChatRooms = async () => {
    try {
      const { results } = await ResourcesStore.getChatRooms({
        per: '-1',
      });

      this.chatRooms.replace(results);
    } catch (err) {
      console.debug('[getChatRooms] failed', err);
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'sort', 'room', 'pinned', 'autorefresh'].every(
      param => params[param] === this.chatMessages?.[param],
    );
  };

  @action deleteChatMessage = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = [id];

      await ResourcesStore.deleteChatMessage({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deleteChatMessage] failed', err);
    } finally {
      this.isDeletingId = [];
    }
  };

  @action bulkDeleteChatMessages = async ({ payload, onSuccess }) => {
    try {
      this.setSelectedRows([]);
      this.isDeletingId = payload.ids;
      await ResourcesStore.bulkDeleteChatMessages({
        payload,
      });
      await onSuccess?.();
      return null;
    } catch (err) {
      console.debug('[bulkDeleteChatMessages] failed', err);
      return Promise.reject(err);
    } finally {
      this.isDeletingId = [];
    }
  };

  @action setPinnedMessage = async ({ id, payload, onSuccess }) => {
    try {
      await ResourcesStore.updateMessagePinned({
        id,
        payload,
      });

      await onSuccess?.();

      return null;
    } catch (err) {
      console.debug('[setPinnedMessage] failed', err);
      return Promise.reject(err);
    }
  };

  @action getChatMessagesExport = async ({ query, room }) => {
    try {
      toast.cSuccess(
        'Export started, soon you will be prompted to download an exported file',
      );

      const data = await ResourcesStore.getChatMessagesExport({
        query,
        room,
      });

      const blob = new Blob([data]);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, { type: 'text/csv' });
      a.download = `chat_messages_${new Date().toISOString()}.csv`;
      a.style = 'display:none;';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      toast.cError('Export has failed');
      console.debug('[getChatMessagesExport] failed', err);
    }
  };

  @action softClearStore = () => {
    this.setSelectedRows([]);
  };
}

export default new ChatMessagesPageStore();
