import { action, extendObservable, computed } from 'mobx';
import { CancelToken } from '@app/api';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_TYPES } from '@constants';
import { routerStore } from '@stores';
import routes from '@routes';

const initialState = {
  isLoading: false,
  product: {},
  eventSelectOptions: [],
  agendaItems: [],
  defaultFormValues: {},
  formType: null,
};
export class ProductStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateProduct(...props);
    }
    if (this.isCreate) {
      return this.createProduct(...props);
    }

    return null;
  };

  @action getProduct = async ({ id }) => {
    try {
      this.product = await ResourcesStore.getProduct(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );
    } catch (err) {
      console.debug('[getProduct] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.products);
      }
      throw err;
    }
  };

  @action createProduct = async ({ values, onSuccess }) => {
    this.isLoading = true;
    const payload = {
      ...values,
      event_id: values.event_id?.value || undefined,
      agenda_item_id: values.agenda_item_id?.value || undefined,
    };

    try {
      await ResourcesStore.prodPageCreateDocument({ payload, onSuccess });
    } catch (err) {
      console.debug('[createProduct] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateProduct = async ({ id, values, onSuccess }) => {
    this.isLoading = true;
    const onS = newVals => {
      onSuccess?.(newVals);
      this.product = newVals;
      this.setupDefaultFormValues();
    };

    const payload = {
      ...values,
      products_owning_entity_type: values.event_id ? 'Event' : 'AgendaItem',
      products_owning_entity_id: values.event_id
        ? values.event_id.value
        : values.agenda_item_id.value,
    };

    try {
      await ResourcesStore.updateProduct({ id, payload, onSuccess: onS });
    } catch (err) {
      console.debug('[updateProduct] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteProduct = async ({ id, onSuccess }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteProduct({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteProduct] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    this.defaultFormValues = {
      ...this.product,
      event_id:
        this.product.products_owning_entity_type === 'AgendaItem'
          ? undefined
          : this.eventSelectOptions.find(
              ev => ev.value === this.product.products_owning_entity_id,
            ),
      agenda_item_id:
        this.product.products_owning_entity_type === 'AgendaItem'
          ? this.agendaItems.find(
              ai => ai.value === this.product.products_owning_entity_id,
            )
          : undefined,
    };
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
      throw err;
    }
  };

  @action getAgendaSelectOptions = async () => {
    try {
      this.agendaItems = await ResourcesStore.getAgendaItemsSelectOptions();
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
      throw err;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.isLoading = true;
    try {
      await Promise.all([
        this.isEdit && this.getProduct({ id }),
        this.getEventSelectOptions(),
        this.getAgendaSelectOptions(),
      ]);

      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ProductStore();
