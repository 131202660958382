import { action, extendObservable, computed } from 'mobx';
import _ from 'lodash';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: null,
  upcomingEvents: null,
  pastEvents: null,
  liveEvents: null,
};
export class DashboardPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get showCreate() {
    return this.isUpcomingEmpty && this.isPastEmpty;
  }

  @computed get isUpcomingEmpty() {
    return _.isEmpty(this.upcomingEvents);
  }

  @computed get isPastEmpty() {
    return _.isEmpty(this.pastEvents);
  }

  @computed get isLiveEmpty() {
    return _.isEmpty(this.lastEvents);
  }

  @action setupData = async () => {
    try {
      this.isLoading = true;
      const promises = [
        this.getPastEvents(),
        this.getUpcomingEvents(),
        this.getLiveEvents(),
      ];
      await Promise.all(promises);
    } catch (error) {
      console.debug('[setupData failed]', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action getLiveEvents = async () => {
    try {
      this.liveEvents = await ResourcesStore.getLiveEvents({
        per: 3,
        page: 1,
        order_by: 'start_date DESC',
      });
      return null;
    } catch (error) {
      console.debug('[getLiveEvents failed]', error);
      return error;
    }
  };

  @action getPastEvents = async () => {
    try {
      this.pastEvents = await ResourcesStore.getPastEvents({
        per: 3,
        page: 1,
        order_by: 'start_date DESC',
      });
      return null;
    } catch (error) {
      console.debug('[getPastEvents failed]', error);
      return error;
    }
  };

  @action getUpcomingEvents = async () => {
    try {
      this.upcomingEvents = await ResourcesStore.getUpcomingEvents({
        per: 3,
        page: 1,
        order_by: 'start_date DESC',
      });
      return null;
    } catch (error) {
      console.debug('[getUpcomingEvents failed]', error);
      return error;
    }
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new DashboardPageStore();
