export default ({ theme }) => ({
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: theme.palette.primary.main,
        '&.Mui-disabled': {
          color: theme.palette.primary.main,
          '+.MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 0.38,
          },
        },
      },
    },
  },
});
