export default ({ theme }) => ({
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: theme.palette.red[800],
        fontSize: '16px',
      },
    },
  },
});
