import PasswordInput from '@components/_form/PasswordInput';
import { FullWidthForm } from '@pages/signIn/components/SignInForm';
import { useTheme, Box } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { styled } from '@mui/material/styles';
import { Button, BUTTON_TYPES } from '@components/Buttons/Button';
import {
  passwordsMustMatchIfExists,
  validatePasswordIfExists,
  required,
  composeValidators,
} from '@app/formValidators';

const FieldWrapper = styled(Box)`
  width: 100%;
  max-width: 400px;
`;

const ExpiredForm = ({ onSubmit, isCplusLogin, isLoading }) => {
  const theme = useTheme();
  const customTextInputStyles = useMemo(
    () => ({
      padding: '10px 10px 8px',
      fontSize: theme.fontSizes.body,
      borderRadius: isCplusLogin ? '20px' : '3px',
      backgroundColor: '#fff',
      outline: 'none',
    }),
    [isCplusLogin, theme],
  );
  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const err = {
          password: composeValidators(required, validatePasswordIfExists)(
            values.password,
            values,
          ),
          password_confirmation: composeValidators(
            required,
            passwordsMustMatchIfExists,
          )(values.password_confirmation, values),
        };
        return err;
      }}
    >
      {({ handleSubmit, submitError }) => (
        <FullWidthForm onSubmit={handleSubmit}>
          <Box
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Box
              mb="25px"
              fontSize={theme.fontSizes.subtitle}
              color={theme.colors.error}
              fontWeight={theme.fontWeights.semibold}
            >
              Your password has expired. Set new one!
            </Box>
            <FieldWrapper>
              <Field
                name="current_password"
                autocomplete="current_password"
                label="Current password"
                customStyle={customTextInputStyles}
                validate={required}
                component={PasswordInput}
              />
            </FieldWrapper>
            <FieldWrapper mt="12px">
              <Field
                name="password"
                autocomplete="new_password"
                label="New password"
                customStyle={customTextInputStyles}
                component={PasswordInput}
                infoText={
                  <>
                    <strong>Password requirements:</strong> <br />
                    At least 1 uppercase character <br />
                    At least 1 lowercase character <br />
                    At least 1 digit <br />
                    At least 1 special character <br />
                    Minimum 8 characters <br />
                  </>
                }
              />
            </FieldWrapper>
            <FieldWrapper mt="12px">
              <Field
                name="password_confirmation"
                autocomplete="new_password"
                label="New password confirmation"
                customStyle={customTextInputStyles}
                component={PasswordInput}
              />
            </FieldWrapper>

            {submitError && (
              <FieldWrapper mt="16px">
                {submitError.map(err => (
                  <Box
                    mb="2px"
                    color={theme.colors.error}
                    fontSize={theme.fontSizes.tiny}
                    key={err}
                  >
                    {err.replace(/^\w/, c => c.toUpperCase())}
                  </Box>
                ))}
              </FieldWrapper>
            )}
            <Box
              display="flex"
              width={1}
              mt="16px"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Button
                  isLoading={isLoading}
                  label="CHANGE PASSWORD"
                  type={BUTTON_TYPES.PRIMARY}
                  onClick={handleSubmit}
                  disabled={isLoading}
                  size="l"
                  buttonType="submit"
                  btnPadding="0 15px"
                  borderRadius="20px"
                />
              </Box>
            </Box>
          </Box>
        </FullWidthForm>
      )}
    </Form>
  );
};

export default memo(ExpiredForm);
