import React from 'react';
import { toast } from 'react-toastify';
import SuccessToast from './SuccessToast';
import ErrorToast from './ErrorToast';
import CreatedToast from './CreatedToast';

const BASE_CLASS = 'Toast-cplus';
const DEFAULT_DURATION = 5000;
const BASE_OPTIONS = {
  className: BASE_CLASS,
  closeButton: false,
  autoClose: false,
  hideProgressBar: true,
  pauseOnFocusLoss: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

export const monkeyPatchToasts = () => {
  toast.cSuccess = (text, { duration } = {}) =>
    toast(
      <SuccessToast text={text} duration={duration || DEFAULT_DURATION} />,
      {
        ...BASE_OPTIONS,
      },
    );
  toast.cError = (text, { duration } = {}) =>
    toast(<ErrorToast text={text} duration={duration || DEFAULT_DURATION} />, {
      ...BASE_OPTIONS,
    });
  toast.cCreated = (text, cb1, cb2, { duration } = {}) =>
    toast(
      <CreatedToast
        text={text}
        cb1={cb1}
        cb2={cb2}
        duration={duration || DEFAULT_DURATION}
      />,
      {
        ...BASE_OPTIONS,
      },
    );
};
