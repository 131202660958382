import React from 'react';
import moment from 'moment';
import { action, extendObservable, computed, toJS } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import modalStore from '@stores/modalStore';
import subscriptionStore from '@stores/subscriptionStore';
import { WarningModalContent } from '@app/components/WarningModalContent';
import { CancelToken } from '@app/api';
import { AGENDA_TYPES } from '@constants';
import routerStore from '@stores/routerStore';
import routes from '@routes';

const FORM_TYPES = { CREATE: 1, EDIT: 2 };

const initialState = {
  formType: null,
  isLoading: true,
  isBreakoutLoading: true,
  isThemeLoading: true,
  isProductsLoading: false,
  isGroupsLoading: true,
  isImportsLoading: true,
  externalDefaultState: null,
  defaultFormValues: {},
  eventsSelectOptions: [],
  breakoutRoom: {},
  event: null,
  organization: null,
  breakoutThemeFields: null,
  documents: [],
  products: [],
  groups: [],
  imports: [],
  speakers: [],
  eventSelectOptions: [],
  importMappingInterval: null,
  lastImportBreakoutMapping: null,
  initialLoading: true,
  isDocumentsLoading: false,
  isProductsInitialLoading: false,
  isSpeakersLoading: false,
};

export class BreakoutRoomStore {
  __cancelToken = null;

  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @computed get isNotInitializedType() {
    return this.formType === null;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    this.getResources({ id });
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      this.prepareAndUpdateBreakoutRoom(...props);
    }
    if (this.isCreate) {
      this.createBreakoutRoom(...props);
    }

    return null;
  };

  @action getResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      if (this.isEdit) {
        await this.getBreakoutRoom({ id });
        this.getSpeakers();
        this.getDocuments();
        this.getProducts();
        this.getGroups();
        this.getImports();
      } else {
        await this.getEventSelectOptions();
        this.isBreakoutLoading = false;
      }
      this.getTheme();
      this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getResources] failed');
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = () => {
    if (this.isEdit) {
      this.defaultFormValues = {
        ...this.breakoutRoom,
        agenda_type: this.breakoutRoom?.agenda_type,
        event_id: this.eventsSelectOptions.find(
          ev => ev.value === this.breakoutRoom.event_id,
        ),
      };
    } else {
      if (this.externalDefaultState) {
        this.defaultFormValues = {
          ...this.externalDefaultState,
          event_id: this.eventsSelectOptions.find(
            ev => ev.value === this.externalDefaultState.event_id,
          ),
        };

        this.externalDefaultState = null;
        return;
      }

      this.defaultFormValues = {
        agenda_type: AGENDA_TYPES.AGENDA_ITEM,
        start_date: moment()
          .utc()
          .toISOString(),
        end_date: moment()
          .add(1, 'hour')
          .utc()
          .toISOString(),
        locked: true,
        show_external_icon_on_breakout: true,
      };
    }
  };

  @action getBreakoutRoom = async ({ id }) => {
    try {
      this.isBreakoutLoading = true;
      const br = await ResourcesStore.getAgendaItem(
        { id },
        { cancelToken: this.__cancelToken?.token },
      );

      this.breakoutRoom = this.manipulateAgendaObject(br);

      await Promise.allSettled([
        this.getEventContext(),
        this.getEventSelectOptions(),
      ]);

      this.setupDefaultFormValues();
      this.isBreakoutLoading = false;
    } catch (error) {
      routerStore.push(routes.main.breakout);
      console.debug('[getBreakoutRoom] failed', error);
    }
  };

  @action getEventContext = async () => {
    await subscriptionStore.setContextEvent(null, this.breakoutRoom.event_id);

    this.event = toJS(subscriptionStore.contextEvent);

    const org = await ResourcesStore.getOrganization({
      id: this.event.organization_id,
    });
    this.organization = org;
  };

  @action getTheme = async () => {
    try {
      this.isThemeLoading = true;
      const data = await ResourcesStore.getAgendaThemeFields({
        cancelToken: this.__cancelToken?.token,
      });
      this.breakoutThemeFields = data.reduce(
        (o, k) => ({ ...o, [k.name]: k.type }),
        {},
      );
      this.isThemeLoading = false;
    } catch (error) {
      console.debug('[getTheme] failed', error);
    }
  };

  @action getSpeakers = async () => {
    try {
      this.speakers = this.breakoutRoom.speakers;
    } catch (err) {
      console.debug('[getSpeakers] failed', err);
    }
  };

  @action createSpeaker = async (values, form) => {
    try {
      this.isSpeakersLoading = true;
      const res = await ResourcesStore.createSpeaker({
        parentId: this.event.id,
        payload: { ...values, agenda_item_ids: [this.breakoutRoom.id] },
      });
      this.speakers = [...this.speakers, res];
      form.restart();
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action unpinSpeaker = async speaker => {
    try {
      this.isSpeakersLoading = true;
      const modifiedSpeaker = {
        ...speaker,
        agenda_item_ids: speaker.agenda_item_ids.filter(
          id => id !== this.breakoutRoom.id,
        ),
      };

      await ResourcesStore.updateSpeaker({
        id: modifiedSpeaker.id,
        payload: modifiedSpeaker,
      });

      this.speakers = this.speakers.filter(
        spk => spk.id !== modifiedSpeaker.id,
      );
    } catch (err) {
      console.debug('[unpinSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action addExistingSpeaker = async (values, form) => {
    try {
      this.isSpeakersLoading = true;
      const res = await ResourcesStore.updateSpeaker({
        id: values.speaker.id,
        payload: {
          ...values.speaker,
          agenda_item_ids: [
            ...values.speaker.agenda_item_ids,
            this.breakoutRoom.id,
          ],
        },
      });
      form.restart();
      this.speakers = [...this.speakers, res];
    } catch (err) {
      console.debug('[createSpeaker] failed', err);
    } finally {
      this.isSpeakersLoading = false;
    }
  };

  @action getSpeakersSelectOptions = async ({ eventId }) => {
    try {
      const { results } = await ResourcesStore.getSpeakers({
        per: '-1',
        event: eventId,
      });
      return results;
    } catch (e) {
      return [];
    }
  };

  @action getDocuments = async () => {
    try {
      this.documents = this.breakoutRoom.agenda_item_documents;
    } catch (err) {
      console.debug('[getDocuments] failed', err);
    }
  };

  @action createDocument = async (values, form) => {
    try {
      this.isDocumentsLoading = true;
      const res = await ResourcesStore.createDocument({
        parentId: this.event.id,
        payload: {
          ...values,
          agenda_item_ids: [this.breakoutRoom.id],
        },
      });

      this.documents = [...this.documents, res];
      form.restart();
      return {};
    } catch (err) {
      console.debug('[createDocument] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isDocumentsLoading = false;
    }
  };

  @action deleteDocumentModal = ({ id }) => {
    modalStore.openModal({
      content: (
        <WarningModalContent
          onCancel={modalStore.closeModal}
          onConfirm={() => {
            modalStore.closeModal();
            this.deleteDocument({ id });
          }}
        />
      ),
    });
  };

  @action deleteDocument = async ({ id }) => {
    try {
      this.isDocumentsLoading = true;
      await ResourcesStore.deleteDocument({ id });
      this.documents = this.documents.filter(doc => doc.id !== id);
    } catch (err) {
      console.debug('[deleteDocument] failed', err);
    } finally {
      this.isDocumentsLoading = false;
    }
  };

  @action getProducts = async () => {
    try {
      this.isProductsInitialLoading = true;
      const { results } = await ResourcesStore.getEntityProducts(
        {
          id: this.breakoutRoom.id,
          type: 'agenda_item',
          per: '-1',
        },
        { cancelToken: this.__cancelToken?.token },
      );
      this.products = results;

      this.isProductsInitialLoading = false;
    } catch (err) {
      console.debug('[getProducts] failed', err);
    }
  };

  @action createProduct = async (values, form) => {
    try {
      this.isProductsLoading = true;
      const res = await ResourcesStore.createProduct({
        type: 'agenda_item',
        parentId: this.breakoutRoom.id,
        payload: values,
      });

      this.products = [...this.products, res];
      form.restart();
    } catch (err) {
      console.debug('[createProduct] failed', err);
    } finally {
      this.isProductsLoading = false;
    }
  };

  @action deleteProduct = async ({ id }) => {
    try {
      this.isProductsLoading = true;
      await ResourcesStore.deleteProduct({ id });
      this.products = this.products.filter(p => p.id !== id);
    } catch (err) {
      console.debug('[deleteProduct] failed', err);
    } finally {
      this.isProductsLoading = false;
    }
  };

  @action getGroups = async () => {
    try {
      this.isGroupsLoading = true;
      const { results } = await ResourcesStore.getGroups({
        agenda_item_id: this.breakoutRoom.id,
      });

      this.groups = results;
      this.isGroupsLoading = false;
    } catch (err) {
      console.debug('[getGroups] failed', err);
    }
  };

  @action deleteGroup = async ({ id, onSuccess }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deleteGroupItem({ id, onSuccess });
    } catch (err) {
      console.debug('[deleteGroupItem] failed', err);
    } finally {
      this.isDeletingId = null;
    }
  };

  @action getImports = async () => {
    try {
      this.isImportsLoading = true;
      const { results } = await ResourcesStore.getGroupsImports({
        agenda_item_id: this.breakoutRoom.id,
      });

      this.imports = results;
      this.isImportsLoading = false;
    } catch (err) {
      console.debug('[getImports] failed', err);
    }
  };

  @action createImport = async (values, form) => {
    try {
      values.agenda_item_id = this.breakoutRoom.id;
      await ResourcesStore.breakoutMappingImportCreate({ payload: values });
      this.lastImportBreakoutMapping = await ResourcesStore.getlastImportBreakoutMapping(
        {
          agendaId: this.breakoutRoom.id,
        },
      );

      this.startImportMappingInterval();
      form.restart();
    } catch (err) {
      console.debug('[createImport] failed');
    }
  };

  @action startImportMappingInterval = () => {
    this.importMappingInterval = setInterval(async () => {
      const res = await ResourcesStore.getlastImportBreakoutMapping({
        agendaId: this.breakoutRoom.id,
      });

      if (res.status !== 'pending') {
        clearInterval(this.importMappingInterval);
        this.importMappingInterval = null;
        this.getImports();
        this.getGroups();
      }
    }, 3000);
  };

  @action manipulateAgendaObject = ai => {
    return {
      ...ai,
      agenda_item_custom_theme: {
        ...ai.agenda_item_custom_theme,
      },
    };
  };

  @action createBreakoutRoom = async ({ payload, ...props }) => {
    this.isBreakoutLoading = true;

    const result = await ResourcesStore.createAgendaItem({
      payload: {
        ...payload,
        event_id: payload.event_id.value,
        agenda_type: 'breakout_room',
      },
      ...props,
    });

    this.isBreakoutLoading = false;

    return result;
  };

  @action prepareAndUpdateBreakoutRoom = async ({ payload, ...props }) => {
    const { ...theme } = payload.agenda_item_custom_theme;

    const { agenda_end_datetime: eventAgendaEndDatetime } = this.event;

    const {
      start_date: agendaStartDate,
      end_date: agendaEndDate,
    } = this.breakoutRoom;

    const anyTimeChanged =
      !moment(agendaStartDate).isSame(moment(payload.start_date)) ||
      !moment(agendaEndDate).isSame(moment(payload.end_date));

    const preparedPayload = {
      ...payload,
      agenda_item_custom_theme: theme,
      document_ids: undefined,
      product_ids: undefined,
      event_id: payload.event_id.value,
    };

    if (!moment().isAfter(moment(eventAgendaEndDatetime)) || !anyTimeChanged) {
      this.updateBreakoutRoom({ payload: preparedPayload, ...props });
    } else {
      modalStore.openModal({
        content: (
          <WarningModalContent
            msg="Warning: editing this item may skew post-event analytics, please refrain from editing times post-event where possible."
            onCancel={modalStore.closeModal}
            onConfirm={() => {
              modalStore.closeModal();
              this.updateBreakoutRoom({ payload: preparedPayload, ...props });
            }}
          />
        ),
      });
    }
  };

  @action updateBreakoutRoom = async ({ payload, ...props }) => {
    this.isLoading = true;
    try {
      const ai = await ResourcesStore.updateAgendaItem({
        id: this.breakoutRoom.id,
        payload,
        ...props,
      });

      this.breakoutRoom = this.manipulateAgendaObject(ai);
      await this.getEventContext();
      this.setupDefaultFormValues();
      this.getDocuments();
      this.getProducts();
      this.getGroups();
    } catch (err) {
      console.debug('[updateBreakoutRoom] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteBreakoutRoom = async ({ id, ...props }) => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteAgendaItem({ id, ...props });
    } catch (err) {
      console.debug('[deleteBreakoutRoom] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getEventSelectOptions = async () => {
    try {
      this.isLoading = true;
      const { results } = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action reorderResource = type => async ({ startIndex, endIndex }) => {
    try {
      let finalArray = [];
      if (type === 'speakers') {
        finalArray = this.speakers;
      }
      if (type === 'documents') {
        finalArray = this.documents;
      }
      if (type === 'products') {
        finalArray = this.products;
      }
      const result = finalArray;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const ids = result.map(resource => resource.id);
      if (type === 'speakers') {
        this.speakers = result;
        await ResourcesStore.reorderSpeakers({
          agenda_id: this.breakoutRoom.id,
          speaker_ids: ids,
        });
      }
      if (type === 'documents') {
        this.documents = result;
        await ResourcesStore.reorderDocuments({
          agenda_id: this.breakoutRoom.id,
          documents_ids: ids,
        });
      }
      if (type === 'products') {
        this.products = result;
        await ResourcesStore.reorderProducts({
          agenda_id: this.breakoutRoom.id,
          products_ids: ids,
        });
      }
    } catch (err) {
      console.debug('[reorderResource] failed', err);
    }
  };

  @action clearStore = async () => {
    this.__cancelToken?.cancel();
    subscriptionStore.resetContextEvent();
    clearInterval(this.importMappingInterval);
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new BreakoutRoomStore();
