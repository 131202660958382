import { yellow } from '@mui/material/colors';

export const isJackEthos =
  process.env.REACT_APP_ENV === 'production_jackethos' ||
  process.env.REACT_APP_ENV === 'production_jackethos_cms22';
export const envMappingType =
  process.env.REACT_APP_ENV === 'production_jackethos' ||
  process.env.REACT_APP_ENV === 'production_jackethos_cms22'
    ? 'Ethos'
    : 'C+';

const jackEthosTheme = ({ palette }) => ({
  colors: {
    background: palette.almostWhite,
    box: palette.white,
    border: palette.gray,
    border2: palette.lightSilver,
    primary: palette.orange[500],
    secondary: palette.red.main,
    text: palette.dark.dark,
    textSecondary: palette.screamingGrey,
    textTertiary: palette.temperedSteel,
    textWhite: palette.white,
    textPlaceholder: palette.lightSilver,
    badge: palette.lightSilver,
    darkBadge: palette.lightStoneGray,
    sideNavSectionLabel: palette.temperedSteel,
    tableHeaderBg: palette.lightSilver,
    tableRow1: palette.white,
    tableRow2: palette.whitey,
    chatBackground: palette.whiteSmoke,
    chatMsgBorder: palette.temperedSteel,
    chatScroll: palette.temperedSteel,
    success: palette.green[500],
    successBackground: palette.lightGreen,
    error: palette.red.main,
  },
  header: {
    height: 65,
    logoHeight: 35,
    background: '#000000',
    logo: '/assets/images/jackethos/jackethos_white.png',
    profileName: palette.white,
    organizationSelectorBackground: palette.dark.dark,
    roleName: palette.whiteSmoke,
  },
  sidebar: {
    background: palette.white,
    border: `2px solid ${palette.athensGray}`,
    iconColor: palette.screamingGrey,
    sectionColor: palette.screamingGrey,
  },
  mainTable: {
    background: palette.white,
  },
  baseModal: {
    headerBackground: palette.white,
    bodyBackground: palette.closeToWhite,
    border: `1px solid ${palette.gray}`,
  },
  searchBar: {
    background: palette.white,
    border: `1px solid ${palette.gray}`,
    borderFocused: `1px solid ${palette.orange[500]}`,
  },
  innerPage: {
    background: palette.closeToWhite,
  },
  palette: {
    primary: {
      main: palette.orange[500],
      dark: palette.orange[800],
      light: palette.orange[200],
      ...palette.orange,
    },
  },
  analytics: {
    stats: {
      messaging: palette.blue[600],
      interactions: palette.red[500],
      engagement: palette.orange[300],
    },
    dounghts: [
      palette.blue[600],
      palette.red[500],
      palette.orange[300],
      yellow[500],
      palette.dark[500],
    ],
    sidebarUserActivity: {
      one: palette.blue[600],
      two: palette.orange[300],
    },
    sidebar: {
      background: '#fff',
    },
  },
  border: '1px solid #e0e0e0',
  toast: {
    success: {
      backgroundColor: palette.green[50],
      textColor: palette.darkGray,
      closeButtonColor: palette.darkGray,
      borderColor: palette.green.A700,
    },
    error: {
      backgroundColor: palette.red[50],
      textColor: palette.darkGray,
      closeButtonColor: palette.darkGray,
      borderColor: palette.red.main,
    },
  },
});

const defaultTheme = ({ palette }) => ({
  colors: {
    background: palette.almostWhite,
    box: palette.white,
    border: palette.gray,
    border2: palette.lightSilver,
    primary: palette.blue[500],
    secondary: palette.red.main,
    text: palette.dark.dark,
    textSecondary: palette.screamingGrey,
    textTertiary: palette.temperedSteel,
    textWhite: palette.white,
    textPlaceholder: palette.lightSilver,
    badge: palette.lightSilver,
    darkBadge: palette.lightStoneGray,
    sideNavSectionLabel: palette.temperedSteel,
    tableHeaderBg: palette.lightSilver,
    tableRow1: palette.white,
    tableRow2: palette.whitey,
    chatBackground: palette.whiteSmoke,
    chatMsgBorder: palette.temperedSteel,
    chatScroll: palette.temperedSteel,
    success: palette.green[500],
    successBackground: palette.lightGreen,
    error: palette.red.main,
  },

  header: {
    height: 65,
    logoHeight: 20,
    background: palette.materialBlue,
    logo: '/assets/images/logo.png',
    profileName: palette.white,
    organizationSelectorBackground: palette.darkBlue,
    roleName: palette.whiteSmoke,
  },
  sidebar: {
    background: palette.white,
    border: `2px solid ${palette.gray}`,
    iconColor: palette.screamingGrey,
    sectionColor: palette.screamingGrey,
  },
  mainTable: {
    background: palette.white,
    secondaryBackground: palette.dark[50],
  },
  baseModal: {
    headerBackground: palette.white,
    bodyBackground: palette.closeToWhite,
    border: `1px solid ${palette.gray}`,
  },
  searchBar: {
    background: palette.white,
    border: `1px solid ${palette.gray}`,
    borderFocused: `1px solid ${palette.blue[500]}`,
  },
  innerPage: {
    background: palette.closeToWhite,
  },
  palette: {
    primary: {
      main: palette.blue[500],
      dark: palette.blue[800],
      light: palette.blue[200],
      ...palette.blue,
    },
  },
  analyticsSidebar: {
    background: '#fff',
  },
  analytics: {
    stats: {
      messaging: palette.blue[600],
      interactions: palette.red[500],
      engagement: palette.orange[300],
    },
    dounghts: [
      palette.blue[600],
      palette.red[500],
      palette.orange[300],
      yellow[500],
      palette.dark[500],
    ],
    sidebarUserActivity: {
      one: palette.blue[600],
      two: palette.orange[300],
    },
    sidebar: {
      background: '#fff',
    },
  },
  border: '1px solid #e0e0e0',
  toast: {
    success: {
      backgroundColor: palette.green[50],
      textColor: palette.darkGray,
      closeButtonColor: palette.darkGray,
      borderColor: palette.green.A700,
    },
    error: {
      backgroundColor: palette.red[50],
      textColor: palette.darkGray,
      closeButtonColor: palette.darkGray,
      borderColor: palette.red.main,
    },
  },
});

const currentTheme = theme =>
  isJackEthos ? jackEthosTheme(theme) : defaultTheme(theme);

export default currentTheme;
