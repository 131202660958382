import React from 'react';
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepLabel as MuiStepLabel,
  StepConnector as MuiStepConnector,
  styled,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import CircleOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';

const Stepper = styled(MuiStepper)({
  background: 'transparent',
});

const StepConnector = styled(MuiStepConnector)(({ theme }) => ({
  top: '10px',
  left: 'calc(-50% + 7px)',
  right: 'calc(50% + 7px)',
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
  '&.Mui-disabled': {
    borderColor: '#71788A',
  },
  '& .MuiStepConnector-line': {
    transition: 'all',
    borderWidth: '2px',
  },
}));

const StepLabel = styled(MuiStepLabel)({
  '&.MuiStepLabel-alternativeLabel': {
    '& .MuiStepLabel-label': {
      color: '#71788A',
      fontWeight: 'bold',
      '&.Mui-active': {
        color: '#fff',
        fontWeight: 'bold',
      },
      '&.Mui-completed': {
        color: '#71788A',
        fontWeight: 'bold',
      },
    },
  },
});

const StepperComponent = ({ currentStep, steps }) => {
  const stepIconComponent = ({ active, completed, className }) => {
    return (
      <Box
        ownerstate={{ active }}
        className={className}
        sx={{ color: active || completed ? 'primary.main' : '#71788A' }}
      >
        {completed ? <CircleIcon /> : <CircleOutlinedIcon />}
      </Box>
    );
  };

  return (
    <Box sx={{ m: '30px 0' }}>
      <Stepper
        activeStep={currentStep}
        alternativeLabel
        connector={<StepConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={stepIconComponent}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;
