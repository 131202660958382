import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FieldTooltip from '@components/FieldTooltip';
import CloseIcon from '@mui/icons-material/Close';

const ColorInput = styled(HexColorInput)`
  background: none;
  width: 100%;
  border: none;
  outline: none;
  height: 25px;
  cursor: pointer;
  text-transform: uppercase;
`;

const ColorPickerInput = ({
  input,
  label,
  width,
  meta,
  required,
  infoText,
  notAvailableInSP,
}) => {
  const [color, setColor] = useState('');
  const error =
    (meta.touched || !meta.pristine) && (meta.error || meta.submitError);

  useEffect(() => {
    setColor(
      input.value && input.value[0] !== '#' ? `#${input.value}` : input.value,
    );
  }, [input.value]);

  const updateMainForm = () => {
    input.onChange(color.slice(1));
  };

  const handleEraseColor = () => {
    setColor('');
    input.onChange('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: width || '100%',
        position: 'relative',
      }}
    >
      {label && (
        <FormLabel
          error={Boolean(error)}
          required={required}
          sx={{ mb: '5px', display: 'block' }}
        >
          {label}
        </FormLabel>
      )}
      <Tooltip
        title={<HexColorPicker onChange={setColor} color={color} />}
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              borderRadius: '8px',
              padding: 0,
              background: 'transparent',
              boxShadow: '0px 0px 15px 2px rgba(0, 0 ,0, 0.75)',
            },
          },
        }}
        disableHoverListener
        disableTouchListener
        interactive
        placement="bottom-start"
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey[600]}`,
          })}
        >
          <Box
            sx={theme => ({
              width: '25px',
              height: '25px',
              flexShrink: 0,
              backgroundColor: color,
              borderRadius: '2px',
              border: `1px solid ${theme.palette.grey[600]}`,
            })}
          />
          <Box sx={{ ml: '5px', fontWeight: 'fontWeightBold', mr: '1px' }}>
            #
          </Box>
          <Box sx={() => ({ flexGrow: 1 })}>
            <ColorInput
              color={color}
              onChange={setColor}
              onBlur={updateMainForm}
              placeholder="Default color"
            />
          </Box>
          <IconButton onClick={handleEraseColor} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <FieldTooltip
        infoText={infoText}
        corner
        notAvailableInSP={notAvailableInSP}
        sx={{ top: '2px', right: '2px' }}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default ColorPickerInput;
