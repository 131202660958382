import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';

const initialState = {
  isLoading: false,
  player: {},
};
export class AwsHlsStreamStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action setPlayer = data => {
    this.player = data;
  };

  @action refreshInfo = async () => {
    try {
      this.isLoading = true;
      this.player = await ResourcesStore.awsRefreshStackInfo({
        playerId: this.player.id,
      });
    } catch (error) {
      console.debug('[refreshInfo] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action startChannel = async () => {
    try {
      this.isLoading = true;
      this.player = await ResourcesStore.awsStartChannel({
        playerId: this.player.id,
      });
      await this.refreshInfo();
    } catch (error) {
      console.debug('[startChannel] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action stopChannel = async () => {
    try {
      this.isLoading = true;
      this.player = await ResourcesStore.awsStopChannel({
        playerId: this.player.id,
      });
      await this.refreshInfo();
    } catch (error) {
      console.debug('[stopChannel] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action createStack = async values => {
    try {
      this.isLoading = true;
      this.player = await ResourcesStore.awsCreateStack({
        playerId: this.player.id,
        payload: values,
      });
      return true;
    } catch (error) {
      console.debug('[createStack] failed', error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteStack = async () => {
    try {
      this.isLoading = true;
      this.player = await ResourcesStore.awsDeleteStack({
        playerId: this.player.id,
      });
    } catch (error) {
      console.debug('[deleteStack] failed', error);
    } finally {
      this.isLoading = false;
    }
  };

  @action clearStore = () => {
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new AwsHlsStreamStore();
