import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Section = styled('div')`
  width: 33.33%;
  padding: 0 8px;
  box-sizing: border-box;
  display: ${({ forPrint }) => (forPrint ? 'block' : 'flex')};
  flex-direction: column;
`;

export const Wrapper = styled('div')`
  margin: 0 -8px;
  .MuiAccordion-root {
    background-color: transparent;

    @media print {
      background-color: white;
    }

    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    padding: 0 8px;
    &.Mui-expanded {
      min-height: auto;
    }
  }
  .MuiButtonBase-root {
    cursor: default !important;
  }
`;

export const Columns = styled(Box)`
  justify-content: space-between;
  position: relative;
  display: ${({ forPrint }) => (forPrint ? 'table' : 'flex')};
  width: 100%;
`;

export const LoaderCover = styled('div')`
  position: absolute;
  z-index: 10;
  background-color: rgba(244, 244, 251, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
