import { action, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { API, APIRoutes } from '@app/api';

const initialState = {
  isLoading: 0,
  isDeletingId: null,
  isPollAnswerLoading: 0,
  polls: null,
  eventsSelectOptions: [],
  agendaSelectOptions: [],
  pollAnswerUsers: null,
  pollAnswerUsersRaw: null,
  pollAnswer: null,
};

export class PollsPageStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @action getPolls = async ({
    page,
    search,
    event_id,
    silent,
    active,
    agenda_item_id,
  }) => {
    if (!silent) this.isLoading += 1;
    try {
      const res = await ResourcesStore.getPolls({
        page,
        search,
        event_id,
        active,
        agenda_item_id,
      });

      this.polls = {
        ...res,
        page,
        search,
        event_id,
        active,
        agenda_item_id,
      };
    } catch (err) {
      console.debug('[getPolls] failed', err);
    } finally {
      if (!silent) this.isLoading -= 1;
    }
  };

  @action deletePoll = async ({ id, onSuccess, onError }) => {
    try {
      this.isDeletingId = id;
      await ResourcesStore.deletePoll({ id });
      await onSuccess?.();
    } catch (err) {
      console.debug('[deletePoll] failed', err);
      onError?.();
    } finally {
      this.isDeletingId = null;
    }
  };

  @action handleActiveSwitch = async ({ id, payload }) => {
    try {
      const response = await API.put(`${APIRoutes.POLLS}/${id}`, payload);

      return response;
    } catch (err) {
      console.debug('[handleActiveSwitch] failed', err);
      return Promise.reject(err);
    }
  };

  @action matchPageSearchQuery = (params = {}) => {
    return ['search', 'page', 'event_id', 'agenda_item_id', 'active'].every(
      param => params[param] === this.polls?.[param],
    );
  };

  @action getEventSelectOptions = async () => {
    try {
      const resp = await ResourcesStore.getEventSelectOptions();
      this.eventsSelectOptions = resp.results.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
        return 0;
      });
    } catch (err) {
      console.debug('[getEventSelectOptions] failed', err);
    }
  };

  @action getAgendaSelectOptions = async eventId => {
    try {
      this.agendaSelectOptions = await ResourcesStore.getAgendaItemsSelectOptions(
        {
          eventId,
        },
      );
    } catch (err) {
      console.debug('[getAgendaSelectOptions] failed', err);
    }
  };

  @action getPollAnswer = async ({ id }) => {
    try {
      this.pollAnswer = await ResourcesStore.getPollAnswer({ id });
    } catch (err) {
      console.debug('[getPollAnswer] failed', err);
    }
  };

  @action getPollAnswerUsers = async ({ id, sort }) => {
    try {
      this.isPollAnswerLoading += 1;

      const res = await ResourcesStore.getPollAnswerUsers({
        id,
      });
      this.pollAnswerUsersRaw = res;
      this.pollAnswerUsers = this.sortPollAnswersManually({ sort, list: res });
    } catch (err) {
      console.debug('[getPollAnswerUsers] failed', err);
    } finally {
      this.isPollAnswerLoading -= 1;
    }
  };

  @action sortPollAnswersManually = ({ sort, list = null }) => {
    if (!sort) {
      if (!list) this.pollAnswerUsers = this.pollAnswerUsersRaw;
      return this.pollAnswerUsersRaw;
    }
    const [field, order] = sort.split(' ');

    const finalList = (list || this.pollAnswerUsers)?.slice();
    if (finalList) {
      finalList.sort((a, b) => {
        if (order === 'ASC') {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        }

        if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
        if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
        return 0;
      });

      if (!list) this.pollAnswerUsers = finalList;
      return finalList;
    }

    return null;
  };

  @action clearAnswersPage = () => {
    this.pollAnswer = null;
    this.pollAnswerUsersRaw = null;
    this.pollAnswerUsers = null;
  };
}

export default new PollsPageStore();
