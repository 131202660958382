import React, { useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from '@mui/material';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { LoadingButton } from '@mui/lab';

const StripeForm = ({ paymentStore: { setErrorMessage } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formMounted, setFormMounted] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${window.location.pathname}?step=reviewDetails`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setErrorMessage(result.error.message);
      console.debug(result.error.message);
    }

    setIsLoading(false);
  };

  const disable = useMemo(() => isLoading || !stripe || !elements, [
    isLoading,
    stripe,
    elements,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => setFormMounted(true)} />
      {formMounted && (
        <Box display="flex" justifyContent="end" mt="15px">
          <LoadingButton
            variant="contained"
            loading={disable}
            size="large"
            onClick={handleSubmit}
          >
            CONFIRM
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};
export default inject('paymentStore')(observer(StripeForm));
