import React from 'react';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import {
  FormControl,
  Input,
  MenuItem,
  Box,
  Select as MaterialSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { FormError } from '../FormError';

const Label = styled(Box)`
  opacity: 0.7;
  padding-bottom: 6px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

const FieldWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: inline-block;
`;

const styles = () => ({
  selectRoot: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  inputRoot: {
    width: '100%',
    height: '36px',
    borderBottom: `2px solid #55596f`,
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '18px',
    borderRadius: '3px',
    padding: '8px 10px 8px 0',
  },
  formControlRoot: {
    width: '100%',
  },
  icon: {
    color: '#55596f',
  },
});

const Select = ({
  classes,
  meta,
  label,
  selectOptions,
  displayLabelValue,
  input,
  markAsRequired,
  getLabelPath,
  disabled,
  onChangeCallback,
  previewComponent: PreviewComponent,
  inputProps,
  ...props
}) => {
  const handleSelect = e => {
    if (onChangeCallback !== undefined) {
      onChangeCallback(e);
    }

    const { value } = e.target;
    input.onChange(value);
  };

  const getSelectedDisplayValue = () => {
    if (getLabelPath) return getLabelPath(input.value);
    if (displayLabelValue) {
      return selectOptions.find(el => el.value === input.value)?.label;
    }

    return input.value;
  };

  return (
    <Box>
      <Box position="relative" width="100%">
        {label && (
          <Label htmlFor={label}>
            {label}
            {markAsRequired && (
              <Box ml={1} display="inline-block" color="red">
                *
              </Box>
            )}
          </Label>
        )}
        <FieldWrapper>
          <FormControl classes={{ root: classes.formControlRoot }}>
            <MaterialSelect
              classes={{ root: classes.selectRoot, icon: classes.icon }}
              value={input.value !== null ? input.value : ''}
              onChange={handleSelect}
              disableUnderline
              input={
                <Input
                  classes={{
                    root: classes.inputRoot,
                  }}
                  variant="outlined"
                  inputProps={inputProps}
                  id={get(inputProps, 'id')}
                />
              }
              renderValue={() => (
                <Box display="flex" alignItems="center">
                  {PreviewComponent ? (
                    <PreviewComponent>
                      {getLabelPath ? getLabelPath(input.value) : input.value}
                    </PreviewComponent>
                  ) : (
                    <Box>{getSelectedDisplayValue()}</Box>
                  )}
                </Box>
              )}
              disabled={disabled}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              {...props}
            >
              {selectOptions.map(({ label: itemLabel, value }, idx) => (
                <MenuItem key={`menu-item-${idx}`} value={value}>
                  {itemLabel}
                </MenuItem>
              ))}
            </MaterialSelect>
          </FormControl>
        </FieldWrapper>
      </Box>
      {meta && meta.touched && (meta.error || meta.submitError) && (
        <Box mt={1}>
          <FormError meta={meta} />
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(Select);
