import { action, computed, extendObservable } from 'mobx';
import ResourcesStore from '@stores/resourcesStore';
import { FORM_TYPES } from '@constants';
import { CancelToken } from '@api';
import { ASSIGNMENT_TYPE } from '@components/Assignments';
import assignmentsStore from '@stores/assignmentsStore';
import routerStore from '@stores/routerStore';
import routes from '@routes';

const initialState = {
  chatRoom: {},
  defaultFormValues: {},
  isLoading: false,
  isLoadingUsers: false,
  initialLoading: true,
  formType: null,
};

export class ChatRoomStore {
  constructor() {
    extendObservable(this, initialState);
  }

  @computed get isEdit() {
    return this.formType === FORM_TYPES.EDIT;
  }

  @computed get isCreate() {
    return this.formType === FORM_TYPES.CREATE;
  }

  @action setupForm = id => {
    this.__cancelToken = CancelToken.source();
    if (id === 'create') {
      this.formType = FORM_TYPES.CREATE;
    } else {
      this.formType = FORM_TYPES.EDIT;
    }

    if (this.isEdit) {
      this.getHelpResources({ id });
    }

    if (this.isCreate) {
      this.getHelpResources({});
    }
  };

  @action submitForm = async (...props) => {
    if (this.isEdit) {
      return this.updateChatRoom(...props);
    }
    if (this.isCreate) {
      return this.createChatRoom(...props);
    }

    return null;
  };

  @action getChatRoom = async ({ id }) => {
    try {
      this.chatRoom = await ResourcesStore.getChatRoom(
        { id },
        { cancelToken: this.__cancelToken.token },
      );
    } catch (err) {
      console.debug('[getChatRoom] failed', err);
      if (err?.response?.status === 404) {
        routerStore.replace(routes.main.chatRooms);
      }
      throw err;
    }
  };

  @action getHelpResources = async ({ id }) => {
    this.initialLoading = true;
    try {
      const promises = [];
      if (id) {
        promises.push(this.getChatRoom({ id }));
      }

      if (!id) {
        promises.push(
          assignmentsStore.getResources({
            types: { [ASSIGNMENT_TYPE.EVENT]: true },
          }),
        );
      }

      await Promise.all(promises);

      await this.setupDefaultFormValues();
    } catch (err) {
      console.debug('[getHelpResources] failed', err);
    } finally {
      this.initialLoading = false;
    }
  };

  @action setupDefaultFormValues = async () => {
    if (this.isCreate) {
      this.defaultFormValues = {
        chat_type: 'group',
        available_for_all: false,
        chatable_type: 'Event',
      };
    }

    if (this.isEdit) {
      this.defaultFormValues = { ...this.chatRoom };
    }
  };

  @action createChatRoom = async ({ values, onSuccess, onError }) => {
    this.isLoading = true;
    try {
      const preparedValues = {
        ...values,
        user_ids: !values.available_for_all ? values.user_ids : [],
        chatable_id: values.event_id.value,
      };

      const res = await ResourcesStore.createChatRoom({
        payload: preparedValues,
        onError,
      });

      onSuccess?.(res);
      return null;
    } catch (err) {
      console.debug('[createChatRoom] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateChatRoom = async ({ values, onSuccess, ...props }) => {
    this.isLoading = true;
    try {
      const preparedValues = {
        ...values,
        user_ids: !values.available_for_all ? values.user_ids : [],
      };

      const result = await ResourcesStore.updateChatRoom({
        payload: preparedValues,
        ...props,
      });

      this.chatRoom = result;

      onSuccess?.(result);

      await this.setupDefaultFormValues();

      return null;
    } catch (err) {
      console.debug('[updateChatRoom] failed', err);
      return err.response?.data?.errors;
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteChatRoom = async props => {
    this.isLoading = true;
    try {
      await ResourcesStore.deleteChatRoom(props);
    } catch (err) {
      console.debug('[deleteChatRoom] failed', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action getUserSelectOptions = async ({ search, id }) => {
    this.isLoadingUsers = true;
    try {
      const users = await ResourcesStore.getUserSelectOptions({
        search,
        id,
      });

      return users;
    } catch (err) {
      console.debug('[getUserSelectOptions] failed', err);
      return [];
    } finally {
      this.isLoadingUsers = false;
    }
  };

  @action clearStore = () => {
    this.__cancelToken?.cancel();
    Object.entries(initialState).forEach(entry => {
      const [key, val] = entry;
      this[key] = val;
    });
  };
}

export default new ChatRoomStore();
