import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { LoaderSVG } from '@assets/svgs/loader';

const LoaderWrapper = styled('div')`
  width: ${props => props.width || '100%'};
  text-align: center;
  margin: 0 auto;
`;

export const Loader = ({ stroke, width, isLoading, ...props }) => {
  const theme = useTheme();

  return (
    <LoaderWrapper width={width}>
      <LoaderSVG stroke={stroke || theme.colors.primary} {...props} />
    </LoaderWrapper>
  );
};
