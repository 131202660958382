import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from '@mui/material';
import Image from '@components/Image';
import { Button, BUTTON_TYPES } from '@components/Buttons/Button';
import { styled } from '@mui/material/styles';
import SignInForm from '@pages/signIn/components/SignInForm';
import routes from '@routes';
import { isJackEthos } from '@styles/_theme';
import { MOBILE_BREAKPOINT } from '@styles/breakpoints';
import { Logo } from '@components/Logo';
import CplusBg from '@assets/images/Cplus_login_bg.jpeg';
import CplusLogo from '@assets/images/cplus-logo-large-white.png';
import { ROLES } from '@app/authManager';

export const PageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Card = styled(Box)`
  width: 100%;
  padding: 25px 20px;
  background: ${({ theme }) => theme.colors.box};
  border-radius: 4px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 500px;
  }
`;

export const CardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CplusContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: ${({ customBg }) => `url(${customBg || CplusBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: unset;
    justify-content: center;
  }
`;

export const BigCplusLogo = styled(Image)`
  width: 350px;
`;

export const JackEthosContainer = styled(Box)`
  background-color: black;
  height: 100vh;
  width: 100%;
`;

const SignInPage = ({
  authStore: { isAuthenticated, signIn, isLoading },
  history,
}) => {
  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/');
    }
  }, [history]);

  const handleOnSignIn = async val => {
    return signIn(val, ({ _role: role }) => {
      if (role === ROLES.ANALYST) {
        history.push(routes.main.analytics(''));
        return;
      }
      if (role === ROLES.EVENT_MANAGER) {
        history.push(routes.main.chatMessages);
        return;
      }
      if (role === ROLES.MODERATOR) {
        history.push(routes.main.chatMessages);
        return;
      }
      history.push(routes.main.dashboard);
    });
  };

  // CON-1367 - requested to change only on Cplus
  if (!isJackEthos) {
    return (
      <PageContent>
        <CplusContainer pt="15%">
          <Box mb="50px">
            <BigCplusLogo src={CplusLogo} />
          </Box>
          <SignInForm
            isLoading={isLoading}
            onSubmit={handleOnSignIn}
            isCplusLogin
          />
          <Box>
            <Button
              label="Forgot password"
              type={BUTTON_TYPES.TEXT_PRIMARY}
              onClick={() => history.push(routes.auth.resetPassword)}
              size="l"
              buttonType="submit"
              btnPadding="0 15px"
              borderRadius="20px"
            />
          </Box>
        </CplusContainer>
      </PageContent>
    );
  }
  return (
    <JackEthosContainer>
      <PageContent>
        <Box mb="12px">
          <Logo />
        </Box>
        <Card>
          <CardContent>
            <SignInForm isLoading={isLoading} onSubmit={handleOnSignIn} />
            <Box>
              <Button
                label="Forgot password"
                type={BUTTON_TYPES.TEXT_PRIMARY}
                onClick={() => history.push(routes.auth.resetPassword)}
                size="l"
                buttonType="submit"
                btnPadding="0 15px"
                borderRadius="20px"
              />
            </Box>
          </CardContent>
        </Card>
      </PageContent>
    </JackEthosContainer>
  );
};

export default inject('authStore')(observer(SignInPage));
