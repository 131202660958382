import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Avatar,
  Badge,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { GetApp, Search } from '@mui/icons-material';
import { AvatarCharWrapper } from '@pages/analytics/components/Sidebar/Audience/style';
import Filters from '@pages/analytics/components/Sidebar/Audience/Filters';
import { Content } from '@pages/analytics/components/Sidebar/style';
import * as Styled from '@pages/analytics/components/Sidebar/Overview/style';
import { CardTitle } from '@components/Card';

const Audience = ({
  analyticsStore: {
    dataKey,
    eventAttendees,
    selectAttendee,
    generateCSV,
    csvLoadingProgress,
  },
}) => {
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const filtredByName = useMemo(() => {
    const usrs = eventAttendees[dataKey]?.list || [];
    if (!usrs.filter || usrs.length === 0) return usrs;
    return usrs.filter(({ user_name }) =>
      user_name.toLowerCase().includes(name.toLowerCase()),
    );
  }, [name, eventAttendees[dataKey]?.list]);
  const isLoadingCSV = useMemo(() => csvLoadingProgress !== 100, [
    csvLoadingProgress,
  ]);
  return (
    <>
      <Content>
        <TextField
          variant="outlined"
          label="Search"
          fullWidth
          color="lightDark"
          margin="dense"
          sx={{ mb: 0 }}
          onChange={({ target: { value } }) => setName(value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          <Filters
            attendees={eventAttendees[dataKey]}
            users={filtredByName}
            setUsers={setUsers}
          />
        </Box>
        <Typography sx={{ margin: '25px 0 0 5px', fontWeight: 600 }}>
          {users?.length} Attendees
        </Typography>
        <Table>
          <TableBody>
            {users?.length > 0
              ? users.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => selectAttendee(row.user_id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell width="10%" sx={{ paddingLeft: '10px' }}>
                      <Avatar
                        src={row.user_avatar_cropped || null}
                        alt="avatar"
                        sx={{ width: '35px', height: '35px' }}
                      >
                        {!row.user_avatar_cropped ? (
                          <AvatarCharWrapper>
                            {row.user_name.charAt(0)}
                          </AvatarCharWrapper>
                        ) : null}
                      </Avatar>
                    </TableCell>
                    <TableCell width="80%" sx={{ paddingLeft: 0 }}>
                      <Typography variant="h6">{row.user_name}</Typography>
                      <Typography variant="caption">{row.user_job}</Typography>
                    </TableCell>
                    <TableCell width="10%">
                      <Badge
                        badgeContent={row.actions_count}
                        color="primary"
                        sx={{ right: '5px' }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </Content>
      <Styled.ButtonBox>
        <CardTitle color="white">Download data</CardTitle>
        <Button
          endIcon={
            <Styled.TranslateAnimation animate={isLoadingCSV}>
              <GetApp />
            </Styled.TranslateAnimation>
          }
          onClick={isLoadingCSV ? undefined : generateCSV}
          variant="contained"
          fullWidth
          sx={{ fontWeight: 'fontWeightBold', justifyContent: 'space-between' }}
        >
          <span>
            {isLoadingCSV ? `${csvLoadingProgress}%` : 'Export attendees'}
          </span>
        </Button>
      </Styled.ButtonBox>
    </>
  );
};

export default inject('analyticsStore')(observer(Audience));
